import { ActionData } from '../../../../types/actionData';
import ActionMenu from '../../../../components/common/actionMenu';
import { useAnchorElement } from '../../../../hooks/useAnchorElement';
import { http } from '../../../../libs/axios';
import { toast } from 'react-toastify';
import LoopSharpIcon from '@mui/icons-material/LoopSharp';
import CheckIcon from '@mui/icons-material/Check';
import { ILeadItemExtended } from '../../../properties/stepper/steps/leads';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import SendFilesPopup from '../../../properties/stepper/steps/leads/sendFilesPopup';

const LeadActionsMenu = ({
  row,
  mutateForStatus,
  withSendFiles,
}: {
  row: ILeadItemExtended;
  mutateForStatus?: any;
  withSendFiles?: boolean;
}) => {
  const [statusChanging, toggleStatusChanging] = useState(false);
  const [searchCriteriaGenerating, toggleSearchCriteriaGenerating] =
    useState(false);
  const [filesPopupOpen, setFilesPopupOpen] = useState(false);
  const { t } = useTranslation('common');

  const changeLeadStatus = async () => {
    toggleStatusChanging(true);
    const changeLeadPromise = new Promise((resolve, reject) => {
      http
        .patch(`/prospects/${row.id}`, { status: '2' })
        .then((json) => resolve(json))
        .then(() => {
          if (mutateForStatus) {
            mutateForStatus();
          }
        })
        .catch(() => reject())
        .finally(() => toggleStatusChanging(false));
    });

    toast.promise(
      changeLeadPromise,
      {
        pending: t('common:Lead_status_is_changing'),
        success: t('common:Lead_status_successfully_changed'),
        error: t(
          'common:There_is_been_an_server_error_Lead_status_not_changed'
        ),
      },
      {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        progress: undefined,
      }
    );
  };

  const generateSearchCriteria = () => {
    toggleSearchCriteriaGenerating(true);
    const generateSearchCriteriaPromise = new Promise((resolve, reject) => {
      http
        .post(`/contacts/${row.parentId}/search_criteria`, {
          property: row.property.id,
        })
        .then((json) => resolve(json))
        .catch(() => reject())
        .finally(() => toggleSearchCriteriaGenerating(false));
    });

    toast.promise(
      generateSearchCriteriaPromise,
      {
        pending: t('common:Search_criteria_is_generating'),
        success: t('common:Search_criteria_was_successfully_generated'),
        error: t(
          'common:There_is_been_an_server_error_Search_criteria_was_not_generated'
        ),
      },
      {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        progress: undefined,
      }
    );
  };

  const menuActionData: ActionData[] = [];

  if (!row.promotion) {
    menuActionData.push({
      label: 'common:Generate_Search_Criteria',
      icon: LoopSharpIcon,
      onClick: generateSearchCriteria,
      disabled: searchCriteriaGenerating,
    });
  }

  if (withSendFiles) {
    menuActionData.push({
      label: 'propertiesPage:Send_brochure',
      icon: AttachEmailOutlinedIcon,
      onClick: () => setFilesPopupOpen(true),
    });
  }

  if (row.status.keyname === 'in progress') {
    menuActionData.push({
      label: 'common:Mark_as_processed',
      icon: CheckIcon,
      onClick: changeLeadStatus,
      disabled: statusChanging,
    });
  }

  const anchor = useAnchorElement();

  return (
    <>
      <ActionMenu
        {...anchor}
        menuAction={menuActionData}
        transPage={'brochureEditor'}
      />

      {filesPopupOpen ? (
        <SendFilesPopup
          id={row.property.id.toString()}
          contact={row.contact}
          leadId={row.id}
          openModal={filesPopupOpen}
          setOpenModal={setFilesPopupOpen}
        />
      ) : null}
    </>
  );
};

export default LeadActionsMenu;
