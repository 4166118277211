import { FC } from 'react';

import { Box } from '@mui/material';

import {
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import DateRangeField from '../../../../../components/form/dateRangeField';
import SelectFormField from '../../../../../components/form/selectFormField';
import InputLayout from '../../../../../components/form/components/inputLayout';
import { useTranslation } from 'react-i18next';

type Props = {
  mainFilterState: any;
  getValues?: any;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  control: any;
};

const PropertiesHistoryFilter: FC<Props> = ({
  register,
  watch,
  control,
  mainFilterState,
  getValues,
}) => {
  const { t } = useTranslation('propertiesPage');
  return (
    <form>
      <Box className="grid grid-cols-3 gap-8 mb-4">
        <InputLayout label={t('Date')}>
          {/* <Typography color="rgb(0 0 0 / 60%)">Date</Typography> */}
          <DateRangeField
            nameFrom="historicDateFrom"
            nameTo="historicDateTo"
            labelFrom={t('From')}
            labelTo={t('To')}
            control={control}
          />
        </InputLayout>
        <SelectFormField
          label={t('Action_type')}
          className="h-14"
          options={
            mainFilterState['history']?.find(
              (elm: any) => elm.label === 'historicAction'
            )?.options || []
          }
          //multiple
          defaultValue={getValues('historicAction')}
          value={watch('historicAction')}
          {...register('historicAction')}
        />
        <SelectFormField
          label={t('Done_by')}
          className="h-14"
          options={
            mainFilterState['history']?.find(
              (elm: any) => elm.label === 'doneBy'
            )?.options || []
          }
          //multiple
          defaultValue={getValues('doneBy')}
          value={watch('doneBy')}
          {...register('doneBy')}
        />
      </Box>
    </form>
  );
};

export default PropertiesHistoryFilter;
