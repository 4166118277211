import { useTranslation } from 'react-i18next';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ActionBtnDataGrid from '../../../features/icons/actionBtnDataGrid';
import { GridValidRowModel } from '@mui/x-data-grid';
import GoBackTag from '../../../features/tags/goBackTag';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import { useState } from 'react';
import { captureException } from '@sentry/react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { http } from '../../../libs/axios';
import { Box, Button, Typography } from '@mui/material';
import WithModal, {
  InjectedModalState,
} from '../../../components/modal/widthModal';
import Modal from '../../../components/modal';
import UserForm from '../addNewUserPage/userForm';
import { confirmDelete } from '../../properties/utils';
import { useNotification } from '../../../hooks/useNotification';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SpinnerLoading from '../../../features/spinner/spinnerLoading';
function SingleUserHeader({ data, ...rest }: { data: GridValidRowModel }) {
  const { t } = useTranslation('usersPage');
  const { addNotification } = useNotification();
  const { userId } = useParams();
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();
  const modalState = rest as InjectedModalState;
  const {
    firstname,
    lastname,
    email,
    phone,
    isSuperAdmin,
    roles,
    users,
    firstSuperAdmin,
    enabled,
    isVerified,
    subscription,
  } = data;
  const isUserAssistant = roles.includes('ROLE_ASSISTANT');

  const isAdmin = roles.includes('ROLE_ADMIN');

  const storedAuthUser = localStorage.getItem('@auth');
  const currentUser = storedAuthUser ? JSON.parse(storedAuthUser) : null;

  const { state } = currentUser;

  const isCurrentUserSuperAdmin = state.user.isSuperAdmin;

  const dataToEdit = { firstname, lastname, email, phone, users };

  const apiKey = isUserAssistant
    ? `/users/assistants/${userId}`
    : `/users/${userId}`;

  const deleteSingleUserHandler = async () => {
    try {
      setIsDeleteLoading(true);
      await http.delete(`/users/${userId}`);
      toast.success(t('alerts:user_deleted') as string);
      navigation('/users');
    } catch (e: any) {
      captureException(e);
      if (e.response.status === 466) {
        toast.warning(e.response.data.message as string);
        navigation('/users');
      } else {
        toast.error(t('alerts:user_not_deleted') as string);
      }
    }
  };

  const toggleSuperAdminRole = async () => {
    setLoading(true);
    try {
      await http.patch(`/users/${userId}/switch-super-admin`, {});
      addNotification(
        t('Super_Admin_role_has_been_successfully_updated'),
        'success'
      );
      currentUser.state.user.isSuperAdmin = false;
      currentUser.state.user.firstSuperAdmin = false;
      localStorage.setItem('@auth', JSON.stringify(currentUser));
    } catch (e) {
      console.error('Error toggling Super Admin role:', e);
      addNotification(
        t('Failed_to_update_Super_Admin_role_Please_try_again_later'),
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  if (!data) return null;

  return (
    <Box className="w-full pb-4">
      <GoBackTag link="/users" />
      <div className="flex flex-col sm:flex-row justify-between">
        <Box className=" flex flex-col justify-center">
          <Typography variant="h3" className="mb-2 w-full capitalize">{`${
            data?.firstname
          } ${data?.lastname?.toUpperCase()}`}</Typography>
        </Box>
        <Box className="flex flex-col xl:flex-row gap-2 xl:gap-0 items-center sm:items-end py-4">
          {!firstSuperAdmin &&
            enabled &&
            isCurrentUserSuperAdmin &&
            isVerified &&
            subscription.level == '1' &&
            isAdmin && (
              <Button
                className="xl:!mr-2"
                onClick={toggleSuperAdminRole}
                disabled={loading}
                disableElevation
                variant="outlined"
                sx={
                  loading
                    ? {
                        width: '23%',
                        height: '75%',
                        px: 1.5,
                        py: 1,
                        borderRadius: 1,
                        background: '#dbd7d7',
                      }
                    : {}
                }
              >
                {loading ? (
                  <Box>
                    <SpinnerLoading />
                  </Box>
                ) : (
                  <Box className="flex justify-center items-center" gap="10px">
                    <div>
                      <SupervisorAccountIcon className="mr-2" />
                    </div>
                    <div className="text-sm font-medium leading-none">
                      {t('Grant_Super_Admin_Access')}
                    </div>
                  </Box>
                )}
              </Button>
            )}

          <Modal
            width={{
              md: 500,
            }}
            title={isUserAssistant ? t('edit_assistant') : t('edit_user')}
            buttonProps={{
              title: t('Edit_info'),
              variant: 'outlined',
              className: 'text-[#1D1D1F]',
              startIcon: <ActionBtnDataGrid />,
            }}
            {...modalState}
          >
            <UserForm
              type="edit"
              role={isUserAssistant ? 'assistant' : 'user'}
              closeModal={modalState.closeModal}
              dataToEdit={dataToEdit}
              apiKey={apiKey}
            />
          </Modal>
          {!isSuperAdmin && (
            <button
              onClick={() => confirmDelete(deleteSingleUserHandler)}
              className="px-4 h-9 rounded border border-gray-300 xl:ml-2 cursor-pointer flex items-center remove_btn"
            >
              {isDeleteLoading ? (
                <ScatterPlotIcon
                  fontSize="large"
                  className="animate-spin"
                  sx={{
                    fill: '#6E6E72',
                    width: 20,
                    height: 20,
                  }}
                />
              ) : (
                <DeleteOutlineIcon fontSize="small" sx={{ color: '#F50057' }} />
              )}
              <Box component="span" className="ml-2 text-sm text-[#F50057]">
                {t('Remove')}
              </Box>
            </button>
          )}
        </Box>
      </div>
    </Box>
  );
}

export default WithModal(SingleUserHeader);
