import { createElement } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { ActionData } from '../../types/actionData';
import { useAnchorElement } from '../../hooks/useAnchorElement';
import classNames from 'classnames';

interface Props {
  menuAction: ActionData[];
  transPage: string;
  action?: string;
  isList?: boolean;
  propertiesCards?: boolean;
  networksCards?: boolean;
  class?: string;
}

export default function ActionMenu({
  menuAction,
  transPage,
  action = '',
  anchorEl,
  handleClick,
  open,
  handleClose,
  isList,
  propertiesCards,
  networksCards,
}: Props & ReturnType<typeof useAnchorElement>) {
  const { t } = useTranslation(transPage);

  const listStyle = () => {
    if (isList) {
      return {
        height: '48px !important',
        borderRadius: '0 !important',
        width: '100% !important',
        backgroundColor: '#FBFBFD',
        color: '#3446B9 !important',
      };
    }

    if (propertiesCards)
      return {
        height: 'auto',
        maxWidth: '5px !important',
        paddingTop: '2px !important',
        '&.MuiButtonBase-root:hover': {
          bgcolor: 'transparent',
        },
      };

    if (networksCards)
      return {
        maxWidth: '5px !important',
        borderRadius: '0 !important',
        backgroundColor: '#fff',
      };

    return null;
  };

  return (
    <div>
      {action == 'propertyDetails' ? (
        <Button
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {t('common:actions')}
        </Button>
      ) : (
        <Button
          disableRipple={propertiesCards}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{ color: '#808080', borderRadius: '50%' }}
          sx={listStyle}
          classes={{ root: '!min-w-[29px]' }}
        >
          <MoreHorizIcon
            sx={
              isList
                ? {
                    width: '14px',
                    height: '14px',
                  }
                : null
            }
          />
        </Button>
      )}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          style: {
            background: '#FFFFFF',
            width: '273px',
            marginTop: '5px',
          },
        }}
      >
        {menuAction?.map((elem: ActionData, index: number) => {
          const content = (
            <div className="px-4 py-2 flex gap-2 items-center">
              <div>
                {elem.icon &&
                  createElement(elem.icon, {
                    // @ts-ignore
                    width: '16',
                    height: '16',
                    classes: {
                      root: '!block !text-[1rem]',
                    },
                  })}
              </div>
              <span className="text-sm font-normal">{t(`${elem.label}`)}</span>
            </div>
          );
          return (
            elem?.hide != action && (
              <MenuItem
                className={classNames(`${elem.class}`, {
                  'opacity-50 pointer-events-none': elem.disabled,
                })}
                key={index}
                onClick={() => {
                  if (elem?.stopClose) return;
                  handleClose?.();
                }}
                classes={{ root: '!p-0 !block' }}
              >
                {elem.onClick ? (
                  <div onClick={elem.onClick}>{content}</div>
                ) : elem.link ? (
                  <Link to={elem.link} className="block">
                    {content}
                  </Link>
                ) : null}
              </MenuItem>
            )
          );
        })}
      </Menu>
    </div>
  );
}
