import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useBrochureTemplateListColumns } from './lib/hooks/useBrochureTemplateListColumns';
import useListingCollection from '../../hooks/useListingCollection';
import { PreviewSubjectTypes, TemplateApi } from '../../types/brochureEditor';
import Layout from '../../layout/layout';
import TableGridX from '../../components/tableGridX';
import TitleHeader from '../../components/common/titleHeader';

const skeletonItems: TemplateApi[] = Array(6)
  .fill('')
  .map((_, index) => ({
    id: index,
    name: '',
    usedFor: String(PreviewSubjectTypes.PROPERTY),
    brand: '',
    pages: [],
    layout: [],
    properties: [],
    promotions: [],
    departments: [],
    createdAt: '',
    updatedAt: '',
    createdBy: null,
  }));

const ListTemplates = () => {
  const { t } = useTranslation('brochureEditor');

  const { itemsToDisplay, setPage, itemsPerPage, page, loadingItemsToDisplay } =
    useListingCollection<TemplateApi>({
      basedUrl: `/patterns`,
      isActiveProperties: true,
      currentPage: 'brochureTemplates',
    });

  const columns = useBrochureTemplateListColumns({
    isLoading: loadingItemsToDisplay,
  });

  const totalCount = itemsToDisplay?.['hydra:totalItems'] || 0;
  const results = itemsToDisplay?.['hydra:member'] || [];
  const rows = loadingItemsToDisplay ? skeletonItems : results;
  const isHigherThen768 = useMediaQuery('(min-width:768px)');

  return (
    <Layout>
      <TitleHeader
        title={''}
        wrapperClassName={'h-full flex flex-col items-start'}
        titleElement={
          <div>
            <Typography className="!text-2xl md:!text-3xl">
              {t('brochure_templates')}
            </Typography>
            {itemsToDisplay && itemsToDisplay['hydra:totalItems'] ? (
              <Typography variant="subtitle1" className="text-sm text-gray-400">
                {totalCount
                  ? `${totalCount} ${t('template', { count: totalCount })}`
                  : ''}
              </Typography>
            ) : null}
          </div>
        }
        goBackLink="/settings"
        rightElement={
          isHigherThen768 && (
            <Link to={'/brochure-templates/new'}>
              <Button
                variant="contained"
                sx={{ background: 'background.default' }}
                startIcon={<AddIcon fontSize="medium" />}
                disableElevation
              >
                {t('new_template')}
              </Button>
            </Link>
          )
        }
      >
        <div className="h-full w-full">
          <TableGridX
            key={String(loadingItemsToDisplay)}
            disableSelectionOnClick={true}
            disableColumnSelector={true}
            rows={rows}
            // @ts-ignore
            columns={columns}
            autoPageSize
            pageSize={itemsPerPage}
            page={page - 1}
            paginationMode="server"
            rowCount={totalCount}
            onPageChange={(page: number) => {
              setPage(page + 1);
            }}
            rowHeight={64}
          />
        </div>
      </TitleHeader>
    </Layout>
  );
};

export default ListTemplates;
