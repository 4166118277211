import { Button } from '@mui/material';
import LoopSharpIcon from '@mui/icons-material/LoopSharp';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TitleWithButton = ({
  totalItems,
  onClick,
  disabled,
}: {
  totalItems?: number;
  onClick: () => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation('propertiesPage');

  return (
    <div className="self-stretch justify-between items-end inline-flex">
      <div className="text-zinc-900 text-2xl font-normal leading-7">
        {t('usersPage:users')} {totalItems ? `(${totalItems})` : ''}
      </div>
      <Button
        className="cursor-pointer gap-2"
        type={'submit'}
        variant={'outlined'}
        disabled={disabled}
        onClick={onClick}
        sx={{
          height: '50px',
          border: '1px solid #3446B9 !important',
        }}
      >
        <LoopSharpIcon
          sx={{
            width: '22px',
            height: '22px',
          }}
          fontSize={'small'}
        />
        <span> {t('Regenerate_matching_list')} </span>
      </Button>
    </div>
  );
};

export default TitleWithButton;
