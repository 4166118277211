import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Box, Fade, Modal } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TableGridX from '../../../components/tableGridX';
import CloseIcon from '@mui/icons-material/Close';
import { useOffersListColumns } from '../utils/useOffersListColumns';
import useListingCollection from '../../../hooks/useListingCollection';
import { IDashboardOfferItem } from '../../../types/user';
import GridTableView from '../../../components/cardsTablesList';
import GridView from '../../../components/cardsTablesList/cards/list';

const generateRandomId = (): number => {
  return Math.floor(1000 + Math.random() * 9000);
};

interface Props {
  title: string;
  openModal: boolean;
  onClose: () => void;
}

const skeletonItems: IDashboardOfferItem[] = Array(6)
  .fill('')
  .map((_, index) => ({
    currency: '',
    dealType: '',
    id: index,
    media: null,
    name: '',
    offerDate: '',
    offerType: '',
    price: 0,
    reference: '',
    source: '',
    title: null,
  }));

const OffersPopup = (props: Props) => {
  const { openModal, onClose, title } = props;
  const { t } = useTranslation('common');

  const { itemsToDisplay, setPage, itemsPerPage, page, loadingItemsToDisplay } =
    useListingCollection<IDashboardOfferItem>({
      basedUrl: 'pipeline_comments/my-offers',
      currentPage: 'offers',
      initialItemsPerPage: 8,
    });

  const columns = useOffersListColumns({
    isLoading: loadingItemsToDisplay,
  });

  const totalCount = itemsToDisplay?.['hydra:totalItems'] || 0;
  const results = itemsToDisplay?.['hydra:member'] || [];
  const rows = loadingItemsToDisplay
    ? skeletonItems
    : results.map((el) => ({
        ...el,
        id: generateRandomId(),
        propertyId: el.id,
      }));

  return (
    <Modal
      open={openModal}
      onClose={onClose}
      classes={{ root: 'grid place-items-center' }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Fade in={openModal}>
          <Box
            sx={{
              maxHeight: '87vh',
              overflowY: 'auto',
              overflowX: 'hidden',
              width: {
                xs: '85%',
              },
              borderRadius: 2,
              bgcolor: '#FBFBFD',
              boxShadow: 24,
              '&::-webkit-scrollbar': {
                width: '10px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#BBC0C4',
                borderRadius: 8,
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#888',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1',
              },
            }}
          >
            <div
              className={'p-8 flex flex-col h-[87vh] min-h-[500px] relative'}
            >
              <div className="flex justify-between !items-start w-full h-[7%]">
                <p className={'mb-8 text-lg md:text-2xl'}>{t(title)} </p>
              </div>
              <button
                onClick={onClose}
                className={
                  'absolute right-8 top-8 opacity-60 transition-opacity duration-300 hover:opacity-80'
                }
              >
                <CloseIcon />
              </button>
              <div className={'flex-grow min-h-[500px]'}>
                <GridTableView
                  isGridView={false}
                  gridElement={
                    <GridView
                      offerItems={results}
                      isLoading={loadingItemsToDisplay}
                      isOffers
                      page={page - 1}
                      rowCount={totalCount}
                      handleChangePage={(
                        event: React.MouseEvent | null,
                        page: number
                      ) => {
                        setPage(page + 1);
                      }}
                      rowsPerPage={itemsPerPage}
                      handleChangeRowsPerPage={(page: number) => {
                        setPage(page + 1);
                      }}
                    />
                  }
                  tableElement={
                    <Box className="w-full h-full flex-grow">
                      <TableGridX
                        sx={{
                          '&': {
                            minHeight: '500px',
                          },
                          '& .MuiDataGrid-virtualScroller': {
                            overflow: 'hidden',
                          },
                          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                            width: 10,
                          },
                          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb':
                            {
                              backgroundColor: '#BBC0C4',
                              borderRadius: 8,
                            },
                          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover':
                            {
                              backgroundColor: '#888',
                            },
                          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track':
                            {
                              backgroundColor: '#f1f1f1',
                            },
                        }}
                        key={'offers'}
                        disableSelectionOnClick={true}
                        disableColumnSelector={true}
                        rows={rows}
                        // @ts-ignore
                        columns={columns}
                        autoPageSize
                        pageSize={itemsPerPage}
                        page={page - 1}
                        paginationMode="server"
                        rowHeight={74}
                        rowCount={totalCount}
                        onPageChange={(page: number) => {
                          setPage(page + 1);
                        }}
                      />
                    </Box>
                  }
                />
              </div>
            </div>
          </Box>
        </Fade>
      </ClickAwayListener>
    </Modal>
  );
};

export default OffersPopup;
