import React, { useState } from 'react';
import DraggableList from './draggable/list';
import { Box, Button, Fade, Modal as MuiModal } from '@mui/material';
import SpinnerLoading from '../../../../features/spinner/spinnerLoading';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';

export interface SectionItem {
  name: string;
  wideWidget?: boolean;
}

interface Props {
  isOpen: boolean;
  loading: boolean;
  items: {
    active_widgets: {
      name: string;
    }[];
    hidden_widgets: {
      name: string;
    }[];
  };
  onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onApplyClick: (updatedItems: Record<string, SectionItem[]>) => void;
}

const CustomizeDashboardPopup = (props: Props) => {
  const { isOpen } = props;
  const { t } = useTranslation('common');
  const draggableTop = props.items['active_widgets'];
  const draggableBottom = props.items['hidden_widgets'];
  const [items, setItems] = useState<Record<string, SectionItem[]>>({
    'draggable-top': draggableTop,
    'draggable-bottom': draggableBottom,
  });

  const onPopupClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (props.onClose) {
      props.onClose(e);
    }
  };

  const onSubmit = () => {
    props.onApplyClick(items);
  };

  const onDragEnd = (
    listId: string,
    itemsList: {
      hidden_widgets: SectionItem[];
      active_widgets: SectionItem[];
    }
  ) => {
    const updatedItems = itemsList;

    setItems(updatedItems);
  };

  return (
    <MuiModal
      open={isOpen}
      onClose={onPopupClose}
      classes={{ root: 'grid place-items-center' }}
    >
      <Fade in={isOpen}>
        <Box
          className={''}
          sx={{
            maxHeight: '95vh',
            overflowY: 'auto',
            width: {
              xs: '90%',
              md: '40%',
            },
            borderRadius: 2,
            bgcolor: 'background.paper',
            boxShadow: 24,
            '&::-webkit-scrollbar': {
              width: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#BBC0C4',
              borderRadius: 8,
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#888',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
            },
          }}
        >
          <div className={'min-w-full md:min-w-[528px] p-8 md:p-14 relative'}>
            <>
              <div className="flex justify-between items-start pb-8 border-b mb-8">
                <div className="w-full flex-col justify-start items-start gap-2 inline-flex">
                  <div className="self-stretch text-zinc-900 text-2xl font-normal leading-7">
                    {t('DashboardOrder')}
                  </div>
                  <div className="text-neutral-500 text-sm font-normal leading-none">
                    {t('DragDropItemsToReorderYourWidgets')}
                  </div>
                </div>
                <button onClick={(e) => onPopupClose(e)} className={'ml-8'}>
                  <CloseIcon fontSize={'small'} />
                </button>
              </div>
              <div className={'min-h-[340px]'}>
                <DraggableList
                  id={'draggable-top'}
                  itemsActive={draggableTop}
                  itemsHidden={draggableBottom}
                  onDragEnd={onDragEnd}
                />
              </div>
              <div className={'flex items-center justify-between w-full pt-8'}>
                <Button
                  variant={'outlined'}
                  onClick={(e) => onPopupClose(e)}
                  sx={{
                    color: '#000',
                  }}
                >
                  <span>{t('Cancel')}</span>
                </Button>
                <Button
                  variant={'outlined'}
                  onClick={onSubmit}
                  className={'flex gap-2'}
                >
                  <CheckIcon
                    fontSize="small"
                    sx={{
                      width: '16px',
                      height: '14px',
                    }}
                  />
                  <div className="font-medium"> {t('Save')} </div>
                </Button>
              </div>
            </>
            {props.loading ? (
              <div
                className={
                  'absolute flex items-center justify-center inset-0 w-full h-full bg-gray-400 bg-opacity-20 pointer-events-none'
                }
              >
                <SpinnerLoading />
              </div>
            ) : null}
          </div>
        </Box>
      </Fade>
    </MuiModal>
  );
};

export default CustomizeDashboardPopup;
