import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

import { IPropertyApi } from '../../types/property';
import PermissionIcon from '../../features/icons/permissionIcon';

function PropertyTags({ property }: { property: IPropertyApi }) {
  const { t } = useTranslation('propertiesPage');

  return (
    <div className="flex pb-2 gap-2">
      <div
        className="flex py-1 px-2 rounded-md items-center"
        style={{ border: '1px solid #3446B9 ' }}
      >
        <PermissionIcon className="mr-2 col-span-1" color="#3446B9" />
        <span className="text-blue leading-5">{property.reference}</span>
      </div>

      {property.agencyReference && (
        <Tooltip title={t('Reference_Agency')} arrow placement="top">
          <div
            className="flex py-1 px-2 rounded-md items-center"
            style={{ border: '1px solid #00bfff' }}
          >
            <PermissionIcon className="mr-2 col-span-1" color="#00bfff" />
            <span className="text-[#00bfff] leading-5">
              {property.agencyReference}
            </span>
          </div>
        </Tooltip>
      )}
    </div>
  );
}

export default PropertyTags;
