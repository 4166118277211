import React, { useState } from 'react';

import { Box, IconButton, Menu, MenuItem } from '@mui/material';

import GlobalNotificationIcon from '../../features/icons/globalNotificationIcon';
import InfoNotificationIcon from '../../features/icons/infoNotificationIcon';
import HelpInterrogationIcon from '../../features/icons/helpInterrogationIcon';
import RedDotIcon from '../../features/icons/redDotIcon';
import { INotificationApi } from '../../types/notification';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type Props = {
  count: number | null;
  activeDot?: INotificationApi;
  toggleDrawer?: (bool: boolean) => void;
  changeActive?: (index: number) => void;
};

export default function NotifBtn({
  toggleDrawer,
  changeActive,
  count,
  activeDot,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation('notificationsPage');
  type notificationsList = {
    id: number;
    label: string;
    icon?: any;
  };

  const notificationLink: notificationsList[] = [
    {
      id: 1,
      label: 'What’s_New',
      icon: <InfoNotificationIcon />,
    },
    { id: 2, label: 'Help_and_Support', icon: <HelpInterrogationIcon /> },
  ];
  return (
    <Box className="w-[100px]">
      <IconButton
        sx={{
          position: 'absolute',
          right: '20px',
          bottom: '16px',
          zIndex: '1300',
          width: '48px',
          height: '48px',
          borderRadius: '50%',
          background: 'white',
          boxShadow: '0px 4px 40px 0px #0000001A',
        }}
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(event) => {
          handleClick(event);
          changeActive && changeActive(1);
        }}
      >
        {activeDot && (
          <RedDotIcon className="absolute top-0 right-0" color="#F50057" />
        )}

        <GlobalNotificationIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        PaperProps={{
          style: {
            transform: 'translateX(-3%) translateY(-53%)',
            background: '#FFFFFF',
            width: '217px',
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {notificationLink.map(({ id, label, icon }) => (
          <MenuItem
            sx={{
              color: 'black',
              marginRight: '0px',
              marginLeft: '0px',
            }}
            className={`${
              id == 1
                ? '!hidden lg:!flex !justify-between'
                : '!flex !justify-between'
            }`}
            key={`${label}-${id}`}
            selected={label === 'Pyxis'}
            onClick={() => {
              if (id === 1) {
                toggleDrawer && toggleDrawer(true);
              }
              handleClose();
            }}
          >
            <div className="flex justify-center items-center">
              <span className="pr-2">{icon}</span>
              <p className=" Inter text-sm leading-6 tracking-1 font-normal">
                {id == 2 ? (
                  <Link to={`mailto:support@reals.swiss`}>{t(label)}</Link>
                ) : (
                  t(label)
                )}{' '}
                {id == 1 && ` (${count})`}
              </p>
            </div>
            {id === 1 && count !== 0 ? (
              <RedDotIcon className="w-[8px] h-[8px]" color="#F50057" />
            ) : (
              ''
            )}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
