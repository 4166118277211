import { FC, useEffect, useState } from 'react';
import useSWR from 'swr';
import flatten from 'lodash/flatten';
import { useTranslation } from 'react-i18next';

import { IConvenienceApi } from '../../types/conveniences';
import { Translations } from '../../types/translations';
import {
  DatafieldsGroupedByTagIdAndStepName,
  FieldValue,
  FieldValuePayload,
} from '../../types/property';

import { useProtectedForm } from '../../hooks/useProtectedForm';

import SelectFormField from '../form/selectFormField';
import StepsFieldset from '../stepper/stepsFieldset';
import { stepperFormId } from '../../const';
import SpinnerLoading from '../../features/spinner/spinnerLoading';
import { StepComponentProps } from '../stepper';
import CustomAttributesDisplay from '../../pages/properties/stepper/customAttributesDisplay';
import useLocale from '../../hooks/useLocale';
import SaveButton from '../stepper/saveButton';

const mapKeysToLabels = {
  environment: 'Environment',
  stores: 'Stores',
  transports: 'Transports',
  children: 'Children and schooling',
  sport: 'Sport',
  leisure: 'Leisure and point of interest',
  rooms: 'Rooms',
  'outside environment': 'Environment',
} as Record<string, string>;

const neighbourhoodKeys = [
  'environment',
  'stores',
  'transports',
  'children',
  'sport',
  'leisure',
  'rooms',
];
const outsideKeys = ['outside environment'];

interface FormState {
  conveniences: Record<string, string[]>;
  dataFields: DatafieldsGroupedByTagIdAndStepName;
  fieldValues: FieldValuePayload[];
}

const ConveniencesReusableStep: FC<
  StepComponentProps<
    {
      conveniences: string[];
      dataFields: DatafieldsGroupedByTagIdAndStepName;
      fieldValues: FieldValuePayload[];
    },
    {
      conveniences: IConvenienceApi[];
      dataFields: DatafieldsGroupedByTagIdAndStepName;
      fieldValues: FieldValue[];
    }
  >
> = ({ defaultValues, onSubmit, serverData, isLoading }) => {
  const { register, watch, setValue, getValues, handleSubmit } =
    useProtectedForm<FormState>(
      {
        defaultValues: {
          ...defaultValues,
          conveniences: {
            environment: [],
            stores: [],
            transports: [],
            children: [],
            sport: [],
            leisure: [],
            outsideEnvironment: [],
          },
        },
      },
      serverData
    );
  const { locale } = useLocale();
  const selection = defaultValues?.conveniences;
  const { data: options } = useSWR<IConvenienceApi[]>(`/conveniences`);
  const [isset, setIsset] = useState(false);
  const { t } = useTranslation('propertiesPage');
  useEffect(() => {
    if (options && selection) {
      for (const option of options) {
        const optionChoiceIds = option.choices.map((c) => String(c.id));
        const selectedOptionChoiceIds = selection.filter((id) =>
          optionChoiceIds.includes(id)
        );
        setValue(`conveniences.${option.keyname}`, selectedOptionChoiceIds);
      }
    }
    if (options) {
      setTimeout(() => setIsset(true), 0);
    }
  }, [options, setValue, selection]);

  const getTranslationName = (
    translations: Translations | [],
    locale: string
  ): string | undefined => {
    if (Array.isArray(translations)) {
      return undefined;
    }
    return translations[locale]?.name;
  };

  const optionsHash = options?.reduce(
    (acc, curr) => ({ ...acc, [curr.keyname]: curr }),
    {}
  ) as Record<string, IConvenienceApi>;

  const internalHandleSubmit = ({ conveniences, fieldValues }: FormState) => {
    onSubmit({
      conveniences: flatten(Object.values(conveniences)),
      fieldValues,
    });
  };

  if (!isset) {
    return <SpinnerLoading />;
  }

  return (
    <>
      <form id={stepperFormId} onSubmit={handleSubmit(internalHandleSubmit)}>
        <div className="grid gap-14">
          <StepsFieldset title={t('Neighbourhood')}>
            {neighbourhoodKeys.map((key) => (
              <SelectFormField
                key={key}
                label={t(mapKeysToLabels[key]?.replaceAll(' ', '_') || '')}
                options={
                  optionsHash?.[key]?.choices.map((c) => ({
                    id: String(c.id),
                    value: getTranslationName(c.translations, locale) || c.name,
                  })) || []
                }
                multiple
                defaultValue={getValues(`conveniences.${key}`)}
                {...register(`conveniences.${key}`)}
              />
            ))}
          </StepsFieldset>
          <StepsFieldset title={t('Outside_conveniences')}>
            {outsideKeys.map((key) => (
              <SelectFormField
                key={key}
                label={t(mapKeysToLabels[key]?.replaceAll(' ', '_') || '')}
                options={
                  optionsHash?.[key]?.choices.map((c) => ({
                    id: String(c.id),
                    value: getTranslationName(c.translations, locale) || c.name,
                  })) || []
                }
                multiple
                defaultValue={getValues(`conveniences.${key}`)}
                {...register(`conveniences.${key}`)}
              />
            ))}
          </StepsFieldset>
        </div>

        {defaultValues?.dataFields?.conveniences && (
          <CustomAttributesDisplay
            groupedDataFields={defaultValues?.dataFields?.conveniences}
            fieldValues={defaultValues.fieldValues}
            register={register}
            watch={watch}
          />
        )}

        <div className="pt-7 pb-10">
          <div className="grid grid-flow-col justify-end gap-6">
            <SaveButton isLoading={isLoading} form={stepperFormId} />
          </div>
        </div>
      </form>
    </>
  );
};

export default ConveniencesReusableStep;
