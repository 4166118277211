import useListingCollection from '../../../hooks/useListingCollection';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import SkeletonTableGrid from '../../../components/skeletonTableGrid';
import useTranslationForTableGridXConfig from '../../../hooks/useTranslationForTableGridX';
import { useTranslation } from 'react-i18next';
import TableGridX from '../../../components/tableGridX';
import {
  portalsListingConfig,
  skeletonPortalsListingConfig,
} from '../../../components/material-ui/tablesConfig/portalsListingConfig';
import { useMemo } from 'react';
import { IPortalSetting } from '../../../types/portals';
import TitleHeader from '../../../components/common/titleHeader';
import useMediaQuery from '@mui/material/useMediaQuery';
const PortalsPage = () => {
  const { t } = useTranslation('usersPage');

  const {
    itemsToDisplay,
    loadingItemsToDisplay,
    // resetFilter,
    setPage,
    itemsPerPage,
    // getFiltredProperties,
    page,
    // setLoadingItemsToDisplay,
  } = useListingCollection({
    basedUrl: `/setting_portals`,
    isActiveProperties: true,
    currentPage: 'portals',
  });
  const isHigherThen768 = useMediaQuery('(min-width:768px)');
  const skeletonPortalsListingColumns = useTranslationForTableGridXConfig([
    skeletonPortalsListingConfig,
    'common',
  ]);

  const portalsListingColumns = useTranslationForTableGridXConfig([
    portalsListingConfig,
    'common',
  ]);
  const rows: IPortalSetting[] | null = useMemo(() => {
    if (itemsToDisplay) {
      return itemsToDisplay['hydra:member'];
    }
    return null;
  }, [itemsToDisplay]);

  return (
    <>
      <TitleHeader
        title={''}
        wrapperClassName={'h-full flex flex-col'}
        titleElement={
          <div>
            <Typography className="!text-2xl md:!text-3xl">
              {t('common:portals')}
            </Typography>
            {itemsToDisplay && itemsToDisplay['hydra:totalItems'] ? (
              <Typography variant="subtitle1" className="text-sm text-gray-400">
                {itemsToDisplay['hydra:totalItems']} {t('common:portals')}
              </Typography>
            ) : null}
          </div>
        }
        goBackLink="/settings"
        rightElement={
          isHigherThen768 && (
            <Link to={'/settings/portals/create'}>
              <Button
                variant="contained"
                disableElevation
                sx={{ background: 'background.default' }}
                startIcon={<AddIcon fontSize="medium" />}
              >
                {t('common:Add_portal')}
              </Button>
            </Link>
          )
        }
      >
        <section className="w-full h-full flex flex-col">
          <div id="specificDivId" />
          <Box className="w-full h-full with-border">
            {loadingItemsToDisplay ? (
              <SkeletonTableGrid
                className="w-full !min-h-[80%]"
                disableSelectionOnClick={true}
                disableColumnSelector={true}
                rows={Array.from({ length: itemsPerPage }).map((el, index) => {
                  return {
                    id: index,
                    status: '',
                    name: '',
                    agency: '',
                    agent: '',
                    type: '',
                    createdAt: '',
                    action: '',
                  };
                })}
                columns={skeletonPortalsListingColumns}
                rowCount={0}
              />
            ) : (
              <TableGridX
                disableSelectionOnClick={true}
                disableColumnSelector={true}
                rows={rows || []}
                columns={portalsListingColumns}
                autoPageSize
                rowHeight={80}
                pageSize={itemsPerPage}
                page={page - 1}
                paginationMode="server"
                rowCount={
                  (itemsToDisplay && itemsToDisplay['hydra:totalItems']) || 0
                }
                onPageChange={(page: number) => {
                  setPage(page + 1);
                }}
                sx={{
                  '& .MuiDataGrid-row': {
                    borderBottom: '1px solid #E6E8EC',
                  },
                }}
              />
            )}
          </Box>
        </section>
      </TitleHeader>
    </>
  );
};

export default PortalsPage;
