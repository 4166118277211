import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LocationIcon from '../../../features/icons/locationIcon';
import PhoneIcon from '../../../features/icons/phoneIcon';

import PropertyDetailsItems from '../singleProperty/propertyInfos/propertyDetailsItems';
import PropertyFeaturesItems from '../singleProperty/propertyInfos/features/items';
import GoogleMapContainer from '../../../components/map/googleMapContainer';

import { renderFullPropertyAdress } from '../../../helpers/utils';
import { useQuery } from '../../../hooks/useQuery';

import ContactForm from './contact-form';
import ContactBrokerPopup from './contact-broker-popup';

import { IPropertyApi, PricingBuy, PricingRent } from '../../../types/property';
import { useIsRent } from '../../../hooks/useIsRent';
import { ContactData, useAnalytics } from './utils';

import LandingHeader from '../../../components/landing/header';
import LandingHeroSlider from '../../../components/landing/heroSlider';
import LandingTitle from '../../../components/landing/title';
import PropertyKeySpecs from './keySpecs';
import LandingH2 from '../../../components/landing/h2';
import { use404 } from '../../../hooks/use404';
import LandingUserEditableHtml from '../../../components/landing/userEditableHtml';
import { useLandingNavLinks } from '../../../components/landing/useLandingNavLinks';
import { useGtm } from '../../../components/landing/useGtm';
import { formatPriceWithCurrency } from '../../index/utils/useOffersListColumns';
import { standardizePhoneNumber } from '../../../helpers/utils/formatters';

const PropertyLandingPage: FC = () => {
  useAnalytics();

  const [brokerPopupOpen, setBrokerPopupOpen] = useState(false);

  const { t } = useTranslation('propertiesPage');

  const query = useQuery();
  const contact = query.get('contact');
  const locale = query.get('lang') || 'en';

  const { reference } = useParams();
  const { data, error } = useSWR<IPropertyApi & { contact?: ContactData }>(
    `${process.env.REACT_APP_API_HOST}/properties/${reference}${
      contact ? `?contact=${contact}` : ''
    }`
  );

  useGtm({
    type: 'property',
    reference,
  });

  const isRent = useIsRent(data);
  const getPricingOption = (name: keyof PricingRent | keyof PricingBuy) => {
    return (
      isRent
        ? pricingRent?.[name as keyof PricingRent]
        : pricingBuy?.[name as keyof PricingBuy]
    ) as number | { value: string } | null | undefined;
  };

  use404(error?.response?.status >= 400);

  const navLinks = useLandingNavLinks();
  const linksArray = Object.values(navLinks).map((el) => ({
    ...el,
    element: el.ref.current,
  }));

  if (!data || !reference) return null;

  const {
    bedrooms,
    bathrooms,
    livingArea,
    useTildeForSurface,
    addFromToLivingArea,
    translations,
    address,
    mainBroker,
    department,
    media,
    pricingRent,
    pricingBuy,
  } = data || {};
  const { latitude, longitude } = address || {};
  const { avatar, firstname, lastname, phone } = mainBroker || {};
  const descriptionHtml = translations[locale]?.description;

  const currencyOption = getPricingOption('currency');
  const currencyValue =
    typeof currencyOption === 'object' && currencyOption !== null
      ? currencyOption.value
      : undefined;

  return (
    <div className="pb-16">
      <LandingHeader
        locale={locale}
        logo={department?.logo}
        email={department?.email}
        phone={department?.phone}
        brochureLink={data.brochure}
        cta={!!data.contact}
        onCtaClick={() => setBrokerPopupOpen(true)}
        navLinks={linksArray}
      />

      <div className="h-16"></div>

      <LandingTitle
        title={translations[locale]?.title || renderFullPropertyAdress(data)}
      >
        <PropertyKeySpecs
          locale={locale}
          bathrooms={bathrooms}
          bedrooms={bedrooms}
          livingArea={livingArea}
          useTildeForSurface={useTildeForSurface}
          addFromToLivingArea={addFromToLivingArea}
        />
      </LandingTitle>

      {!Array.isArray(media) && media.images && media.images.length ? (
        <LandingHeroSlider images={media.images} />
      ) : null}

      <section className="pt-16 w-full grid grid-cols-12 max-w-[93rem] m-auto px-4 xl:px-8 2xl:px-0">
        <div className="col-start-1 col-end-13 xl:col-end-8">
          <div className="max-w-[728px] divide-y">
            {descriptionHtml && (
              <LandingUserEditableHtml
                className="pb-16"
                html={descriptionHtml}
              />
            )}
            <div className="py-16">
              <LandingH2>{t('property_details', { lng: locale })}</LandingH2>
              <PropertyDetailsItems property={data} locale={locale} />
            </div>

            <div
              id="project"
              ref={navLinks.project.ref}
              className="scroll-mt-20 py-16"
            >
              <LandingH2>{t('property_features', { lng: locale })}</LandingH2>

              <div className="pt-8">
                <PropertyFeaturesItems data={data} />
              </div>
            </div>

            {address && (
              <div
                id="location"
                ref={navLinks.location.ref}
                className="scroll-mt-20 grid gap-4 py-16"
              >
                <LandingH2>{t('Location', { lng: locale })}</LandingH2>
                <div className="flex items-center">
                  <LocationIcon
                    className="mr-2 col-span-1 shrink-0"
                    color="#6E6E72"
                  />
                  <p>{renderFullPropertyAdress(data)}</p>
                </div>
                {latitude && longitude && (
                  <GoogleMapContainer
                    coordinates={{
                      lat: latitude,
                      lng: longitude,
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="col-start-1 xl:col-start-9 col-end-13 flex flex-col gap-8">
          <Accordion
            key={'title'}
            className={''}
            defaultExpanded={true}
            sx={{
              boxShadow: 'none',
              margin: '0',
              '&::before': {
                display: 'none',
              },
              '&.Mui-expanded': {
                margin: '0',
              },
              '&.Mui-disabled': {
                backgroundColor: 'inherit',
              },
            }}
          >
            <AccordionSummary
              className={`contact`}
              expandIcon={<KeyboardArrowDownIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                padding: '2rem',
                '&.Mui-expanded': {
                  minHeight: '48px',
                  borderBottom: '1px solid #E6E8EC',
                },
                '& .MuiAccordionSummary-content': {
                  margin: '4px 0',
                  alignItems: 'center',
                },
                '& .MuiAccordionSummary-content.Mui-expanded': {
                  alignItems: 'center',
                },
              }}
            >
              <div className="flex-col justify-start items-start inline-flex">
                <div className="text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
                  {t('price', { lng: locale })}
                </div>
                <div className="text-zinc-900 text-[32px] font-medium font-['Inter'] leading-10">
                  {formatPriceWithCurrency(
                    getPricingOption('displayedPrice') as number,
                    currencyValue
                  )}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails
              style={{
                boxShadow: 'none',
                padding: '1rem 0 0 0',
              }}
            >
              <div className="flex-col justify-center items-start gap-4 inline-flex w-full p-8 pt-4">
                {getPricingOption('displayedPrice') ? (
                  <div className="w-full justify-between items-start inline-flex">
                    <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium font-['Inter'] leading-[16.80px]">
                      {t('Total_price', { lng: locale })} :
                    </div>
                    <div className="grow shrink basis-0 text-right text-zinc-900 text-sm font-medium font-['Inter'] leading-[16.80px]">
                      {formatPriceWithCurrency(
                        getPricingOption('displayedPrice') as number,
                        currencyValue
                      )}
                    </div>
                  </div>
                ) : null}

                {getPricingOption('pricem2') ? (
                  <div className="w-full justify-between items-start inline-flex">
                    <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium font-['Inter'] leading-[16.80px]">
                      {t('Price_per_m2', { lng: locale })} :
                    </div>
                    <div className="grow shrink basis-0 text-right text-zinc-900 text-sm font-medium font-['Inter'] leading-[16.80px]">
                      {formatPriceWithCurrency(
                        getPricingOption('pricem2') as number,
                        currencyValue
                      )}
                    </div>
                  </div>
                ) : null}

                {getPricingOption('monthlyPPECharges') ? (
                  <div className="w-full justify-between items-start inline-flex">
                    <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium font-['Inter'] leading-[16.80px]">
                      {t('Charges_monthly', { lng: locale })} :
                    </div>
                    <div className="grow shrink basis-0 text-right text-zinc-900 text-sm font-medium font-['Inter'] leading-[16.80px]">
                      {formatPriceWithCurrency(
                        getPricingOption('monthlyPPECharges') as number,
                        currencyValue
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </AccordionDetails>
          </Accordion>
          <div className="p-4 xl:p-8 bg-white flex-col justify-start items-start gap-8 inline-flex divide-y">
            {department ? (
              <div className="flex-col justify-start items-start gap-4 flex">
                {department.logo?.x96 ? (
                  <div className="py-4 flex-col justify-start items-center gap-2 flex w-[50%]">
                    <div>
                      <img className="w-fit" src={department.logo.x96} />
                    </div>
                  </div>
                ) : null}
                <div className="flex-col justify-start items-start gap-1 flex">
                  <div className="text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
                    {department.name}
                  </div>
                </div>
              </div>
            ) : null}

            <div className="flex-col justify-start items-start gap-8 flex pt-8">
              <div className="justify-start items-start grid grid-cols-4 gap-8">
                {avatar && (
                  <Avatar
                    src={avatar}
                    alt=""
                    sx={{
                      width: '100%',
                      height: '100%',
                      aspectRatio: '1/1',
                      gridColumn: 'span 1 / span 1',
                    }}
                  />
                )}
                <div className="col-span-3 grow shrink basis-0 flex-col justify-start items-start inline-flex">
                  <div className="text-zinc-900 text-[32px] font-medium font-['Inter'] leading-10">{`${firstname} ${lastname}`}</div>
                  <div className="opacity-80 text-zinc-900 text-base font-normal font-['Inter'] leading-none">
                    {t('Agent', { lng: locale })}
                  </div>
                </div>
              </div>
            </div>
            <div className={'w-full pt-8'}>
              <a
                href={`tel:${phone}`}
                className="p-4 rounded-lg border border-blue-800 justify-center items-center gap-2.5 inline-flex"
              >
                <PhoneIcon color="#3446B9" />
                <div className="text-blue-800 text-base font-normal font-['Inter'] leading-tight">
                  {standardizePhoneNumber(phone)}
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" ref={navLinks.contact.ref} className="scroll-mt-20">
        {data.contact ? (
          <ContactBrokerPopup
            contactData={data.contact}
            reference={reference}
            isModalOpen={brokerPopupOpen}
            handleCloseModal={() => setBrokerPopupOpen(false)}
            setIsModalOpen={setBrokerPopupOpen}
          />
        ) : (
          <div className="w-full max-w-[93rem] m-auto p-8 bg-white flex-col justify-start gap-8 flex px-8">
            <LandingH2>{t('Contact_us', { lng: locale })}</LandingH2>
            <ContactForm reference={reference} contactData={data.contact} />
          </div>
        )}
      </section>
    </div>
  );
};

export default PropertyLandingPage;
