import { useState } from 'react';
import { mutate } from 'swr';
import { IDataFields, ISettings } from '../../../../../../types/settings';
import RepeatableBlock from '../../../../../../components/form/repeateableBlock';
import CustomAttributesForm from './customAttributesForm';
import { http } from '../../../../../../libs/axios';
import { useNotification } from '../../../../../../hooks/useNotification';
import useLocale, {
  Locale,
  langCodes,
} from '../../../../../../hooks/useLocale';
import { copyLanguageOfField } from '../utils';
import { useTranslation } from 'react-i18next';
import { useProtectedForm } from '../../../../../../hooks/useProtectedForm';

const defaultFieldState = {
  id: undefined,
  name: '',
  label: '',
  value: '',
};

const emptyCustomField = {
  isVisible: '',
  type: '',
  departments: [],
  step: '',
  category: '',
  propertyTypes: [],
  translations: {
    en: { ...defaultFieldState, locale: 'en' as Locale },
    fr: { ...defaultFieldState, locale: 'fr' as Locale },
    de: { ...defaultFieldState, locale: 'de' as Locale },
  },
  fieldSelect: {
    options: [],
  },
};

const defaultValues = {
  dataFields: [],
};

const RepeatableCustomAttributesForm = () => {
  const [shouldRemoved, setShouldRemoved] = useState(false);
  const {
    register,
    watch,
    handleSubmit,
    resetField,
    control,
    getValues,
    setValue,
    reset,
  } = useProtectedForm<ISettings>({ defaultValues }, shouldRemoved);
  const { addNotification } = useNotification();
  const { locale: siteLocale } = useLocale();
  const [locale, setLocale] = useState<Locale>(siteLocale);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('propertiesPage');
  const handleData = async (data: ISettings) => {
    if (data && data.dataFields) {
      setIsLoading(true);
      const dataToSend = data.dataFields[0];
      if (dataToSend) {
        dataToSend.isVisible = Boolean(+dataToSend.isVisible);
        dataToSend.type = +dataToSend.type;
        dataToSend.step = +dataToSend.step;
        // @ts-ignore
        if (dataToSend.tag == '') dataToSend.tag = null;
      }

      for (const loc of langCodes) {
        if (loc && loc !== locale) {
          copyLanguageOfField(locale, loc, dataToSend as IDataFields);
        }
      }
      try {
        const res = await http.post('/data_fields', dataToSend);

        if (res.status === 201) {
          addNotification(
            t('common:Custom_attribute_has_been_successfully_created')
          );
          mutate('/data_fields', null);
          setShouldRemoved(true);
        }
        setTimeout(() => {
          reset({ ...defaultValues });
          setShouldRemoved(false);
        }, 500);
      } catch (e) {
        console.error(e);
        addNotification(
          t(
            'common:Failed_to_create_a_custom_attribute_please_check_required_field_before_submitting_the_form'
          ),
          'error'
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const count = watch('dataFields')?.length || 0;
  return (
    <form onSubmit={handleSubmit(handleData)}>
      <div className="w-full pb-2 border-b border-gray-200 justify-between items-center inline-flex">
        <div className="grow shrink basis-0 text-zinc-900 text-base md:text-2xl font-normal leading-7">{`${t(
          'Custom_attributes'
        )} ${count ? `(${count})` : ''}`}</div>
      </div>

      <div className="col-span-2">
        <RepeatableBlock
          control={control}
          name="dataFields"
          addLabel={t('Add_composition')}
          maxItems={1}
          appendObj={emptyCustomField}
          renderField={(field, index, remove) => {
            return (
              <div key={field.id}>
                <CustomAttributesForm
                  shouldRemoved={shouldRemoved}
                  handleSubmit={handleSubmit}
                  control={control}
                  isLoading={isLoading}
                  resetField={resetField}
                  name="dataFields"
                  field={field}
                  setValue={setValue}
                  watch={watch}
                  index={index}
                  remove={remove}
                  getValues={getValues}
                  register={register}
                  locale={locale}
                  setLocale={setLocale}
                />
              </div>
            );
          }}
        />
      </div>
    </form>
  );
};

export default RepeatableCustomAttributesForm;
