import { ReactNode } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { LayoutPart as LayoutPartProps } from '../../../types/brochureEditor';
import { useLayoutPartStyles } from '../lib/hooks/useLayoutPartStyles';
import { formatDate } from '../../../helpers/utils';
import useLocale from '../../../hooks/useLocale';

const areas = ['a', 'b', 'c'];
const gridTemplateAreas = `'a b c'`;

const justifyContent = ['start', 'center', 'end'];

const LayoutPart = ({
  options,
  withStyles,
  isHeader,
  displayGeneratedDateIfItIsEnabled = false,
  renderCell,
}: {
  options: LayoutPartProps;
  withStyles?: boolean;
  isHeader?: boolean;
  displayGeneratedDateIfItIsEnabled?: boolean;
  renderCell?: ({
    area,
    index,
    isHeader,
    isFooter,
    justifyContent,
  }: {
    area: string;
    index: number;
    isHeader?: boolean;
    isFooter?: boolean;
    justifyContent?: string;
  }) => ReactNode;
}) => {
  const style = useLayoutPartStyles(options);
  const { locale } = useLocale();
  const { t } = useTranslation('brochureEditor');

  const generatedText = (
    <div className="text-center leading-none font-bold text-[10px] px-16">
      {t('document_created_at')} {formatDate(new Date().toString(), locale)}
      <br />
      {t('document_is_not_contractual')}
    </div>
  );
  const shouldDisplayGeneratedText =
    displayGeneratedDateIfItIsEnabled && options.showGeneratedDate;

  return (
    <>
      {shouldDisplayGeneratedText && !isHeader && generatedText}
      <div
        className={classNames('relative min-h-0', {
          'pb-[10.3%]': !options.bgImage,
        })}
        style={withStyles ? style : undefined}
      >
        {options.bgImage && <img src={options.bgImage.url.original} alt="" />}
        <div
          className="absolute inset-0 grid grid-cols-3 gap-[2%] min-h-0 px-[6.9%]"
          style={{ gridTemplateAreas }}
        >
          {areas.map((area, index) => (
            <div
              key={area}
              style={{ gridArea: area }}
              className="grid relative min-h-0"
            >
              {renderCell?.({
                area,
                index,
                isHeader,
                isFooter: !isHeader,
                justifyContent: justifyContent[index],
              })}
            </div>
          ))}
        </div>
      </div>
      {shouldDisplayGeneratedText && isHeader && generatedText}
    </>
  );
};

export default LayoutPart;
