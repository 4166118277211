import useSWR from 'swr';
import UsersHeader from './usersHeader';
import UsersListingTable from './usersListingTable';
import { Stack } from '@mui/material';
import User from '../../../types/user';
import { useEffect, useState, useRef } from 'react';
import { useMedia } from 'react-use';
import { useAuth } from '../../../hooks/useAuth';
interface ApiListingUsers<T> {
  '@context': string;
  'hydra:member': Array<T>;
  'hydra:totalItems': number;
  'hydra:view': {
    'hydra:next'?: string;
    'hydra:last': string;
  };
  disabled?: string;
  enabled?: string;
}

export default function UsersListingPage() {
  const isLarge = useMedia('(min-width: 1640px)');
  const urlRef = useRef('');
  const [page, setPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(7);
  const { isAdmin } = useAuth();

  const { data } = useSWR<ApiListingUsers<User>>(
    `/users${
      isAdmin ? '/assigned' : ''
    }?page=${page}&itemsPerPage=${itemsPerPage}`
  );

  useEffect(() => setItemsPerPage(isLarge ? 11 : 7), [isLarge]);

  useEffect(() => {
    urlRef.current = `/users?page=${page}&itemsPerPage=${itemsPerPage}`;
    localStorage.setItem(
      'page_userItemPerPage',
      JSON.stringify({ page, itemsPerPage })
    );
  }, [page, itemsPerPage]);
  if (!data) return null;
  return (
    <Stack component="section" sx={{ width: '100%', height: '100%' }}>
      <UsersHeader
        nbUsers={data && data['hydra:totalItems']}
        itemsPerPage={itemsPerPage}
        disabled={data && data.disabled}
        enabled={data && data.enabled}
      />

      {data && (
        <>
          <UsersListingTable
            itemsPerPage={itemsPerPage}
            data={data}
            handlePage={setPage}
            page={page}
            urlRef={urlRef.current}
          />
        </>
      )}
    </Stack>
  );
}
