import useSWR from 'swr';
import { HttpError } from '../../../../libs/axios';
import { useParams } from 'react-router-dom';
import TitleHeader from '../../../../components/common/titleHeader';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { IAgency, INetworkApi } from '../../../../types/networks';
import { Button, Skeleton, Typography } from '@mui/material';
import classNames from 'classnames';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import addToNetwork from '../actions/addToNetwork';
import terminate from '../actions/terminate';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import { useQuery } from '../../../../hooks/useQuery';

const TableItem = (props: { children: ReactNode; title: string }) => {
  const { children, title } = props;

  return (
    <div className={'grid grid-cols-12'}>
      <div className={'col-start-1 col-end-4 p-2'}>
        <p>{title}</p>
      </div>
      <div className={'col-start-4 col-end-12 p-2'}>{children}</div>
    </div>
  );
};

const SingleAgency = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const idAgency = query.get('itemId');
  const { id, agencyId } = useParams();
  const { data: agencyItem, mutate } = useSWR<IAgency, HttpError>(
    `/agencies/${agencyId}/public_details?network=${id}`
  );
  const { data: networkItem } = useSWR<INetworkApi, HttpError>(
    `/networks/${id}`
  );

  return (
    <>
      <TitleHeader
        title={t('networks:mls-networks')}
        titleElement={
          <div className={'w-full'}>
            {agencyItem ? (
              <Typography className="!text-3xl">{agencyItem?.name}</Typography>
            ) : (
              <Skeleton
                sx={{
                  height: '40px',
                  marginBottom: '.5rem',
                  maxWidth: '500px',
                }}
                variant={'rectangular'}
              />
            )}
            {agencyItem ? (
              <Typography variant="subtitle1" className="text-sm text-gray-600">
                {t('networks:MemberOf', {
                  networks: agencyItem.totalNetworksMember,
                  pending: agencyItem.networksMemberPending,
                  declined: agencyItem.networksMemberDeclined,
                })}
              </Typography>
            ) : (
              <Skeleton
                sx={{
                  height: '28px',
                  marginBottom: '.5rem',
                  maxWidth: '350px',
                }}
                variant={'rectangular'}
              />
            )}
          </div>
        }
        goBackLink={'/settings/networks'}
        rightElement={
          <div className={'shrink-0'}>
            {agencyItem && networkItem?.userAuthorizedToEdit ? (
              <>
                {agencyItem.isCurrentNetworkMember ? (
                  <>
                    {idAgency ? (
                      <Button
                        className="add_contact flex gap-2 text-red-500"
                        variant="outlined"
                        onClick={() => idAgency && terminate(idAgency, mutate)}
                        sx={{
                          background: 'background.default',
                          height: '40px',
                          color: '#1D1D1F',
                        }}
                      >
                        <DoDisturbOnOutlinedIcon
                          fontSize={'small'}
                          className={'text-[#F50057]'}
                        />
                        {t('networks:terminate')}
                      </Button>
                    ) : null}
                  </>
                ) : (
                  <Button
                    className="add_contact flex gap-2"
                    variant="contained"
                    onClick={() =>
                      addToNetwork(id as string, agencyId as string, mutate)
                    }
                    sx={{ background: 'background.default', height: '40px' }}
                  >
                    <AddOutlinedIcon fontSize={'small'} />
                    {t('networks:invite')}
                  </Button>
                )}
              </>
            ) : null}
          </div>
        }
      >
        <section className="w-full h-full flex flex-col bg-white p-16 gap-8">
          {agencyItem ? (
            <div
              className={classNames(
                'w-[256px] h-[184px] overflow-hidden flex agencies-center justify-center',
                {
                  'bg-[#34B9B9]': !agencyItem?.logo,
                  'bg-[gray-100] border': agencyItem?.logo,
                }
              )}
            >
              {agencyItem?.logo?.x96 ? (
                <img
                  className={'object-contain'}
                  src={agencyItem?.logo.x96}
                  alt={agencyItem?.name}
                />
              ) : null}
            </div>
          ) : (
            <Skeleton
              sx={{
                height: '184px',
                marginBottom: '.5rem',
                maxWidth: '256px',
              }}
              variant={'rectangular'}
            />
          )}
          <div className={'flex flex-col gap-4'}>
            {agencyItem ? (
              <>
                {agencyItem?.description ? (
                  <>
                    <p className={'text-[24px]'}>{t('networks:Description')}</p>
                    <p className={'max-w-[696px]'}>{agencyItem?.description}</p>
                  </>
                ) : null}
              </>
            ) : (
              <>
                <Skeleton
                  sx={{
                    height: '36px',
                    marginBottom: '.5rem',
                    maxWidth: '150px',
                  }}
                  variant={'rectangular'}
                />
                <Skeleton
                  sx={{
                    height: '72px',
                    marginBottom: '.5rem',
                    maxWidth: '696px',
                  }}
                  variant={'rectangular'}
                />
              </>
            )}
          </div>
          <div className={'flex flex-col gap-4'}>
            {agencyItem ? (
              <>
                <p className={'text-[24px]'}>{t('networks:Contacts')}</p>
                <div className={'divide-y border-t border-b'}>
                  <TableItem title={t('common:Address')}>
                    <span>{agencyItem?.location}</span>
                  </TableItem>
                  <TableItem title={t('common:Phone')}>
                    <a href={`tel:${agencyItem?.phone}`}>{agencyItem?.phone}</a>
                  </TableItem>
                  <TableItem title={t('common:Mail')}>
                    <a href={`mailto:${agencyItem?.email}`}>
                      {agencyItem?.email}
                    </a>
                  </TableItem>
                  <TableItem title={t('common:Website')}>
                    <a
                      href={`${agencyItem?.website}`}
                      target={'_blank'}
                      rel="noreferrer"
                    >
                      {agencyItem?.website}
                    </a>
                  </TableItem>
                  <TableItem title={t('common:Fax')}>
                    <span>{agencyItem?.fax}</span>
                  </TableItem>
                  <TableItem title={t('common:Social')}>
                    <span>{agencyItem?.social}</span>
                  </TableItem>
                </div>
              </>
            ) : (
              <>
                <Skeleton
                  sx={{
                    height: '36px',
                    marginBottom: '.5rem',
                    maxWidth: '150px',
                  }}
                  variant={'rectangular'}
                />
                <Skeleton
                  sx={{
                    height: '326px',
                    marginBottom: '.5rem',
                  }}
                  variant={'rectangular'}
                />
              </>
            )}
          </div>
          <div className={'flex flex-col gap-4'}>
            {agencyItem ? (
              <>
                <p className={'text-[24px]'}>{t('networks:Details')}</p>
                <div className={'divide-y border-t border-b'}>
                  <TableItem title={t('common:Founder')}>
                    <span>{agencyItem?.founder}</span>
                  </TableItem>
                  <TableItem title={t('networks:FoundedIn')}>
                    <span>{agencyItem?.foundedIn}</span>
                  </TableItem>
                  <TableItem title={t('networks:Director')}>
                    <span>{agencyItem?.director}</span>
                  </TableItem>
                  <TableItem title={t('networks:Headquarter')}>
                    <span>{agencyItem?.headQuarter}</span>
                  </TableItem>
                  <TableItem title={t('common:Branches')}>
                    <span>{agencyItem?.branches}</span>
                  </TableItem>
                  <TableItem title={t('networks:Employees')}>
                    <span>{agencyItem?.employees}</span>
                  </TableItem>
                  <TableItem title={t('networks:BuyerSearches')}>
                    <span>{agencyItem?.buyerSearches}</span>
                  </TableItem>
                  <TableItem title={t('networks:RentalSearches')}>
                    <span>{agencyItem?.rentalSearches}</span>
                  </TableItem>
                </div>
              </>
            ) : (
              <>
                <Skeleton
                  sx={{
                    height: '36px',
                    marginBottom: '.5rem',
                    maxWidth: '150px',
                  }}
                  variant={'rectangular'}
                />
                <Skeleton
                  sx={{
                    height: '326px',
                    marginBottom: '.5rem',
                  }}
                  variant={'rectangular'}
                />
              </>
            )}
          </div>
        </section>
      </TitleHeader>
    </>
  );
};

export default SingleAgency;
