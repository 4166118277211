import { FC } from 'react';
import { IProperty, PropertyStepperStep } from '../../../../../types/property';
import { stepperFormId } from '../../../../../const';
import RentPricing from './components/RentPricing';
import BuyPricing from './components/BuyPricing';
import { useIsRent } from '../../../../../hooks/useIsRent';
import { useProtectedForm } from '../../../../../hooks/useProtectedForm';

const PropertyPricingStep: FC<PropertyStepperStep> = ({
  onSubmit,
  defaultValues,
  serverData,
}) => {
  const { register, handleSubmit, getValues, control, watch } =
    useProtectedForm<IProperty>(
      {
        defaultValues,
      },
      serverData
    );

  const isRent = useIsRent(serverData);
  if (isRent === null) {
    return null;
  }

  return (
    <form
      id={stepperFormId}
      onSubmit={handleSubmit(onSubmit)}
      className="grid gap-16"
    >
      {isRent ? (
        <RentPricing
          register={register}
          control={control}
          getValues={getValues}
          watch={watch}
        />
      ) : (
        <BuyPricing
          register={register}
          control={control}
          getValues={getValues}
          watch={watch}
        />
      )}
    </form>
  );
};

export default PropertyPricingStep;
