import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IPromotionListItem } from '../../../types/promotion';
import PromotionsListingTable from './components/promotionsListingTable';
import { isArray, mapValues, pickBy } from 'lodash';
import FilterLayout from '../../../components/globalFilter/filterLayout/filterLayout';
import usePropertyFilterStore from '../../../hooks/usePropertyFilterStore';
import { useLocation } from 'react-router-dom';
import useLocale from '../../../hooks/useLocale';
import { useForm } from 'react-hook-form';
import useListingCollection from '../../../hooks/useListingCollection';
import { useFiltersProperties } from '../../../hooks/useFiltersProperties';
import { getCountry } from '../../agencies/tree/utils';
import { extratagentsFromDept } from '../../../helpers/utils';
import { findCountryAlpha2 } from '../../properties/utils';
import PromotionsFilter from './components/promotionsFilter';
import { initialPromotionFilterValues } from '../../../const/mainPromotionFilter';
import { countries } from '../../../const/countries';
import useTranslationForTableGridXConfig from '../../../hooks/useTranslationForTableGridX';
import { skeletonPromotionsListingConfig } from '../../../components/material-ui/tablesConfig/promotionsListingConfig';
import SkeletonTableGrid from '../../../components/skeletonTableGrid';
import { Box } from '@mui/material';
import { useAuth } from '../../../hooks/useAuth';
import { collectUsersFromAgencies } from '../stepper/steps/properties/utils';
import ListingHeader from '../../../components/tableGridX/listingHeader';
// import { guaranteeArray } from '../../../helpers/utils/formatters';
import GridTableView from '../../../components/cardsTablesList';
import GridView from '../../../components/cardsTablesList/cards/list';
import { IMercureMatchingTime } from '../../../types/matching';
import useMercure from '../../../features/mercure/useMercure';

function PromotionsListingPage() {
  const { t } = useTranslation('promotionPage');
  const location = useLocation();
  const { locale } = useLocale();
  const { pathname } = location;
  const isArchivedPromotion = pathname.includes('archived');

  const {
    itemsToDisplay,
    loadingItemsToDisplay,
    resetFilter,
    setPage,
    itemsPerPage,
    setSelectedRows,
    getFiltredProperties,
    page,
    setLoadingItemsToDisplay,
  } = useListingCollection({
    basedUrl: `/v1/promotions`,
    isActiveProperties: !isArchivedPromotion,
    currentPage: 'promotions',
  });

  const {
    agencyHierarchy,
    agentsPerDept,
    setAssistantAgencyHierarchy,
    setAgencyHierarchy,
  } = useFiltersProperties();
  const { isAssistant, user } = useAuth();
  const { reset, register, setValue, watch, control, getValues } = useForm({
    defaultValues: initialPromotionFilterValues,
  });

  const { updateElementOptions, getInputValue, mainFilterState } =
    usePropertyFilterStore({
      basedUrl: `/v1/promotions`,
      currentPage: 'promotions',
    });

  const { data: matchingRemainingTime } = useMercure<IMercureMatchingTime>(
    'matching-remaining-time'
  );

  useEffect(() => {
    handleResetFilter();
    setPage(1);
    setLoadingItemsToDisplay(true);
  }, [isArchivedPromotion]);

  // update agents options
  useEffect(() => {
    let agentsOptions: any = [];
    // if the user is assistant we should display in the agent field only the agents that he belong to
    if (isAssistant) {
      const { users }: any = user;
      agentsOptions =
        users.length > 0
          ? users.map(({ id, firstname, lastname }: any) => {
              return { id, value: `${firstname} ${lastname}` };
            })
          : [];
    } else {
      if (watch('departments').length > 0) {
        setValue('agents', null);
        const agenciesDept = watch('departments');
        agentsOptions = collectUsersFromAgencies(agenciesDept, agencyHierarchy);
      } else {
        if (agentsPerDept) {
          agentsOptions = extratagentsFromDept(agentsPerDept);
        }
      }
    }

    updateElementOptions('agents', agentsOptions);
  }, [watch('departments'), agentsPerDept]);

  // update department depending to selected agent when the user is assistant
  useEffect(() => {
    if (isAssistant && watch('agents')) {
      const brokerId = watch('agents');
      setValue('departments', []);
      setAssistantAgencyHierarchy(brokerId, Number(watch('service')));
    }
  }, [watch('agents')]);

  //setValue of canton and district if country is not Switzerland

  useEffect(() => {
    const country = findCountryAlpha2(locale, watch('country'));
    const isSwitzerland = country === 'CH';
    if (!isSwitzerland) {
      setValue('canton', '');
      setValue('district', '');
    }
  }, [watch('country')]);

  //setValue of different values in promotion location fields

  useEffect(() => {
    if (watch('address')) {
      const { zipCode, translations }: any = watch('address');
      const { adresse, city, country, canton, district } =
        translations[`${locale}`];
      setValue('zipCode', zipCode);
      setValue('adresse', adresse);
      setValue('city', city);
      setValue('canton', canton);
      setValue('district', district);
      setValue('country', getCountry(countries, country)[locale]);
    }
  }, [watch('address')]);

  // update department depending to selected service and depending to the role of user if assistant or not

  useEffect(() => {
    const serviceId = watch('service');
    setValue('departments', []);
    if (!isAssistant) {
      setAgencyHierarchy(Number(serviceId));
    } else {
      if (isAssistant && watch('agents')) {
        setAssistantAgencyHierarchy(watch('agents'), Number(serviceId));
      }
    }
  }, [watch('service')]);

  const handleResetFilter = () => {
    reset();
    resetFilter();
  };

  const handleFilterDataPreparation = () => {
    const dataTosend = pickBy(watch(), (value) => {
      return isArray(value)
        ? value.length > 0
        : value !== undefined && value !== null && value !== '';
    });

    const newdataTosend = mapValues(dataTosend, (value, key) => {
      if (key === 'agents') {
        return [Number(value)];
      }
      if (key === 'country') {
        return findCountryAlpha2(locale, value);
      }
      if (key === 'reference' && Array.isArray(value)) {
        const refrencesToSend: string[] = value?.map(({ id }: any) => id);
        return refrencesToSend;
      }
      if (key === 'service' || key === 'status') {
        return Number(value);
      }
      if (typeof value === 'string') {
        return value.trim();
      }
      return value;
    });

    return newdataTosend;
  };

  //scroll to specific div
  const smoothScrollToElement = async (elementId: string) => {
    const targetElement = document.getElementById(elementId);

    if (!targetElement) {
      return;
    }
    targetElement.scrollIntoView({ behavior: 'smooth' });
    await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust the timeout as needed
  };

  const submitSearch = async () => {
    try {
      // Start smooth scrolling and wait for it to complete before proceeding
      await smoothScrollToElement('specificDivId');
      // Proceed with the rest of the logic
      let newdataTosend: any = await handleFilterDataPreparation();
      const { reference } = newdataTosend;

      if (reference) {
        newdataTosend = {
          references: newdataTosend.reference,
          ...newdataTosend,
        };
        delete newdataTosend.reference;
      }
      newdataTosend.archive = isArchivedPromotion ? true : false;
      newdataTosend.lang = locale;
      delete newdataTosend.name;
      delete newdataTosend.address;
      delete newdataTosend.id;
      await getFiltredProperties(newdataTosend);
    } catch (error) {
      console.log('Error occurred:', error);
    }
  };

  const skeletonPromotionsListingColumns = useTranslationForTableGridXConfig([
    skeletonPromotionsListingConfig,
    'common',
  ]);

  const activeProperties: IPromotionListItem[] = useMemo(() => {
    if (itemsToDisplay) {
      return itemsToDisplay['hydra:member'];
    }
    return [];
  }, [itemsToDisplay]);

  useEffect(() => {
    !localStorage.getItem('promotionlistInfos') &&
      localStorage.setItem(
        'promotionlistInfos',
        JSON.stringify({ page: 1, itemsPerPage })
      );
  }, []);

  const filters = (
    <FilterLayout
      page="promotion"
      handleResetFilter={handleResetFilter}
      submitSearch={submitSearch}
    >
      <PromotionsFilter
        register={register}
        setValue={setValue}
        watch={watch}
        control={control}
        getValues={getValues}
        getInputValue={getInputValue}
        mainFilterState={mainFilterState}
      />
    </FilterLayout>
  );

  let itemsCountText;
  if (itemsToDisplay) {
    itemsCountText = `${itemsToDisplay['hydra:totalItems']} ${t(
      'promotionPage:promotions_found'
    )}`;
  }

  return (
    <section className="w-full h-full flex flex-col">
      <ListingHeader
        title={t('common:Promotions')}
        itemsCountText={itemsCountText}
        newLinkUrl="/promotions/new"
        newLinkText={t('promotionPage:add_new_promotion')}
        newLinkClassName="add_new_promotion"
      />

      {filters}

      <div id="specificDivId" />

      <>
        <GridTableView
          gridElement={
            <GridView
              items={activeProperties}
              isLoading={loadingItemsToDisplay}
              page={page - 1}
              isPromotion
              matchingRemainingTime={matchingRemainingTime}
              rowCount={
                (itemsToDisplay && itemsToDisplay['hydra:totalItems']) || 0
              }
              handleChangePage={(
                event: React.MouseEvent | null,
                page: number
              ) => {
                localStorage.setItem(
                  'promotionlistInfos',
                  JSON.stringify({ page: page + 1, itemsPerPage })
                );
                setPage(page + 1);
              }}
              rowsPerPage={itemsPerPage}
              handleChangeRowsPerPage={(page: number) => {
                setPage(page + 1);
              }}
              rowsPerPageOptions={[10, 25, 50, 100]}
            />
          }
          tableElement={
            <Box className="w-full h-full">
              {loadingItemsToDisplay ? (
                <SkeletonTableGrid
                  className="w-full !min-h-[80%]"
                  disableSelectionOnClick={true}
                  disableColumnSelector={true}
                  rows={Array.from({ length: itemsPerPage }).map(
                    (el, index) => {
                      return {
                        id: index,
                        cover: '',
                        name: '',
                        reference: '',
                        address: '',
                        max: '',
                        createdAt: '',
                        action: '',
                      };
                    }
                  )}
                  columns={skeletonPromotionsListingColumns}
                  rowCount={0}
                />
              ) : (
                <PromotionsListingTable
                  itemsPerPage={itemsPerPage}
                  matchingRemainingTime={matchingRemainingTime}
                  data={itemsToDisplay}
                  rows={activeProperties}
                  handlePage={setPage}
                  page={page}
                  setSelectedRows={(selectedRows: IPromotionListItem[]) =>
                    setSelectedRows(selectedRows)
                  }
                />
              )}
            </Box>
          }
        />
      </>
    </section>
  );
}

export default PromotionsListingPage;
