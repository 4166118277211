import { generateProps as generateDescriptionProps } from '../components/widgets/universal/multipageDescription';
import { generateProps as generateGalleryProps } from '../components/widgets/universal/multipageGallery';
import { generateProps as generateAreasProps } from '../components/widgets/property/areas';

export function pickMultipagePropsGenerator(widgetType?: string) {
  if (widgetType === 'multipageGallery') {
    return generateGalleryProps;
  }
  if (widgetType === 'multipageDescription') {
    return generateDescriptionProps;
  }
  if (widgetType === 'areas') {
    return generateAreasProps;
  }
  return null;
}
