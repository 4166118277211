import { Box, Fade, Modal, Skeleton } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { http } from '../../../../../../libs/axios';
import { formatDateAndTime, fullName } from '../../../../../../helpers/utils';
interface Props {
  title: string;
  openModal: boolean;
  onClose: () => void;
  id?: number;
}
interface DoneBy {
  firstname: string;
  lastname: string;
}

interface ActionData {
  action: string;
  updatedAt: string;
  doneBy: DoneBy;
}
const PricesHistoryPopup = (props: Props) => {
  const { openModal, onClose, id, title } = props;
  const { t } = useTranslation('propertiesPage');
  const itemsPerPage = 5;
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState<ActionData[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const fetchData = async () => {
    try {
      const res = await http.get(
        `/v1/properties/${id}/pricing_historic?page=${page}&itemsPerPage=${itemsPerPage}`
      );

      const dataWithId = res.data['hydra:member']
        .filter((item: ActionData) => item.action && item.action.trim() !== '')
        .map((item: ActionData, index: number) => ({
          ...item,
          id: index,
        }));
      setTotalCount(res.data['hydra:totalItems']);
      setRows(dataWithId);
      setLoading(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    openModal && fetchData();
  }, [openModal, page]);
  const columns: GridColDef<ActionData>[] = [
    {
      field: 'firstname',
      headerClassName: '!pl-5',
      cellClassName: '!pl-6 !pt-2',
      width: 230,
      sortable: false,
      renderHeader: () => (
        <Box className="leading-5 flex gap-2 font-[Inter] font-medium text-sm pl-2 py-5 text-[#030814]">
          <span>{t('Added_by')}</span>
        </Box>
      ),
      renderCell: ({ row }) => {
        return (
          <span className="font-normal font-[Inter]">
            {fullName(row.doneBy?.firstname, row.doneBy?.lastname)}
          </span>
        );
      },
    },

    {
      field: 'action',
      headerClassName: '!pl-5',
      cellClassName: '!pl-6 !pt-2',
      width: 250,
      sortable: false,
      renderHeader: () => (
        <Box className="leading-5 flex gap-2 font-[Inter] font-medium text-sm pl-2 py-5 text-[#030814]">
          <span>{t('action')}</span>
        </Box>
      ),
      renderCell: ({ row }) => {
        return (
          <span className="font-normal font-[Inter] text-blue-600">
            {row.action == 'Price has been removed'
              ? t(row.action.replaceAll(' ', '_'))
              : row.action}
          </span>
        );
      },
    },
    {
      field: 'doneAt',
      headerClassName: '!pl-5 hideRightSeparator',
      width: 180,
      sortable: false,
      renderHeader: () => (
        <Box className="leading-5 flex gap-2 font-[Inter] font-medium text-sm py-5 text-[#030814]">
          <span>{t('Date')}</span>
        </Box>
      ),
      renderCell: ({ row }) => {
        return <span className="pl-2">{formatDateAndTime(row.updatedAt)}</span>;
      },
    },
  ];

  const skeletonRows = Array.from(new Array(2)).map((_, index) => (
    <div
      key={index}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 0',
      }}
    >
      <Skeleton
        sx={{ mx: 1, my: 1 }}
        animation="wave"
        variant="text"
        width={200}
      />
      <Skeleton
        sx={{ mx: 1, my: 1 }}
        animation="wave"
        variant="text"
        width={90}
        height={20}
      />
      <Skeleton
        sx={{ mx: 1, my: 1 }}
        animation="wave"
        variant="text"
        width={200}
      />
      <Skeleton
        sx={{ mx: 1, my: 1 }}
        animation="wave"
        variant="text"
        width={90}
        height={20}
      />
    </div>
  ));

  return (
    <Modal
      open={openModal}
      onClose={onClose}
      classes={{ root: 'grid place-items-center' }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Fade in={openModal}>
          <Box
            sx={{
              maxHeight: '72vh',
              overflowY: 'auto',
              overflowX: 'hidden',
              width: {
                xs: '55%',
              },
              borderRadius: 2,
              bgcolor: '#FBFBFD',
              boxShadow: 24,
            }}
          >
            <div
              className={
                'px-8 pb-6 pt-4 flex flex-col h-[50vh] min-h-[415px] relative'
              }
            >
              <div className="flex justify-between h-[15%] !items-start w-full">
                <p className={'mb-4 text-lg md:text-2xl'}>{`${t(
                  `promotionPage:${title}`
                )}`}</p>
              </div>
              <button
                onClick={onClose}
                className={
                  'absolute right-8 top-5 opacity-60 transition-opacity duration-300 hover:opacity-80'
                }
              >
                <CloseIcon fontSize="medium" />
              </button>
              <Box sx={{ width: '100%' }}>
                <div>
                  {loading ? (
                    <div>
                      <DataGridPro
                        sx={{
                          '& .MuiDataGrid-virtualScroller': {
                            overflowY: 'scroll !important',
                          },
                          '& .MuiDataGrid-columnSeparator': {
                            visibility: 'visible',
                          },
                          '& .hideRightSeparator > .MuiDataGrid-columnSeparator':
                            {
                              display: 'none',
                            },
                          '& .MuiDataGrid-footerContainer': {
                            minHeight: '0px',
                          },
                          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                            width: 10,
                          },
                          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb':
                            {
                              backgroundColor: '#BBC0C4',
                              borderRadius: 8,
                            },
                          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover':
                            {
                              backgroundColor: '#888',
                            },
                          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track':
                            {
                              backgroundColor: '#f1f1f1',
                            },
                        }}
                        rows={rows}
                        columns={columns}
                        disableColumnMenu
                        disableRowSelectionOnClick={true}
                        disableColumnSelector={true}
                        pagination
                        paginationMode="server"
                        paginationModel={{
                          page: page - 1,
                          pageSize: 5,
                        }}
                        onPaginationModelChange={(model) => {
                          setPage(model.page + 1);
                        }}
                        autoHeight
                        rowCount={totalCount}
                        hideFooterSelectedRowCount
                        style={{ height: '46vh' }}
                      />
                    </div>
                  ) : (
                    <div>{skeletonRows}</div>
                  )}
                </div>{' '}
              </Box>
            </div>
          </Box>
        </Fade>
      </ClickAwayListener>
    </Modal>
  );
};
export default PricesHistoryPopup;
