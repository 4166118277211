import { useEffect, useState } from 'react';
import MercureService from './mercureService';

export interface NotificationData {
  message: string;
}

const useMercure = <T>(topicUrl: string) => {
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    // Subscribe to the specified topic
    MercureService.subscribe(topicUrl);

    const listener = (newData: T) => {
      setData(newData);
    };

    MercureService.addListener(topicUrl, listener);

    // Cleanup listener on component unmount
    return () => {
      MercureService.removeListener(topicUrl, listener);
    };
  }, [topicUrl]);

  return { data };
};

export default useMercure;
