import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { GridSelectionModel } from '@mui/x-data-grid';
import { KeyedMutator } from 'swr';

import TableGridX from '../../components/tableGridX';
import useColumns from './useColumns';
import { Document } from '../../types/property';
import { generateInternal } from '../brochureTemplates/lib/hooks/useGenerate';
import useLocale from '../../hooks/useLocale';

const FileSelection = ({
  files,
  mutate,
  rowSelectionModel = [],
  onRowsSelectionHandler,
  isPopup = false,
}: {
  files?: Document[] | null;
  mutate: KeyedMutator<Document[]>;
  rowSelectionModel?: GridSelectionModel;
  onRowsSelectionHandler: (ids: GridSelectionModel) => unknown;
  isPopup?: boolean;
}) => {
  const { propertyId, promotionId } = useParams();
  const { locale } = useLocale();
  const [regeneratedId, setRegeneratedId] = useState<number>();
  async function regenerate(templateId: number) {
    setRegeneratedId(templateId);
    try {
      await generateInternal({
        templateId: String(templateId),
        propertyId,
        promotionId,
        locale,
      });
      mutate();
    } catch (e) {
      console.error(e);
    }
    setRegeneratedId(undefined);
  }

  const columns = useColumns({
    isLoading: !files,
    onMainChange: mutate,
    regenerate,
    regeneratedId,
    isPopup,
  });

  const rows = files || skeletonItems;

  const [page, setPage] = useState(0);

  return (
    <TableGridX
      key={files ? '1' : '0'}
      checkboxSelection
      onSelectionModelChange={onRowsSelectionHandler}
      selectionModel={rowSelectionModel}
      rows={rows}
      columns={columns}
      pageSize={100}
      page={page - 1}
      onPageChange={(page: number) => {
        setPage(page + 1);
      }}
      rowHeight={64}
    />
  );
};

export default FileSelection;

const skeletonItems = Array(6)
  .fill('')
  .map((_, index) => ({ id: index }));
