import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Fade, Modal } from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

// icons
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// steps
import PopupStepper, { Step } from '../../../../components/popupStepper';
import useSWR from 'swr';
import PopupFormSuccess from '../../../properties/propertiesListing/propertiesMatching/steps/success';
import SendEmailForm from '../../../properties/propertiesListing/propertiesMatching/steps/email';
import UsersStep from '../../../../components/matching/common/steps/users';

type Props = {
  id: number;
  isModalOpen: boolean;
  handleCloseModal: () => void;
};

enum Steps {
  USERS,
  EMAIL,
}

const PromotionMatchingModal = ({
  id,
  isModalOpen,
  handleCloseModal,
}: Props) => {
  const [successMessage, setSuccessMessage] = useState('');
  const formRef = useRef<HTMLFormElement | null>(null);
  const { t } = useTranslation('propertiesPage');
  const [selectedMatching, setSelectedMatching] = useState<any>(null);
  const [rowSelectionUsers, setRowSelectionUser] = useState<GridSelectionModel>(
    []
  );

  const { data: matchings } = useSWR(`/v1/promotions/${id}/matchings`, {
    revalidateOnMount: true,
    refreshWhenHidden: true,
  });

  const matchingItems = useMemo(() => {
    if (matchings) {
      return matchings['hydra:member'];
    }
    return [];
  }, [matchings]);

  useEffect(() => {
    let selected = null;

    if (matchingItems.length > 1) {
      const filteredItems = matchingItems.filter(
        (item: { contacts: any[] }) => item.contacts.length > 0
      );
      selected = filteredItems.length > 0 ? filteredItems[0] : null; // Select the first filtered item, if available
    } else if (matchingItems.length === 1) {
      selected = matchingItems[0];
    }

    setSelectedMatching(selected);
  }, [matchingItems]);

  const shouldDisableNext = (step: Step | undefined) => {
    switch (step?.id) {
      case Steps.USERS:
        return !rowSelectionUsers.length;
        break;
      default:
        return false;
    }
  };

  function handleEmailSent(messageHtml?: string) {
    setSuccessMessage(messageHtml || 'Success');
  }

  function onClose() {
    setRowSelectionUser([]);
    setSuccessMessage('');
    handleCloseModal();
  }

  const content = successMessage ? (
    <PopupFormSuccess onClose={onClose}>
      <div dangerouslySetInnerHTML={{ __html: successMessage }}></div>
    </PopupFormSuccess>
  ) : (
    <PopupStepper
      onClose={onClose}
      disableNext={shouldDisableNext}
      onBack={(step) => {
        if (step?.id === Steps.USERS) {
          setRowSelectionUser([]);
        }
      }}
      steps={[
        {
          id: Steps.USERS,
          Icon: PeopleOutlineIcon,
          title: `${t('usersPage:users')}`,
          body: (
            <UsersStep
              type={'promotion'}
              matchings={matchingItems}
              selectedMatching={selectedMatching}
              onMatchingSelect={setSelectedMatching}
              rowSelectionUsers={rowSelectionUsers}
              onRowsSelectionHandler={setRowSelectionUser}
              promotionId={id}
            />
          ),
        },
        {
          id: Steps.EMAIL,
          Icon: MailOutlineIcon,
          title: `${t('Email_preview')}`,
          body: (
            <SendEmailForm
              rows={matchingItems}
              rowSelectionUsers={rowSelectionUsers}
              rowSelectionProperty={
                selectedMatching
                  ? ([selectedMatching.id] as GridSelectionModel)
                  : [0]
              }
              onEmailSent={handleEmailSent}
              formRef={formRef}
            />
          ),
          nextButton: () => {
            const onClick = () => {
              if (formRef && formRef.current) {
                formRef.current.requestSubmit();
              }
            };

            return (
              <div
                onClick={onClick}
                className="h-[40px] rounded flex items-center justify-center p-4 gap-y-2 bg-blue cursor-pointer gap-6 text-white text-[14px] transition-colors duration-100 drop-shadow hover:bg-[rgb(36,49,129)]"
              >
                <span>{t('Send_email')}</span>
                <ArrowForwardIosIcon
                  sx={{
                    width: '16px',
                    height: '16px',
                  }}
                  fontSize={'small'}
                />
              </div>
            );
          },
        },
      ]}
    />
  );

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={onClose}
        classes={{ root: 'grid place-items-center' }}
      >
        <Fade in={isModalOpen}>
          <Box
            sx={{
              maxHeight: '92vh',
              overflowY: 'auto',
              overflowX: 'hidden',
              width: {
                xs: successMessage ? '40%' : '90%',
              },
              borderRadius: 2,
              bgcolor: 'background.paper',
              boxShadow: 24,
            }}
          >
            {content}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default PromotionMatchingModal;
