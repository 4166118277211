import { GridColumns } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Skeleton, Tooltip } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  PreviewSubjectTypes,
  TemplateApi,
} from '../../../../types/brochureEditor';
import { convertTemplateToClient, getPagesWithoutLayoutParts } from '../utils';
import CreatedUpdated from '../../../../components/tableGridX/createdUpdated';
import CreatedUpdatedHeader from '../../../../components/tableGridX/createdUpdatedHeader';
import BrochureTemplatesListingActionMenu from '../../brochureTemplatesListingActionMenu';
import CircleWithFullName from '../../../../components/tableGridX/circleWithFullName';

export const useBrochureTemplateListColumns = ({
  isLoading,
}: {
  isLoading?: boolean;
}): GridColumns<TemplateApi> => {
  const { t } = useTranslation('brochureEditor');
  const isHigherThen768 = useMediaQuery('(min-width:768px)');
  const commonSettings = {
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  };

  const columns: GridColumns<TemplateApi> = [
    {
      field: 'name',
      headerName: t('name'),
      width: 200,
      flex: 0,
      renderCell: ({ row }) => {
        return (
          <Tooltip
            title={row.name || `${t('unnamed_template')} #${row.id}`}
            arrow
            placement="top"
          >
            <Link
              to={`/brochure-templates/${row.id}`}
              className="capitalize font-medium text-base leading-5 truncate"
            >
              {row.name || `${t('unnamed_template')} #${row.id}`}
            </Link>
          </Tooltip>
        );
      },
    },
    {
      field: 'usedFor',
      width: 120,
      flex: 0,
      headerName: t('used_for'),
      valueGetter: ({ row }) => {
        if (row.usedFor === String(PreviewSubjectTypes.PROMOTION)) {
          return t('common:Promotions');
        }
        // if (row.usedFor === String(PreviewSubjectTypes.PROPERTY)) {
        return t('common:Properties');
        // }
      },
    },
    {
      field: 'pageCount',
      headerName: t('number_of_pages'),
      width: 140,
      flex: 0,
      cellClassName: '!pl-4',
      valueGetter: ({ row }) => {
        const template = convertTemplateToClient(row);
        return getPagesWithoutLayoutParts(template.pages).length;
      },
    },
    {
      field: 'propertyCount',
      headerName: t('common:Properties'),
      width: 120,
      flex: 0,
      cellClassName: '!pl-4',
      valueGetter: ({ row }) => {
        const template = convertTemplateToClient(row);
        return template.properties.length;
      },
    },
    {
      field: 'promotionCount',
      headerName: t('common:Promotions'),
      width: 120,
      flex: 0,
      cellClassName: '!pl-4',
      valueGetter: ({ row }) => {
        const template = convertTemplateToClient(row);
        return template.promotions.length;
      },
    },
    {
      field: 'createdBy',
      width: 130,
      flex: 0,
      headerName: t('created_by'),
      renderCell: ({ row }) => {
        return row.createdBy && <CircleWithFullName {...row.createdBy} />;
      },
    },
    {
      field: 'createdUpdated',
      width: 210,
      headerClassName: ` ${!isHigherThen768 && '!border-r-0'}`,
      flex: 0,
      renderHeader: () => <CreatedUpdatedHeader />,
      renderCell: ({ row }) => (
        <CreatedUpdated created={row.createdAt} updated={row.updatedAt} />
      ),
    },
    {
      field: 'actions',
      width: 80,
      flex: 0,
      headerClassName: '!border-r-0 !hidden md:!block',
      headerName: t('common:actions'),
      renderCell: ({ row }) =>
        isHigherThen768 && <BrochureTemplatesListingActionMenu row={row} />,
    },
  ];

  return columns.map((column) => ({
    ...commonSettings,
    ...column,
    ...(isLoading
      ? {
          renderCell: () => (
            <Skeleton
              variant="text"
              animation="wave"
              width="100%"
              height={20}
            />
          ),
        }
      : {}),
  }));
};
