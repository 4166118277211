import { Box, Typography } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import WithModal, {
  InjectedModalState,
} from '../../components/modal/widthModal';
import UserForm from '../users/addNewUserPage/userForm';
import Modal from '../../components/modal';
import ProfileWidget from './widgets/profile';
import AgencyWidget from './widgets/agency';
import LeadsWidget from './widgets/leads';
import PropertiesWidget from './widgets/properties';
import NetworkRequestsWidget from './widgets/networkRequests';
import useSWR from 'swr';
import { IDashboard, IDashboardSettings } from '../../types/user';
import { Skeleton } from '@mui/lab';
import SalesWidget from './widgets/sales';
import OffersWidget from './widgets/offers';
import PropertiesSoldWidget from './widgets/propertiesSold';
import LeadSourceWidget from './widgets/leadSource';
import DashboardDatePicker from './components/datepicker';
import dayjs, { Dayjs } from 'dayjs';
// @ts-ignore
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import SettingsBarsIcon from '../../features/icons/settingsBarsIcon';
import CustomizeDashboardPopup from './components/customize/popup';
import { http } from '../../libs/axios';
import { toast } from 'react-toastify';
import AgencyLeadsWidget from './widgets/agencyLeads';
import AgencyLeadSourceWidget from './widgets/agencyLeadSource';
import PipelineStatusWidget from './widgets/pipelineStatus';
import SentEmailsWidget from './widgets/sentEmails';
import publishedPropertiesWidget from './widgets/publishedProperties';

const sections = (name: string) => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const items: Record<string, any> = {
    profile: ProfileWidget,
    agency: AgencyWidget,
    'recent-leads': LeadsWidget,
    'agency-recent-leads': AgencyLeadsWidget,
    properties: PropertiesWidget,
    'mls-requests': NetworkRequestsWidget,
    'lead-source': LeadSourceWidget,
    'agency-lead-source': AgencyLeadSourceWidget,
    sales: SalesWidget,
    offers: OffersWidget,
    'properties-sold': PropertiesSoldWidget,
    'pipelines-stats': PipelineStatusWidget,
    'sent-emails': SentEmailsWidget,
    'published-properties': publishedPropertiesWidget,
  };
  return items[name];
};

export interface WidgetProps {
  data: IDashboard;
  wide?: boolean;
  mutate?: any;
}

function getDates(dateRange: any) {
  if (dateRange && dateRange.length) {
    if (dateRange[0]) {
      return `?from=${dayjs(dateRange[0]).format('DD-MM-YYYY')}${
        dateRange[1] ? `&to=${dayjs(dateRange[1]).format('DD-MM-YYYY')}` : ''
      }`;
    }
  }

  return '';
}

const Widget = (props: {
  name: string;
  data: IDashboard;
  wide?: boolean;
  mutate?: any;
}) => {
  const WidgetComponent = sections(props.name);

  if (!WidgetComponent) {
    return null;
  }

  return (
    <WidgetComponent
      data={props.data}
      wide={props.wide}
      mutate={props.mutate}
    />
  );
};

function Dashboard({ ...rest }) {
  const {
    user: { id, firstname, roles },
  } = useAuth();

  const today = dayjs();
  const previousDate = today.subtract(1, 'month');
  const modalState = rest as InjectedModalState;
  const [isAbleToAddUser, setIsAbleToAddUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    previousDate,
    today,
  ]);
  const { t } = useTranslation();
  const isAdmin = JSON.parse(localStorage.getItem('isAdmin') as string);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  useEffect(() => {
    if (!isAdmin.isAdmin || roles.includes('ROLE_ADMIN_OPERATOR')) {
      setIsAbleToAddUser(false);
    } else {
      setIsAbleToAddUser(true);
    }
  }, [isAdmin, roles]);
  const open = Boolean(anchorEl);
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = (event: KeyboardEvent) => {
    event.key != 'Tab' && setAnchorEl(null);
  };

  const { data, mutate: mutateDashboard } = useSWR<IDashboard>(
    `/users/${id}/dashboard${getDates(dateRange)}`
  );
  const { data: dashboard, mutate } = useSWR<IDashboardSettings>(
    `/users/${id}/dashboard_settings`
  );

  const onChangeDashboard = async (data: any) => {
    setIsLoading(true);
    let userDashboard = data;

    if (data['draggable-top'] && data['draggable-bottom']) {
      userDashboard = {
        active_widgets: data['draggable-top'],
        hidden_widgets: data['draggable-bottom'],
      };
    }

    await http
      .patch(`/users/${id}/dashboard`, {
        userDashboard,
      })
      .then((res) => {
        if (res.status === 200) {
          setIsPopupOpen(false);
          toast.success(res.data.message, {
            autoClose: 3000,
          });
          mutate();
          setIsLoading(false);
        }
      });
  };

  return (
    <Box sx={{ color: 'text.primary' }}>
      <Box
        className="flex flex-col md:flex-row justify-between md:items-center pt-8"
        sx={{ color: 'text.primary' }}
      >
        <Box>
          <Typography className="!text-3xl">{t('common:Dashboard')}</Typography>
          <Typography variant="body2" sx={{ margin: '4px' }}>
            {t('common:welcomeBack')}, {firstname}
          </Typography>
        </Box>
        <Box
          className={
            'pt-4 md:pt-0 flex flex-wrap 2xl:flex-nowrap gap-2 md:gap-4 items-center'
          }
        >
          <DashboardDatePicker value={dateRange} setValue={setDateRange} />
          <Button
            variant="outlined"
            disableElevation
            onClick={() => setIsPopupOpen(true)}
            sx={{
              height: '40px',
              width: '48%',
              paddingX: '2px',
              color: '#000',
            }}
          >
            <SettingsBarsIcon color={'currentColor'} />
            <Box
              component="span"
              className="text-xs md:text-sm py-1 ml-2 md:whitespace-nowrap"
            >
              {t('common:editDashboard')}
            </Box>
          </Button>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {isAbleToAddUser && (
              <MenuItem>
                <Box>
                  <Modal
                    width={{
                      md: 500,
                    }}
                    title={t('usersPage:Add_new_user')}
                    buttonProps={{
                      title: t('common:User'),
                      onClick: () => modalState.openModal(),
                      sx: { ':hover': { background: 'none' }, padding: '0' },
                      className:
                        'font-normal text-base leading-normal !w-full text-left text-[#1D1D1F]',
                    }}
                    {...modalState}
                  >
                    <UserForm
                      type="add"
                      closeModal={modalState.closeModal}
                      apiKey={'/users'}
                      itemsPerPage={7}
                    />
                  </Modal>
                </Box>
              </MenuItem>
            )}
            <Link to="/properties/new">
              <MenuItem>{t('common:property')}</MenuItem>
            </Link>

            {/* <MenuItem onClick={handleClose}>{t('Website')}</MenuItem> */}
          </Menu>
        </Box>
      </Box>

      <Box className="grid md:grid-cols-2 2xl:grid-cols-3 gap-x-3 gap-y-6 mt-8">
        {data && dashboard && dashboard.userDashboard ? (
          <>
            {dashboard.userDashboard['active_widgets']?.map((item) => (
              <Widget
                key={item.name}
                data={data}
                name={item.name}
                wide={item.wideWidget}
                mutate={mutateDashboard}
              />
            ))}
            <CustomizeDashboardPopup
              loading={isLoading}
              isOpen={isPopupOpen}
              onClose={() => setIsPopupOpen(false)}
              items={dashboard.userDashboard}
              onApplyClick={onChangeDashboard}
            />
          </>
        ) : (
          <>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((el) => (
              <Skeleton key={el} variant={'rounded'} height={480} />
            ))}
          </>
        )}
      </Box>
    </Box>
  );
}

export default WithModal(Dashboard);
