import { Controller, useController } from 'react-hook-form';
import { stepperFormId } from '../../../../const';
import { INetwork } from '../../../../types/networks';
import { FC } from 'react';
import { NetworkStep } from '../create';
import InputFormField from '../../../../components/form/inputFormField';
import StepsFieldset from '../../../../components/stepper/stepsFieldset';
import useLocale from '../../../../hooks/useLocale';
import { useTranslation } from 'react-i18next';
import { FormControl } from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import InputLayout from '../../../../components/form/components/inputLayout';
import useSWR from 'swr';
import { ICompany } from '../../../../types/hierarchy/legacy';
import { HierarchyNode } from '../../../../types/hierarchy';
import { useAuth } from '../../../../hooks/useAuth';
import { useFiltersProperties } from '../../../../hooks/useFiltersProperties';
import LevelThreeAgenciesSelect from '../elements/levelThreeAgenciesSelect';
import { useProtectedForm } from '../../../../hooks/useProtectedForm';
import useMediaQuery from '@mui/material/useMediaQuery';
const NetworkInformation: FC<NetworkStep> = ({
  defaultValues,
  onSubmit,
  serverData,
}) => {
  const { handleSubmit, register, control } = useProtectedForm<INetwork>(
    {
      defaultValues,
    },
    serverData
  );
  const { locale } = useLocale();
  const { t } = useTranslation();
  const { isAssistant } = useAuth();
  const isHigherThen900 = useMediaQuery('(min-width:900px)');
  const { field } = useController({ name: 'creator', control });
  const localOnSubmit = (data: any) => {
    const body = { ...data };

    onSubmit(body);
  };

  const { assistantAgencyHierarchy } = useFiltersProperties();
  const { data: basicHierarchy } = useSWR<ICompany>(
    !isAssistant && '/users/hierarchy'
  );

  const hierarchy: HierarchyNode = isAssistant
    ? assistantAgencyHierarchy
    : basicHierarchy;

  return (
    <form id={stepperFormId} onSubmit={handleSubmit(localOnSubmit)}>
      <StepsFieldset
        className={`grid !gap-4 xl:!gap-16 lg:grid-cols-3 ${
          isHigherThen900 ? 'grid-cols-1' : 'grid-cols-3'
        }`}
        title={t('networks:Network_information')}
      >
        <input
          type="hidden"
          {...register(`translations.${locale}.locale`)}
          value={locale}
        />
        <InputFormField
          type="text"
          isRequired
          label={t('common:Name')}
          {...register(`translations.${locale}.name`)}
        />
        {/*@ts-ignore*/}
        <LevelThreeAgenciesSelect
          className="Department_property"
          label={t('common:Agency')}
          node={hierarchy}
          isRequired
          disabled={!!defaultValues?.creator}
          {...field}
        />
        <InputLayout label={t('common:Description')}>
          <Controller
            name={`translations.${locale}.description`}
            control={control}
            defaultValue={''}
            render={({ field: { value, ...restField } }) => (
              <FormControl
                sx={{
                  border: '1px solid #c4c4c4',
                  borderRadius: '4px',
                  '&:hover': {
                    borderColor: '#1D1D1F',
                  },
                }}
              >
                <TextareaAutosize
                  minRows={4}
                  maxRows={5}
                  value={value}
                  className={
                    'MuiInputBase-input MuiOutlinedInput-input rounded p-4'
                  }
                  {...restField}
                />
              </FormControl>
            )}
          />
        </InputLayout>
      </StepsFieldset>
    </form>
  );
};

export default NetworkInformation;
