import classNames from 'classnames';
import { Tooltip } from '@mui/material';
import { IMercureMatchingTime } from '../../../../types/matching';
import {
  IMatchingProperty,
  IPropertyApi,
  PropertyListingItem,
} from '../../../../types/property';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SinglePropertyMatchingModal from '../../../../pages/properties/propertiesListing/propertiesMatching/singlePropertyMatching';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { formatRemainingTime } from '../../../../pages/properties/propertiesListing/propertiesMatching/matchingStatus';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PromotionMatchingModal from '../../../../pages/promotions/promotionsListing/promotionsMatching/matchingPopup';
import { IPromotionApi, IPromotionListItem } from '../../../../types/promotion';

interface Props {
  matchingRemainingTime: IMercureMatchingTime | null;
  small?: boolean;
  isStatic?: boolean;
  item: PropertyListingItem | IPromotionListItem | IPropertyApi | IPromotionApi;
  isPromotion?: boolean;
}

export const matchingStatuses = {
  PENDING: 1,
  RUNNING: 2,
  READY: 3,
  EMPTY: 4,
  ERROR: 5,
};

const getMatchingTitleKey = (
  matching: IMercureMatchingTime | IMatchingProperty | null,
  t: any
) => {
  let key: string | null = null;

  if (!matching) {
    return key;
  }

  if (!matching.status) {
    return key;
  }

  switch (matching.status) {
    case 'running':
      key = null;
      break;
    case 'completed':
      key = t('view_matching');
      break;
    case matchingStatuses.RUNNING:
      key = t('Matching_in_progress');
      break;
    case matchingStatuses.READY:
      key = t('view_matching');
      break;
    case matchingStatuses.EMPTY:
      key = t('view_matching_empty');
      break;
    case matchingStatuses.PENDING:
      key = t('Matching_is_pending');
      break;
    case matchingStatuses.ERROR:
      key = t('Matching_error');
      break;
  }

  return key;
};

const Popup = (props: {
  isOpen: boolean;
  onClose: () => void;
  matching: IMercureMatchingTime | IMatchingProperty | null;
  item: PropertyListingItem | IPropertyApi | IPromotionListItem | IPromotionApi;
  isPromotion?: boolean;
}) => {
  if (!props.matching) {
    return null;
  }

  const propertyId =
    'remaining_time' in props.matching
      ? props.matching.matching
      : props.item?.matching?.id;

  const promotionId =
    'remaining_time' in props.matching
      ? props.matching.promotion
      : props.item?.id;

  const id = props.isPromotion ? promotionId : propertyId;

  return id ? (
    <>
      {props.isPromotion ? (
        <PromotionMatchingModal
          id={id}
          handleCloseModal={props.onClose}
          isModalOpen={props.isOpen}
        />
      ) : (
        <SinglePropertyMatchingModal
          handleCloseModal={props.onClose}
          isModalOpen={props.isOpen}
          matchingId={id}
        />
      )}
    </>
  ) : null;
};

export const MatchingIndicator = (props: Props) => {
  const { small, isStatic, matchingRemainingTime, item, isPromotion } = props;
  const [currentMatchingData, setCurrentMatchingData] = useState<
    IMercureMatchingTime | IMatchingProperty | null
  >(item.matching as IMatchingProperty);
  const [viewModalMatching, setViewModalMatching] = useState(false);
  const { t } = useTranslation('propertiesPage');

  useEffect(() => {
    if (matchingRemainingTime) {
      const itemId =
        matchingRemainingTime.property || matchingRemainingTime.promotion;

      if (itemId === item.id) {
        setCurrentMatchingData(matchingRemainingTime);
      }
    }
  }, [matchingRemainingTime]);

  const handleOpenModalMatching = () => {
    setViewModalMatching(true);
  };

  return currentMatchingData ? (
    <div className="flex gap-2 flex-col justify-end">
      <>
        {'remaining_time' in currentMatchingData &&
        currentMatchingData.remaining_time &&
        currentMatchingData.remaining_time > 1 ? (
          <>
            <div
              className={classNames(
                'cursor-pointer self-stretch justify-start items-center gap-1 inline-flex text-[#ED6C02] px-1 py-1 pr-2 bg-neutral-100 rounded justify-start items-center gap-1 flex',
                {
                  'h-[47px]': isStatic,
                }
              )}
            >
              <AccessTimeIcon
                sx={{
                  width: '14px',
                  height: '14px',
                }}
                fontSize={'small'}
                color={'inherit'}
              />
              <div
                className={classNames(
                  'text-[10px] font-medium leading-none flex gap-1',
                  {
                    'whitespace-nowrap': isStatic,
                    'flex-wrap': !isStatic,
                  }
                )}
              >
                {!small ? (
                  <span className={'first-letter:uppercase'}>
                    {t('propertiesPage:ready_in')}{' '}
                  </span>
                ) : null}
                <span>
                  {formatRemainingTime(currentMatchingData.remaining_time, t)}
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <Tooltip
              title={<span>{getMatchingTitleKey(currentMatchingData, t)}</span>}
              arrow
              placement="top"
            >
              <div
                onClick={handleOpenModalMatching}
                className={classNames(
                  'bg-neutral-100 rounded justify-start items-center gap-1 flex',
                  {
                    'text-[#ED6C02]':
                      currentMatchingData.status === matchingStatuses.PENDING ||
                      currentMatchingData.status === matchingStatuses.RUNNING,
                    'text-[#4CAF50] cursor-pointer':
                      currentMatchingData.status === matchingStatuses.READY ||
                      currentMatchingData.status === 'completed',
                    'text-blue cursor-pointer':
                      currentMatchingData.status === matchingStatuses.EMPTY,
                    'p-1': !small,
                    'p-[3px] pr-1': small,
                    'justify-center w-[37px] h-[37px]': isStatic,
                    'justify-start': isStatic,
                  }
                )}
              >
                <span className="opacity-70 flex">
                  {currentMatchingData.status === matchingStatuses.PENDING ||
                  currentMatchingData.status === matchingStatuses.RUNNING ? (
                    <AccessTimeIcon
                      sx={{
                        width: small ? '14px' : '18px',
                        height: small ? '14px' : '18px',
                      }}
                      fontSize={'small'}
                      color={'inherit'}
                    />
                  ) : (
                    <DoneAllIcon
                      sx={{
                        width: small ? '16px' : '18px',
                        height: small ? '16px' : '18px',
                      }}
                    />
                  )}
                </span>
              </div>
            </Tooltip>
          </>
        )}
      </>
      {viewModalMatching ? (
        <Popup
          matching={currentMatchingData}
          item={item}
          isOpen={viewModalMatching}
          onClose={() => setViewModalMatching(false)}
          isPromotion={isPromotion}
        />
      ) : null}
    </div>
  ) : null;
};
