import { useEffect, useState } from 'react';
import TitleHeader from '../../../components/common/titleHeader';
import { Box, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { http } from '../../../libs/axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFieldArray, useForm } from 'react-hook-form';
import InputFormField from '../../../components/form/inputFormField';
import AddressFields from './addressFields';
import PlusIcon from '../../../features/icons/plusIcon';
import RemoveIcon from '../../../features/icons/removeIcon';
import { sectorAddresses } from './createAddress';
import useLocale from '../../../hooks/useLocale';
import SaveButton from '../../../components/stepper/saveButton';
import { Translations } from '../../../types/translations';
import { AddressElement } from '.';
export interface PlaceDetails {
  id?: string;
  city?: string;
  zipCode?: string;
  translations?: Translations;
}

const EditAddress = () => {
  const { t } = useTranslation('common');
  const { locale } = useLocale();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AddressElement | null>(null);
  const { register, watch, control, handleSubmit, setValue } = useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const fieldArray = useFieldArray({
    control,
    name: 'sectorAddresses',
  });
  const name = 'sectorAddresses';
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      await http.patch('/sectors/' + id, {
        name: data.name,
        sectorAddresses: data.sectorAddresses.map(
          (item: { translations: Translations; zipCode: string }) => ({
            city: item.translations[locale]?.city,
            zipCode: item.zipCode,
          })
        ),
      });
      toast.success(t('address_updated_successfully') as string, {
        position: 'bottom-center',
        autoClose: 3000,
        style: { width: '500px' },
      });

      navigate('/settings/addresses');
    } catch (error: any) {
      toast.error(t('Error_updating_address') as string, {
        position: 'bottom-center',
        autoClose: 3000,
        style: { width: '500px' },
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await http.get('/sectors/' + id);
        setData(res.data);
        setValue('name', res.data.name);
        fieldArray.replace(res.data.sectorAddresses || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <TitleHeader
        title={''}
        titleElement={
          <div className="sm:!mb-[10%] !mr-2 sm:!mr-0 w-[90%]">
            <Typography className="!text-lg sm:!text-3xl">
              {t('edit_address')}
            </Typography>
          </div>
        }
        goBackLink="/settings/addresses"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-[40%] mb-10">
            <InputFormField
              isRequired
              {...register('name')}
              defaultValue={data ? data.name : ''}
              label={t('address_name')}
              variant="outlined"
            />
          </div>

          {fieldArray.fields.map((element: PlaceDetails, index: number) => {
            const addressElement = {
              id: element?.id || '',
              city: element?.city || '',
              zipCode: element?.zipCode || '',
              translations: element?.translations || {},
            };
            return (
              <div key={index} className="grid grid-cols-12 gap-4 mb-8">
                <div className="col-span-10">
                  <AddressFields
                    register={register}
                    control={control}
                    watch={watch}
                    name={name}
                    index={index}
                    element={addressElement}
                  />
                </div>
                <div className="flex items-center mt-6 xl:gap-4">
                  <button
                    disabled={!watch(`${name}.${index}.zipCode`)}
                    type="button"
                    onClick={() => fieldArray.append({ ...sectorAddresses })}
                    className={`text-white rounded px-2 xl:px-3 py-2 inline-flex items-center lg:gap-2 w-[10vw] ${
                      watch(`${name}.${index}.zipCode`)
                        ? 'bg-blue'
                        : 'bg-gray-400 cursor-not-allowed'
                    }`}
                  >
                    <PlusIcon
                      className="hidden min-[1100px]:block"
                      color="currentcolor"
                    />
                    {t('add')}
                  </button>
                  <div>
                    <IconButton
                      type="button"
                      onClick={() => {
                        setValue(
                          `${name}.${index}.translations.${locale}.city`,
                          ''
                        );
                        setValue(`${name}.${index}.zipCode`, '');

                        fieldArray.fields.length > 1 &&
                          fieldArray.remove(index);
                      }}
                      aria-label="Remove"
                    >
                      <RemoveIcon className="text-attention" />
                    </IconButton>
                  </div>
                </div>
              </div>
            );
          })}
          <Box className="w-full flex  justify-end px-16 py-8">
            <SaveButton isLoading={loading} />
          </Box>
        </form>
      </TitleHeader>
    </div>
  );
};
export default EditAddress;
