import { useEffect, useState } from 'react';

import TitleHeader from '../../../components/common/titleHeader';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import LangSwitcher from '../../../layout/footer/langSwitcher';
import { Locale } from '../../../hooks/useLocale';
import InputFormField from '../../../components/form/inputFormField';
import { http } from '../../../libs/axios';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function EditCustomTags() {
  const { t } = useTranslation('propertiesPage');
  const [locale, setLocale] = useState<Locale>('en');
  const [data, setData] = useState<any>([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const { register, setValue } = useForm<any>();
  const [value, setValueState] = useState<string>('');
  const [defaultVal, setDefaultVal] = useState<string>('');
  const handleInputChange = (newValue: string) => {
    inputDefaultValue(newValue, false);
    setValueState(newValue);
    setValue(`translations.${locale}.name`, newValue);
  };

  const onSubmit = async () => {
    try {
      data.translations[locale] = {
        name: value,
        locale: locale,
      };
      await http.patch('data_field_tags/' + id, data);
      toast.success(t('Custom_attribute_tag_edited_successfully') as string, {
        position: 'bottom-center',
        autoClose: 3000,
        style: { width: '500px' },
      });

      navigate('/settings/customAttrTags');
    } catch (error: any) {
      if (error.response.status === 400) {
        toast.error(t('You_are_trying_to_update_an_existing_tag') as string, {
          position: 'bottom-center',
          autoClose: 3000,
          style: { width: '500px' },
        });
      } else {
        toast.error(t('Error_editing_custom_attribute_tag') as string, {
          position: 'bottom-center',
          autoClose: 3000,
          style: { width: '500px' },
        });
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await http.get('data_field_tags/' + id);
        setData(res.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    inputDefaultValue('', true);
  }, []);

  useEffect(() => inputDefaultValue('', true), [data, locale]);

  const inputDefaultValue = (text: string, select: boolean) => {
    !select
      ? setDefaultVal(text)
      : data && data.translations && data.translations[locale]?.name
      ? setDefaultVal(
          data && data.translations && data.translations[locale]?.name
        )
      : setDefaultVal('');
  };
  return (
    <div>
      <TitleHeader
        title={''}
        titleElement={
          <div className="sm:!mb-[10%] !mr-2 sm:!mr-0 w-[90%]">
            <Typography className="!text-lg sm:!text-3xl">
              {t('Edit_Custom_Attributes_Tags')}
            </Typography>
          </div>
        }
        goBackLink="/settings/customAttrTags"
        rightElement={
          <Button
            className="sm:!mt-[7%] sm:!mr-5"
            variant="contained"
            disableElevation
            onClick={onSubmit}
            startIcon={
              <CheckIcon className="!hidden sm:!block" fontSize="small" />
            }
          >
            {t('usersPage:save')}
          </Button>
        }
      >
        <div className="sm:!w-[90%] sm:mx-auto flex flex-col sm:flex-row justify-between gap-8">
          <div className="w-[100%] text-center sm:text-left sm:w-[50%] mt-2">
            <LangSwitcher
              inputDefaultValue={inputDefaultValue}
              currentLocale={locale}
              setLanguage={setLocale}
              changeAppLocale={false}
              className="!w-[40%] sm:!w-[65%] lg:!w-[45%] !mx-0 !h-16"
            />
          </div>
          <div className="w-full sm:w-[50%]">
            <InputFormField
              label={t('Edit_Custom_Attribute_Tag')}
              type="text"
              value={defaultVal}
              {...register(`translations.${locale}.name`, {
                onChange: (e) => handleInputChange(e.target.value),
              })}
            />
          </div>
        </div>
      </TitleHeader>
    </div>
  );
}
