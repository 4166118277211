import { GridColumns } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Skeleton, Tooltip } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import CreatedUpdated from '../../../components/tableGridX/createdUpdated';
import { Pipeline, PipelineProjectTypes } from '../../../types/pipelines';
import PipelinesActionMenu from './actionMenu';
import useLocale from '../../../hooks/useLocale';
import CircleWithFullName from '../../../components/tableGridX/circleWithFullName';

export const useColumns = ({
  isLoading,
  mutate,
}: {
  isLoading?: boolean;
  mutate?: () => Promise<unknown>;
}): GridColumns<Pipeline> => {
  const { t } = useTranslation('common');
  const { locale } = useLocale();
  const isHigherThen768 = useMediaQuery('(min-width:768px)');
  const commonSettings = {
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  };

  const columns: GridColumns<Pipeline> = [
    {
      field: 'name',
      width: 210,
      flex: 0,
      headerName: t('Name'),
      renderCell: ({ row }) => {
        return (
          <Tooltip
            title={row.name || `${t('unnamed')} #${row.id}`}
            arrow
            placement="top"
          >
            <Link
              to={`/settings/pipelines/${row.id}`}
              className="capitalize font-medium text-base leading-5 truncate"
            >
              {row.name || `${t('unnamed')} #${row.id}`}
            </Link>
          </Tooltip>
        );
      },
    },
    {
      field: 'projectType',
      minWidth: 110,
      flex: 1,
      headerName: t('project_type'),
      renderCell: ({ row }) => {
        let type = '';
        if (row.projectType === PipelineProjectTypes.PROPERTY) {
          type = t('property');
        } else if (row.projectType === PipelineProjectTypes.PROMOTION) {
          type = t('Promotion');
        }
        return <span className="capitalize">{type}</span>;
      },
    },
    {
      field: 'service',
      minWidth: 110,
      flex: 1,
      headerName: t('service_type'),
      renderCell: ({ row }) => {
        return (
          <span className="capitalize">
            {row.serviceType?.translations?.[locale]?.name || ''}
          </span>
        );
      },
    },
    {
      field: 'usedOn',
      width: 110,
      flex: 0,
      cellClassName: '!pl-4',
      headerName: t('used_on'),
    },
    {
      field: 'createdBy',
      minWidth: 110,
      flex: 1,
      headerName: t('brochureEditor:created_by'),
      renderCell: ({ row }) => {
        return row.createdBy && <CircleWithFullName {...row.createdBy} />;
      },
    },
    {
      field: 'createdUpdated',
      width: 190,
      flex: 0,
      cellClassName: '!pl-0',
      renderHeader: () => t('date_created'),
      renderCell: ({ row }) => (
        <CreatedUpdated created={row.createdAt || undefined} updated={null} />
      ),
    },
    {
      field: 'status',
      minWidth: 110,
      flex: 1,
      headerName: t('Status'),
      headerClassName: ` ${!isHigherThen768 && '!border-r-0'}`,
      valueGetter: ({ row }) => (row.status ? t(row.status) : ''),
    },
    {
      field: 'actions',
      minWidth: 80,
      flex: 1,
      headerName: t('actions'),
      headerClassName: '!border-r-0 !hidden md:!block',
      renderCell: ({ row }) =>
        isHigherThen768 && <PipelinesActionMenu row={row} mutate={mutate} />,
    },
  ];

  return columns.map((column) => ({
    ...commonSettings,
    ...column,
    ...(isLoading
      ? {
          renderCell: () => (
            <Skeleton
              variant="text"
              animation="wave"
              width="100%"
              height={20}
            />
          ),
        }
      : {}),
  }));
};
