import { Alert, Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InputPassword from '../../components/form/inputPassword';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { http } from '../../libs/axios';
import AuthLayout from '../../layout/authLayout';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Tokens, useAuth, UserPayload } from '../../hooks/useAuth';
import { useThemeMode } from '../../hooks/useThemeMode';
import MountTransition from '../../layout/mountTransition';

type Props = {
  type: 'set' | 'reset';
};
type FormT = {
  password: string;
  confirmPassword: string;
};

const schema = yup
  .object()
  .shape({
    password: yup.string().min(6),
    confirmPassword: yup.string().when('password', {
      is: (value: string) => value?.length,
      then: yup.string().oneOf([yup.ref('password')]),
    }),
  })
  .required();

export default function ModifyPassword({ type }: Props) {
  const { login } = useAuth();
  const { token } = useParams();
  const [isTokenValid, setToken] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [isPasswordSetSuccessfully, setPasswordSuccessfully] =
    useState<boolean>(false);
  const { t } = useTranslation('auth');
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FormT>({ resolver: yupResolver(schema) });

  useEffect(() => {
    const checkToken = async () => {
      try {
        if (type === 'set') {
          await http.post('/users/password/check-token', {
            token,
          });
          toast.success(t('propertiesPage:your_email_is_activated') as string, {
            position: 'bottom-center',
            autoClose: 4000,
          });
        } else {
          await http.post('/users/password/check-token', {
            resetToken: token,
          });
        }
        setToken(true);
      } catch (e: unknown) {
        setToken(false);
        navigate('/signin');
        console.log('error', e);
      }
    };
    checkToken();
  }, [type]);

  const formTitle = type === 'set' ? 'set_password' : 'reset_password';

  const onSubmit = async ({ password }: FormT) => {
    const querykey =
      type === 'set'
        ? '/users/password/activate'
        : '/users/password/reset-password';

    const queryBody =
      type === 'set'
        ? {
            password,
            token,
          }
        : {
            password,
            resetToken: token,
          };

    try {
      const { data } = await http.post(querykey, queryBody);
      if (type === 'set') {
        const { token, refresh_token, user } = data[0];
        login({ token, refresh_token } as Tokens, user as UserPayload);
        navigate('/profile');
      } else if (type === 'reset') {
        setPasswordSuccessfully(true);
      }
    } catch (e: unknown) {
      console.log('error', e);
      setError(true);
    }
  };
  const { mode } = useThemeMode();

  if (!isTokenValid) return null;

  return (
    <AuthLayout>
      <MountTransition
        mountDelay={100}
        className="transition-transform duration-700"
        transitionStyles={{
          exited: { transform: 'translateX(-100%)' },
          entered: { transform: 'translateX(0%)' },
        }}
      >
        <Box
          sx={{
            backgroundColor: mode === 'dark' ? 'background.default' : '#fff',
            color: 'text.primary',
          }}
          className=" px-8 py-10 shadow-sm"
        >
          <Box className="text-center mb-8">
            <Typography className="!mb-2" variant="h5">
              {t(formTitle)}
            </Typography>
          </Box>

          {error ? (
            <Stack spacing={2} alignItems="stretch" direction="column">
              <Alert severity="error">
                {t('reset_password_error_message')}
              </Alert>

              <Button
                variant="contained"
                disableElevation
                onClick={() => navigate('/forget-password')}
              >
                {t('request_new_reset_request')}
              </Button>
            </Stack>
          ) : isPasswordSetSuccessfully ? (
            <Stack spacing={2} alignItems="stretch" direction="column">
              <Alert severity="success">
                {t('reset_password_successfully')}
              </Alert>
            </Stack>
          ) : (
            <Box
              component="form"
              className="flex flex-col gap-y-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <InputPassword
                helperText={t('password_length', { length: 6 })}
                label={t('set_new_password')}
                error={Boolean(errors.password)}
                {...register('password')}
              />

              <InputPassword
                helperText={t('passwords_do_not_match')}
                label={t('confirm_new_password')}
                error={Boolean(errors.confirmPassword)}
                {...register('confirmPassword')}
              />

              <Button
                variant="contained"
                disableElevation
                type="submit"
                disabled={isSubmitting}
              >
                <span> {t(formTitle)}</span>
              </Button>
            </Box>
          )}
        </Box>

        <Box className="mt-3 text-right">
          <Link className="text-primary" to="/signin">
            {t('login')}
          </Link>
        </Box>
      </MountTransition>
    </AuthLayout>
  );
}
