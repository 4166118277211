import {
  FormControl,
  MenuItem,
  Select,
  SelectProps,
  SelectVariants,
} from '@mui/material';
import classNames from 'classnames';
import { forwardRef } from 'react';

import InputLayout from './components/inputLayout';

export type selectOptionStringT = {
  id: string | number;
  value: string;
  locale?: string;
};

export type SelectFormFieldProps = Omit<SelectProps, 'variant'> & {
  options: selectOptionStringT[];
  label?: string;
  isRequired?: boolean;
  className?: string;
  error?: string;
  defaultValue?: string | string[] | null | number;
  isDisable?: boolean;
  placeholder?: string;
  variant?: SelectVariants;
  capitalize?: boolean | null;
};

const SelectFormField = forwardRef<HTMLSelectElement, SelectFormFieldProps>(
  (
    {
      label,
      isDisable,
      placeholder,
      className,
      isRequired = false,
      error,
      options,
      defaultValue = '',
      capitalize,
      ...rest
    },
    ref
  ) => {
    return (
      <InputLayout
        capitalize={capitalize !== null ? capitalize : undefined}
        label={label}
        isRequired={isRequired}
      >
        <FormControl error={!!error} fullWidth>
          <Select
            placeholder={placeholder}
            disabled={isDisable}
            className={classNames('w-full h-12', className)}
            displayEmpty
            defaultValue={defaultValue}
            required={isRequired}
            ref={ref}
            {...rest}
          >
            {options?.map((item: selectOptionStringT, index: number) => (
              <MenuItem
                id={`${item.value}_${item.id}`}
                key={index}
                value={item?.id}
                className="capitalize"
              >
                {item.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </InputLayout>
    );
  }
);

SelectFormField.displayName = 'SelectFormField';

export default SelectFormField;
