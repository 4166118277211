import { t } from 'i18next';

import {
  Choice,
  PropertyWidgetPreviewProps,
} from '../../../../../types/brochureEditor';
import { FieldValue, IPropertyApi } from '../../../../../types/property';

import TableWidget from '../../tableWidget';
import { guaranteeArray } from '../../../../../helpers/utils/formatters';
import useLocale from '../../../../../hooks/useLocale';

const CustomAttributesWidget = ({
  property,
  widgetOptions,
}: PropertyWidgetPreviewProps) => {
  const tag = widgetOptions?.tag?.value;
  const fieldValues = guaranteeArray(property.fieldValues);

  const attributesRelatedToTag = fieldValues.filter((value) => {
    if (!value) {
      return false;
    }

    if (tag === SpecialValues.ALL) {
      return true;
    }

    if (tag === SpecialValues.NONE) {
      return !value.dataField.tag;
    }

    return String(value.dataField.tag?.id) === tag;
  }) as FieldValue[];

  const { locale } = useLocale();
  const mapTagsToAttributes: Record<number | string, FieldValue[]> = {};
  for (const attr of attributesRelatedToTag) {
    const tagName = attr.dataField.tag?.translations[locale]?.name || '';
    if (!mapTagsToAttributes[tagName]) {
      mapTagsToAttributes[tagName] = [];
    }
    const arr = mapTagsToAttributes[tagName] as FieldValue[];
    arr.push(attr);
  }

  const tables = Object.entries(mapTagsToAttributes).map(
    ([tagName, fieldValues]) => ({
      title: tagName,
      lines: fieldValues.map((value) => ({
        id: String(value.dataField.id),
        label: value.dataField.translations[locale]?.name || '',
        value: customAttributeValue(value, locale),
      })),
    })
  );

  return (
    <div className="flex flex-col gap-4">
      {tables.map((table) => (
        <TableWidget
          key={table.title}
          title={table.title}
          items={table.lines}
          widgetOptions={widgetOptions}
        />
      ))}
    </div>
  );
};

export default CustomAttributesWidget;

export const tagOptionsGetter = (
  { property }: { property?: IPropertyApi },
  locale: string
) => {
  const tagOptions: Choice[] = [
    {
      label: t('propertiesPage:any_tag'),
      value: SpecialValues.ALL,
    },
    {
      label: t('propertiesPage:no_tag'),
      value: SpecialValues.NONE,
    },
  ];
  if (property) {
    for (const step of Object.values(property.dataFields)) {
      for (const [id, fields] of Object.entries(step)) {
        tagOptions.push({
          value: id,
          label: fields[0]?.tag?.translations?.[locale]?.name || '',
        });
      }
    }
  }
  return tagOptions;
};

// search codebase `fieldTypes` for more info
function customAttributeValue(
  { dataField, value }: FieldValue,
  locale: string
) {
  if (String(dataField.type) === '1' || String(dataField.type) === '2') {
    const options = dataField.fieldSelect.options;
    return value
      .map((id) => {
        const option = options.find(
          (option) => String(option.id) === String(id)
        );
        return option?.translations[locale]?.value;
      })
      .filter(Boolean)
      .join(', ');
  }
  return value.join(', ');
}

export enum SpecialValues {
  ALL = 'ALL',
  NONE = 'NONE',
}
