import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import {
  DetailsTableOption,
  PropertyWidgetPreviewProps,
} from '../../../../../types/brochureEditor';
import TableWidget from '../../tableWidget';

const PropertySurfacesWidget = ({
  property,
  widgetOptions,
}: PropertyWidgetPreviewProps) => {
  const { t } = useTranslation('propertiesPage');

  const formatSurface = useCallback(
    (value: number, suffix = '', addFromPrefix = false) => {
      if (value) {
        let string = addDigitSeparatorsToNumber(value);

        if (property.useTildeForSurface) {
          string = `~${string}`;
        }
        if (addFromPrefix) {
          string = `${t('propertiesPage:from')} ${string}`;
        }
        if (suffix) {
          string += ` ${suffix}`;
        }

        return string;
      }
      return '';
    },
    [property, t]
  );

  const withLivingArea = widgetOptions?.withLivingArea?.value;
  const withUsefulArea = widgetOptions?.withUsefulArea?.value;
  const withPPESurface = widgetOptions?.withPPESurface?.value;
  const withLandSurface = widgetOptions?.withLandSurface?.value;
  const withTerraceSurface = widgetOptions?.withTerraceSurface?.value;
  const withLoggiaSurface = widgetOptions?.withLoggiaSurface?.value;
  const withGardenSurface = widgetOptions?.withGardenSurface?.value;
  const withVolume = widgetOptions?.withVolume?.value;
  const withSurfaceEmprise = widgetOptions?.withSurfaceEmprise?.value;
  const withAbriToiture = widgetOptions?.withAbriToiture?.value;

  return (
    <TableWidget
      title={t('brochureEditor:surfaces')}
      items={
        [
          withLivingArea && {
            id: '10',
            label: t('Living_Area'),
            value: formatSurface(
              property.livingArea,
              'm²',
              property.addFromToLivingArea
            ),
          },
          withUsefulArea && {
            id: '11',
            label: t('useful_area'),
            value: formatSurface(
              property.usefulArea,
              'm²',
              property.addFromToUsefulArea
            ),
          },
          withPPESurface && {
            id: '111',
            label: t('PPE_surface'),
            value: formatSurface(property.surfacePPE, 'm²'),
          },
          withLandSurface && {
            id: '12',
            label: t('Land_surface'),
            value: formatSurface(
              property.landSurface,
              'm²',
              property.addFromToLandSurface
            ),
          },
          withTerraceSurface && {
            id: '13',
            label: t('terrace_surface'),
            value: formatSurface(property.terraceSurface, 'm²'),
          },
          withLoggiaSurface && {
            id: '131',
            label: t('Loggia_surface'),
            value: formatSurface(property.surfaceLoggia, 'm²'),
          },
          withGardenSurface && {
            id: '14',
            label: t('garden_surface'),
            value: formatSurface(property.gardenSurface, 'm²'),
          },
          withVolume && {
            id: '15',
            label: t('Volume'),
            value: formatSurface(property.volume, 'm³'),
          },
          withSurfaceEmprise && {
            id: '16',
            label: t('common:Surface_emprise'),
            value: formatSurface(
              property.surfaceEmprise,
              'm²',
              property.addFromToSurfaceEmprise
            ),
          },
          withAbriToiture && {
            id: '17',
            label: t('common:Abri_toiture'),
            value: formatSurface(
              property.abriToiture,
              'm²',
              property.addFromToAbriToiture
            ),
          },
        ].filter(Boolean) as DetailsTableOption[]
      }
      widgetOptions={widgetOptions}
    />
  );
};

export default PropertySurfacesWidget;

function addDigitSeparatorsToNumber(number: number) {
  return number.toLocaleString('de-CH');
}
