import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

import SelectPdfTemplate from '../../../components/common/selectPdfTemplate';
import { IPropertyApi } from '../../../types/property';
import { renderFullPropertyAdress } from '../../../helpers/utils';
import BackLink from '../../../components/common/backLink';
import EditPropertyButton from './editPropertyButton';
import { PreviewSubjectTypes } from '../../../types/brochureEditor';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import React, { useState } from 'react';
import ViewArchivedLeads from '../propertiesListing/viewArchivedLeads';

const SinglePropertyHeader = ({ property }: { property: IPropertyApi }) => {
  const { t } = useTranslation('propertiesPage');
  const addressString = renderFullPropertyAdress(property);
  const [viewModalArchivedLeads, setViewModalArchivedLeads] = useState(false);

  return (
    <Box className="w-full flex flex-col mb-2">
      <Box className="col-span-2 flex mb-4">
        <BackLink url="/properties" label="usersPage:Back_to_list" />
      </Box>
      <Box className="w-full pb-4 grid grid-cols-4">
        <Box className="col-span-2 flex flex-col mr-2">
          <Typography variant="h2" className="text-3xl mb-2">
            {t('property_details')}
          </Typography>

          <Typography className=" col-span-1 text-base text-gray-400">
            {addressString}
          </Typography>
        </Box>
        <Box className="col-span-2 flex gap-4 items-center justify-end py-4">
          {property.isNetworkProperty || property.archive ? null : (
            <>
              <SelectPdfTemplate
                subjectId={String(property.id)}
                subjectType={PreviewSubjectTypes.PROPERTY}
              />

              <EditPropertyButton propertyId={property.id} />
            </>
          )}
          {property.archive ? (
            <>
              <Button
                variant={'outlined'}
                className={'flex gap-2'}
                onClick={() => setViewModalArchivedLeads(true)}
              >
                <ArchiveOutlinedIcon
                  fontSize="small"
                  sx={{
                    width: '16px',
                    height: '14px',
                  }}
                />
                <div className="font-medium">
                  {' '}
                  {t('propertiesPage:archived_leads')}{' '}
                </div>
              </Button>
              {viewModalArchivedLeads && (
                <ViewArchivedLeads
                  id={Number(property.id)}
                  isModalOpen={viewModalArchivedLeads}
                  setIsModalOpen={setViewModalArchivedLeads}
                />
              )}
            </>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default SinglePropertyHeader;
