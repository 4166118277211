import { GridColumns } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Skeleton, Tooltip } from '@mui/material';
import { ILeadItem } from '../../../types/contacts';
import {
  DateCell,
  TypeCell,
  StatusCell,
  ReferenceCell,
  ContactCell,
  SourceCell,
  MessageCell,
} from './leadTableCells';
import useLocale from '../../../hooks/useLocale';
import LeadActionsMenu from '../../contacts/singleContactPage/utils/leadActions';
import { KeyedMutator } from 'swr';
export const useLeadsListColumns = ({
  isLoading,
  mutate,
}: {
  isLoading?: boolean;
  mutate?: KeyedMutator<unknown>;
}): GridColumns<ILeadItem> => {
  const { t } = useTranslation();
  const { locale } = useLocale();

  const commonSettings = {
    sortable: false,
    disableColumnMenu: true,
  };

  const columns: GridColumns<ILeadItem> = [
    {
      field: 'date',
      headerName: t('common:Date'),
      width: 130,
      renderCell: ({ row }) => (
        <DateCell item={row} wrapperClassName={'h-full pt-4'} />
      ),
    },
    {
      maxWidth: 100,
      field: 'type',
      renderHeader: () => (
        <Tooltip title={t('common:Deal_type')} placement="top" arrow>
          <span className="block truncate font-medium">
            {t('common:Deal_type')}
          </span>
        </Tooltip>
      ),
      renderCell: ({ row }) => (
        <TypeCell
          item={row}
          locale={locale}
          wrapperClassName={'h-full pt-4 pl-[6px]'}
        />
      ),
    },
    {
      width: 135,
      field: 'status',
      headerName: t('common:Status'),
      renderCell: ({ row }) => (
        <StatusCell
          item={row}
          withSendEmail
          wrapperClassName={'h-full pt-4 pl-[6px]'}
        />
      ),
    },
    {
      field: 'reference',
      width: 270,
      headerName: t('common:Property_reference'),
      renderCell: ({ row }) => (
        <ReferenceCell
          item={row}
          locale={locale}
          wrapperClassName={'max-w-full h-[100px] py-2'}
        />
      ),
    },
    {
      width: 160,
      field: 'contact',
      headerName: t('common:Contact'),
      renderCell: ({ row }) => (
        <ContactCell item={row} wrapperClassName={'h-full pt-2'} />
      ),
    },
    {
      width: 200,
      field: 'source',
      headerName: t('common:Source'),
      renderCell: ({ row }) => (
        <SourceCell
          item={row}
          customHeight
          wrapperClassName={'!h-full pt-2 !text-xs'}
        />
      ),
    },
    {
      field: 'message',
      width: 220,
      headerName: t('common:Message'),
      renderCell: ({ row }) => (
        <MessageCell item={row} wrapperClassName={' pt-2'} />
      ),
    },
    {
      field: 'action',
      width: 80,
      headerClassName: '!border-r-0',
      cellClassName: '!ml-3',
      headerName: t('common:Action'),
      renderCell: ({ row }) => (
        <LeadActionsMenu
          row={{
            ...row,
            parentId: row.contact ? row.contact.id.toString() : '',
          }}
          mutateForStatus={mutate}
          withSendFiles
        />
      ),
    },
  ];

  return columns.map((column) => ({
    ...commonSettings,
    ...column,
    ...(isLoading
      ? {
          renderCell: () => (
            <Skeleton
              variant="text"
              animation="wave"
              width="100%"
              height={20}
            />
          ),
        }
      : {}),
  }));
};
