import { GridColDef } from '@mui/x-data-grid-pro';
import {
  Controller,
  UseFieldArrayReturn,
  useFormContext,
} from 'react-hook-form';
import Swal from 'sweetalert2';
import { ReactNode } from 'react';
import { IconButton } from '@mui/material';
import { DraggableProvided } from 'react-beautiful-dnd';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';

import {
  Dimension,
  IProperty,
  IPropertyApi,
  SpaceType,
} from '../../../../../../types/property';
import SwitcherIosFormField from '../../../../../../components/form/switcherIosFormField';
import SelectFormField, {
  selectOptionStringT,
} from '../../../../../../components/form/selectFormField';
import SelectWithOptionsFromApi from '../selectWithOptionsFromApi';

import useLocale from '../../../../../../hooks/useLocale';
import RemoveIcon from '../../../../../../features/icons/removeIcon';
import UserDragIcon from '../../../../../../features/icons/userDragIcon';
import PlusIcon from '../../../../../../features/icons/plusIcon';

export const defaultDimension: Dimension = {
  quantity: 1,
  spaceType: null,
  isHabitable: false,
  habitable: null,
  weighting: 1,
  usable: null,
  building: null,
  floor: null,
  orientation: null,
  flooring: null,
  celling: null,
  sort: null,
};

export function useColumns({
  fieldArray: { fields, append, remove },
  property,
}: {
  fieldArray: UseFieldArrayReturn<IProperty, 'dimensions', 'id'>;
  property?: Partial<IPropertyApi>;
}) {
  const rows = fields as Dimension[];

  const { register, getValues, watch, control, setValue } =
    useFormContext<IProperty>();

  const { data: spaceTypes } = useSWR<SpaceType[]>('/space_types_settings');
  const spaceTypeOptions = useSpaceTypeOptions(spaceTypes);

  const { t } = useTranslation('propertiesPage');

  const confirmRemoval = async (index: number) => {
    const { isConfirmed } = await Swal.fire({
      text: t('Are_you_sure_you_want_to_delete_this_space'),
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: t('common:Delete'),
      cancelButtonText: t('common:Cancel'),
      showClass: {
        popup: 'block',
      },
      hideClass: {
        popup: 'hidden',
      },
    });
    if (isConfirmed) {
      remove(index);
    }
  };

  const { data: blocks } = useSWR<{ name: string; id: number }[]>(
    `/v1/properties/${property?.id}/building_blocks`
  );
  const blocksOptions = blocks?.map((block) => ({
    id: block.id,
    value: block.name,
  })) as selectOptionStringT[];

  const columns: Column[] = [
    {
      field: 'id',
      width: '116px',
      renderHeader() {
        return (
          <div className="px-2">
            <button
              type="button"
              onClick={() => append({ ...defaultDimension })}
              className="bg-blue text-white rounded px-3 py-2 inline-flex items-center gap-2 w-full"
            >
              <PlusIcon color="currentcolor" />
              {t('common:add')}
            </button>
          </div>
        );
      },
      renderCell({ row, dragProvided }) {
        return (
          <div className="grid grid-cols-2 h-14 divide-x divide-x-gray-200 w-[116px]">
            <div className="grid place-items-center">
              <div {...dragProvided.dragHandleProps}>
                <UserDragIcon />
              </div>
            </div>
            <div className="grid place-items-center">
              <IconButton
                type="button"
                onClick={() => confirmRemoval(rows.indexOf(row))}
                aria-label="Remove"
              >
                <RemoveIcon className="text-attention" />
              </IconButton>
            </div>
          </div>
        );
      },
    },
    {
      field: 'quantity',
      headerName: t('quantity'),
      width: '92px',
      renderCell: ({ row }) => (
        <input
          type="number"
          min={1}
          max={1000}
          step={1}
          className="custom-cell-input w-[60px]"
          {...register(`dimensions.${rows.indexOf(row)}.quantity`, {
            valueAsNumber: true,
          })}
          onBlur={(e) => e.target.reportValidity()}
        />
      ),
    },
    {
      field: 'spaceType',
      width: '160px',
      headerName: t('common:Space_type'),
      renderCell: ({ row }) => {
        const index = rows.indexOf(row);
        const registerReturn = register(`dimensions.${index}.spaceType`);
        return (
          <div className="w-32">
            <SelectFormField
              options={spaceTypeOptions}
              className="h-8"
              defaultValue={getValues(`dimensions.${index}.spaceType`)}
              {...registerReturn}
              onChange={(e) => {
                registerReturn.onChange(e);
                const spaceType = spaceTypes?.find(
                  (item) => item.spaceType?.id === e.target.value
                );
                if (spaceType) {
                  setValue(
                    `dimensions.${index}.isHabitable`,
                    spaceType.isHabitable
                  );
                  setValue(
                    `dimensions.${index}.weighting`,
                    spaceType.weighting
                  );
                }
              }}
            />
          </div>
        );
      },
    },
    {
      field: 'isHabitable',
      width: '97px',
      headerName: t('common:Habitable'),
      renderCell: ({ row }) => (
        <Controller
          control={control}
          name={`dimensions.${rows.indexOf(row)}.isHabitable`}
          render={({ field }) => (
            <div className="flex justify-center">
              <SwitcherIosFormField {...field} checked={field.value || false} />
            </div>
          )}
        />
      ),
    },
    {
      field: 'habitable',
      width: '112px',
      renderHeader: () => <WithPerUnitNote>{t('Living_Area')}</WithPerUnitNote>,
      renderCell: ({ row }) => (
        <CustomAdornmentAdder>
          <input
            type="number"
            min={0}
            max={10000}
            step={0.01}
            className="custom-cell-input"
            {...register(`dimensions.${rows.indexOf(row)}.habitable`, {
              valueAsNumber: true,
            })}
            onBlur={(e) => e.target.reportValidity()}
          />
        </CustomAdornmentAdder>
      ),
    },
    {
      field: 'weighting',
      width: '168px',
      renderHeader: () => (
        <WithPerUnitNote>{t('common:weighting')}</WithPerUnitNote>
      ),
      renderCell: ({ row }) => {
        const habitable =
          watch(`dimensions.${rows.indexOf(row)}.habitable`) || 0;
        const weighting =
          watch(`dimensions.${rows.indexOf(row)}.weighting`) || 0;
        const result = habitable * weighting;
        return (
          <div className="flex items-center gap-2.5">
            x
            <input
              type="number"
              min={0}
              max={1}
              step={0.01}
              className="custom-cell-input w-10"
              {...register(`dimensions.${rows.indexOf(row)}.weighting`, {
                valueAsNumber: true,
              })}
              onBlur={(e) => e.target.reportValidity()}
            />
            {result ? (
              <>
                =<span>{trimFloat(result)}m²</span>
              </>
            ) : null}
          </div>
        );
      },
    },
    {
      field: 'usable',
      width: '112px',
      renderHeader: () => <WithPerUnitNote>{t('useful_area')}</WithPerUnitNote>,
      renderCell: ({ row }) => (
        <CustomAdornmentAdder>
          <input
            type="number"
            min={0}
            max={10000}
            step={0.01}
            className="custom-cell-input"
            {...register(`dimensions.${rows.indexOf(row)}.usable`, {
              valueAsNumber: true,
            })}
            onBlur={(e) => e.target.reportValidity()}
          />
        </CustomAdornmentAdder>
      ),
    },
    {
      field: 'building',
      width: '112px',
      headerName: t('block'),
      renderCell: ({ row }) => (
        <SelectFormField
          options={blocksOptions}
          className="h-8"
          defaultValue={getValues(`dimensions.${rows.indexOf(row)}.building`)}
          {...register(`dimensions.${rows.indexOf(row)}.building`)}
        />
      ),
    },
    {
      field: 'floor',
      width: '112px',
      headerName: t('floor'),
      renderCell: ({ row }) => (
        <div className="w-20">
          <SelectWithOptionsFromApi
            endpoint="/floors"
            className="h-8"
            defaultValue={getValues(`dimensions.${rows.indexOf(row)}.floor`)}
            {...register(`dimensions.${rows.indexOf(row)}.floor`)}
          />
        </div>
      ),
    },
    {
      field: 'orientation',
      width: '112px',
      headerName: t('orientation'),
      renderCell: ({ row }) => (
        <div className="w-20">
          <SelectWithOptionsFromApi
            endpoint="/orientations"
            className="h-8"
            defaultValue={getValues(
              `dimensions.${rows.indexOf(row)}.orientation`
            )}
            {...register(`dimensions.${rows.indexOf(row)}.orientation`)}
          />
        </div>
      ),
    },
    {
      field: 'flooring',
      width: '112px',
      headerName: t('flooring'),
      renderCell: ({ row }) => (
        <div className="w-20">
          <SelectWithOptionsFromApi
            endpoint="/property_floorings"
            className="h-8"
            defaultValue={getValues(`dimensions.${rows.indexOf(row)}.flooring`)}
            {...register(`dimensions.${rows.indexOf(row)}.flooring`)}
          />
        </div>
      ),
    },
    {
      field: 'celling',
      renderHeader: () => (
        <WithPerUnitNote note="m">{t('ceiling')}</WithPerUnitNote>
      ),
      renderCell: ({ row }) => (
        <input
          type="number"
          min={0}
          max={10}
          step={0.01}
          className="custom-cell-input !w-10"
          {...register(`dimensions.${rows.indexOf(row)}.celling`, {
            valueAsNumber: true,
          })}
          onBlur={(e) => e.target.reportValidity()}
        />
      ),
    },
  ];

  const columnDefaults = {
    editable: false,
    sortable: false,
    disableColumnMenu: true,
  };

  return columns.map((col) => ({
    ...col,
    ...columnDefaults,
  }));
}

type Column = Omit<
  GridColDef<Dimension>,
  'width' | 'renderHeader' | 'renderCell'
> & {
  width?: string;
  renderHeader?(): ReactNode;
  renderCell(params: {
    row: Dimension;
    dragProvided: DraggableProvided;
  }): ReactNode;
};

function CustomAdornmentAdder({ children }: { children: ReactNode }) {
  return (
    <div className="relative custom-adornment-wrapper">
      {children}
      <span className="absolute top-1/2 -translate-y-1/2 right-2 pointer-events-none">
        m²
      </span>
    </div>
  );
}

function WithPerUnitNote({
  children,
  note = 'per_unit',
}: {
  children: ReactNode;
  note?: string;
}) {
  const { t } = useTranslation('propertiesPage');
  return (
    <div className="whitespace-pre-wrap leading-none">
      {children}
      {'\n'}
      <span className="text-xs">({t(note)})</span>
    </div>
  );
}

function useSpaceTypeOptions(spaceTypes: SpaceType[] | undefined) {
  const { locale } = useLocale();

  return spaceTypes
    ?.map((item) => {
      if (item.spaceType) {
        return {
          id: item.spaceType.id,
          value: item.spaceType.translations?.[locale]?.name || '',
        };
      }
      return null;
    })
    .filter(Boolean) as selectOptionStringT[];
}

function trimFloat(value: number) {
  if (value % 1 === 0) {
    return value;
  }
  return value.toFixed(2);
}
