import { CSSProperties } from 'react';

import { formatPrice } from '../../../../../helpers/utils';
import { useIsRent } from '../../../../../hooks/useIsRent';
import { PropertyWidgetPreviewProps } from '../../../../../types/brochureEditor';
import { useAlignmentStyles } from '../../../lib/hooks';

const PropertyJustPriceWidget = ({
  property,
  widgetOptions,
}: PropertyWidgetPreviewProps) => {
  const isRent = useIsRent(property);

  const textAlign = String(widgetOptions?.textAlign?.value);
  const color = String(widgetOptions?.color?.value);
  const textStyle = { textAlign, color } as CSSProperties;

  const gridStyle = useAlignmentStyles(widgetOptions);

  if (isRent !== null) {
    const source = isRent ? property.pricingRent : property.pricingBuy;
    if (!source.hidePriceOnBrochure) {
      return (
        <div className="grid" style={textStyle}>
          <div className="grid gap-4" style={gridStyle}>
            <div className="text-4xl font-bold">
              {formatPrice(source?.displayedPrice)}
            </div>
          </div>
        </div>
      );
    }
  }
  return null;
};

export default PropertyJustPriceWidget;
