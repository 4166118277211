import { Box, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';

import RedirectIcon from '../../../../features/icons/redirectIcon';
import { IPromotionApi } from '../../../../types/promotion';
import useLocale from '../../../../hooks/useLocale';

import DescriptionHeader from './header';
import PromotionStats from './stats';

interface Props {
  data: IPromotionApi;
}

function PromotionDescription({ data }: Props) {
  const { locale } = useLocale();
  const propertyDescription = useMemo(() => {
    if (data && data.translations) {
      const translationArray = data.translations[locale];

      if (translationArray) {
        return translationArray;
      } else {
        return { title: '', description: '' };
      }
    } else {
      return { title: '', description: '' };
    }
  }, [data.translations, locale]);

  const { title, description } = propertyDescription;

  return (
    <Stack
      sx={{ backgroundColor: 'background.white', color: 'text.primary' }}
      className="p-2 xl:p-8 flex flex-col gap-6 bg-white border border-gray-200 rounded"
    >
      <DescriptionHeader promotionTitle={title} data={data} />
      <div
        className={'opacity-80'}
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
      <PromotionStats data={data} />
      {data.youtubeLink ? (
        <Box
          sx={{
            backgroundColor: 'background.default',
            color: 'text.primary',
          }}
          className="border rounded p-4 "
        >
          <Link className="flex items-center gap-3" to={data.youtubeLink}>
            <RedirectIcon />
            <a className={'underline text-blue'}>{data.youtubeLink}</a>
          </Link>
        </Box>
      ) : null}
    </Stack>
  );
}

export default PromotionDescription;
