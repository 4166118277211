import { ScheduleSend } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

function EmailAutomationIndicator({
  className,
  iconSize = 'inherit',
}: {
  className?: string;
  iconSize?: 'inherit' | 'small';
}) {
  const { t } = useTranslation('propertiesPage');
  return (
    <Tooltip title={t('send_documents_automation')} arrow placement="top">
      <div
        className={classNames(
          'bg-neutral-100 rounded justify-center items-center flex',
          className
        )}
      >
        <ScheduleSend fontSize={iconSize} color="inherit" opacity={0.7} />
      </div>
    </Tooltip>
  );
}

export default EmailAutomationIndicator;
