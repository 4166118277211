import { Avatar, Box, Stack } from '@mui/material';
import PhoneIcon from '../../../../features/icons/phoneIcon';
import { Broker } from '../../../../types/property';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useTranslation } from 'react-i18next';

function UserInfoCard({ mainBroker }: { mainBroker: Broker }) {
  const { avatar, email, firstname, lastname, phone } = mainBroker;
  const { t } = useTranslation('propertiesPage');
  return (
    <Stack className="w-full rounded bg-[#FFFFFF] border  flex flex-col">
      <Box
        sx={{ backgroundColor: 'background.default' }}
        className="w-full px-2 py-8 grid grid-cols-3 gap-4"
      >
        {avatar && (
          <Avatar
            variant="rounded"
            src={avatar}
            alt=""
            sx={{
              width: '100%',
              height: '100%',
              aspectRatio: '1/1',
              gridColumn: 'span 1 / span 1',
            }}
          />
        )}

        <div className=" col-span-2 flex flex-col justify-center">
          <h3 className="font-medium mb-1">{`${firstname} ${lastname}`}</h3>
          <h5 className="text-sm">{t('Agent')}</h5>
        </div>
      </Box>
      <Box className="w-full px-2 lg:px-8 py-6">
        {email && (
          <div className=" mt-6">
            <a
              href={`mailto:${email}`}
              className="rounded border border-blue justify-center items-center text-blue px-4 py-2 flex gap-4"
            >
              <MailOutlineIcon /> {email}
            </a>
          </div>
        )}
        {phone && (
          <div className=" mt-6  ">
            <a
              href={`tel::${phone}`}
              className="rounded border border-blue justify-center items-center text-blue px-4 py-2 flex gap-4"
            >
              <PhoneIcon color="#3446B9" /> {phone}
            </a>
          </div>
        )}
      </Box>
    </Stack>
  );
}

export default UserInfoCard;
