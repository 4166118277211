import { useTranslation } from 'react-i18next';
import TitleHeader from '../../../components/common/titleHeader';
import { Button, Skeleton, TablePagination, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { INetworkListItem } from '../../../types/networks';
import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import NetworkCard from './elements/networkCard';
import MockCard from './elements/mockCard';
import useListingCollection from '../../../hooks/useListingCollection';
import useMediaQuery from '@mui/material/useMediaQuery';
const filters = [
  {
    nameKey: 'All',
    filterKey: null,
  },
  {
    nameKey: 'Created_by_me',
    filterKey: 'me',
  },
  {
    nameKey: 'Others',
    filterKey: 'others',
  },
];

const mockUpItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export function addEnding(
  word: string,
  count: number,
  replaceWord?: string
): string {
  if (count === 1) {
    return `${count} ${word}`;
  } else {
    return `${count} ${replaceWord || word}s`;
  }
}

export function replaceEnding(
  word: string,
  count: number,
  replaceWord?: string
): string {
  if (count === 1) {
    return `${count} ${word}`;
  } else {
    return `${count} ${replaceWord}`;
  }
}

const NetworkStartPage = () => {
  const { t } = useTranslation('networks');
  const isAdmin = JSON.parse(localStorage.getItem('isAdmin') as string);
  const [filter, setFilter] = useState<null | string>(null);
  const isHigherThen768 = useMediaQuery('(min-width:768px)');
  const {
    itemsToDisplay,
    setPage,
    itemsPerPage,
    page,
    loadingItemsToDisplay,
    setItemsPerPage,
    mutate,
  } = useListingCollection<INetworkListItem>({
    basedUrl: `/networks`,
    isActiveProperties: true,
    currentPage: 'networks-all',
    status: filter,
  });

  const rows: INetworkListItem[] | null = useMemo(() => {
    if (itemsToDisplay) {
      return itemsToDisplay['hydra:member'];
    }
    return null;
  }, [itemsToDisplay]);

  if (!isAdmin.isAdmin) {
    return null;
  }

  const handleRowsPerPage = (event: { target: { value: number } }) => {
    setItemsPerPage(event.target.value);
    if (page > 1) {
      setPage(1);
    }
  };

  return (
    <>
      <TitleHeader
        title={t('mls-networks')}
        titleElement={
          <div>
            <Typography className="!text-3xl">{t('mls-networks')}</Typography>
            {itemsToDisplay && itemsToDisplay['hydra:totalItems'] ? (
              <Typography variant="subtitle1" className="text-sm text-gray-600">
                {addEnding(t('network'), itemsToDisplay['hydra:totalItems'])}
                {', '}
                {itemsToDisplay['waitingForResponseCount']}{' '}
                {itemsToDisplay['waitingForResponseCount'] ? t('new') : ''}{' '}
                {t('invites')}
              </Typography>
            ) : null}
          </div>
        }
        goBackLink={'/settings'}
        rightElement={
          isHigherThen768 && (
            <Link to={'/networks/create'}>
              <Button
                className="add_contact"
                variant="contained"
                disableElevation
                sx={{ background: 'background.default', height: '40px' }}
              >
                {t('Add_network')}
              </Button>
            </Link>
          )
        }
      >
        <section className="w-full h-full flex flex-col">
          <div className="w-full justify-start items-start gap-2 inline-flex">
            {filters.map((el) => (
              <button
                key={el.nameKey}
                onClick={() => setFilter(el.filterKey)}
                className={classNames(
                  'px-4 py-2 rounded justify-center items-center gap-4 flex border',
                  {
                    'bg-blue border-blue': filter === el.filterKey,
                    'bg-gray-50': !(filter === el.filterKey),
                  }
                )}
              >
                <div
                  className={classNames('text-sm font-normal leading-none', {
                    'text-white': filter === el.filterKey,
                  })}
                >
                  {t(el.nameKey)}{' '}
                </div>
              </button>
            ))}
          </div>
          <div>
            <div
              className={
                'pt-8 grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4'
              }
            >
              {loadingItemsToDisplay ? (
                <>
                  {mockUpItems.map((el) => (
                    <MockCard key={el} />
                  ))}
                </>
              ) : (
                <>
                  {rows && rows.length ? (
                    rows.map((el) => (
                      <NetworkCard key={el.id} item={el} mutate={mutate} />
                    ))
                  ) : (
                    <div
                      className={
                        'h-[400px] col-span-4 flex items-center justify-center bg-white rounded p-4 text-[20px] text-center'
                      }
                    >
                      {filter
                        ? t('There_is_no networks')
                        : t('There_is_no_networks_yet')}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={'w-full flex items-end justify-end pt-8'}>
              {loadingItemsToDisplay ? (
                <Skeleton variant="rectangular" height={40} width={200} />
              ) : rows && rows.length ? (
                <TablePagination
                  component="div"
                  sx={{
                    '&::-webkit-scrollbar': {
                      height: '10px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#BBC0C4',
                      borderRadius: 8,
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      backgroundColor: '#888',
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: '#f1f1f1',
                    },
                  }}
                  count={
                    itemsToDisplay ? itemsToDisplay['hydra:totalItems'] : 0
                  }
                  page={page - 1}
                  labelRowsPerPage={`${t('RowsPerPage')}:`}
                  labelDisplayedRows={function defaultLabelDisplayedRows({
                    from,
                    to,
                    count,
                  }) {
                    return `${from}–${to} ${t('of')} ${
                      count !== -1 ? count : `${t('moreThan')} ${to}`
                    }`;
                  }}
                  onPageChange={(
                    event: React.MouseEvent | null,
                    pageNew: number
                  ) => {
                    setPage(pageNew + 1);
                  }}
                  rowsPerPage={itemsPerPage}
                  // @ts-ignore
                  onRowsPerPageChange={handleRowsPerPage}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                />
              ) : null}
            </div>
          </div>
        </section>
      </TitleHeader>
    </>
  );
};

export default NetworkStartPage;
