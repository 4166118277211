import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Modal,
  Tooltip,
} from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { http } from '../../../../../libs/axios';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import CheckIcon from '@mui/icons-material/Check';
import useLocale from '../../../../../hooks/useLocale';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
interface Props {
  title: string;
  openModal: boolean;
  onClose: () => void;
  id: string | null;
  subscriptionType: string | undefined | null;
}

type RowType = {
  maxPropertiesToPublish: string;
  nbExceededPublishedProperties: number;
  nbOfPublishedProperties: number;
};

const PublishedProperties = (props: Props) => {
  const { openModal, onClose, id, title, subscriptionType } = props;
  const { t } = useTranslation('propertiesPage');
  const { locale } = useLocale();
  useEffect(() => {
    if (locale === 'fr') {
      dayjs.locale('fr');
    } else {
      dayjs.locale('en');
    }
  }, [locale]);
  const [rows, setRows] = useState<Partial<RowType>>({});
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await http.get(
        `/setting_portals/${id}/published_properties?from=${from}&to=${to}`
      );

      setRows(res.data);
      setLoading(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    openModal == false && setRows({});
  }, [openModal]);

  return (
    <Modal
      open={openModal}
      onClose={onClose}
      classes={{ root: 'grid place-items-center' }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Fade in={openModal}>
          <Box
            sx={{
              maxHeight: '75vh',
              overflowY: 'auto',
              overflowX: 'hidden',
              width: {
                xs: '50%',
              },
              borderRadius: 2,
              bgcolor: '#FBFBFD',
              boxShadow: 24,
            }}
          >
            <div className={`px-8 pb-6 pt-6 ml-3 mt-1 flex flex-col h-fit`}>
              <div className="flex justify-between h-[15%] !items-start w-full">
                <p className={'mb-4 text-lg md:text-2xl'}>{t(title)}</p>
              </div>
              <button
                onClick={onClose}
                className={
                  'absolute right-8 top-6 opacity-60 transition-opacity duration-300 hover:opacity-80'
                }
              >
                <CloseIcon fontSize="medium" />
              </button>
              <Box sx={{ width: '100%' }}>
                <div>
                  <div className="mb-12 mt-4 relative">
                    {subscriptionType == 'daily' && (
                      <DesktopDateRangePicker
                        className="w-[90%]"
                        onChange={(e: any) => {
                          setFrom(dayjs(e[0]).format('DD-MM-YYYY'));
                          setTo(dayjs(e[1]).format('DD-MM-YYYY'));
                        }}
                      />
                    )}

                    {subscriptionType == 'monthly' && (
                      <div className="flex justify-between w-[90%]">
                        <DatePicker
                          className="w-[47%]"
                          label={t('month_start')}
                          views={['month', 'year']}
                          onChange={(e: any) => {
                            setFrom(dayjs(e).format('MM-YYYY'));
                          }}
                        />
                        <span className="flex items-center">–</span>
                        <DatePicker
                          className="w-[47%]"
                          label={t('month_end')}
                          views={['month', 'year']}
                          onChange={(e: any) => {
                            setTo(dayjs(e).format('MM-YYYY'));
                          }}
                        />
                      </div>
                    )}
                    <Box>
                      <Tooltip
                        title={`${t('propertiesPage:Save')}`}
                        arrow
                        placement="top"
                      >
                        <Button
                          sx={{
                            '&.MuiButtonBase-root:hover': {
                              bgcolor: 'transparent',
                            },
                            position: 'absolute',
                            right: '0%',
                            bottom: '12%',
                          }}
                          onClick={() => fetchData()}
                        >
                          <CheckIcon
                            sx={{ color: '#4CAF50', fontSize: '38px' }}
                          />
                        </Button>
                      </Tooltip>
                    </Box>
                  </div>

                  <div className=" w-[95%]">
                    {Object.entries(rows).map(([key, value]) => {
                      return (
                        <div
                          key={key}
                          className="flex justify-between items-center mb-5"
                        >
                          <div>
                            <span>-</span>
                            <span className="font-[Inter] font-normal text-lg ml-2">
                              {t(key)}
                            </span>
                          </div>
                          {loading ? (
                            <div className="z-10">
                              <CircularProgress size={20} disableShrink />
                            </div>
                          ) : (
                            <span className="text-blue-600 text-lg font-normal">
                              {value == null || value === ''
                                ? '-'
                                : key == 'message'
                                ? t(
                                    `${
                                      typeof value === 'string'
                                        ? value.replaceAll(' ', '_')
                                        : value
                                    }`
                                  )
                                : typeof value === 'string' &&
                                  value.includes('per')
                                ? (() => {
                                    const [amount, per, duration, unit] = value
                                      .split(' ')
                                      .filter(Boolean);
                                    return `${amount} ${t(
                                      `${per}`
                                    )} ${duration} ${t(`${unit}`)}`;
                                  })()
                                : value}
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>{' '}
              </Box>
            </div>
          </Box>
        </Fade>
      </ClickAwayListener>
    </Modal>
  );
};
export default PublishedProperties;
