import { useTranslation } from 'react-i18next';
import LangSwitcher from './langSwitcher';
import SocialMedias from './socialMedias';
import { Box } from '@mui/material';

export default function Footer() {
  const { t } = useTranslation('common');
  return (
    <Box
      sx={{
        color: 'text.primary',
      }}
      component="footer"
      className="flex flex-col lg:flex-row lg:gap-6 justify-between text-sm mb-3 items-center w-full"
    >
      <Box className="flex items-center sm:gap-x-20 lg:gap-x-0 xl:gap-x-20">
        <Box>
          © {new Date().getFullYear()} {t('Reals_All_rights_reserved')}
        </Box>
        <LangSwitcher />
      </Box>
      <SocialMedias />
      <Box>
        <Box
          component="ul"
          className="flex gap-x-2 sm:gap-x-6 text-[12px] sm:text-[0.875rem]"
        >
          <Box component="li">{t('About_us')}</Box>
          <Box component="li">{t('Privacy_Policy')}</Box>
          <Box component="li">{t('Terms_of_Service')}</Box>
          <Box component="li">{t('Contact_Us')}</Box>
          <Box component="li">{t('Help_center')}</Box>
        </Box>
      </Box>
    </Box>
  );
}
