import { GridColumns } from '@mui/x-data-grid';
import { IPropertyApi } from '../../../types/property';
import useLocale from '../../../hooks/useLocale';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { IMedia } from '../../../types/api';
import PermissionIcon from '../../../features/icons/permissionIcon';
import { useIsRent } from '../../../hooks/useIsRent';
import { formatPriceWithCurrency } from './useOffersListColumns';

const RenderPriceCell = ({ row }: { row: IPropertyApi }) => {
  const isRent = useIsRent(row);

  return (
    <div>
      {isRent
        ? formatPriceWithCurrency(
            row.pricingRent?.displayedPrice,
            row?.pricingRent?.currency?.value
          )
        : formatPriceWithCurrency(
            row.pricingBuy?.displayedPrice,
            row?.pricingBuy?.currency?.value
          )}
    </div>
  );
};

export const usePublishedPropertiesListColumns = ({
  isLoading,
}: {
  isLoading?: boolean;
}): GridColumns<IPropertyApi> => {
  const { t } = useTranslation('propertiesPage');
  const { locale } = useLocale();

  const commonSettings = {
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  };

  const findImagesProperty = (item: IPropertyApi): string | undefined => {
    if (Array.isArray(item?.media)) {
      return undefined;
    }

    const images = item?.media.images;

    if (images && images.length > 0) {
      const mainImage = images.find((elem: IMedia) => elem.isMain);
      return mainImage ? mainImage.url.thumbnail : images[0]?.url.thumbnail;
    }

    return undefined;
  };

  const columns: GridColumns<IPropertyApi> = [
    {
      field: 'Reference',
      headerClassName: '!pl-3',
      cellClassName: '!pl-3 !pt-1',
      width: 400,
      sortable: false,
      renderHeader: () => (
        <Box className="leading-5 flex gap-2 Inter font-medium text-sm pl-2 py-5 text-[#030814]">
          <span>{t('common:Property_title')}</span>
          <span>/</span>

          <span>{t('common:Reference')} </span>
        </Box>
      ),
      renderCell: ({ row }) => {
        return (
          <div className="w-full flex flex-wrap h-full">
            <Link
              to={`/properties/${row?.id}`}
              id={`img_property_${row?.id}`}
              className="block  w-[100%] h-full"
            >
              <div className="w-full flex flex-wrap h-full">
                <div className="w-[30%] h-full">
                  <div className="h-full max-w-[92%] min-w-[92%]">
                    <img
                      src={findImagesProperty(row) || '/img/default_image.jpg'}
                      alt=""
                      className="!object-cover max-w-inherit min-w-[100%] h-full"
                    />
                  </div>
                </div>
                <div className="w-[70%] flex flex-col justify-around">
                  <Tooltip
                    title={row?.translations?.[locale]?.title}
                    arrow
                    placement="top"
                  >
                    <span className="block truncate">
                      {row?.translations?.[locale]?.title}
                    </span>
                  </Tooltip>

                  {row?.agencyReference ? (
                    <Tooltip
                      title={`${t('Reference_Agency')}: ${
                        row?.agencyReference
                      }`}
                      arrow
                      placement="top"
                    >
                      <div
                        className="flex gap-1 py-1 px-1 rounded-md items-center min-w-[135px] max-w-[135px]"
                        style={{ border: '1px solid #00bfff' }}
                      >
                        <PermissionIcon
                          className="w-[25px] col-span-1"
                          color="#00bfff"
                        />
                        <span className="text-[#00bfff] block truncate inter leading-4 font-normal text-sm">
                          {row?.agencyReference}
                        </span>
                      </div>
                    </Tooltip>
                  ) : (
                    <div>
                      <div
                        className="flex gap-1 py-1 px-1 mb-[6px] rounded-md items-center min-w-[135px] max-w-[135px]"
                        style={{ border: '1px solid #3446B9 ' }}
                      >
                        <PermissionIcon
                          className=" col-span-1"
                          color="#3446B9"
                        />{' '}
                        <span className="text-blue inter leading-4 font-normal text-sm">
                          {row?.reference}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Link>
          </div>
        );
      },
    },
    {
      field: 'department',
      headerName: 'Related_agency',
      headerClassName: '!pl-5',
      cellClassName: '!pl-6',
      width: 270,
      sortable: false,
      renderHeader: () => (
        <span className="leading-5 font-medium text-sm font-[Inter]">
          {t('Related_agency')}
        </span>
      ),
      renderCell: ({ row }) => {
        return <div>{row.department?.name}</div>;
      },
    },
    {
      field: 'city',
      headerName: 'city',
      width: 250,
      sortable: false,
      renderHeader: () => (
        <span className="leading-5 font-medium text-sm font-[Inter]">
          {t('city')}
        </span>
      ),
      renderCell: ({ row }) => {
        return <span>{row?.address?.translations?.[locale]?.city}</span>;
      },
    },
    {
      field: 'displayedPrice',
      headerName: 'price',
      width: 250,
      sortable: false,
      headerClassName: 'hideRightSeparator',
      renderHeader: () => (
        <span className="leading-5 font-medium text-sm font-[Inter]">
          {t('price')}
        </span>
      ),
      renderCell: ({ row }) => <RenderPriceCell row={row} />,
    },
  ];

  return columns.map((column) => ({
    ...commonSettings,
    ...column,
    ...(isLoading
      ? {
          renderCell: () => (
            <Skeleton
              variant="text"
              animation="wave"
              width="100%"
              height={20}
            />
          ),
        }
      : {}),
  }));
};
