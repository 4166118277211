import { Box } from '@mui/material';
import useListingCollection from '../../hooks/useListingCollection';
import { useLeadsListColumns } from './useLeadsListColumns';
import GridTableView from '../../components/cardsTablesList';
import GridView from '../../components/cardsTablesList/cards/list';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

const skeletonItems = Array(6)
  .fill('')
  .map((_, index) => ({
    createdAt: '',
    createdBy: '',
    date: '',
    id: index,
    message: '',
    property: null,
    source: '',
    updatedAt: '',
    updatedBy: '',
    status: {
      id: 0,
      keyname: '',
      translations: {},
    },
    type: {
      id: 0,
      keyname: '',
      translations: {},
    },
    contact: null,
  }));

const LeadsListingTable = () => {
  const {
    itemsToDisplay,
    setPage,
    mutate,
    setItemsPerPage,
    itemsPerPage,
    page,
    loadingItemsToDisplay,
  } = useListingCollection<any>({
    basedUrl: '/prospects/my-agency-leads',
    currentPage: 'leadsList',
    initialItemsPerPage: Number(localStorage.getItem('leadItemsPerPage')) || 10,
  });

  const columns = useLeadsListColumns({
    isLoading: loadingItemsToDisplay,
    mutate,
  });
  const { t } = useTranslation('propertiesPage');

  const totalRows = itemsToDisplay?.['hydra:totalItems'] || 0;
  const results = itemsToDisplay?.['hydra:member'] || [];
  const rows = loadingItemsToDisplay ? skeletonItems : results;

  return (
    <div className={'flex-grow min-h-[500px]'}>
      <div>
        <span className="font-normal text-3xl font-[Inter]">{t('leads')}</span>
        <div className="mt-4 text-lg flex gap-3 font-[Inter] text-gray-400">
          <span>{totalRows}</span>
          <span> {t('leads')}</span>
        </div>
      </div>
      <GridTableView
        gridElement={
          <GridView
            leadsItems={results}
            isLoading={loadingItemsToDisplay}
            isLeads
            isAgency
            page={page - 1}
            rowCount={totalRows}
            handleChangePage={(
              event: React.MouseEvent | null,
              page: number
            ) => {
              setPage(page + 1);
            }}
            handleChangeRowsPerPage={(event: { target: { value: number } }) => {
              setItemsPerPage(event.target.value);
              if (page > 1) {
                setPage(1);
              }
              localStorage.setItem(
                'leadItemsPerPage',
                JSON.stringify(event.target.value)
              );
            }}
            rowsPerPage={JSON.parse(
              localStorage.getItem('leadItemsPerPage') || '10'
            )}
            rowsPerPageOptions={[10, 25, 50, 100]}
          />
        }
        tableElement={
          <Box sx={{ width: '100%', overflowX: 'auto' }}>
            <DataGrid
              sx={{
                '& .hideRightSeparator > .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                  height: 10,
                },
                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                  backgroundColor: '#BBC0C4',
                  borderRadius: 8,
                },
                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover':
                  {
                    backgroundColor: '#888',
                  },
                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                  backgroundColor: '#f1f1f1',
                },
              }}
              rows={rows}
              columns={columns}
              autoHeight={true}
              loading={!rows}
              disableColumnMenu
              disableSelectionOnClick
              page={page - 1}
              paginationMode="server"
              rowCount={totalRows || 0}
              pageSize={itemsPerPage}
              pagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              rowHeight={90}
              onPageChange={(newPage: number) => {
                setPage(newPage + 1);
              }}
              onPageSizeChange={(newPageSize: number) => {
                setItemsPerPage(newPageSize);
                setPage(1);
                localStorage.setItem(
                  'leadItemsPerPage',
                  JSON.stringify(newPageSize)
                );
              }}
              componentsProps={{
                pagination: {
                  labelRowsPerPage: t('Rows_Per_Page'),
                },
              }}
            />
          </Box>
        }
      />
    </div>
  );
};

export default LeadsListingTable;
