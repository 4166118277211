import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import { IProperty } from '../../../../../../types/property';
import StepsFieldset from '../../../../../../components/stepper/stepsFieldset';
import InputFormField from '../../../../../../components/form/inputFormField';
import SelectFormField, {
  selectOptionStringT,
} from '../../../../../../components/form/selectFormField';
import useSWR from 'swr';
import {
  getOptionsByLanguageString,
  optionType,
} from '../../../../../../const/propertiesOptions';
import SwitcherFormField from '../../../../../../components/form/switcherFormField';
import { useEffect, useState } from 'react';
import { Button, InputAdornment, Typography } from '@mui/material';
import { getCurrency } from './BuyPricing';
import PricingPublication, {
  IPublicationListItem,
} from './utils/pricingPublication';
import useLocale from '../../../../../../hooks/useLocale';
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../../../../features/icons/closeIcon';
import RepeatableBlock from '../../../../../../components/form/repeateableBlock';

type Props = {
  register: UseFormRegister<IProperty>;
  getValues: UseFormGetValues<IProperty>;
  watch: UseFormWatch<IProperty>;
  control: Control<IProperty>;
};
const PricesList: {
  id: number;
  label: string;
  name: any;
}[] = [
  {
    id: 0,
    label: 'Displayed_Net_Price',
    name: 'pricingRent.displayedPrice',
  },
  {
    id: 1,
    label: 'Original_Price_Rent_per_month',
    name: 'pricingRent.originalPrice',
  },
  {
    id: 2,
    label: 'Price_m2_an',
    name: 'pricingRent.pricem2',
  },
  {
    id: 3,
    label: 'Estimated_Price',
    name: 'pricingRent.estimatedPrice',
  },
];

const publicationList: IPublicationListItem[] = [
  { name: 'pricingRent.hidePriceOnWebsite', label: 'Hide price on website' },
  { name: 'pricingRent.hidePriceOnPortal', label: 'Hide price on portal' },
  { name: 'pricingRent.hidePriceOnBrochure', label: 'Hide price on brochure' },
];
const RentPricing = ({ register, getValues, control, watch }: Props) => {
  const { locale } = useLocale();
  const [defaultCurrencyValue, setDefaultCurrencyValue] =
    useState<selectOptionStringT>();
  const { t } = useTranslation('propertiesPage');
  // fetching options lists
  const { data: parkingIncludesOptionsApi } =
    useSWR<optionType[]>('parking_includeds');
  const { data: parkingMandatoryOptionsApi } = useSWR<optionType[]>(
    'parking_mandatories'
  );
  const { data: chargesIncludedOptionsApi } =
    useSWR<optionType[]>('charges_includeds');

  const { data: currenciesOptionsApi } =
    useSWR<selectOptionStringT[]>('currencies');

  const { data: frequencyRentsOptionsApi } =
    useSWR<optionType[]>('frequency_rents');

  const { data: parkingTypesApi } = useSWR<optionType[]>('parking_types');
  // transform option list by language
  const parkingIncludesOptions = getOptionsByLanguageString(
    parkingIncludesOptionsApi,
    locale
  );
  const parkingMandatoryOptions = getOptionsByLanguageString(
    parkingMandatoryOptionsApi,
    locale
  );
  const chargesIncludedOptions = getOptionsByLanguageString(
    chargesIncludedOptionsApi,
    locale
  );
  const frequencyRentsOptions = getOptionsByLanguageString(
    frequencyRentsOptionsApi,
    locale
  );
  const parkingTypes = getOptionsByLanguageString(parkingTypesApi, locale);
  useEffect(() => {
    if (currenciesOptionsApi && currenciesOptionsApi[0]) {
      setDefaultCurrencyValue(currenciesOptionsApi[0]);
    }
  }, [currenciesOptionsApi]);

  if (!defaultCurrencyValue) return null;
  const currencyChoice = getCurrency(
    currenciesOptionsApi,
    Number(watch('pricingRent.currency')),
    defaultCurrencyValue
  );
  return (
    <>
      <StepsFieldset className="grid grid-cols-2" title={t('Prices')}>
        <SelectFormField
          className="Status_property"
          label={t('Currency')}
          options={currenciesOptionsApi!}
          defaultValue={String(currencyChoice?.id)}
          {...register('pricingRent.currency')}
        />
        {PricesList.map(({ id, label, name }) => (
          <InputFormField
            key={id}
            type="number"
            label={t(label)}
            InputProps={{
              endAdornment: (
                <InputAdornment className="uppercase" position="start">
                  {currencyChoice?.value}
                </InputAdornment>
              ),
            }}
            {...register(name, {
              valueAsNumber: true,
            })}
          />
        ))}{' '}
        <SwitcherFormField
          label={t('Price_reduction')}
          name="pricingRent.priceReduction"
          checked={Boolean(getValues('pricingRent.priceReduction'))}
          register={register}
        />
        <InputFormField
          type="number"
          label={t('Recommended_price')}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                {currencyChoice?.value}
              </InputAdornment>
            ),
          }}
          {...register('pricingRent.recommendedPrice', {
            valueAsNumber: true,
          })}
        />
        <SelectFormField
          className="Status_property"
          label={t('Frequency')}
          options={frequencyRentsOptions}
          defaultValue={getValues('pricingRent.frequency')}
          {...register('pricingRent.frequency')}
        />{' '}
        <SwitcherFormField
          label={t('Moderated_price')}
          name="pricingRent.moderatedPrice"
          checked={Boolean(getValues('pricingRent.moderatedPrice'))}
          register={register}
        />{' '}
        <InputFormField
          type="number"
          label={t('Rental_guarantee_deposit')}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                {currencyChoice?.value}
              </InputAdornment>
            ),
          }}
          {...register('pricingRent.rentalGuaranteeDeposit', {
            valueAsNumber: true,
          })}
        />
      </StepsFieldset>
      <StepsFieldset className="grid grid-cols-1" title={t('Parkings')}>
        <RepeatableBlock
          control={control}
          name={'pricingRent.parkings'}
          addLabel={t('Add_parking_price')}
          appendObj={{
            parkingPrice: '',
            parkingIncluded: '',
            parkingMandatory: '',
            parkingType: '',
          }}
          renderField={(field, index, remove) => {
            return (
              <div
                className="flex flex-wrap justify-between mb-8"
                key={field.id}
              >
                <div className="w-[75%] flex flex-col gap-6">
                  <div className={'grid grid-cols-2 gap-8'}>
                    <InputFormField
                      type="number"
                      label={t('Parking_price')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            className="uppercase"
                            position="start"
                          >
                            {currencyChoice?.value}
                          </InputAdornment>
                        ),
                      }}
                      {...register(
                        `pricingRent.parkings.${index}.parkingPrice`,
                        {
                          valueAsNumber: true,
                          setValueAs: (v: any) =>
                            v === '' || v === undefined ? null : v,
                        }
                      )}
                    />
                    <SelectFormField
                      label={t('Parking_type')}
                      options={parkingTypes}
                      defaultValue={getValues(
                        `pricingRent.parkings.${index}.parkingType`
                      )}
                      {...register(
                        `pricingRent.parkings.${index}.parkingType`,
                        {
                          setValueAs: (v) =>
                            v === '' || v === undefined ? null : v,
                        }
                      )}
                    />
                    <SelectFormField
                      className="Status_property"
                      label={t('Parking_included')}
                      options={parkingIncludesOptions}
                      defaultValue={getValues(
                        `pricingRent.parkings.${index}.parkingIncluded`
                      )}
                      {...register(
                        `pricingRent.parkings.${index}.parkingIncluded`,
                        {
                          setValueAs: (v) =>
                            v === '' || v === undefined ? null : v,
                        }
                      )}
                    />
                    <SelectFormField
                      className="Status_property"
                      label={t('Parking_mandatory')}
                      options={parkingMandatoryOptions}
                      defaultValue={getValues(
                        `pricingRent.parkings.${index}.parkingMandatory`
                      )}
                      {...register(
                        `pricingRent.parkings.${index}.parkingMandatory`,
                        {
                          setValueAs: (v) =>
                            v === '' || v === undefined ? null : v,
                        }
                      )}
                    />
                  </div>
                </div>
                <div className="flex justify-end items-start w-[25%] mt-6">
                  <Button
                    className="flex !px-0 items-center gap-3 w-[48%]"
                    variant="outlined"
                    aria-label="Delete"
                    onClick={() => remove(index)}
                  >
                    <CloseIcon color="#F50057" />
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: '#F50057',
                        fontWeight: 500,
                      }}
                    >
                      {t('Delete')}
                    </Typography>
                  </Button>
                </div>
              </div>
            );
          }}
        />
      </StepsFieldset>
      <StepsFieldset className="grid grid-cols-2" title={t('Charges')}>
        <InputFormField
          type="number"
          label={t('Annual_charges')}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                {currencyChoice?.value}
              </InputAdornment>
            ),
          }}
          {...register('pricingRent.annualCharges', {
            valueAsNumber: true,
          })}
        />{' '}
        <SelectFormField
          className="Status_property"
          label={t('Charges_included')}
          options={chargesIncludedOptions}
          defaultValue={getValues('pricingRent.chargesIncluded')}
          {...register('pricingRent.chargesIncluded')}
        />{' '}
        <InputFormField
          type="number"
          label={t('Monthly_Heating_charges')}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                {currencyChoice?.value}
              </InputAdornment>
            ),
          }}
          {...register('pricingRent.monthlyHeatingCharges', {
            valueAsNumber: true,
          })}
        />{' '}
        <SelectFormField
          label={t('Individual_charges_included')}
          options={chargesIncludedOptions}
          defaultValue={getValues('pricingRent.individualChargesIncluded')}
          {...register('pricingRent.individualChargesIncluded')}
        />
        <SelectFormField
          label={t('Maintenance_contract')}
          options={chargesIncludedOptions}
          defaultValue={getValues('pricingRent.maintenanceContract')}
          {...register('pricingRent.maintenanceContract')}
        />
        <InputFormField
          type="number"
          label={t('Accessory_expenses')}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                {currencyChoice?.value}
              </InputAdornment>
            ),
          }}
          {...register('pricingRent.accessoryExpenses', {
            valueAsNumber: true,
          })}
        />{' '}
        <InputFormField
          type="number"
          label={t('Monthly_charges')}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                {currencyChoice?.value}
              </InputAdornment>
            ),
          }}
          {...register('pricingRent.monthlyCharges', {
            valueAsNumber: true,
          })}
        />
        <InputFormField
          type="number"
          label={t('Charges/m2/Year')}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                {currencyChoice?.value}
              </InputAdornment>
            ),
          }}
          {...register('pricingRent.chargesm2Year', {
            valueAsNumber: true,
          })}
        />{' '}
      </StepsFieldset>
      <StepsFieldset className="grid grid-cols-2" title={t('General_infos')}>
        <SwitcherFormField
          label={t('Property_liable_to_VAT')}
          name="pricingRent.propertyLiableToVAT"
          checked={Boolean(getValues('pricingRent.propertyLiableToVAT'))}
          register={register}
        />{' '}
        <InputFormField
          type="number"
          label={t('Income')}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                {currencyChoice?.value}
              </InputAdornment>
            ),
          }}
          {...register('pricingRent.income', {
            valueAsNumber: true,
          })}
        />
        <SwitcherFormField
          label={t('Parking_liable_to_VAT')}
          name="pricingRent.parkingLiableToVAT"
          checked={Boolean(getValues('pricingRent.parkingLiableToVAT'))}
          register={register}
        />{' '}
        <InputFormField
          type="number"
          label={t('Serafe')}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                {currencyChoice?.value}
              </InputAdornment>
            ),
          }}
          {...register('pricingRent.serafe', {
            valueAsNumber: true,
          })}
        />{' '}
        <InputFormField
          type="number"
          label={t('telenetwork')}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                {currencyChoice?.value}
              </InputAdornment>
            ),
          }}
          {...register('pricingRent.telenetwork', {
            valueAsNumber: true,
          })}
        />
        <InputFormField
          type="number"
          label={t('water_electricity')}
          InputProps={{
            endAdornment: (
              <InputAdornment className="uppercase" position="start">
                {currencyChoice?.value}
              </InputAdornment>
            ),
          }}
          {...register('pricingRent.waterElectricity', {
            valueAsNumber: true,
          })}
        />{' '}
      </StepsFieldset>
      <PricingPublication
        list={publicationList}
        getValues={getValues}
        register={register}
      />
    </>
  );
};

export default RentPricing;
