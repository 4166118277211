import { useEffect } from 'react';

import { usePagesEditor, usePagesEditorComputables } from './usePagesEditor';
import { PreviewSubject, Widget } from '../../../../types/brochureEditor';
import { finalWidgetOptions } from '../utils';

export function useDefaultImages(previewSubject?: PreviewSubject | null) {
  const { setWidgetOption, overrides } = usePagesEditor();
  const { pages } = usePagesEditorComputables();
  useEffect(() => {
    if (previewSubject && pages.length && Object.values(overrides).length) {
      const { images } = previewSubject;
      console.log('overrides', overrides);
      let currentImageIndex = 0;
      if (images.length > 0) {
        for (const page of pages.filter((page) => page.widgets)) {
          console.log('page', page.id);
          const widgetEntries = Object.entries(
            page.widgets as Record<string, Widget>
          );
          const imageWidgets = widgetEntries.filter(
            ([, { type }]) => type === 'image' || type === 'imageWithPrice'
          );
          for (const [areaId, widget] of imageWidgets) {
            const widgetOptions = finalWidgetOptions({
              widget,
              overrides,
              page,
              areaId,
              previewSubject,
            });
            console.log(areaId, widgetOptions);
            if (!widgetOptions?.image?.value) {
              const image = images[currentImageIndex];
              if (image) {
                setWidgetOption(
                  page.id,
                  areaId,
                  'image',
                  String(image.id),
                  previewSubject.id
                );
              }
              currentImageIndex = (currentImageIndex + 1) % images.length;
            }
          }
        }
      }
    }
  }, [previewSubject, pages, overrides]);
}
