import { GridColumns } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Skeleton, Tooltip } from '@mui/material';
import LeadDateAndStatus from '../../../../../components/tableGridX/leads/dateAndStatus';
import { Link } from 'react-router-dom';
import { getContactName } from '../../../../../helpers/utils/contacts';
import RelatedAgent from '../../../../../components/tableGridX/leads/relatedAgent';
import LeadSource from '../../../../../components/tableGridX/leads/source';
import ContactTypes from '../../../../../components/tableGridX/leads/contactTypes';
import LeadsListingActionMenu from './leadsListingActionsMenu';
import { IContact } from '../../../../../types/contacts';
import { ILeadItemExtended } from './index';

export default function useColumns({
  isLoading,
  isArchived,
}: {
  isLoading: boolean;
  isArchived?: boolean;
}) {
  const { t } = useTranslation('propertiesPage');
  const columns: GridColumns<ILeadItemExtended> = [
    {
      field: 'date',
      sortable: false,
      flex: 1,
      minWidth: 130,
      maxWidth: 155,
      disableColumnMenu: true,
      renderHeader: () => {
        return (
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <span className="leading-none font-medium">
              {t('propertiesPage:Date')}
            </span>
            <span className="leading-none font-medium">
              {t('propertiesPage:Status')}
            </span>
          </span>
        );
      },
      renderCell: ({ row }) => {
        return <LeadDateAndStatus lead={row} />;
      },
    },
    {
      field: 'name',
      headerName: t('Name'),
      minWidth: 180,
      maxWidth: 280,
      flex: 1,
      sortable: false,
      resizable: false,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { row } = params;
        const { contact } = row;
        return (
          <div className={'flex w-full justify-between items-center'}>
            <div className="flex flex-col gap-1">
              <Link
                className={
                  'text-[12px] 2xl:text-[16px] overflow-hidden overflow-ellipsis font-medium'
                }
                to={`/contacts/${contact?.id}/contact-information`}
              >
                {getContactName(contact as IContact)}
              </Link>
            </div>
          </div>
        );
      },
    },
    {
      field: 'agent',
      headerName: t('Related_agent'),
      minWidth: 155,
      maxWidth: 180,
      flex: 1,
      sortable: false,
      resizable: false,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return <RelatedAgent contact={row.contact} />;
      },
    },
    {
      field: 'source',
      headerName: t('Lead_source'),
      maxWidth: 240,
      minWidth: 200,
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <LeadSource customHeight lead={row} />,
    },
    {
      field: 'type',
      headerName: t('Contact_types'),
      minWidth: 230,
      maxWidth: 250,
      flex: 1,
      sortable: false,
      resizable: false,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return <ContactTypes contact={row.contact as IContact} />;
      },
    },
    {
      field: 'message',
      headerName: t('Message'),
      minWidth: 200,
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <div
          className={
            'h-full pt-2 text-wrap font-medium text-xs whitespace-normal'
          }
        >
          <Tooltip title={row.message} arrow placement="top">
            <span
              style={{
                width: 'fit-content',
                marginRight: '5px',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '5',
              }}
            >
              {row.message}
            </span>
          </Tooltip>
        </div>
      ),
    },
  ];

  if (!isArchived) {
    columns.push({
      field: 'actions',
      headerName: t('Actions'),
      width: 96,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <LeadsListingActionMenu row={row} />,
    });
  }

  const commonSettings = {
    sortable: false,
    disableColumnMenu: true,
  };

  return columns.map((column) => ({
    ...commonSettings,
    ...column,
    ...(isLoading
      ? {
          renderCell: () => (
            <Skeleton
              variant="text"
              animation="wave"
              width="100%"
              height={20}
            />
          ),
        }
      : {}),
  }));
}
