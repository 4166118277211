import isEqual from 'lodash/isEqual';
import { useLocation } from 'react-router-dom';

export function useCurrentTab<T extends { slug: string }>(
  tabs: T[]
): T | undefined {
  const { pathname } = useLocation();
  const currentTab = tabs.find((tab) => pathname.includes(tab.slug));
  return currentTab;
}

export function sanitizePayload<ClientType>(body: Partial<ClientType>) {
  const entries = Object.entries(body);
  for (const [key, value] of entries) {
    if (value === '') {
      // @ts-ignore
      body[key] = null;
    }
  }
  return body;
}

export function calculateDiff(
  a: Record<string, unknown>,
  b: Record<string, unknown>
) {
  const diffKeys = findDiffKeys(a, b);
  const diff: Record<string, unknown> = {};
  for (const key of diffKeys) {
    diff[key] = b[key];
  }
  return diff;
}

/*
 * Compare two objects by reducing an array of keys in obj1, having the
 * keys in obj2 as the intial value of the result. Key points:
 *
 * - All keys of obj2 are initially in the result.
 *
 * - If the loop finds a key (from obj1, remember) not in obj2, it adds
 *   it to the result.
 *
 * - If the loop finds a key that are both in obj1 and obj2, it compares
 *   the value. If it's the same value, the key is removed from the result.
 */
// https://stackoverflow.com/a/40610459/6603253
function findDiffKeys(
  obj1: Record<string, unknown>,
  obj2: Record<string, unknown>
) {
  const diff = Object.keys(obj1).reduce((result, key) => {
    // eslint-disable-next-line
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
}

export class SilentAbortion extends Error {}
