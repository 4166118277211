import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import EditIcon from '../../../features/icons/editIcon';

function EditPropertyButton({ propertyId }: { propertyId: string | number }) {
  const { t } = useTranslation('usersPage');
  return (
    <div className="px-2 xl:px-4 py-2 rounded bg-blue cursor-pointer h-[47px] flex">
      <NavLink
        className="flex items-center"
        to={`/properties/${propertyId}/edit`}
      >
        <EditIcon color="#FFFFFF" />
        <span className="ml-3 text-sm text-white">{t('Edit_info')}</span>
      </NavLink>
    </div>
  );
}

export default EditPropertyButton;
