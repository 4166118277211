import React, { useEffect, useState } from 'react';
import { Box, Button, Fade, Modal, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import InputFormField from '../../components/form/inputFormField';
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Document } from '../../types/property';
import { FilePreview, getFileName } from './useColumns';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { parseFileName } from '../../helpers/utils/downloadFile';
import SpinnerLoading from '../../features/spinner/spinnerLoading';
import { toast } from 'react-toastify';
import { http } from '../../libs/axios';
import CallMergeIcon from '@mui/icons-material/CallMerge';

const MergeFilesPopup = ({
  openModal,
  setOpenModal,
  selectedFiles,
  propertyId,
  mutate,
  onMergedFilesSuccess,
}: {
  propertyId?: string;
  selectedFiles: (Document | undefined)[];
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  mutate?: any;
  onMergedFilesSuccess?: () => void;
}) => {
  const { t } = useTranslation('brochureEditor');
  const [items, setItems] = useState<(Document | undefined)[]>(selectedFiles);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setItems(selectedFiles);
  }, [selectedFiles]);

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = Array.from(items);
    const [movedItem] = newItems.splice(result.source.index, 1);
    if (movedItem) {
      newItems.splice(result.destination.index, 0, movedItem);
    }

    setItems(newItems);
  };

  const { handleSubmit, register, watch, reset } = useForm<{
    pdfName: string;
  }>();

  const onClose = () => {
    setOpenModal(false);
    reset();
  };

  const onSubmit = async (data: any) => {
    const body = {
      pdfName: data.pdfName,
      pdfs: items.map((item) => ({
        idPdf: item?.id,
        isDocument: item?.isDocument,
        isBrochure: item?.isBrochure,
        isMergedPdf: item?.isMergedPdf,
      })),
    };

    setIsLoading(true);
    try {
      await http.post(`/v1/properties/${propertyId}/merge-pdf`, body);
      setIsLoading(false);
      toast.success(`${t('Merge_successfully')}`, {
        position: 'top-right',
        autoClose: 5000,
      });

      onClose();
      if (mutate) {
        mutate();
      }
      if (onMergedFilesSuccess) {
        onMergedFilesSuccess();
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(`${t('Merge_failed')}`, {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={onClose}
      classes={{ root: 'grid place-items-center' }}
    >
      <Fade in={openModal}>
        <Box
          sx={{
            maxHeight: '90vh',
            overflowY: 'auto',
            overflowX: 'hidden',
            width: { xs: '90%' },
            borderRadius: 2,
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
        >
          <div className="p-8 relative">
            {isLoading ? (
              <div
                className={
                  'absolute bg-gray-100 bg-opacity-40 inset-0 z-10 pointer-events-none'
                }
              >
                <SpinnerLoading />
              </div>
            ) : null}
            <button
              className="absolute right-4 top-4 p-2 opacity-80 transition-opacity duration-300 hover:opacity-50"
              onClick={onClose}
            >
              <CloseIcon
                sx={{ width: '22px', height: '22px' }}
                fontSize="small"
              />
            </button>
            <p className="text-2xl mb-8">{t('Merge_selected_files')}</p>
            <form
              className="flex flex-col gap-y-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <InputFormField
                type="text"
                label={t('Merged_file_name')}
                className="max-w-2xl"
                required
                {...register('pdfName')}
              />
              <div className="flex items-center gap-2 pt-8">
                <p className="text-xl">{t('Order_of_the_files')}</p>
                <Tooltip
                  arrow
                  placement="top"
                  className="cursor-pointer"
                  title={t('drag_and_drop_to_reorder_files')}
                >
                  <InfoOutlinedIcon sx={{ fill: '#939191' }} />
                </Tooltip>
              </div>
              <div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="files_order">
                    {(provided) => (
                      <div
                        className="flex flex-col gap-4"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {items.map((item, index) => (
                          <Draggable
                            key={item?.name}
                            draggableId={item?.name || ''}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                }}
                              >
                                <div
                                  className={classNames(
                                    'p-4 border border-gray-200 rounded flex gap-4 items-center',
                                    {
                                      'opacity-50': snapshot.isDragging,
                                    }
                                  )}
                                >
                                  {item ? (
                                    <>
                                      <FilePreview
                                        item={item}
                                        previewWidth={40}
                                        isSmall
                                      />
                                      {parseFileName(getFileName(item))}
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              <div className="flex justify-end pt-2">
                <Button
                  variant="contained"
                  disableElevation
                  disabled={!watch('pdfName')}
                  type="submit"
                  sx={{ height: '48px', gap: '0.5rem' }}
                >
                  <CallMergeIcon />
                  <span>{t('merge_files')}</span>
                </Button>
              </div>
            </form>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default MergeFilesPopup;
