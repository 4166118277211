import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputFormField from '../../../components/form/inputFormField';
import SpinnerLoading from '../../../features/spinner/spinnerLoading';
import { useNotification } from '../../../hooks/useNotification';
import {
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
  RadioGroup,
  Radio,
} from '@mui/material';
import InputLayout from '../../../components/form/components/inputLayout';
import useSWR from 'swr';
import { categoryOptionType } from '../../../const/propertiesOptions';
import { ContactData, getShortLangCode } from '../newsletterConfirmation';
import { choicesToOptions } from '../../contacts/components/forms/contact-type-form';
import { selectOptionStringT } from '../../../components/form/selectFormField';
import { IContactType, IContactTypeChoice } from '../../../types/contacts';
import { http } from '../../../libs/axios';
import classNames from 'classnames';

interface Props {
  contactData: ContactData | null;
  onLanguageChange?: (lang: string) => void;
  onRequestSuccessful?: (value: boolean) => void;
  isRequestSuccessful: boolean;
  locale: string;
  tokenId?: string;
}

const types: IContactType[] = [
  {
    id: 1,
    keyname: 'owner',
    translations: {
      en: { name: 'owner', locale: 'en' },
      fr: { name: 'propriétaire', locale: 'fr' },
    },
    choices: [
      {
        id: 1,
        keyname: 'seller',
        translations: {
          en: { name: 'seller', locale: 'en' },
          fr: { name: 'vendeur', locale: 'fr' },
        },
      },
      {
        id: 2,
        keyname: 'renter',
        translations: {
          en: { name: 'renter', locale: 'en' },
          fr: { name: 'locataire', locale: 'fr' },
        },
      },
    ],
  },
  {
    id: 2,
    keyname: 'client',
    translations: {
      en: { name: 'client', locale: 'en' },
      fr: { name: 'client', locale: 'fr' },
    },
    choices: [
      {
        id: 3,
        keyname: 'buyer',
        translations: {
          en: { name: 'buyer', locale: 'en' },
          fr: { name: 'Acheteur', locale: 'fr' },
          de: { name: '', locale: 'de' },
          it: { name: '', locale: 'it' },
        },
      },
      {
        id: 4,
        keyname: 'tenant',
        translations: {
          en: { name: 'tenant', locale: 'en' },
          fr: { name: 'Locataire', locale: 'fr' },
          de: { name: '', locale: 'de' },
          it: { name: '', locale: 'it' },
        },
      },
    ],
  },
  {
    id: 3,
    keyname: 'external',
    translations: {
      en: { name: 'external', locale: 'en' },
      fr: { name: 'externe', locale: 'fr' },
    },
    choices: [
      {
        id: 5,
        keyname: 'broker',
        translations: {
          en: { name: 'broker', locale: 'en' },
          fr: { name: 'courtier', locale: 'fr' },
        },
      },
      {
        id: 6,
        keyname: 'agent',
        translations: {
          en: { name: 'agent', locale: 'en' },
          fr: { name: 'agent', locale: 'fr' },
        },
      },
      {
        id: 7,
        keyname: 'notary',
        translations: {
          en: { name: 'notary', locale: 'en' },
          fr: { name: 'notaire', locale: 'fr' },
        },
      },
      {
        id: 8,
        keyname: 'lawyer',
        translations: {
          en: { name: 'lawyer', locale: 'en' },
          fr: { name: 'avocat', locale: 'fr' },
        },
      },
      {
        id: 9,
        keyname: 'constructor',
        translations: {
          en: { name: 'constructor', locale: 'en' },
          fr: { name: 'constructeur', locale: 'fr' },
        },
      },
      {
        id: 10,
        keyname: 'visits',
        translations: {
          en: { name: 'Visit', locale: 'en' },
          fr: { name: 'Visite', locale: 'fr' },
          de: { name: '', locale: 'de' },
          it: { name: '', locale: 'it' },
        },
      },
    ],
  },
];

const getTypesIds = (data: any): number[] => {
  const typesIds: number[] = [];

  // Check if the user is a client and add the client type
  if (data.clientType) {
    typesIds.push(parseInt(data.clientType));
  }

  if (data.ownerType) {
    typesIds.push(parseInt(data.ownerType));
  }

  // Add any external contact types
  if (
    data.isOther &&
    data.externalContactType &&
    Array.isArray(data.externalContactType)
  ) {
    typesIds.push(...data.externalContactType);
  }

  return typesIds;
};

const getChoices = (keyname: string, types?: IContactType[]) => {
  let returnValue: IContactTypeChoice[] = [];

  if (types) {
    const item = types.find((el) => el.keyname === keyname);
    if (item) {
      returnValue = item.choices;
    }
  }

  return returnValue;
};

export const languages = [
  {
    value: 'English',
    key: 'en',
  },
  {
    value: 'French',
    key: 'fr',
  },
];

export const checkType = (
  types: IContactType[],
  data: ContactData,
  type: string
) => {
  const typesChoices: Record<string, any> | null = types
    ? types.reduce((a, b) => ({ ...a, [b.keyname]: b.choices }), {})
    : null;
  const typesSelectedIds =
    data.types && data.types.length ? data.types.map((el) => el.id) : [];

  return (
    typesChoices &&
    typesChoices[type] &&
    typesChoices[type].some((el: { id: number }) =>
      typesSelectedIds.includes(el.id)
    )
  );
};

const getInnerType = (
  type: string,
  typesChoices: Record<string, any> | null,
  typesSelectedIds: number[]
) => {
  let returnValue = '';
  if (typesChoices && typesChoices[type]) {
    const item = typesChoices[type]
      .filter((el: { id: number }) => typesSelectedIds.includes(el.id))
      .find((item: { id: number }) => item.id);

    if (item) {
      returnValue = item.id;
    }
  }
  return returnValue;
};

const transformValues = (
  contactData: ContactData | null,
  types?: IContactType[]
) => {
  const typesChoices: Record<string, any> | null = types
    ? types.reduce((a, b) => ({ ...a, [b.keyname]: b.choices }), {})
    : null;

  const typesSelectedIds =
    contactData && contactData.types && contactData.types.length
      ? contactData.types.map((el) => el.id)
      : [];

  return {
    firstname: contactData?.firstname || '',
    lastname: contactData?.lastname || '',
    phone: contactData?.phone || '',
    types: contactData?.types || [], // Store selected contact types
    isOwner:
      contactData && types ? checkType(types, contactData, 'owner') : false,
    isClient:
      contactData && types ? checkType(types, contactData, 'client') : false,
    isOther:
      contactData && types ? checkType(types, contactData, 'external') : false,
    externalContactType:
      contactData && typesChoices && typesChoices.external
        ? typesChoices.external
            .filter((el: { id: number }) => typesSelectedIds.includes(el.id))
            .map((item: { id: number }) => item.id)
        : [],
    ownerType: contactData
      ? getInnerType('owner', typesChoices, typesSelectedIds)
      : '',
    clientType: contactData
      ? getInnerType('client', typesChoices, typesSelectedIds)
      : '',
    lang: contactData?.lang ? getShortLangCode(contactData?.lang) : 'en',
    propertyTypeInterested: [],
  };
};

function ContactForm(props: Props) {
  const {
    contactData,
    onLanguageChange,
    locale,
    tokenId,
    onRequestSuccessful,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, control, reset, register, watch, resetField } = useForm(
    {
      defaultValues: transformValues(contactData, types),
    }
  );

  const { data: categoriesOptionsApi } =
    useSWR<categoryOptionType[]>('/public/categories');

  const { addNotification } = useNotification();
  const { t } = useTranslation('usersPage');

  useEffect(() => {
    if (contactData) {
      reset(transformValues(contactData, types));
    }
  }, [contactData, types]);

  useEffect(() => {
    if (onLanguageChange) {
      onLanguageChange(watch('lang'));
    }
  }, [watch('lang')]);

  useEffect(() => {
    if (!watch('isClient')) {
      resetField('clientType', {
        defaultValue: '',
      });
    }
    if (!watch('isOwner')) {
      resetField('ownerType', {
        defaultValue: '',
      });
    }
    if (!watch('isOther')) {
      resetField('externalContactType', {
        defaultValue: [],
      });
    }
  }, [watch('isClient'), watch('isOwner'), watch('isOther')]);

  const onSubmit = async (data: any) => {
    const body: Record<string, string | number[]> = {
      firstname: data.firstname,
      lastname: data.lastname,
      phone: data.phone,
      lang: data.lang,
      types: getTypesIds(data),
      propertyTypeInterested: data.propertyTypeInterested,
    };

    setIsLoading(true);
    try {
      await http.patch(
        `${process.env.REACT_APP_API_HOST}/contacts/${tokenId}`,
        body
      );
      reset();
      if (onRequestSuccessful) {
        onRequestSuccessful(true);
      }
    } catch (error) {
      addNotification(t('alerts:server_error', { lng: locale }), 'error');
    }
    setIsLoading(false);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames('w-full relative', {
        'opacity-60 select-none': isLoading,
      })}
    >
      <div className={'flex flex-col gap-4'}>
        <InputFormField
          type="text"
          label={t('firstname', { lng: locale })}
          {...register('firstname', { required: true })}
        />
        <InputFormField
          type="text"
          label={t('lastname', { lng: locale })}
          {...register('lastname', { required: true })}
        />
        <InputFormField
          type="text"
          label={t('phone', { lng: locale })}
          {...register('phone', { required: true })}
        />

        <Controller
          name="lang"
          control={control}
          render={({ field }) => (
            <InputLayout label={t('common:main_language', { lng: locale })}>
              <Select
                className={'h-[56px]'}
                labelId="level-label"
                {...field}
                value={field.value as string}
              >
                {languages.map((item, index: number) => (
                  <MenuItem key={index} value={item.key} className="capitalize">
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </InputLayout>
          )}
        />

        {/* Contact Type Checkboxes */}
        <div className={'flex flex-col gap-2'}>
          <InputLayout
            capitalize={false}
            label={t('common:Are_you_an_owner_or_a_client', { lng: locale })}
          >
            <div className={'flex gap-4'}>
              <Controller
                name="isClient"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox checked={field.value} />}
                    label={t('common:client', { lng: locale })}
                    {...field}
                  />
                )}
              />
              <Controller
                name="isOwner"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox checked={field.value} />}
                    label={t('propertiesPage:owner', { lng: locale })}
                    {...field}
                  />
                )}
              />
              <Controller
                name="isOther"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox checked={field.value} />}
                    label={t('common:other', { lng: locale })}
                    {...field}
                  />
                )}
              />
            </div>
          </InputLayout>

          {watch('isClient') && (
            <InputLayout
              capitalize={false}
              label={t('common:Are_you_a_buyer_or_a_tenant', { lng: locale })}
            >
              <Controller
                control={control}
                name="clientType"
                render={({ field }) => (
                  <RadioGroup sx={{ flexDirection: 'row' }} {...field}>
                    {getChoices('client', types).map((el) => (
                      <FormControlLabel
                        key={el.id}
                        value={el.id}
                        control={<Radio />}
                        label={el.translations?.[locale]?.name}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </InputLayout>
          )}

          {watch('isOwner') && (
            <InputLayout
              capitalize={false}
              label={t('common:Are_you_a_seller_or_a_renter', { lng: locale })}
            >
              <Controller
                control={control}
                name="ownerType"
                render={({ field }) => (
                  <RadioGroup sx={{ flexDirection: 'row' }} {...field}>
                    {getChoices('owner', types).map((el) => (
                      <FormControlLabel
                        key={el.id}
                        value={el.id}
                        className={'capitalize'}
                        control={<Radio />}
                        label={el.translations?.[locale]?.name}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </InputLayout>
          )}

          {watch('isOther') && (
            <InputLayout
              label={t('common:External_Types', { lng: locale })}
              capitalize={false}
            >
              <Controller
                name={'externalContactType'}
                control={control}
                render={({ field }) => (
                  <Select
                    sx={{
                      '& .MuiSelect-select.MuiSelect-multiple': {
                        textTransform: 'capitalize',
                      },
                    }}
                    multiple
                    className={'h-[56px]'}
                    labelId="level-label"
                    {...field}
                    value={field.value as number[]}
                  >
                    {types && types[2]
                      ? choicesToOptions(types[2].choices, locale).map(
                          (item: selectOptionStringT, index: number) =>
                            item?.value != '' && (
                              <MenuItem
                                key={index}
                                value={item?.id}
                                className={`capitalize option_${item.id}`}
                              >
                                {item.value}
                              </MenuItem>
                            )
                        )
                      : null}
                  </Select>
                )}
              />
            </InputLayout>
          )}
        </div>

        {/* Property Interest Checkboxes */}
        <InputLayout
          className={'border-t pt-4'}
          capitalize={false}
          label={t('common:What_type_of_properties_are_you_interested_in', {
            lng: locale,
          })}
        >
          <Controller
            name="propertyTypeInterested"
            control={control}
            render={({ field }) => (
              <div>
                {categoriesOptionsApi
                  ? categoriesOptionsApi.map((category) => (
                      <FormControlLabel
                        key={category.id}
                        control={
                          <Checkbox
                            checked={(field.value as number[]).includes(
                              category.id as number
                            )}
                          />
                        }
                        label={category?.translations?.[locale]?.value}
                        onChange={(e) => {
                          const target = e.target as HTMLInputElement;
                          if (target.checked) {
                            field.onChange([...field.value, category.id]);
                          } else {
                            field.onChange(
                              field.value.filter(
                                (item: string) => item !== category.id
                              )
                            );
                          }
                        }}
                      />
                    ))
                  : null}
              </div>
            )}
          />
        </InputLayout>

        <div className={'flex flex-col gap-2 justify-center items-center pt-8'}>
          <p className={'text-center opacity-70'}>
            {t('common:Please_review_your_details_carefully', { lng: locale })}
          </p>
          <button
            disabled={isLoading}
            type="submit"
            className="w-full text-center px-8 py-4 rounded-full bg-blue text-white transition-colors hover:bg-blue-900"
          >
            <span className="text-[14px] leading-none uppercase">
              {t('common:Confirm_Subscription', { lng: locale })}
            </span>
          </button>
        </div>
      </div>
      {isLoading ? (
        <div className={'absolute inset-0 z-10'}>
          <SpinnerLoading />
        </div>
      ) : null}
    </form>
  );
}

export default ContactForm;
