import { useTranslation } from 'react-i18next';
import EmailTemplateForm from './form';
import useSWR from 'swr';
import { ISettingEmails } from '../../../types/settings';
import TitleHeader from '../../../components/common/titleHeader';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Fade, Modal } from '@mui/material';
import React, { useState } from 'react';
import { AccordionView } from '../../contacts/creator/individual-view';
import useLocale from '../../../hooks/useLocale';

const EmailTemplatesPage = () => {
  const { t } = useTranslation('common');
  const { data: settings } = useSWR<ISettingEmails[]>('/setting_emails');
  const { data: templates, mutate } =
    useSWR<ISettingEmails[]>('/email_templates');
  const isAdmin = JSON.parse(localStorage.getItem('isAdmin') as string);
  const [openNewTemplatePopup, setOpenNewTemplatePopup] = useState(false);
  const { locale } = useLocale();

  const onClose = () => {
    setOpenNewTemplatePopup(false);
  };

  if (!isAdmin.isAdmin) {
    return null;
  }

  return (
    <>
      <TitleHeader
        title={t('email_templates')}
        goBackLink={'/settings'}
        rightElement={
          <Button
            className="!ml-[6px] sm:!ml-0"
            variant="contained"
            sx={{ background: 'background.default' }}
            startIcon={<AddIcon fontSize="medium" />}
            disableElevation
            onClick={() => setOpenNewTemplatePopup(true)}
          >
            {t('new_template')}
          </Button>
        }
      >
        <section className="w-full h-full flex flex-col">
          <div className={'sm:p-10 pb-16 bg-white flex flex-col gap-8'}>
            <AccordionView
              titleClassName={'text-lg md:text-[24px]'}
              className={'w-full col-start-1 col-end-4'}
              title={`${t('matching-emails')} ${t('via-marketing-automation')}`}
            >
              <EmailTemplateForm
                initialData={settings?.find((el) => el.type === '3')}
                staticMode
              />
            </AccordionView>

            {templates?.map((item) => (
              <AccordionView
                key={item.id}
                titleClassName={'text-lg md:text-[24px]'}
                className={'w-full col-start-1 col-end-4'}
                title={item?.translations?.[locale]?.title || '...'}
              >
                <EmailTemplateForm mutate={mutate} initialData={item} />
              </AccordionView>
            ))}
          </div>
          <Modal
            open={openNewTemplatePopup}
            onClose={onClose}
            classes={{ root: 'grid place-items-center' }}
          >
            <Fade in={openNewTemplatePopup}>
              <Box
                sx={{
                  maxHeight: '95vh',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  width: {
                    xs: '80%',
                    md: '70%',
                  },
                  borderRadius: 2,
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  '&::-webkit-scrollbar': {
                    width: '10px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#BBC0C4',
                    borderRadius: 8,
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#888',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: '#f1f1f1',
                  },
                }}
              >
                <div
                  className={
                    'p-4 sm:p-8 flex justify-center items-center max-h-full relative'
                  }
                >
                  <div className="w-full flex flex-col gap-4">
                    <h2 className={'text-[2rem]'}>
                      {t('create_new_email_template')}
                    </h2>
                    <EmailTemplateForm
                      createMode
                      mutate={mutate}
                      onClose={onClose}
                    />
                  </div>
                </div>
              </Box>
            </Fade>
          </Modal>
        </section>
      </TitleHeader>
    </>
  );
};

export default EmailTemplatesPage;
