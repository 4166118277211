import TableItem from './item';
import { IContactIndividual } from '../../../../types/contacts';
import useLocale from '../../../../hooks/useLocale';
import { useTranslation } from 'react-i18next';
import { Translations } from '../../../../types/translations';

export const minTwoDigits = (n: number) => {
  return ('0' + n).slice(-2);
};

export const formatDate = (date: string) => {
  if (date) {
    const formattedDate = new Date(date);
    return `${minTwoDigits(formattedDate.getDate())}/${
      formattedDate.getMonth() + 1
    }/${formattedDate.getFullYear()}`;
  }
};

const IndividualInformationTable = (props: {
  data: IContactIndividual;
  agentInCharge?: string;
  archived?: boolean;
  status?: {
    id?: number;
    keyname: string;
    translations: Translations;
  };
  agencies?: {
    categoryAgence?: string;
    idAgence?: number;
    nameAgence?: string;
  }[];
}) => {
  const { data, agentInCharge, archived, status, agencies } = props;
  const { locale } = useLocale();
  const { t } = useTranslation('common');
  const agenciesList =
    Array.isArray(agencies) && agencies.map((elem) => elem.nameAgence);

  return data ? (
    <div className={'divide-y border'}>
      <TableItem
        title={t('Title')}
        value={
          data.translations && data.translations[locale]
            ? data.translations[locale]?.title
            : null
        }
      />
      <TableItem title={t('First_name')} value={data.firstname} />
      <TableItem title={t('Last_name')} value={data.lastname} />
      <TableItem
        title={t('Greeting')}
        value={
          data.translations && data.translations[locale]
            ? data.translations[locale]?.greeting
            : null
        }
      />
      <TableItem
        title={t('In_the_capacity_of')}
        value={
          data.translations && data.translations[locale]
            ? data.translations[locale]?.capacity
            : null
        }
      />
      <TableItem
        title={t('Status')}
        color={
          status?.keyname === 'rejected' || archived
            ? '#F50057'
            : status?.keyname === 'active'
            ? '#4CAF50'
            : '#ED6C02'
        }
        value={archived ? t('Archived') : status?.translations[locale]?.value}
      />
      <TableItem title={t('Phone')} value={data.phone} />
      <TableItem title={t('Email')} value={data.email} />
      <TableItem
        title={t('Language')}
        value={data.language ? data.language.name : null}
      />
      <TableItem title={t('Age')} value={data.age} />
      <TableItem
        title={t('Date_of_birth')}
        value={data.birthdate ? formatDate(data.birthdate) : null}
      />
      <TableItem title={t('Place_of_birth')} value={data.birthplace} />
      <TableItem title={t('Nationality')} value={data.nationality} />
      <TableItem
        title={t('Country')}
        value={
          data.address && data.address.translations[locale]
            ? data.address?.translations?.[locale]?.country
            : null
        }
      />
      <TableItem
        title={t('City')}
        value={
          data.address && data.address.translations[locale]
            ? data.address?.translations?.[locale]?.city
            : null
        }
      />
      <TableItem title={t('Area_code')} value={data.address?.zipCode} />
      <TableItem
        title={t('Address')}
        value={
          data.address && data.address.translations[locale]
            ? data.address?.translations?.[locale]?.adresse
            : null
        }
      />
      <TableItem title={t('Agent_in_charge')} value={agentInCharge} />
      <TableItem
        title={t('usersPage:secondary_agencies')}
        value={agenciesList.toString().replaceAll(',', ', ')}
      />
    </div>
  ) : null;
};

export default IndividualInformationTable;
