import { useForm } from 'react-hook-form';
import { FC } from 'react';

import {
  GatewayApi,
  IProperty,
  IPropertyApi,
  PropertyStepperStep,
} from '../../../../types/property';
import { stepperFormId } from '../../../../const';
import StepsFieldset from '../../../../components/stepper/stepsFieldset';
import SwitcherFormField from '../../../../components/form/switcherFormField';
import DateRangeField from '../../../../components/form/dateRangeField';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { guaranteeArray } from '../../../../helpers/utils/formatters';

const PropertyPublicationStep: FC<PropertyStepperStep> = ({
  onSubmit,
  defaultValues,
  serverData,
}) => {
  const { register, handleSubmit, setValue, watch, control } =
    useForm<IProperty>({
      defaultValues,
    });
  const { t } = useTranslation('propertiesPage');

  const disabledCheck = (
    serverData: Partial<IPropertyApi>,
    singleGateway: GatewayApi,
    compare: string | null
  ) => {
    return (
      (serverData?.publishGateways &&
        serverData?.translations &&
        serverData?.translations[singleGateway?.gateway?.language ?? '']
          ?.title === compare) ||
      (serverData?.publishGateways &&
        serverData?.translations &&
        serverData?.translations[singleGateway?.gateway?.language ?? '']
          ?.description === compare)
    );
  };

  return (
    <form
      id={stepperFormId}
      onSubmit={handleSubmit(onSubmit)}
      className="grid gap-16"
    >
      <StepsFieldset
        className="grid grid-cols-2"
        title={t('Publish_on_websites')}
      >
        <div>
          <DateRangeField
            control={control}
            nameFrom="publishWebsite.startDate"
            nameTo="publishWebsite.endDate"
            labelFrom={t('Start_date')}
            labelTo={t('End_date')}
          />
        </div>
        <div className="flex flex-col gap-4">
          <SwitcherFormField
            checked={serverData?.publishWebsite?.publishWebsite}
            register={register}
            className="flex justify-between items-center"
            label={t('Publish_on_websites')}
            name="publishWebsite.publishWebsite"
          />
          <SwitcherFormField
            checked={serverData?.publishWebsite?.featured}
            register={register}
            className="flex justify-between items-center"
            label={t('featured')}
            name="publishWebsite.featured"
          />
          <SwitcherFormField
            checked={serverData?.publishWebsite?.publishHomepage}
            register={register}
            className="flex justify-between items-center"
            name="publishWebsite.publishHomepage"
            label={t('Publish_on_home_page')}
          />
          <SwitcherFormField
            checked={serverData?.publishLanding || false}
            register={register}
            className="flex justify-between items-center"
            name="publishLanding"
            label={t('landing_page')}
          />
          <SwitcherFormField
            checked={serverData?.publishLandingPromotion || false}
            register={register}
            className="flex justify-between items-center"
            name="publishLandingPromotion"
            label={t('promotionPage:publish_on_promotion_landing')}
          />
        </div>
      </StepsFieldset>{' '}
      {serverData?.publishGateways?.length ? (
        <StepsFieldset
          className="grid grid-cols-1"
          title={t('Publish_on_gateways')}
        >
          {guaranteeArray(serverData.publishGateways).map(
            (singleGateway, index: number) => {
              const {
                gateway: { name },
                isPublished,
              } = singleGateway;
              if (!singleGateway) return null;
              const isDisabled =
                disabledCheck(serverData, singleGateway, '') ||
                disabledCheck(serverData, singleGateway, '<p><br></p>') ||
                disabledCheck(serverData, singleGateway, null);

              return (
                <div key={index} className=" grid grid-cols-4 gap-16">
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: '#E7931C',
                          fontSize: '14px',
                          '& .MuiTooltip-arrow': {
                            color: '#E7931C',
                          },
                        },
                      },
                    }}
                    title={t(
                      'propertiesPage:Please_fill_the_fields_for_title_and_description'
                    )}
                    arrow
                    placement="right"
                    disableHoverListener={!isDisabled}
                  >
                    <div className="col-span-2">
                      <SwitcherFormField
                        disabled={isDisabled}
                        checked={isPublished}
                        handleChange={() => {
                          if (!watch(`publishGateways.${index}.isPublished`)) {
                            setValue(
                              `publishGateways.${index}.startDate`,
                              null
                            );
                            setValue(`publishGateways.${index}.endDate`, null);
                          }
                        }}
                        className="flex justify-between items-center lab_link"
                        label={<span className="text-lg">{name}</span>}
                        name={`publishGateways.${index}.isPublished`}
                        register={register}
                      />
                    </div>
                  </Tooltip>
                  {watch(`publishGateways.${index}.isPublished`) && (
                    <div className="col-span-2">
                      <DateRangeField
                        control={control}
                        nameFrom={`publishGateways.${index}.startDate`}
                        nameTo={`publishGateways.${index}.endDate`}
                        labelFrom={t('Start_date')}
                        labelTo={t('End_date')}
                      />
                    </div>
                  )}
                </div>
              );
            }
          )}
        </StepsFieldset>
      ) : null}
    </form>
  );
};

export default PropertyPublicationStep;
