import { useTranslation } from 'react-i18next';
import useLocale from '../../../hooks/useLocale';
import { Item, Lead } from '..';

interface NotificationProps {
  notificationType?: string;
  data?: Item | Lead;
  onHide?: () => void;
  onView?: () => void;
}

const Message = ({ notificationType, data }: NotificationProps) => {
  const { t } = useTranslation();
  const { locale } = useLocale();

  if (!data) {
    return null;
  }

  switch (notificationType) {
    case 'matching_promotion_done':
      return (
        <p className="text-lg !text-black">
          {t('alerts:matching_promotion_done_start')}{' '}
          {'translations' in data && data.translations?.[locale]?.title ? (
            <a href={`/promotions/${data.id}`} className="text-blue">
              <span>{data.translations?.[locale]?.title}</span>
            </a>
          ) : null}{' '}
          {t('alerts:matching_done_end')}
        </p>
      );
    case 'matching_property_done':
      return (
        <p className="text-lg !text-black">
          {t('alerts:matching_property_done_start')}{' '}
          {'reference' in data && data.reference ? (
            <a href={`/properties/${data.id}`} className="text-blue">
              <span>{data.reference}</span>
            </a>
          ) : null}{' '}
          {t('alerts:matching_done_end')}
        </p>
      );
    case 'new_lead':
      return (
        <p className="text-lg !text-black">
          {t('alerts:new_lead_for_the')}{' '}
          {'property' in data && data.property ? (
            <>
              <span className={'lowercase'}>{t('common:property')} </span>
              <a href={`/properties/${data.property.id}`} className="text-blue">
                <span>
                  {data.property.agencyReference || data.property.reference}
                </span>
              </a>
            </>
          ) : null}
          {'promotion' in data && data.promotion ? (
            <>
              <span className={'lowercase'}>{t('common:Promotion')} </span>
              <a
                href={`/promotions/${data.promotion.id}`}
                className="text-blue"
              >
                <span>{data.promotion.reference}</span>
              </a>
            </>
          ) : null}
          {'source' in data && data.source ? (
            <div>
              <span>
                {' '}
                {t('common:From')}
                {': '}
              </span>
              <p
                title={t(`${data.source}`)}
                className="text-blue whitespace-nowrap overflow-hidden overflow-ellipsis"
              >
                {t(`${data.source}`)}
              </p>
            </div>
          ) : null}
          {'message' in data && data.message ? (
            <div>
              <span>
                {t('common:Message')}
                {': '}
              </span>
              <span className="font-medium italic">
                {'message' in data && data.message}
              </span>
            </div>
          ) : null}
        </p>
      );
    default:
      return null;
  }
};

const Notification = ({
  notificationType,
  data,
  onHide,
  onView,
}: NotificationProps) => {
  const { t } = useTranslation();

  const onViewAndClose = () => {
    if (onView) {
      onView();
    }
    if (onHide) {
      onHide();
    }
  };

  if (!data) {
    return null;
  }

  return (
    <div className="p-4 flex flex-col divide-y">
      <div className={'grid grid-cols-12 pb-4'}>
        <div className={'col-span-4 rounded overflow-hidden flex items-start'}>
          <a href={'/'}>
            <div className={'flex justify-center items-center max-h-28'}>
              {'coverImage' in data ? (
                <img
                  className="w-full object-contain rounded"
                  src={
                    data.coverImage && data.coverImage.url
                      ? data.coverImage.url.thumbnail
                      : '/img/default_image.jpg'
                  }
                  alt="Property"
                />
              ) : null}
              {'mainImage' in data ? (
                <img
                  className="w-full object-contain rounded"
                  src={
                    data.mainImage && data.mainImage.url
                      ? data.mainImage.url.thumbnail
                      : '/img/default_image.jpg'
                  }
                  alt="Property"
                />
              ) : null}
              {'property' in data && data.property ? (
                <img
                  className="w-full object-contain rounded"
                  src={
                    data.property?.mainImage && data.property.mainImage.url
                      ? data.property.mainImage.url.thumbnail
                      : '/img/default_image.jpg'
                  }
                  alt="Property"
                />
              ) : null}
              {'promotion' in data && data.promotion ? (
                <img
                  className="w-full object-contain rounded"
                  src={
                    data.promotion?.coverImage && data.promotion.coverImage.url
                      ? data.promotion.coverImage.url.thumbnail
                      : '/img/default_image.jpg'
                  }
                  alt="Property"
                />
              ) : null}
            </div>
          </a>
        </div>
        <div className={'col-start-6 col-end-13'}>
          <Message notificationType={notificationType as string} data={data} />
        </div>
      </div>
      <div className="col-span-12 grid grid-cols-2 gap-4 pt-4">
        <button
          className="transition-colors duration-300 px-2 py-2 text-blue bg-gray-100 rounded hover:bg-gray-200"
          onClick={onViewAndClose}
        >
          {t('common:View')}
        </button>
        <button
          className="transition-colors duration-300 px-2 py-2 text-blue bg-white rounded hover:bg-gray-100"
          onClick={onHide}
        >
          {t('common:Hide')}
        </button>
      </div>
    </div>
  );
};

export default Notification;
