import {
  deDE,
  enUS,
  frFR,
  GridRowHeightParams,
  GridValidRowModel,
} from '@mui/x-data-grid';

import {
  deDE as coredeDE,
  enUS as coreenEN,
  frFR as corefrFR,
} from '@mui/material/locale';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import useLocale from '../../hooks/useLocale';
import { useThemeMode } from '../../hooks/useThemeMode';
import { appTheme } from '../material-ui/theme';
import { useMemo } from 'react';
import {
  DataGridPro,
  GridColDef,
  DataGridProProps,
} from '@mui/x-data-grid-pro';
import { CustomGridTreeDataGroupingCell } from '../material-ui/tablesConfig/promotionsListingConfig';
import { TableGridXStyling } from './tableGridXStyling';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTranslation } from 'react-i18next';

interface tableGridPropsT extends DataGridProProps {
  rows: Array<GridValidRowModel>;
  columns: GridColDef[];
  checkboxSelection?: boolean;
  disableColumnMenu?: boolean;
  density?: 'standard' | 'compact' | 'comfortable';
  rowsPerPageOptions?: Array<number>;
  loading?: boolean;
  showCellRightBorder?: boolean;
  rowsPerPage?: any;
  page: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  customColumnsTitle?: string;
  setItemsPerPage: (value: number) => void;
}

export function CustomActionsIcon() {
  return <MoreHorizIcon sx={{ fontSize: 25 }} />;
}
function TableGridXWithTreeData({
  rows,
  columns,
  density = 'standard',
  page,
  pageSize,
  onPageChange,
  showCellRightBorder,
  customColumnsTitle,
  rowsPerPage,
  setItemsPerPage,
  rowsPerPageOptions = [10, 25, 50, 100],
  rowCount,
  ...rest
}: tableGridPropsT) {
  const { locale } = useLocale();
  const [lang, coreLang] =
    locale == 'fr'
      ? [frFR, corefrFR]
      : locale == 'de'
      ? [deDE, coredeDE]
      : [enUS, coreenEN];

  const { mode } = useThemeMode();
  const { t } = useTranslation('propertiesPage');
  const theme = useMemo(() => createTheme(appTheme(mode)), [mode]);

  const tableGridTheme = createTheme(theme, lang, coreLang);

  const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => {
    return row.hierarchy;
  };

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: '',
    minWidth: 40,
    maxWidth: 60,
    flex: 1,
    align: 'center',
    renderCell: (params) => (
      <CustomGridTreeDataGroupingCell {...params} title={customColumnsTitle} />
    ),
  };

  return (
    <ThemeProvider theme={tableGridTheme}>
      <Box sx={TableGridXStyling}>
        <DataGridPro
          sx={{ fontSize: '12px' }}
          slots={{
            moreActionsIcon: CustomActionsIcon,
          }}
          slotProps={{
            panel: {
              placement: 'bottom-end',
            },
            pagination: {
              labelRowsPerPage: t('Rows_Per_Page'),
            },
          }}
          {...rest}
          rows={rows}
          columns={columns}
          density={density}
          treeData
          // autoPageSize
          getTreeDataPath={getTreeDataPath}
          hideFooterSelectedRowCount
          pagination
          paginationMode={'server'}
          paginationModel={{ page, pageSize }}
          rowCount={rowCount}
          className={'only-vertical-scroll'}
          pageSizeOptions={rowsPerPageOptions}
          onPaginationModelChange={(model) => {
            setItemsPerPage(
              rowsPerPageOptions.includes(model.pageSize)
                ? model.pageSize
                : rowsPerPage
            );
            localStorage.setItem(
              'contactItemsPerPage',
              JSON.stringify(
                rowsPerPageOptions.includes(model.pageSize)
                  ? model.pageSize
                  : rowsPerPage
              )
            );

            onPageChange(model.page);
          }}
          rowHeight={64}
          rowSelection={false}
          showCellVerticalBorder={showCellRightBorder}
          disableColumnResize
          disableColumnReorder
          groupingColDef={groupingColDef}
          getRowClassName={({ row }) =>
            `super-app-theme--${row.hierarchy.length > 1 ? 'inner' : ''}`
          }
          getRowHeight={({ model }: GridRowHeightParams) => {
            if (model.hierarchy.length > 1) {
              return 67;
            }
            return null;
          }}
        />
      </Box>
    </ThemeProvider>
  );
}

export default TableGridXWithTreeData;
