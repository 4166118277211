import { lazy, Suspense, useEffect, useState } from 'react';
import { PipelineValue } from '../../types/pipelines';
import PipelineSelect from '../../components/reusableSteps/pipelines/pipelineSelect';
import useSWR from 'swr';
import { http } from '../../libs/axios';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LeadManagement = lazy(
  () => import('../../../src/components/reusableSteps/pipelines/leadManagement')
);
const PipelinesOverview = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('common');

  const [pipelineId, setSelectedPipelineId] = useState<number>();

  const { data: pipelineValues, mutate } = useSWR<PipelineValue[]>(
    pipelineId ? `/pipelines/${pipelineId}/agency` : null,
    null,
    {
      shouldRetryOnError: false,
    }
  );
  const preselectedPipeline = pipelineValues?.find(
    (value) => value.pipeline
  )?.pipeline;

  useEffect(() => {
    if (!pipelineId) return;

    const fetchPipeline = async () => {
      setIsLoading(true);
      try {
        const response = await http.get(`/pipelines/${pipelineId}/agency`);

        setData(response.data);
      } catch (error) {
        console.error('Error fetching pipeline:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPipeline();
  }, [pipelineId]);

  const seenIds = new Set();
  return (
    <div className="pb-4 grid gap-10">
      <div className="my-4">
        <span className="font-normal text-3xl font-[Inter]">Pipelines</span>
      </div>
      <div className="max-w-[400px]">
        <PipelineSelect
          pipelinesEndpoint={`/pipelines/published`}
          handlePipelineChange={(e) =>
            setSelectedPipelineId(e.target.value as number)
          }
        />
      </div>
      <div className="h-px bg-gray-200"></div>
      {!pipelineId && (
        <div className="text-center mt-16 font-[Inter] font-medium">
          {t(
            'No_pipeline_selected_Please_select_one_from_the_list_to_continue'
          )}
        </div>
      )}

      {isLoading ? (
        <div className="flex justify-center items-center h-[100%] mt-16">
          <CircularProgress disableShrink />
        </div>
      ) : (
        <div>
          {data
            .filter((elem: PipelineValue) => {
              const id = elem.property?.id || elem.promotion?.id;
              if (seenIds.has(id)) return false;
              seenIds.add(id);
              return true;
            })
            .map((elem: any) =>
              elem.promotion ? (
                <Suspense fallback={null} key={elem.id}>
                  <LeadManagement
                    selectedPipelineId={preselectedPipeline?.id || pipelineId}
                    leadsEndpoint={`/v1/promotions/${elem.promotion.id}/prospects?itemsPerPage=400`}
                    pipelineValuesEndpoint={`/promotion/pipeline_values`}
                    entity={{ promotion: elem.promotion }}
                    pipelineValues={pipelineValues}
                    mutatePipelineValues={mutate}
                    promotion
                    reference={
                      elem.promotion.agencyReference
                        ? elem.promotion.agencyReference
                        : elem.promotion.reference
                    }
                  />
                </Suspense>
              ) : (
                <Suspense fallback={null} key={elem.id}>
                  <LeadManagement
                    selectedPipelineId={preselectedPipeline?.id || pipelineId}
                    leadsEndpoint={`/v1/properties/${elem.property.id}/prospects?itemsPerPage=400`}
                    pipelineValuesEndpoint={`/property/pipeline_values`}
                    entity={{ property: elem.property }}
                    pipelineValues={pipelineValues}
                    mutatePipelineValues={mutate}
                    reference={
                      elem.property.agencyReference
                        ? elem.property.agencyReference
                        : elem.property.reference
                    }
                  />
                </Suspense>
              )
            )}
        </div>
      )}
    </div>
  );
};

export default PipelinesOverview;
