import { Box, Fade, Modal } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import { ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const HashControlledModal = ({
  hash,
  children,
  disableBackdropClick = false,
}: {
  hash: string;
  disableBackdropClick?: boolean;
  children?: ({
    isOpen,
    close,
  }: {
    isOpen: boolean;
    close: () => unknown;
  }) => ReactNode;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const close = () => {
    if (!disableBackdropClick) {
      navigate('#');
    }
  };
  const isOpen = hash === location.hash;

  return (
    <Modal open={isOpen} onClose={close}>
      <ClickAwayListener onClickAway={close}>
        <Fade in={isOpen}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              maxHeight: '92vh',
              overflowY: 'auto',
              width: {
                xs: '76%',
              },
              borderRadius: 2,
              bgcolor: 'background.paper',
              boxShadow: 24,
            }}
          >
            {children?.({ isOpen, close })}
          </Box>
        </Fade>
      </ClickAwayListener>
    </Modal>
  );
};

export default HashControlledModal;
