import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SwitcherIosFormField from '../../../components/form/switcherIosFormField';
import { http } from '../../../libs/axios';
import { toast } from 'react-toastify';

function ToggleSendingAutomation({
  propertyId,
  checked,
  onChange,
}: {
  propertyId?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}) {
  const [isBusy, setIsBusy] = useState(false);
  async function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (!isBusy) {
      await persist(e.target.checked);
    }
  }

  const { t } = useTranslation('propertiesPage');

  async function persist(isChecked: boolean) {
    setIsBusy(true);
    try {
      await http.patch(`/v1/properties/${propertyId}/auto-email`, {
        autoEmail: { isActive: isChecked },
      });
      toast.success(t('automation_preferences_updated') as string);
      onChange(isChecked);
    } catch (e) {
      console.error(e);
      toast.error(t('alerts:something_went_wrong') as string);
    }
    setIsBusy(false);
  }

  return (
    <label className="flex gap-4">
      <SwitcherIosFormField
        checked={checked}
        onChange={handleChange}
        disabled={isBusy}
      />
      <span>{t('enable_automation')}</span>
    </label>
  );
}

export default ToggleSendingAutomation;
