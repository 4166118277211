import { ActionData } from '../../../../../types/actionData';
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import { useAnchorElement } from '../../../../../hooks/useAnchorElement';
import ActionMenu from '../../../../../components/common/actionMenu';
import { http } from '../../../../../libs/axios';
import { toast } from 'react-toastify';
import { mutate } from 'swr';
import LoopSharpIcon from '@mui/icons-material/LoopSharp';
import CheckIcon from '@mui/icons-material/Check';
import { useState } from 'react';
import SendFilesPopup from './sendFilesPopup';
import { ILeadItemExtended } from './index';
import { useTranslation } from 'react-i18next';

const LeadsListingActionMenu = ({ row }: { row: ILeadItemExtended }) => {
  const [filesPopupOpen, setFilesPopupOpen] = useState(false);
  const { t } = useTranslation('propertiesPage');
  const changeLeadStatus = () => {
    http
      .patch(`/prospects/${row.id}`, { status: '2' })
      .then(() => {
        toast.success(t('Lead_status_successfully_changed') as string);
        mutate(`/v1/properties/${row.parentId}/prospects`);
      })
      .catch(() => {
        toast.error(
          t('There_is_been_an_server_error_Lead_status_not_changed') as string
        );
      });
  };

  const generateSearchCriteria = () => {
    if (row.contact && row.contact.id) {
      http
        .post(`/contacts/${row.contact.id}/search_criteria`, {
          property: row.parentId,
        })
        .then(() => {
          toast.success(
            t('Search_criteria_was_successfully_generated') as string
          );
        })
        .catch(() => {
          toast.error(
            t(
              'There_is_been_an_server_error_Search_criteria_was_not_generated'
            ) as string
          );
        });
    }
  };

  const menuActionData: ActionData[] = [
    {
      label: 'propertiesPage:Generate_Search_Criteria',
      icon: LoopSharpIcon,
      onClick: generateSearchCriteria,
    },
    {
      label: 'propertiesPage:Send_brochure',
      icon: AttachEmailOutlinedIcon,
      onClick: () => setFilesPopupOpen(true),
    },
  ];

  if (row.status.keyname === 'in progress') {
    menuActionData.push({
      label: 'propertiesPage:Mark_as_processed',
      icon: CheckIcon,
      onClick: changeLeadStatus,
    });
  }

  const anchor = useAnchorElement();

  return (
    <>
      <ActionMenu
        {...anchor}
        menuAction={menuActionData}
        transPage={'brochureEditor'}
      />
      {filesPopupOpen ? (
        <SendFilesPopup
          id={row.parentId}
          contact={row.contact}
          leadId={row.id}
          openModal={filesPopupOpen}
          setOpenModal={setFilesPopupOpen}
        />
      ) : null}
    </>
  );
};

export default LeadsListingActionMenu;
