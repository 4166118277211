import * as React from 'react';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

import AddressFields from '../search-criteria/addresses/address-fields';
import { useGoogleApis } from '../../../../hooks/useGoogleApis';
import AddressMap from '../search-criteria/addresses/address-map';
import useLocale from '../../../../hooks/useLocale';
import { useTranslation } from 'react-i18next';

export interface Polygon {
  id: string;
  name: number;
  coords: { lat: number; lng: number }[];
  polygon?: any;
}

const initialPolygon = [
  { lat: 46.208376, lng: 6.127148 },
  { lat: 46.21175, lng: 6.151425 },
  { lat: 46.198897, lng: 6.151944 },
  { lat: 46.199831, lng: 6.128911 },
];

const AddressForm = (props: {
  index: number;
  inner?: boolean;
  register: any;
  watch: any;
  setValue?: any;
  defaultValues?: any;
  control: any;
}) => {
  const [paths, setPaths] = useState<Polygon[]>(
    props.defaultValues.addresses?.[props.index]?.polygon || []
  );

  const { register, control, setValue, watch } = props;
  const { isLoaded } = useGoogleApis();
  const { locale } = useLocale();
  const { t } = useTranslation('common');
  const onLoadPolygon = (polygon: any, id: string) => {
    const polygonIndex = paths.findIndex((item) => item.id === id);
    const updatedPath = paths;

    if (polygonIndex >= 0 && updatedPath[polygonIndex]) {
      // @ts-ignore
      updatedPath[polygonIndex].polygon = polygon;
    }

    setPaths(updatedPath);
  };

  const onAddPolygon = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    const newPolygon: Polygon = {
      id: uuid(),
      name: paths.length ? (paths[paths.length - 1]?.name || 0) + 1 : 1,
      coords: initialPolygon,
    };

    setPaths([...paths, newPolygon]);
  };

  const onRemovePolygon = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ) => {
    event.preventDefault();
    const updatedPath = paths;

    updatedPath.splice(
      updatedPath.findIndex(function (i) {
        return i.id === id;
      }),
      1
    );

    setPaths([...updatedPath]);
  };

  const onEditPolygon = (event: any, id: string) => {
    const polygonIndex = paths.findIndex((item) => item.id === id);
    const updatedPath = paths;

    if (polygonIndex >= 0 && updatedPath && updatedPath[polygonIndex]) {
      const item = updatedPath?.[polygonIndex]?.polygon;
      let arr = [];

      if (item) {
        const vertices = item.getPath();

        for (let i = 0; i < vertices.getLength(); i++) {
          const xy = vertices.getAt(i);
          arr.push({ lat: xy.lat(), lng: xy.lng() });
        }
      } else {
        arr = item.coords;
      }

      // @ts-ignore
      updatedPath[polygonIndex].coords = arr;

      setPaths(updatedPath);
      setValue(`addresses.${props.index}.polygon`, updatedPath, {
        shouldDirty: true,
      });
    }
  };

  useEffect(() => {
    setValue(`addresses.${props.index}.polygon`, paths, { shouldDirty: true });
    if (paths.length) {
      setValue(
        `addresses.${props.index}`,
        {
          location: '',
          latitude: '',
          longitude: '',
          zipCode: '',
          translations: {
            [`${locale}`]: {
              locale,
              adresse: '',
              city: '',
              district: '',
              canton: '',
              country: '',
            },
          },
        },
        { shouldDirty: true }
      );
    }
  }, [paths]);

  return (
    <div className={'grid grid-cols-12 gap-16 pt-8 border-b pb-8'}>
      <div
        className={classNames(
          'col-start-1 col-end-6 2xl:col-end-5 flex flex-col gap-8',
          {
            'opacity-50 pointer-events-none': !!paths.length,
          }
        )}
      >
        <AddressFields
          watch={watch}
          register={register}
          control={control}
          index={props.index}
          name={'addresses'}
          isLoaded={isLoaded}
        />
      </div>
      <div className={'2xl:col-start-5 col-start-6 col-end-13'}>
        <p className={'text-[12px] mb-4 text-gray-900'}>
          {' '}
          {t('Search_by_polygons')}{' '}
        </p>
        <AddressMap
          isLoaded={isLoaded}
          paths={paths}
          onAddPolygon={onAddPolygon}
          onLoadPolygon={onLoadPolygon}
          onRemovePolygon={onRemovePolygon}
          onEditPolygon={onEditPolygon}
          center={{ lat: 46.204841, lng: 6.134868 }}
        />
      </div>
    </div>
  );
};

export default AddressForm;
