import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  WidgetOptionValues,
  DetailsTableOption,
} from '../../../types/brochureEditor';
import WidgetTitle from './widgetTitle';

const TableWidget = ({
  title: defaultTitle = '',
  items = [],
  widgetOptions,
}: {
  title?: string;
  items?: DetailsTableOption[];
  widgetOptions?: WidgetOptionValues;
}) => {
  const layout = widgetOptions?.layout?.value;
  const gridTemplateColumns = layout === 'twocolumns' ? '1fr 1fr' : undefined;

  const variant = widgetOptions?.variant?.value || 'withseparators';
  const isBig = variant === 'biggertext';
  const withSeparator = widgetOptions?.withSeparator?.value;

  const nonEmptyItems = items.filter(
    (item) => item.value && item.value !== '-' && item.value !== 'null'
  );

  const { t } = useTranslation('common');

  return (
    <div
      className="flex flex-col gap-4 min-h-0 relative overflow-hidden bg-white"
      style={{ gridTemplateColumns, alignContent: 'start' }}
    >
      <WidgetTitle title={defaultTitle} widgetOptions={widgetOptions} />

      <div
        className={classNames('font-medium', {
          'text-sm leading-none': !isBig,
          'text-xl leading-[calc(28/24)]': isBig,
        })}
      >
        {nonEmptyItems.length === 0 && <div>{t('nothing_found')}</div>}

        {nonEmptyItems.length > 0 && (
          <div
            className={classNames(
              'flex flex-col min-h-0 relative overflow-hidden',
              { 'gap-2': isBig }
            )}
          >
            {nonEmptyItems.map((item) => (
              <div
                key={item.id}
                className={classNames('flex justify-between gap-4', {
                  'border-t border-gray-200 py-1': withSeparator,
                })}
              >
                <div
                  style={{ color: 'var(--brand-color)' }}
                  className="min-w-[25%]"
                >
                  {item.label}:
                </div>
                <div className="text-right">{item.value}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TableWidget;
