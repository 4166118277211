// import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { HistoryT } from '..';
import { ApiListing } from '../../../../../types/api';
import useTranslationForTableGridXConfig from '../../../../../hooks/useTranslationForTableGridX';
import {
  propertyHistoryListingConfig,
  skeletonPropertiesHistoryListingConfig,
} from '../../../../../components/material-ui/tablesConfig/propertyHistoryListingConfig';
import TableGridX from '../../../../../components/tableGridX';
import { HistoryTableGridXStyling } from './style';
import ExportIcon from '../../../../../features/icons/exportIcon';
import SkeletonTableGrid from '../../../../../components/skeletonTableGrid';
import { useTranslation } from 'react-i18next';

type Props = {
  itemsPerPage: number;
  data: ApiListing<HistoryT>;
  handlePage: Dispatch<SetStateAction<number>>;
  page: number;
  loadingItemsToDisplay: boolean;
};
const PropertiesHistoryListingTable = ({
  itemsPerPage,
  data,
  handlePage,
  page,
  loadingItemsToDisplay,
}: Props) => {
  const propertiesHistoryListingColumns = useTranslationForTableGridXConfig([
    propertyHistoryListingConfig,
    'usersPage',
  ]);

  const skeletonPropertiesHistoryListingColumns =
    useTranslationForTableGridXConfig([
      skeletonPropertiesHistoryListingConfig,
      'usersPage',
    ]);
  const { t } = useTranslation('propertiesPage');
  const rows = useMemo(() => {
    if (data) {
      return data['hydra:member'];
    }
    return null;
  }, [data]);

  return (
    <Box className="w-full min-h-[80%]">
      {loadingItemsToDisplay ? (
        <SkeletonTableGrid
          customTableStyle={HistoryTableGridXStyling}
          style={{
            width: '100%',
            maxHeight: '340px',
            minHeight: '340px',
            height: '100%',
          }}
          disableSelectionOnClick={true}
          disableColumnSelector={true}
          rows={Array.from({ length: itemsPerPage }).map((el, index) => {
            return {
              id: index,
              date: '',
              action: '',
              doneby: '',
            };
          })}
          columns={skeletonPropertiesHistoryListingColumns}
          rowCount={0}
        />
      ) : (
        <TableGridX
          sx={{
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
              width: 10,
              height: 10,
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
              backgroundColor: '#BBC0C4',
              borderRadius: 8,
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#888',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
            },
          }}
          customTableStyle={HistoryTableGridXStyling}
          style={{ width: '100%', minHeight: '340px', height: '100%' }}
          disableSelectionOnClick={true}
          disableColumnSelector={true}
          rows={rows || []}
          columns={propertiesHistoryListingColumns}
          autoPageSize
          pageSize={itemsPerPage}
          page={page - 1}
          paginationMode="server"
          rowCount={(data && data['hydra:totalItems']) || 0}
          onPageChange={(page: number) => {
            handlePage(page + 1);
          }}
          rowHeight={80}
        />
      )}
      <div className="flex flex-row-reverse mt-[-4%]">
        {' '}
        <Button
          variant="contained"
          disableElevation
          sx={{
            background: 'background.default',
          }}
          startIcon={<ExportIcon color="#FFF" />}
        >
          {t('Export')}
        </Button>
      </div>
    </Box>
  );
};
export default PropertiesHistoryListingTable;
