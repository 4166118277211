import { GridColumns } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Skeleton, Tooltip } from '@mui/material';
import useLocale from '../../../../hooks/useLocale';
import LeadDateAndStatus from '../../../../components/tableGridX/leads/dateAndStatus';
import { Link } from 'react-router-dom';
import PropertyFirstImage from '../../../../components/tableGridX/propertyFirstImage';
import PermissionIcon from '../../../../features/icons/permissionIcon';
import { EnsureShortLocale } from '../../../../components/common/ensureShortLocale';
import LeadSource from '../../../../components/tableGridX/leads/source';
import LeadActionsMenu from './leadActions';
import { ILeadItemExtended } from '../../../properties/stepper/steps/leads';
import { PromotionAvatar } from '../../../../components/material-ui/tablesConfig/promotionsListingConfig';

export const useColumns = ({
  isLoading,
}: {
  isLoading?: boolean;
}): GridColumns<ILeadItemExtended> => {
  const { t } = useTranslation('common');
  const { locale } = useLocale();

  const commonSettings = {
    sortable: false,
    disableColumnMenu: true,
  };

  const columns: GridColumns<ILeadItemExtended> = [
    {
      field: 'date',
      sortable: false,
      flex: 1,
      minWidth: 168,
      maxWidth: 200,
      disableColumnMenu: true,
      headerName: t('Date'),
      renderCell: ({ row }) => {
        return <LeadDateAndStatus lead={row} />;
      },
    },
    {
      field: 'reference',
      minWidth: 260,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      headerName: t('Property_reference'),
      renderCell: ({ row }) => {
        const source = row.property || row.promotion;
        const id = source ? source.id : '';

        return (
          <div className={'flex gap-2 max-w-full w-full'}>
            <Link
              to={`/properties/${id}`}
              id={`img_property_${id}`}
              className={'shrink-0 max-w-[94px] max-h-[88px] bg-gray-400 w-2/5'}
            >
              {row.property ? <PropertyFirstImage row={row.property} /> : null}
              {row.promotion ? (
                <PromotionAvatar
                  id={row.promotion.id}
                  coverImage={row.promotion.coverImage}
                />
              ) : null}
            </Link>
            <div className={'w-3/5 pt-1 flex flex-col gap-2 items-start'}>
              {source &&
              source.translations &&
              source.translations[locale] &&
              source.translations?.[locale]?.title ? (
                <Link
                  className={'inline-flex max-w-full w-full'}
                  to={`/${row.promotion ? 'promotions' : 'properties'}/${id}`}
                  id={`img_property_${id}`}
                >
                  <Tooltip
                    title={source.translations[locale]?.title}
                    arrow
                    placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          width: '11vw',
                        },
                      },
                    }}
                  >
                    <p
                      className={
                        'max-w-full font-medium overflow-ellipsis overflow-hidden'
                      }
                    >
                      {source.translations[locale]?.title}
                    </p>
                  </Tooltip>
                </Link>
              ) : null}

              {source?.agencyReference ? (
                <Tooltip
                  title={`${t('propertiesPage:Reference_Agency')}: ${
                    row.property?.agencyReference
                  }`}
                  arrow
                  placement="top"
                >
                  <div
                    className="flex gap-1 py-1 px-1 rounded-md items-center min-w-[135px] max-w-[135px]"
                    style={{ border: '1px solid #00bfff' }}
                  >
                    <PermissionIcon
                      className="w-[25px] col-span-1"
                      color="#00bfff"
                    />
                    <span className="text-[#00bfff] block truncate inter leading-4 font-normal text-sm">
                      {source?.agencyReference}
                    </span>
                  </div>
                </Tooltip>
              ) : (
                <div
                  className="inline-flex py-1 px-2 rounded-md items-center"
                  style={{ border: '1px solid #3446B9 ' }}
                >
                  <PermissionIcon className="mr-2 col-span-1" color="#3446B9" />{' '}
                  <span className="text-blue leading-5">
                    {source ? source.reference : ''}
                  </span>
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: 'type',
      maxWidth: 128,
      minWidth: 155,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      headerName: t('Deal_type'),
      renderCell: ({ row }) => {
        const lang = `${EnsureShortLocale(locale)}`;
        return (
          <div className={'h-[60%] flex items-end pl-[6px]'}>
            {row.type ? (
              <div
                className="inline-flex py-1 px-2 rounded-md items-center"
                style={{ border: '1px solid #3446B9 ' }}
              >
                <span className="text-blue leading-5 first-letter:uppercase">
                  {row?.type?.translations?.[lang]?.name}
                </span>
              </div>
            ) : null}
          </div>
        );
      },
    },
    {
      field: 'source',
      headerName: t('Source'),
      maxWidth: 250,
      minWidth: 210,
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <LeadSource lead={row} />,
    },
    {
      field: 'agency',
      headerName: t('Agency'),
      width: 165,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <div className={'h-full flex items-center'}>
          <div className={'!w-full flex flex-col gap-2'}>
            {row.property && row.property.department ? (
              <div
                className={
                  'w-[40px] h-[40px] rounded-full bg-gray-100 overflow-hidden flex items-center justify-center'
                }
              >
                {row.property.department.logo ? (
                  <img
                    className="!object-contain"
                    src={row.property.department.logo.x96 || ''}
                    alt={row.property.department.name}
                  />
                ) : null}
              </div>
            ) : null}
            <Tooltip
              title={
                row.property && row.property.department
                  ? row.property.department.name
                  : ''
              }
              arrow
              placement="top"
              componentsProps={{
                tooltip: {
                  sx: {
                    width: '9vw',
                  },
                },
              }}
            >
              <span
                className={
                  'text-sm font-medium leading-none overflow-hidden !text-ellipsis !whitespace-nowrap w-[8vw]'
                }
              >
                {row.property && row.property.department
                  ? row.property.department.name
                  : ''}
              </span>
            </Tooltip>
          </div>
        </div>
      ),
    },
    {
      field: 'assignedto',
      headerName: t('Assigned_to'),
      width: 145,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <div className={'h-full flex items-center'}>
          <div className={'flex flex-col gap-2'}>
            {row.property && row.property.mainBroker ? (
              <div
                className={
                  'w-[40px] h-[40px] rounded-full bg-gray-100 overflow-hidden flex items-center justify-center'
                }
              >
                {row.property.mainBroker.avatar ? (
                  <img
                    src={row.property.mainBroker.avatar}
                    alt={`${row.property.mainBroker.firstname} ${row.property.mainBroker.lastname}`}
                  />
                ) : null}
              </div>
            ) : null}
            <Tooltip
              title={
                row.property && row.property.mainBroker
                  ? `${row.property.mainBroker.firstname} ${row.property.mainBroker.lastname}`
                  : ''
              }
              arrow
              placement="top"
              componentsProps={{
                tooltip: {
                  sx: {
                    width: '7vw',
                  },
                },
              }}
            >
              <span
                className={
                  '!text-sm 2xl:text-[16px] font-medium leading-none overflow-hidden !text-ellipsis !whitespace-nowrap !w-[7vw]'
                }
              >
                {row.property && row.property.mainBroker
                  ? `${row.property.mainBroker.firstname} ${row.property.mainBroker.lastname}`
                  : ''}
              </span>
            </Tooltip>
          </div>
        </div>
      ),
    },
    {
      field: 'message',
      headerName: t('Message'),
      minWidth: 160,
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <div className={'h-full flex items-start pt-3 px-[4px] !w-full'}>
          <Tooltip
            title={row.message}
            arrow
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  width: '10vw',
                },
              },
            }}
          >
            <span
              style={{
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '4',
                whiteSpace: 'pre-line',
              }}
              className="text-sm font-medium leading-snug"
            >
              {row.message}
            </span>
          </Tooltip>
        </div>
      ),
    },
    {
      field: 'actions',
      headerName: t('actions'),
      width: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <LeadActionsMenu row={row} />,
    },
  ];

  return columns.map((column) => ({
    ...commonSettings,
    ...column,
    ...(isLoading
      ? {
          renderCell: () => (
            <Skeleton
              variant="text"
              animation="wave"
              width="100%"
              height={20}
            />
          ),
        }
      : {}),
  }));
};
