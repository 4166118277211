import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Button, IconButton, InputAdornment } from '@mui/material';

import { IProperty } from '../../../../../types/property';
import StepsFieldset from '../../../../../components/stepper/stepsFieldset';
import InputFormField from '../../../../../components/form/inputFormField';
import { numberInputProps } from './const';
import { Calculate } from '@mui/icons-material';

function Surfaces({ defaultValues }: { defaultValues?: Partial<IProperty> }) {
  const { register, setValue, getValues } = useFormContext<IProperty>();
  const { t } = useTranslation('propertiesPage');

  const [tailed, setTailed] = useState<string>('');
  useEffect(() => {
    if (defaultValues && defaultValues?.useTildeForSurface == true) {
      setTailed('oui');
    } else if (defaultValues && defaultValues?.useTildeForSurface == false) {
      setTailed('non');
    }
  }, []);

  const calculateLivingArea = () => {
    const dimensions = getValues('dimensions');
    const livingArea = dimensions
      ?.filter((dimension) => dimension.isHabitable)
      .reduce((acc, cur) => {
        return acc + Math.round(10 * cur.quantity * (cur.habitable || 0)) * 0.1;
      }, 0);
    setValue('livingArea', livingArea, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const calculateWeightedSurface = () => {
    const dimensions = getValues('dimensions');
    const weightedSurface = dimensions.reduce((acc, cur) => {
      return (
        acc +
        Math.round(
          100 * cur.quantity * (cur.habitable || 0) * (cur.weighting || 0)
        ) *
          0.01
      );
    }, 0);
    setValue('surfacePonderee', weightedSurface, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const calculateUsefulArea = () => {
    const dimensions = getValues('dimensions');
    const usefulArea = dimensions?.reduce((acc, cur) => {
      return acc + Math.round(10 * cur.quantity * (cur.usable || 0)) * 0.1;
    }, 0);
    setValue('usefulArea', usefulArea, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  return (
    <StepsFieldset
      className="grid grid-cols-2 !gap-6 xl:!gap-12 xl:grid-cols-3 w-full"
      title={t('Surfaces')}
    >
      <InputFormField
        type="number"
        label={t('Living_Area')}
        surfaceSpan={true}
        btnFrom={'addFromToLivingArea'}
        btnFromValue={defaultValues?.addFromToLivingArea}
        setValue={setValue}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              <div className="flex items-center gap-2">
                m²
                <IconButton
                  aria-label="calculate"
                  onClick={calculateLivingArea}
                >
                  <Calculate />
                </IconButton>
              </div>
            </InputAdornment>
          ),
        }}
        {...register('livingArea', {
          valueAsNumber: true,
        })}
      />

      <InputFormField
        type="number"
        label={t('Weighted_surface')}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              <div className="flex items-center gap-2">
                m²
                <IconButton
                  aria-label="calculate"
                  onClick={calculateWeightedSurface}
                >
                  <Calculate />
                </IconButton>
              </div>
            </InputAdornment>
          ),
        }}
        {...register('surfacePonderee', {
          valueAsNumber: true,
        })}
      />

      <InputFormField
        type="number"
        label={t('useful_area')}
        btnFrom={'addFromToUsefulArea'}
        btnFromValue={defaultValues?.addFromToUsefulArea}
        setValue={setValue}
        surfaceSpan={true}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              <div className="flex items-center gap-2">
                m²
                <IconButton
                  aria-label="calculate"
                  onClick={calculateUsefulArea}
                >
                  <Calculate />
                </IconButton>
              </div>
            </InputAdornment>
          ),
        }}
        {...register('usefulArea', {
          valueAsNumber: true,
        })}
      />

      <div className="h-px bg-gray-200 col-span-2 xl:col-span-3"></div>

      <div>
        <p className="mt-[-1px] mb-2 !text-xs capitalize font-normal Inter text-[#8e8f8e]">
          {t('Add_in_front_of_the_dimensions')}
        </p>
        <div
          className="w-full mr-5 grid grid-cols-2 border border-[#bdbdbf] h-[66%]"
          style={{
            borderRadius: '5px',
          }}
        >
          <div
            style={{
              borderRadius: '5px',
            }}
            className={`${
              tailed == 'non' ? 'bg-blue-600' : '!bg-[#e1e4e5]'
            } flex justify-center`}
          >
            <Button
              disableRipple
              onClick={() => {
                setValue('useTildeForSurface', false);
                setTailed('non');
              }}
              className={`${
                tailed == 'non' ? '!text-white' : '!text-black'
              } !font-semibold w-full`}
            >
              {t('common:no')}
            </Button>
          </div>
          <div
            className={`${
              tailed == 'oui' ? 'bg-blue-600 !text-white' : ' text-black '
            } flex justify-center `}
          >
            <Button
              disableRipple
              onClick={() => {
                setValue('useTildeForSurface', true);
                setTailed('oui');
              }}
              className={`${
                tailed == 'oui' ? '!text-white' : '!text-black'
              } !font-semibold w-full`}
            >
              {t('common:yes')}
            </Button>
          </div>
        </div>
      </div>

      <InputFormField
        type="number"
        surfaceSpan={true}
        btnFrom={'addFromToSurfaceHabitableTotale'}
        btnFromValue={defaultValues?.addFromToSurfaceHabitableTotale}
        setValue={setValue}
        label={t('Total_living_surface')}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              m²
            </InputAdornment>
          ),
        }}
        {...register('surfaceHabitableTotale', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        label={t('Balcony_surface')}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              m²
            </InputAdornment>
          ),
        }}
        {...register('surfaceBalcon', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        label={t('terrace_surface')}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              m²
            </InputAdornment>
          ),
        }}
        {...register('terraceSurface', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        label={t('External_surface')}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              m²
            </InputAdornment>
          ),
        }}
        {...register('surfaceExterne', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        label={t('Veranda_winter_garden_surface')}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              m²
            </InputAdornment>
          ),
        }}
        {...register('surfaceVerandaJardinHiver', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        label={t('Loggia_surface')}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              m²
            </InputAdornment>
          ),
        }}
        {...register('surfaceLoggia', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        label={t('garden_surface')}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              m²
            </InputAdornment>
          ),
        }}
        {...register('gardenSurface', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        label={t('land_surf')}
        btnFrom={'addFromToLandSurface'}
        btnFromValue={defaultValues?.addFromToLandSurface}
        setValue={setValue}
        surfaceSpan={true}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              m²
            </InputAdornment>
          ),
        }}
        {...register('landSurface', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        label={t('Net_surface')}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              m²
            </InputAdornment>
          ),
        }}
        {...register('surfaceNette', {
          valueAsNumber: true,
        })}
      />

      <InputFormField
        type="number"
        label={t('Secondary_usable_surface')}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              m²
            </InputAdornment>
          ),
        }}
        {...register('surfaceUtileSecondaire', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        label={t('Total_surface')}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              m²
            </InputAdornment>
          ),
        }}
        {...register('surfaceTotale', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        label={t('PPE_surface')}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              m²
            </InputAdornment>
          ),
        }}
        {...register('surfacePPE', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        label={t('Ceiling_height')}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              m
            </InputAdornment>
          ),
        }}
        {...register('hauteurDuPlafond', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        label={t('Volume')}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              m³
            </InputAdornment>
          ),
        }}
        {...register('volume', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        label={t('Basement_surface')}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              m²
            </InputAdornment>
          ),
        }}
        {...register('surfaceSousSol', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        label={t('Gross_floor_surface')}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              m²
            </InputAdornment>
          ),
        }}
        {...register('surfaceBruteDePlancher', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        label={t('Cellar_surface')}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              m²
            </InputAdornment>
          ),
        }}
        {...register('surfaceCave', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        label={t('Net_floor_surface')}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              m²
            </InputAdornment>
          ),
        }}
        {...register('surfaceNetteDePlancher', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        label={t('Rental_surface')}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              m²
            </InputAdornment>
          ),
        }}
        {...register('surfaceLocative', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        label={t('Attic_surface')}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              m²
            </InputAdornment>
          ),
        }}
        {...register('surfaceGaletas', {
          valueAsNumber: true,
        })}
      />

      <InputFormField
        type="number"
        label={t('common:Surface_emprise')}
        btnFrom={'addFromToSurfaceEmprise'}
        btnFromValue={defaultValues?.addFromToSurfaceEmprise}
        setValue={setValue}
        surfaceSpan={true}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              m²
            </InputAdornment>
          ),
        }}
        {...register('surfaceEmprise', {
          valueAsNumber: true,
        })}
      />
      <InputFormField
        type="number"
        label={t('common:Abri_toiture')}
        btnFrom={'addFromToAbriToiture'}
        btnFromValue={defaultValues?.addFromToAbriToiture}
        setValue={setValue}
        surfaceSpan={true}
        inputProps={numberInputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment className="lowercase" position="start">
              m²
            </InputAdornment>
          ),
        }}
        {...register('abriToiture', {
          valueAsNumber: true,
        })}
      />
    </StepsFieldset>
  );
}

export default Surfaces;
