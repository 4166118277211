import { IconButton, Menu, Tooltip } from '@mui/material';
import classNames from 'classnames';
import { ChangeEventHandler, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import RequestQuoteOutlined from '@mui/icons-material/RequestQuoteOutlined';
import AttachEmailOutlined from '@mui/icons-material/AttachEmailOutlined';

import CloseIcon from '../../../../../features/icons/closeIcon';
import UserDragIcon from '../../../../../features/icons/userDragIcon';
import { useAnchorElement } from '../../../../../hooks/useAnchorElement';
import { PipelineStepsStateItem } from './useStepsState';
import { COLORS } from './colors';

function StepItem({
  isHighlighted = false,
  step,
  canDelete = false,
  handleDelete,
  handleNameChange,
  handleColorChange,
  isOfferStep = false,
  handleOfferStepChange,
  isSentFiles = false,
  handleSentFilesChange,
  allowOfferStep = false,
}: {
  isHighlighted?: boolean;
  step: PipelineStepsStateItem;
  canDelete?: boolean;
  handleDelete?: MouseEventHandler;
  handleNameChange?: ChangeEventHandler<HTMLInputElement>;
  handleColorChange?: (newColor: string) => unknown;
  isOfferStep?: boolean;
  handleOfferStepChange?: (isOfferStep: boolean) => unknown;
  isSentFiles?: boolean;
  handleSentFilesChange?: (isSentFiles: boolean) => unknown;
  allowOfferStep?: boolean;
}) {
  const anchor = useAnchorElement();
  const { t } = useTranslation('common');

  return (
    <div
      className={classNames(
        'group w-full h-[51px] p-4 rounded border bg-gray-50 border-gray-200 justify-start items-center gap-2 inline-flex cursor-pointer hover:border-blue-800 focus-within:bg-white',
        {
          'border-blue-800': isHighlighted,
        }
      )}
    >
      <div className="w-4 h-4 relative">
        <UserDragIcon />
      </div>

      <div className="grow shrink basis-0 flex-col justify-center items-start gap-0.5 inline-flex">
        <div className="self-stretch text-base leading-tight">
          <input
            value={step.name || ''}
            onChange={handleNameChange}
            className="bg-transparent -m-1 p-1 min-w-0 w-full"
          />
        </div>
      </div>

      {allowOfferStep && (
        <div>
          <Tooltip
            title={
              isOfferStep ? t('marked_as_offer_step') : t('mark_as_offer_step')
            }
          >
            <IconButton
              className={classNames({
                '!text-blue': isOfferStep,
                'opacity-50 pointer-events-none': isSentFiles,
              })}
              disabled={isSentFiles}
              onClick={() => handleOfferStepChange?.(!isOfferStep)}
            >
              <RequestQuoteOutlined color="inherit" fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      )}

      <div>
        <Tooltip
          title={
            isSentFiles ? t('marked_as_sent_files') : t('mark_as_sent_files')
          }
        >
          <IconButton
            className={classNames({
              '!text-blue': isSentFiles,
              'opacity-50 pointer-events-none': isOfferStep,
            })}
            disabled={isOfferStep}
            onClick={() => handleSentFilesChange?.(!isSentFiles)}
          >
            <AttachEmailOutlined color="inherit" fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>

      <div>
        <button
          className="w-7 h-7 grid place-items-center rounded-full hover:bg-gray-200"
          type="button"
          onClick={anchor.handleClick}
        >
          {step.color && (
            <div
              className="w-3 h-3 rounded-full border border-gray-200"
              style={{ backgroundColor: step.color }}
            ></div>
          )}
        </button>

        <Menu
          anchorEl={anchor.anchorEl}
          open={anchor.open}
          onClose={anchor.handleClose}
          classes={menuClasses}
          elevation={1}
        >
          {COLORS.map((color) => (
            <li key={color}>
              <button
                type="button"
                className="w-4 h-4 rounded-full"
                style={{ backgroundColor: color }}
                onClick={() => {
                  handleColorChange?.(color);
                  anchor.handleClose();
                }}
              ></button>
            </li>
          ))}
        </Menu>
      </div>

      {canDelete && (
        <div className="-mx-2">
          <IconButton onClick={handleDelete}>
            <CloseIcon color="#F50057" />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default StepItem;

const menuClasses = { list: '!p-2 grid gap-3 grid-cols-4' };
