import React from 'react';
import { INetworkAgency } from '../../../../types/networks';
import MockCard from '../elements/mockCard';
import { Skeleton, TablePagination } from '@mui/material';
import NetworkAgencyCard from '../elements/agencyCard';
import { useTranslation } from 'react-i18next';

interface Props {
  isGrid: boolean;
  letterFilter: string | null;
  agencies: INetworkAgency[];
  isLoading?: boolean;
  page: number;
  handleChangePage: any;
  rowsPerPage: number;
  handleChangeRowsPerPage: any;
  rowCount: number;
}

interface GroupedNetworkAgencies {
  [letter: string]: INetworkAgency[];
}

const mockUpItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const AddAgenciesList = (props: Props) => {
  const {
    isGrid,
    isLoading,
    agencies,
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    rowCount,
    handleChangePage,
    letterFilter,
  } = props;
  const groupedAgencies: GroupedNetworkAgencies = agencies.reduce(
    (groups, agency) => {
      const firstLetter = agency.name.charAt(0).toUpperCase();
      if (!groups[firstLetter]) {
        groups[firstLetter] = [];
      }
      if (groups[firstLetter]) {
        // @ts-ignore
        groups[firstLetter].push(agency);
      }
      return groups;
    },
    {} as GroupedNetworkAgencies
  );

  const sortedGroups = Object.keys(groupedAgencies).sort();
  const { t } = useTranslation('networks');

  return (
    <div className={'pt-8'}>
      <div>
        {isLoading ? (
          <>
            <Skeleton
              variant="rounded"
              height={65}
              width={65}
              className={'mb-8'}
            />
            <div
              className={
                'grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-8'
              }
            >
              {mockUpItems.map((el) => (
                <MockCard key={el} isTable={!isGrid} isAgency />
              ))}
            </div>
          </>
        ) : (
          <div className={'flex flex-col gap-10'}>
            {agencies && agencies.length ? (
              <>
                {sortedGroups.map((letter) => (
                  <div className={'flex flex-col gap-10'} key={letter}>
                    <div className={'text-[48px] leading-none pl-2'}>
                      {letter}
                    </div>
                    <div
                      className={
                        'grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4'
                      }
                    >
                      {groupedAgencies?.[letter]?.map((agency) => (
                        <NetworkAgencyCard
                          key={agency.id}
                          item={agency}
                          isGrid={isGrid}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className={'flex flex-col gap-10'}>
                  {letterFilter ? (
                    <div className={'text-[48px] leading-none pl-2'}>
                      {letterFilter}
                    </div>
                  ) : null}
                  <div className={'h-[200px] flex text-[24px]'}>
                    <p>{t('There_is_no_agencies_for_this_filter')} </p>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <div className={'w-full flex items-end justify-end pt-8'}>
        {isLoading ? (
          <Skeleton variant="rectangular" height={40} width={200} />
        ) : agencies && agencies.length ? (
          <TablePagination
            component="div"
            sx={{
              '&::-webkit-scrollbar': {
                height: '10px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#BBC0C4',
                borderRadius: 8,
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#888',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1',
              },
            }}
            count={rowCount}
            labelRowsPerPage={`${t('RowsPerPage')}:`}
            labelDisplayedRows={function defaultLabelDisplayedRows({
              from,
              to,
              count,
            }) {
              return `${from}–${to} ${t('of')} ${
                count !== -1 ? count : `${t('moreThan')} ${to}`
              }`;
            }}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AddAgenciesList;
