import {
  Choice,
  ChoiceGetter,
  WidgetOption,
} from '../../../types/brochureEditor';

import BooleanControl from '../components/controls/boolean';
import ColorControl from '../components/controls/color';
import TextAlignControl from '../components/controls/textAlign';
import ChoiceControl from '../components/controls/choice';
import Separator from '../components/controls/separator';
import {
  HorizontalAlignmentControl,
  VerticalAlignmentControl,
} from '../components/controls/alignment';
import SelectImage from '../components/controls/image';
import TextControl from '../components/controls/text';
import RichTextControl from '../components/controls/richText';

export function booleanControl(
  name: string,
  defaultValue = true
): WidgetOption<boolean> {
  return {
    name,
    control: BooleanControl,
    defaultValue,
  };
}

export function choiceControl(
  name: string,
  choices: Choice[] | ChoiceGetter,
  defaultValue?: string
): WidgetOption<string> {
  return {
    name,
    control: ChoiceControl,
    choices: typeof choices === 'function' ? undefined : choices,
    getChoices: typeof choices === 'function' ? choices : undefined,
    defaultValue:
      defaultValue ||
      (typeof choices !== 'function' && choices[0]?.value) ||
      '',
  };
}

export const textAlign: WidgetOption<string> = {
  name: 'alignment',
  control: TextAlignControl,
  defaultValue: 'left',
};

export const color: WidgetOption<string> = {
  name: 'text_color',
  control: ColorControl,
  defaultValue: '#1D1D1F',
};

export const separator: WidgetOption<string> = {
  name: 'separator',
  control: Separator,
  defaultValue: '',
};

export const verticalAlign: WidgetOption<string> = {
  name: 'vertical_alignment',
  control: VerticalAlignmentControl,
  defaultValue: 'start',
};

export const horizontalAlign: WidgetOption<string> = {
  name: 'horizontal_alignment',
  control: HorizontalAlignmentControl,
  defaultValue: 'start',
};

export const imageSelector: WidgetOption<string> = {
  name: 'choose_image',
  control: SelectImage,
  defaultValue: '',
  onlyForPreview: true,
};

export const textEditor: WidgetOption<string> = {
  name: 'text',
  control: TextControl,
  defaultValue: '',
};

export const richTextEditor: WidgetOption<string> = {
  name: 'content',
  control: RichTextControl,
  defaultValue: '',
};

export const objectFit = choiceControl('fit_mode', [
  { label: 'crop', value: 'cover' },
  { label: 'fit_within_block', value: 'contain' },
]);
