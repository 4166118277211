import { Link } from 'react-router-dom';
import { Skeleton } from '@mui/lab';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Box from '@mui/material/Box';
import PermissionIcon from '../../../../features/icons/permissionIcon';
import PropertyFirstImage from '../../../tableGridX/propertyFirstImage';
import { IMatching } from '../../../../types/matching';
import { useTranslation } from 'react-i18next';
import { formatRemainingTime } from '../../../../pages/properties/propertiesListing/propertiesMatching/matchingStatus';
import classNames from 'classnames';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { PropertyReplacementName } from '../../../cardsTablesList/cards/item';
import useLocale from '../../../../hooks/useLocale';

type PropertyInfoCardProps = {
  property: IMatching['property'] | null;
  spentTime: number | null;
  selected?: boolean;
  onClick?: () => void;
  contactsMatched?: number;
  isSmall?: boolean;
};

const PropertyInfoCard = ({
  property,
  spentTime,
  selected = true,
  onClick,
  contactsMatched,
  isSmall,
}: PropertyInfoCardProps) => {
  const { t } = useTranslation('propertiesPage');
  const { locale } = useLocale();

  return (
    <div
      onClick={onClick}
      className={classNames(
        'text-sm w-full h-full bg-gray-50 rounded relative border justify-start items-start gap-1 inline-flex',
        {
          'border-blue-800': selected,
          'border-gray-300': !selected,
          'cursor-pointer': onClick,
          'opacity-60 pointer-events-none': contactsMatched === 0,
        }
      )}
    >
      <div className="max-w-[145px] p-2 justify-between items-start gap-2.5 flex flex-col shrink-0">
        {property ? (
          <>
            {'mainImage' in property ? (
              <Box className="flex items-center relative">
                <img
                  style={{
                    width: '95px',
                    height: '58px',
                    objectFit: 'cover',
                  }}
                  src={
                    property.mainImage
                      ? property.mainImage.thumbnail
                      : '/img/default_image.jpg'
                  }
                  alt=""
                />
              </Box>
            ) : (
              <PropertyFirstImage row={property} />
            )}
          </>
        ) : (
          <Skeleton variant="rectangular" height={68} width={95} />
        )}
        {contactsMatched !== undefined ? (
          <div
            className={
              'text-sm flex items-center justify-center gap-0.5 bg-white rounded py-1 px-2'
            }
          >
            <PersonOutlineOutlinedIcon
              className="opacity-70"
              fontSize="small"
            />
            <span className="text-xs font-medium leading-none opacity-70">
              {contactsMatched}
            </span>
          </div>
        ) : null}
      </div>
      <div className="flex flex-col w-full pl-2 pr-4 py-4 gap-2 overflow-hidden">
        {property ? (
          <>
            {isSmall ? (
              <p
                title={property.translations?.['en']?.title || ''}
                className={classNames('font-medium text-base max-w-[170px]', {
                  'whitespace-nowrap overflow-ellipsis overflow-hidden':
                    isSmall,
                  'whitespace-normal': !isSmall,
                })}
              >
                {property.translations?.[locale]?.title || (
                    <PropertyReplacementName
                      propertyItem={property}
                      locale={locale}
                    />
                  ) ||
                  ' '}
              </p>
            ) : (
              <Link
                title={property.translations?.['en']?.title || ''}
                className="text-base inline-flex overflow-ellipsis overflow-hidden"
                to={`/properties/${property.id}`}
              >
                <p
                  className={classNames('font-medium', {
                    'whitespace-nowrap overflow-ellipsis overflow-hidden':
                      isSmall,
                    'whitespace-normal': !isSmall,
                  })}
                >
                  {property.translations?.[locale]?.title || (
                      <PropertyReplacementName
                        propertyItem={property}
                        locale={locale}
                      />
                    ) ||
                    ' '}
                </p>
              </Link>
            )}
          </>
        ) : (
          <Skeleton />
        )}
        <Box className="w-3/5 flex items-center gap-2">
          {property ? (
            <div
              className="flex py-1 px-2 rounded-md items-center"
              style={{ border: '1px solid #3446B9 ' }}
            >
              <PermissionIcon className="mr-2 col-span-1" color="#3446B9" />
              <span className="text-blue leading-5 whitespace-nowrap">
                {property.agencyReference || property.reference}
              </span>
            </div>
          ) : (
            <Skeleton variant="rounded" height={30} />
          )}
        </Box>
        {spentTime ? (
          <div className="self-stretch justify-start items-center gap-1 inline-flex">
            <AccessTimeIcon
              sx={{
                width: '16px',
                height: '16px',
              }}
              fontSize="small"
            />
            <div className="text-[12px] font-medium leading-none flex flex-wrap gap-1">
              <span>{formatRemainingTime(spentTime, t)}</span>
            </div>
          </div>
        ) : (
          <Skeleton sx={{ maxWidth: '90px' }} />
        )}
      </div>
    </div>
  );
};

export default PropertyInfoCard;
