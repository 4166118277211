import { FormProvider } from 'react-hook-form';

import { useServerTemplate } from './lib/hooks/useServerTemplate';
import { useEditorInitializer } from './lib/hooks/useEditorInitializer';
import { useOptionsForm } from './lib/hooks/useOptionsForm';
import { useEnableBodyScroll } from '../../hooks/useEnableBodyScroll';
import { usePagesEditorComputables } from './lib/hooks/usePagesEditor';
import Page from './components/editorLayout/page';
import { usePreviewSubject } from './lib/hooks/usePreviewSubject';
import { useDefaultImages } from './lib/hooks/useDefaultImages';

const PrintableBrochureView = () => {
  const { template } = useServerTemplate();

  useEditorInitializer();

  const methods = useOptionsForm({ options: template?.options });

  const { pages } = usePagesEditorComputables();

  useEnableBodyScroll();

  const { previewSubject } = usePreviewSubject();
  useDefaultImages(previewSubject);

  return (
    <FormProvider {...methods}>
      {pages.map((page, index) => (
        <Page key={page.id} page={page} isFirst={index === 0} />
      ))}
    </FormProvider>
  );
};

export default PrintableBrochureView;
