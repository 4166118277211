import React, { useState } from 'react';
import { AccordionView } from '../creator/individual-view';
import { IContactIndividual } from '../../../types/contacts';
import useLocale from '../../../hooks/useLocale';
import RepeatableIndividualForm from './forms/repeateble-individual-form';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const CompanyContacts = (props: {
  contacts: IContactIndividual[];
  companyInformation?: number;
  id?: string;
  mutate?: any;
  agentInCharge?: number;
  agentInChargeId?: number;
}) => {
  const { contacts, companyInformation, mutate, agentInChargeId } = props;
  const { locale } = useLocale();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [createMode, toggleCreateMode] = useState(!contacts.length);
  const { t } = useTranslation('common');
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div>
      <div className={''}>
        {contacts.map((item) => {
          const title = `${
            item.translations[locale]?.title
              ? `${item.translations[locale]?.title}.`
              : ''
          } ${item.firstname} ${item.lastname}`;

          return (
            <AccordionView
              expanded={expanded === `${item.firstname}-${item.lastname}`}
              onChange={handleChange(`${item.firstname}-${item.lastname}`)}
              key={item.firstname}
              className={'w-full col-start-1 col-end-4'}
              titleClassName={'text-[16px]'}
              title={title}
            >
              <RepeatableIndividualForm
                mutate={mutate}
                isEditMode={true}
                inner
                data={item}
                id={item.contactId}
                agentInChargeId={agentInChargeId}
              />
            </AccordionView>
          );
        })}
      </div>
      {createMode ? (
        <div
          className={classNames('', {
            'pt-16': contacts.length,
          })}
        >
          <p className={'text-[24px] mb-2'}>
            {t('Add_new_individual_contact')}
          </p>
          <RepeatableIndividualForm
            isEditMode={false}
            inner
            mutate={mutate}
            companyInformation={companyInformation}
            agentInChargeId={agentInChargeId}
            additionalButton={
              <Button
                onClick={() => toggleCreateMode(false)}
                variant="outlined"
                className="flex justify-center items-center w-[140px] "
                sx={{
                  height: '42px',
                  color: '#F50057',
                }}
              >
                <Box className="flex justify-center items-center" gap="16px">
                  <DeleteOutlineIcon fontSize="small" />
                  <div className="font-medium">{t('cancel')}</div>
                </Box>
              </Button>
            }
          />
        </div>
      ) : (
        <div className={'py-16 mb-8 border-b'}>
          <button
            onClick={() => toggleCreateMode(true)}
            className={
              'hover:opacity-80 transition-opacity duration-300 rounded flex items-center justify-center gap-2 bg-gray-100 w-full p-3 text-[14px] text-gray-900'
            }
          >
            <AddIcon />
            <span>{t('Add_contact')}</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default CompanyContacts;
