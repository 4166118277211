import {
  Box,
  Button,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import TitleHeader from '../../../components/common/titleHeader';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddIcon from '@mui/icons-material/Add';
import { http } from '../../../libs/axios';
import { useEffect, useState } from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import EditIcon from '../../../features/icons/editIcon';
import RemoveIcon from '../../../features/icons/removeIcon';
import { useNotification } from '../../../hooks/useNotification';
import { confirmDelete } from '../../properties/utils';

export interface AddressElement {
  id: number;
  name: string;
  sectorAddresses: {
    city: string;
    zipCode: string;
  }[];
}
const Addresses = () => {
  const { t } = useTranslation('common');
  const isHigherThen768 = useMediaQuery('(min-width:768px)');
  const isHigherThen900 = useMediaQuery('(min-width:900px)');
  const { addNotification } = useNotification();
  const [rows, setRows] = useState<AddressElement[]>([]);
  const [loading, setLoading] = useState(false);

  const removeBlock = async (id: number) => {
    try {
      http.delete(`/sectors/${id}`);

      addNotification(t('address_deleted_successfully'));
      setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    } catch (err: any) {
      addNotification(err.response.data.message, 'error');
    }
  };

  const fetchData = async () => {
    try {
      const res = await http.get('/sectors');
      setRows(res.data);
      setLoading(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [loading]);
  const columns: GridColDef<AddressElement>[] = [
    {
      field: 'name',
      headerClassName: '!pl-4',
      cellClassName: '!pl-6 !pt-2',
      width: 300,
      sortable: false,
      renderHeader: () => (
        <Box className="leading-5 flex gap-2 Inter font-medium text-sm pl-2 py-5 text-[#030814]">
          <span>{t('Name')}</span>
        </Box>
      ),
      renderCell: ({ row }) => {
        return row.name ? row.name : null;
      },
    },
    {
      field: 'zipCode',
      headerClassName: '!pl-4',
      cellClassName: '!pl-5 !pt-1',
      width: 400,
      sortable: false,
      renderHeader: () => (
        <Box className="leading-5 flex gap-2 Inter font-medium text-sm pl-2 py-5 text-[#030814]">
          <span>{t('zipCodes')}</span>
        </Box>
      ),
      renderCell: ({ row }) => {
        const zipCodes = row.sectorAddresses
          .map((elem) => elem.zipCode)
          .filter((zip) => zip?.trim());

        return (
          <Tooltip title={zipCodes.join(' , ')} arrow placement="top">
            <div className="pl-1 truncate">{zipCodes.join(' , ')}</div>
          </Tooltip>
        );
      },
    },

    {
      field: 'edit',
      headerClassName: '!pl-4 !hidden md:!block',
      cellClassName: '!pl-8 !pt-2 !hidden md:!block',
      width: 150,
      sortable: false,
      renderHeader: () => (
        <Box className="leading-5 flex gap-2 Inter font-medium text-sm pl-2 py-5 text-[#030814]">
          <span>{t('Edit')}</span>
        </Box>
      ),
      renderCell: ({ row }) => (
        <Tooltip title={t('edit_address')} arrow placement="top">
          <Link to={'/settings/edit-address/' + row?.id}>
            <EditIcon width="19" height="19" color="#4CAF50" />
          </Link>
        </Tooltip>
      ),
    },

    {
      field: 'delete',
      headerClassName: ' hideRightSeparator !pl-5',
      cellClassName: '!pl-8 !pt-2',
      width: 150,
      sortable: false,
      renderHeader: () => (
        <Box className="leading-5 flex gap-2 Inter font-medium text-sm py-5 text-[#030814]">
          <span>{t('Delete')}</span>
        </Box>
      ),
      renderCell: ({ row }) => (
        <Tooltip title={t('delete_address')} arrow placement="top">
          <div>
            <IconButton
              type="button"
              onClick={() => confirmDelete(() => removeBlock(row.id))}
              aria-label="Remove"
            >
              <RemoveIcon className="text-attention" />
            </IconButton>
          </div>
        </Tooltip>
      ),
    },
  ];
  const skeletonRows = Array.from(new Array(3)).map((_, index) => (
    <div
      key={index}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 0',
      }}
    >
      <Skeleton
        sx={{ mx: 1, my: 1 }}
        animation="wave"
        variant="text"
        width={200}
      />
      <Skeleton
        sx={{ mx: 1, my: 1 }}
        animation="wave"
        variant="text"
        width={90}
        height={20}
      />{' '}
      <Skeleton
        sx={{ mx: 1, my: 1 }}
        animation="wave"
        variant="text"
        width={100}
        height={20}
      />
    </div>
  ));

  return (
    <>
      <TitleHeader
        titleElement={
          <div>
            <Typography className="!text-3xl">{t('addresses')}</Typography>
          </div>
        }
        wrapperClassName={`${
          isHigherThen900 ? 'w-[92%]' : 'w-full'
        } min-[1180px]:w-full`}
        goBackLink={'/settings'}
        rightElement={
          isHigherThen768 && (
            <Link to={'/settings/create-address'}>
              <Button
                variant="contained"
                disableElevation
                sx={{ background: 'background.default' }}
                startIcon={<AddIcon fontSize="medium" />}
              >
                {t('Add_address')}
              </Button>
            </Link>
          )
        }
      >
        <div>
          {loading ? (
            <div>
              <DataGridPro
                sx={{
                  '& .MuiDataGrid-virtualScroller': {
                    overflowY: 'scroll',
                  },
                  '& .MuiDataGrid-columnSeparator': {
                    visibility: 'visible',
                  },
                  '& .hideRightSeparator > .MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-footerContainer': {
                    minHeight: '0px',
                  },
                  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                    height: 10,
                    width: 10,
                  },
                  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                    backgroundColor: '#BBC0C4',
                    borderRadius: 8,
                  },
                  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover':
                    {
                      backgroundColor: '#888',
                    },
                  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                    backgroundColor: '#f1f1f1',
                  },
                }}
                rows={rows.filter((row) => row.name?.trim())}
                columns={columns}
                disableColumnMenu
                disableRowSelectionOnClick={true}
                disableColumnSelector={true}
                hideFooterPagination
                hideFooterSelectedRowCount
                hideFooter
                autoHeight={false}
                style={{ height: '53vh' }}
              />
            </div>
          ) : (
            <div>{skeletonRows}</div>
          )}
        </div>
      </TitleHeader>
    </>
  );
};

export default Addresses;
