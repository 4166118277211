import PortalForm, { transformDataFromServer } from '../form';
import { AxiosResponse } from 'axios';
import { http } from '../../../../libs/axios';
import { useState } from 'react';
import { useNotification } from '../../../../hooks/useNotification';
import { useNavigate } from 'react-router-dom';
import { useProtectedForm } from '../../../../hooks/useProtectedForm';
import { useTranslation } from 'react-i18next';

const PortalCreator = () => {
  const { register, handleSubmit, control, setValue } = useProtectedForm({
    defaultValues: transformDataFromServer(undefined),
  });
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useNotification();
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const onSubmit = (data: any) => {
    const body = {
      portal: data.portal,
      ftpHost: data.ftpHost,
      ftpLogin: data.ftpLogin,
      ftpPassword: data.ftpPassword,
      ftpPort: parseInt(data.ftpPort),
      isSSL: !!data.isSSL,
      isActive: !!data.status,
      idxFile: data.idxFile,
      dataPath: data.dataPath,
      imagesPath: data.imagesPath,
      videosPath: data.videosPath,
      documentsPath: data.documentsPath,
      departments: data.departments,
      agencyId: data.agencyId,
      agencyEmail: data.agencyEmail,
      contact: data.contact,
      emailRent: data.emailRent,
      telRent: data.telRent,
      emailBuy: data.emailBuy,
      telBuy: data.telBuy,
      language: data.language,
      notifyBroker: data.notifyBroker,
      maxProperties: parseInt(data.maxProperties),
    };

    submitDataToServer(body);
  };
  const submitDataToServer = async (body: any) => {
    const promise: Promise<AxiosResponse> = http.post('/setting_portals', body);

    setIsLoading(true);
    promise
      .finally(() => {
        setIsLoading(false);
      })
      .then(() => {
        addNotification(
          t(
            'Portal_has_been_successfully_created_Keep_going_and_fill_the_rest_of_information'
          )
        );
        navigate(`/settings/portals`, {
          state: {
            forceHideConfirmationPopup: true,
          },
        });
      })
      .catch((error) => {
        let message;
        if ([472, 476].includes(error?.response?.status)) {
          message =
            error.response.data && error.response.data.message
              ? error.response.data.message
              : 'Server error';
        } else if (error.request) {
          message = t('Failed_to_connect_to_server');
        } else {
          message = t('Unknown_error');
        }
        addNotification(message, 'error');
      });
  };

  return (
    <div className={'bg-white p-4 xl:p-16'}>
      <PortalForm
        isCreateMode
        handleSubmit={handleSubmit}
        control={control}
        register={register}
        setValue={setValue}
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
    </div>
  );
};

export default PortalCreator;
