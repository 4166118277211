import { MutableRefObject, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import SendEmailReusableForm, {
  EmailState,
} from '../../../components/sendEmail/form';
import LazyPdfPreview from '../../../components/common/lazyPdfPreview';
import { Link } from 'react-router-dom';
import { Document } from '../../../types/property';
import { parseFileName } from '../../../helpers/utils/downloadFile';
import { IContact } from '../../../types/contacts';
import { ApiListing, Nullable } from '../../../types/api';
import { GridSelectionModel } from '@mui/x-data-grid';
import { AxiosError, AxiosResponse } from 'axios';
import { useNotification } from '../../../hooks/useNotification';
import { useTranslation } from 'react-i18next';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import classNames from 'classnames';
import { getFileName } from '../useColumns';
import { http } from '../../../libs/axios';
import { optionType } from '../../../const/propertiesOptions';

export const FORM_ID = 'email-form';

const SendEmailForm = ({
  onEmailSent,
  propertyId,
  items,
  formRef,
  sendOnServer,
  defaultValues = {
    selectedTemplate: null,
    selectedTemplateLang: null,
    subject: '',
    text: '',
    secondText: '',
    greeting: true,
  },
  disableReceiverField = false,
  contact,
  leadId,
  contacts,
  rowSelectionUsers,
}: {
  onEmailSent: (messageHtml?: string) => void;
  propertyId?: string;
  items: Document[];
  formRef: MutableRefObject<HTMLFormElement | null>;
  sendOnServer?: (body: EmailSettingsPayload) => Promise<AxiosResponse>;
  defaultValues?: EmailState;
  disableReceiverField?: boolean;
} & (
  | {
      contact: IContact | null;
      leadId?: number;
      contacts?: never;
      rowSelectionUsers?: never;
    }
  | {
      contact?: never;
      leadId?: never;
      contacts: ApiListing<any> | null;
      rowSelectionUsers: GridSelectionModel;
    }
)) => {
  const { t } = useTranslation();

  const formMethods = useForm<EmailState>({
    defaultValues,
  });
  const { handleSubmit, reset } = formMethods;
  const [isLoading, setIsLoading] = useState(false);

  const { addNotification } = useNotification();

  const brochures = items.filter((el) => el?.isBrochure && !el.isMergedPdf);
  const files = items.filter((el) => el?.isDocument || el?.isMergedPdf);

  const users = useMemo(() => {
    if (contact) {
      return [contact];
    }
    if (contacts && contacts['hydra:member']) {
      return contacts['hydra:member'].filter((el: any) =>
        rowSelectionUsers.includes(el.id)
      );
    } else {
      return [];
    }
  }, [contacts]);

  const usersList = users
    .map((el: IContact) =>
      el.individual
        ? `${el.individual.firstname} ${el.individual.lastname}`
        : el.fullname
        ? `${el.fullname}`
        : ''
    )
    .join(' ,');

  async function onSubmit(data: EmailState) {
    const body: EmailSettingsPayload = {
      emailTemplate: String(data.selectedTemplate) || undefined,
      emailTemplateLang: data.selectedTemplateLang || undefined,
      displayGreeting: data.greeting,
      subject: data.subject,
      text1: data.text,
      text2: data.secondText,
      brochures: brochures.map((el) => el.id),
      documents: files.map((el) => el.id),
    };

    if (rowSelectionUsers && rowSelectionUsers[0]) {
      body.to = rowSelectionUsers[0].toString();
    }

    if (contact) {
      body.to = contact.id.toString();
    }

    if (leadId) {
      body.leadId = leadId;
    }

    await submitDataToServer(body);
  }

  const submitDataToServer = async (body: EmailSettingsPayload) => {
    setIsLoading(true);
    try {
      if (sendOnServer) {
        await sendOnServer(body);
      } else {
        await http.post(`/v1/properties/${propertyId}/send_brochures`, body);
      }
      reset();
      addNotification(t('auth:email_has_been_sent'), 'success');
      onEmailSent();
    } catch (e) {
      const error = e as AxiosError;
      let message;
      if (error.response) {
        message = t('alerts:server_error');
      } else if (error.request) {
        message = t('alerts:failed_server_error');
      } else {
        message = t('alerts:unknown_error');
      }
      addNotification(message, 'error');
    }
    setIsLoading(false);
  };

  return (
    <SendEmailReusableForm
      formRef={formRef}
      onSubmit={handleSubmit(onSubmit)}
      formId={FORM_ID}
      usersList={usersList}
      isLoading={isLoading}
      formMethods={formMethods}
      disableReceiverField={disableReceiverField}
    >
      <div className="flex flex-col">
        {brochures && brochures.length ? (
          <div className="grid grid-cols-10 gap-2 py-4">
            {brochures.map((el) => (
              <div className="bg-[#F5F5F7] p-2 rounded" key={el.id}>
                <Link
                  className="flex flex-col justify-between h-full"
                  to={el.name}
                  target="_blank"
                  title={parseFileName(el.name)}
                  aria-label="Download file"
                >
                  <LazyPdfPreview
                    documentProps={{ file: el.name }}
                    thumbnailProps={{
                      width: 100,
                      height: 100,
                      className: 'pointer-events-none',
                    }}
                    skeletonHeight={100}
                  />
                </Link>
              </div>
            ))}
          </div>
        ) : null}
        {files && files.length ? (
          <div
            className={classNames('flex flex-wrap gap-2 py-4', {
              'pt-0': brochures && brochures.length,
            })}
          >
            {files.map((el) => (
              <div
                className="overflow-hidden relative pb-4 flex flex-col items-center justify-end gap-2 bg-[#F5F5F7] p-2 rounded w-24 h-28 before:content-[''] before:absolute before:top-0 before:right-0 before:w-8 before:h-8 before:bg-white before:rotate-45 before:transform before:translate-x-4 before:-translate-y-4"
                key={el.id}
              >
                <div className="absolute left-1 top-2">
                  <AttachFileOutlinedIcon
                    sx={{
                      fill: '#9898a1',
                    }}
                  />
                </div>
                <p
                  title={getFileName(el)}
                  className="text-[14px] w-full shrink-0 whitespace-nowrap text-ellipsis overflow-hidden font-medium"
                >
                  {getFileName(el)}
                </p>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </SendEmailReusableForm>
  );
};

export default SendEmailForm;

type EmailSettings = Nullable<{
  emailTemplate: string;
  emailTemplateLang: string;
  to: string;
  subject: string;
  text1: string;
  text2: string;
  brochures: number[];
  documents: number[];
  displayGreeting: boolean;
  isActive: boolean;
  leadId?: number;
}>;

type EmailSettingsPayload = Partial<EmailSettings>;

export type EmailSettingsResponse = Omit<
  EmailSettings,
  'emailTemplate' | 'documents' | 'brochures'
> &
  Nullable<{
    emailTemplate: optionType & { isActive: boolean };
    documents: Document[];
    brochures: Document[];
  }>;
