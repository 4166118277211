import React, { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { Button, Switch, Tooltip, Typography } from '@mui/material';
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import StepsFieldset from '../../../../../components/stepper/stepsFieldset';
import {
  IDataFields,
  ISettings,
  fieldTypes,
  stepTypes,
  visibilityOption,
} from '../../../../../types/settings';
import useLocale, { Locale, langCodes } from '../../../../../hooks/useLocale';
import {
  categoryOptionType,
  getOptionsByLanguageString,
} from '../../../../../const/propertiesOptions';
import SelectFormField, {
  selectOptionStringT,
} from '../../../../../components/form/selectFormField';
import LangSwitcher from '../../../../../layout/footer/langSwitcher';
import CheckedIcon from '../../../../../features/icons/checkedIcon';
import CloseIcon from '../../../../../features/icons/closeIcon';
import InputFormField from '../../../../../components/form/inputFormField';
import AttributesOptions from './form/attributesOptions';
import { http } from '../../../../../libs/axios';
import { useNotification } from '../../../../../hooks/useNotification';
import FilterAgencyMultiselectWithLoadedOptions from '../../../../../components/form/filterAgencyMultiselectWithLoadedOptions';
import { copyLanguageOfField } from './utils';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import SpinnerLoading from '../../../../../features/spinner/spinnerLoading';

type Props = {
  control: Control<ISettings, any>;
  name: string;
  defaultValues: any;
  watch: UseFormWatch<ISettings>;
  getValues: UseFormGetValues<ISettings>;
  index: number;
  register: UseFormRegister<ISettings>;
  setValue: UseFormSetValue<ISettings>;
  resetField: UseFormResetField<ISettings>;
};

const CustomAttributeItem = ({
  control,
  resetField,
  watch,
  index,
  setValue,
  register,
  defaultValues,
}: Props) => {
  const { locale: currentAppLocale } = useLocale();
  const [locale, setLocale] = useState<Locale>(currentAppLocale);
  const [name, setName] = useState<string | null>(null);
  const [label, setLabel] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('propertiesPage');
  const { data: categoriesOptionsApi } =
    useSWR<categoryOptionType[]>('categories');

  const { data: TagsApi } = useSWR<any>('data_field_tags');

  const { addNotification } = useNotification();
  const tagsOptions = getOptionsByLanguageString(TagsApi, locale);

  const categoriesOptions = getOptionsByLanguageString(
    categoriesOptionsApi,
    locale
  );
  const visibilityOptions = getOptionsByLanguageString(
    visibilityOption,
    locale
  );
  const fieldTypesOptions = getOptionsByLanguageString(fieldTypes, locale);
  const stepTypesOptions = getOptionsByLanguageString(stepTypes, locale);
  // Get TypesList via category choice
  const [typeOptions, setTypeOptions] = useState<selectOptionStringT[]>([]);
  const currentCategoryValue = watch(`dataFields.${index}.category`);
  useEffect(() => {
    resetField(`dataFields.${index}.propertyTypes`);
    if (currentCategoryValue?.length && categoriesOptionsApi) {
      const categoryId = Number(currentCategoryValue);

      const categorySelected = categoriesOptionsApi.filter(
        (type) => type.id === categoryId
      );

      const selectedTypeOptions = categorySelected[0]?.propertyTypes;
      const selectedTypeOptionsByLanguage = getOptionsByLanguageString(
        selectedTypeOptions,
        locale
      );

      setTypeOptions(selectedTypeOptionsByLanguage);
    }
  }, [categoriesOptionsApi, currentCategoryValue, locale]);

  const handleDataFields = async (data: IDataFields, id: number) => {
    data.isVisible = data?.isVisible === '1' ? true : (false as boolean);
    data.type = +data.type;
    data.step = +data.step;

    for (const loc of langCodes) {
      if (loc && loc !== locale) {
        copyLanguageOfField(locale, loc, data as IDataFields);
      }
    }
    setIsLoading(true);
    try {
      const res = await http.patch('/data_fields/' + id, data);

      if (res.status === 200) {
        addNotification(`Custom attribute has been updated successfully.`);
        mutate('/data_fields', null);
      }
    } catch (err) {
      console.log('err', err);
      addNotification(`Failed to update a custom attribute.`, 'error');
    } finally {
      setIsLoading(false);
    }
  };
  const removeAttribute = async (id: number) => {
    http
      .delete('/data_fields/' + id)
      .then((res) => {
        if (res.status === 204) {
          addNotification(`Custom attribute has been deleted successfully.`);
          mutate('/data_fields', null);
        }
      })
      .catch((err) => {
        addNotification(`${err.response.data.message}`, 'error');
      });
  };
  const TypeName = fieldTypesOptions.filter(
    (type) => type.id === defaultValues?.dataFields[index]?.type
  );
  const VisibilityInfo = visibilityOptions.filter(
    (type) => type.id === defaultValues?.dataFields[index]?.isVisible
  );
  const StepInfo = stepTypesOptions.filter(
    (step) =>
      Number(step.id) === Number(defaultValues?.dataFields[index]?.step[0])
  );
  const TagName = tagsOptions.filter(
    (tag) => tag.id === defaultValues?.dataFields[index]?.tag
  );

  const [prevLocale, setPrevLocale] = useState<Locale>(locale);
  const dataField = watch(`dataFields.${index}`);
  useEffect(() => {
    if (dataField) {
      copyLanguageOfField(prevLocale, locale, dataField);
    }
  }, [locale, prevLocale, dataField]);

  useEffect(() => {
    setName(defaultValues?.dataFields[index]?.translations[locale]?.name);
    setLabel(defaultValues?.dataFields[index]?.translations[locale]?.label);
  }, [locale]);

  return (
    <StepsFieldset
      defaultExpanded={false}
      className=""
      titleNode={
        <div className="grid grid-cols-5 w-full text-[#6E6E72] text-sm">
          <span>
            {defaultValues?.dataFields[index]?.translations[`${locale}`]?.name}
          </span>
          <span>{TypeName[0]?.value}</span>
          <span>{StepInfo[0]?.value}</span>
          <span>{VisibilityInfo[0]?.value}</span>
          {TagName && (
            <Tooltip title="Custom Attribute Tag" arrow placement="top">
              <span>{TagName[0]?.value}</span>
            </Tooltip>
          )}
        </div>
      }
    >
      <div className="w-full pb-8">
        <div className={'grid grid-cols-3 gap-4 border-b'}>
          <div className={''}>
            <LangSwitcher
              currentLocale={locale}
              setLanguage={setLocale}
              setPrevLanguage={setPrevLocale}
              changeAppLocale={false}
              className="w-full !mx-0 !h-16"
            />
          </div>
          <div className={'pl-2'}>
            <Controller
              control={control}
              name={`dataFields.${index}.isRequired`}
              render={({ field }) => (
                <label
                  className={'cursor-pointer flex items-center ml-[-12px]'}
                >
                  <Checkbox
                    className="private_checkbox"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    defaultChecked={!!field.value}
                  />
                  <span className={'text-[14px] opacity-80'}>
                    {t('Required_field')}
                  </span>
                </label>
              )}
            />
          </div>
        </div>
        <div className="flex gap-4 justify-end pt-4">
          <Button
            className={`${isLoading ? '!bg-gray-200' : '!bg-white'}`}
            variant="outlined"
            disabled={isLoading}
            aria-label="save block"
            onClick={() =>
              handleDataFields(
                watch(`dataFields.${index}`),
                defaultValues?.dataFields[index]?.id
              )
            }
          >
            {isLoading ? (
              <SpinnerLoading />
            ) : (
              <div className="flex justify-center items-center gap-4">
                {' '}
                <CheckedIcon color="#3446B9" />
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: '#3446B9',
                    fontWeight: 500,
                  }}
                >
                  {+watch(`dataFields.${index}`)
                    ? t('Edit_custom_field')
                    : t('Save_custom_field')}
                </Typography>
              </div>
            )}
          </Button>
          <Button
            className="flex justify-center items-center gap-4"
            variant="outlined"
            aria-label="Remove block"
            onClick={() => {
              removeAttribute(+defaultValues?.dataFields[index]?.id);
              mutate('/data_fields', null);
            }}
          >
            <CloseIcon color="#F50057" />
            <Typography
              sx={{
                fontSize: '14px',
                color: '#F50057',
                fontWeight: 500,
              }}
            >
              {t('Delete')}
            </Typography>
          </Button>
        </div>
        <div className="flex flex-col gap-4">
          <div className="grid grid-cols-3 gap-4">
            <SelectFormField
              label="Tags"
              options={tagsOptions}
              defaultValue={defaultValues?.dataFields[index]?.tag}
              {...register(`dataFields.${index}.tag`)}
            />
            <div className="flex justify-between items-center mt-4">
              <p className="font-[Inter] font-medium">
                {t('common:Publish_on_website')}
              </p>
              <Switch
                defaultChecked={
                  defaultValues.dataFields[index].publishedOnWebsite
                }
                {...register(`dataFields.${index}.publishedOnWebsite`)}
              />
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4">
            <InputFormField
              isRequired
              label={t('Custom_field_name')}
              type="text"
              key={index}
              value={name}
              {...register(`dataFields.${index}.translations.${locale}.name`, {
                onChange: (e) => setName(e.target.value),
              })}
              required={index !== 0}
            />
            <input
              hidden
              value={locale}
              {...register(`dataFields.${index}.translations.${locale}.locale`)}
            />

            <SelectFormField
              isRequired
              label={t('Visibility')}
              options={visibilityOptions}
              defaultValue={defaultValues.dataFields[index].isVisible}
              {...register(`dataFields.${index}.isVisible`)}
            />
          </div>
          <div className="grid grid-cols-3 gap-4">
            <InputFormField
              isRequired
              label={t('Custom_field_label')}
              type="text"
              key={index}
              value={label}
              {...register(`dataFields.${index}.translations.${locale}.label`, {
                onChange: (e) => setLabel(e.target.value),
              })}
              required={index !== 0}
            />
            <SelectFormField
              isRequired
              label={t('Field_type')}
              options={fieldTypesOptions}
              defaultValue={defaultValues?.dataFields[index]?.type}
              {...register(`dataFields.${index}.type`)}
            />
            {watch(`dataFields.${index}.type`) === '0' ? null : (
              <AttributesOptions
                key={locale}
                watch={watch}
                nestIndex={index}
                control={control}
                register={register}
                locale={locale}
              />
            )}
          </div>
          <div className="grid grid-cols-3 gap-4">
            <FilterAgencyMultiselectWithLoadedOptions
              isRequired
              defaultValue={defaultValues?.dataFields[index]?.departments}
              label={t('Department')}
              name={`dataFields.${index}.departments`}
              value={watch(`dataFields.${index}.departments`) || []}
              setValue={setValue}
              className="w-full !h-12"
              isMultiple={true}
            />
            <SelectFormField
              isRequired
              label={t('Select_step')}
              options={stepTypesOptions}
              defaultValue={defaultValues?.dataFields[index]?.step}
              {...register(`dataFields.${index}.step`)}
            />
          </div>
          <div className="grid grid-cols-3 gap-4">
            <SelectFormField
              isRequired
              label={t('Category')}
              options={categoriesOptions}
              defaultValue={defaultValues?.dataFields[index]?.category}
              {...register(`dataFields.${index}.category`)}
            />
            <SelectFormField
              isRequired
              disabled={!watch(`dataFields.${index}.category`)?.length}
              label={t('Property_types')}
              multiple
              options={typeOptions}
              defaultValue={defaultValues?.dataFields[index]?.propertyTypes}
              {...register(`dataFields.${index}.propertyTypes`)}
            />
          </div>
        </div>
      </div>
    </StepsFieldset>
  );
};

export default CustomAttributeItem;
