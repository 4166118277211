import {
  Control,
  UseFormRegister,
  UseFormWatch,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputLayout from '../../../components/form/components/inputLayout';
import AddressInput from '../../../components/form/addressInput';
import useLocale from '../../../hooks/useLocale';
import InputFormField from '../../../components/form/inputFormField';
import { PlaceDetails } from './editAddress';

interface Props {
  register: UseFormRegister<any>;
  control: Control<any>;
  watch: UseFormWatch<any>;
  index: number;
  name: string;
  element?: PlaceDetails;
}

const AddressFields = ({
  register,
  control,
  watch,
  name,
  index,
  element,
}: Props) => {
  const { locale } = useLocale();
  const { field } = useController({ name: `${name}.${index}`, control });
  const { t } = useTranslation('common');

  return (
    <>
      <div className="flex gap-12">
        <div className="w-[58%]">
          <InputLayout label={t('search_location')}>
            <AddressInput
              withoutMaps
              value={field.value}
              onChange={field.onChange}
            />
          </InputLayout>
        </div>
        <div className="flex gap-4">
          <InputFormField
            type="text"
            label={t('City')}
            defaultValue={element?.city}
            className="bg-slate-50"
            {...register(`${name}.${index}.translations.${locale}.city`)}
            value={watch(`${name}.${index}.translations.${locale}.city`)}
          />
          <InputFormField
            isRequired
            type="number"
            label={t('Zip_Code')}
            defaultValue={element?.zipCode}
            className="bg-slate-50"
            {...register(`${name}.${index}.zipCode`)}
            value={watch(`${name}.${index}.zipCode`)}
          />
        </div>
      </div>
    </>
  );
};

export default AddressFields;
