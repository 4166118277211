import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import LocationIcon from '../../../../features/icons/locationIcon';
import GoogleMapContainer from '../../../../components/map/googleMapContainer';
import { IPropertyApi } from '../../../../types/property';
import { renderFullPropertyAdress } from '../../../../helpers/utils';

function PropertyLocation({ property }: { property: IPropertyApi }) {
  const addressString = renderFullPropertyAdress(property);

  const { t } = useTranslation('propertiesPage');

  return (
    <Stack
      sx={{ color: 'text.primary' }}
      className="w-full rounded border bg-[#FFFFFF] p-2 xl:p-8 flex flex-col"
    >
      {property.address?.latitude && property.address?.longitude && (
        <GoogleMapContainer
          coordinates={{
            lat: property.address?.latitude,
            lng: property.address?.longitude,
          }}
        />
      )}

      <h2 className=" my-4 font-medium text-lg">{t('property_location')}</h2>

      {addressString && (
        <div className="flex items-center">
          <LocationIcon className="mr-2 col-span-1" color="#6E6E72" />
          <h3 className="col-span-7 text-sm">{addressString}</h3>
        </div>
      )}
    </Stack>
  );
}

export default PropertyLocation;
