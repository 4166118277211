import { useState } from 'react';
import useSWR from 'swr';

import { useTranslation } from 'react-i18next';
import { Box, Tooltip, Fade, Rating } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { renderPropertyAdress } from '../../../../helpers/utils';
import { useIsRent } from '../../../../hooks/useIsRent';
import useLocale from '../../../../hooks/useLocale';

import BathroomOutlinedIcon from '@mui/icons-material/BathroomOutlined';
import BedroomIcon from '../../../../features/icons/bedroomIcon';
import MountainIcon from '../../../../features/icons/mountainIcon';
import PermissionIcon from '../../../../features/icons/permissionIcon';
import HomeIcon from '../../../../features/icons/homeIcon';
import SquareIcon from '../../../../features/icons/squareIcon';
import Star2Icon from '../../../../features/icons/star2Icon';
import WarningIcon from '../../../../features/icons/warningIcon';

import Modal from '../../../../components/modal';
import PropertyContact from '../../../../components/tableGridX/propertyContact';

import { PopupSingleProperty } from '../../../../types/popupSingleProperty';
import { PropertyListingItem } from '../../../../types/property';

import FieldValueToDisplay from '../../../../components/common/fieldValueToDisplay';
import ShowMore from './showMore';
import { textIsLongerThen, trimIf } from './index';

interface Props {
  listingItem: PropertyListingItem;
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
}

const PropertiesInfoModal = ({
  openModal,
  setOpenModal,
  listingItem,
}: Props) => {
  const { t } = useTranslation('propertiesPage');

  const { locale } = useLocale();

  const onClickAway = () => {
    setOpenModal(false);
  };

  const [fullDescriptionOrWarning, setFullDescriptionOrWarning] =
    useState(false);
  const [showAllCharacteristics, toggleAllCharacteristics] = useState(false);

  const { data: row } = useSWR<PopupSingleProperty>(
    openModal && `/v1/properties/${listingItem.id}/popup`
  );

  const isRent = useIsRent(row);

  if (!row) {
    return null;
  }

  const pricingSource = (isRent ? row?.pricingRent : row?.pricingBuy) || {};
  const setFieldBytype = (name: keyof PopupSingleProperty) => {
    return row?.[name] ? 'Yes' : 'No';
  };

  const warningTranslations = Array.isArray(
    listingItem?.warningField?.translations
  )
    ? {}
    : listingItem?.warningField?.translations;
  const warning = warningTranslations?.[locale]?.value;
  const title = listingItem?.translations?.[locale]?.title;
  const description = row?.translations[locale]?.description;
  const publishGateways = row.publishGateways;
  const publishWebsite = row.publishWebsite;
  return (
    <Modal
      sx={{
        transform: 'translate(5%, -5%)',
      }}
      boxClassName="pb-5 border rounded-0"
      CloseIconStyle={{
        cursor: 'pointer',
      }}
      isOpen={openModal}
      closeModal={() => setOpenModal(false)}
      openModal={() => setOpenModal(true)}
      buttonProps={{
        title: ' ',
        className: 'pr-6 text-sm leading-4 text-gray-900',
      }}
      withButton={false}
      closeIconSize="medium"
      title={
        <div>
          <p className="text-2xl font-normal inter leading-7 tracking-[-3%] capitalize ml-10">
            {title}
          </p>
        </div>
      }
      withTitleDivider={false}
      width={{
        md: '86%',
      }}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <Fade in={openModal}>
          <Box className="grid grid-cols-4 w-full p-[10px]">
            <Box className="border  border-[#E6E8EC] w-full grid gap-2 pl-2 pt-2 pr-2 pb-2 min-h-[160px] max-h-[170px]">
              {' '}
              <div className="flex gap-1 inter font-normal text-sm leading-4 pt-2 pb-2">
                <div
                  className="flex px-2 rounded-md items-center h-[90%]"
                  style={{ border: '1px solid #3446B9 ' }}
                >
                  <PermissionIcon className="mr-2 col-span-1" color="#3446B9" />{' '}
                  <span className="text-blue leading-5">{row?.reference}</span>
                </div>
                <span
                  className="flex px-2 rounded-md items-center text-[#4CAF50] border border-[#4CAF50] h-[90%] capitalize"
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {row?.status?.translations[locale]?.value}
                </span>
                <span className="bg-[#F5F5F7] flex px-2 rounded-md text-xs text-center items-center py-1 border h-[90%] capitalize">
                  {row?.type?.keyname}
                </span>
              </div>
              <div className="pl-2 text-lg font-medium leading-5 inter">
                {row?.department?.name}
              </div>
              <div className="flex py-3 text-sm font-medium leading-5 inter">
                {row?.address && renderPropertyAdress(row.address, false)}
              </div>
            </Box>
            {warning ? (
              <Box className="flex flex-col justify-between border border-[#E6E8EC] w-full border-l-0 pl-3 pt-2 pr-4 pb-2 gap-2 text-left min-h-[160px] max-h-[170px] overflow-auto">
                <Tooltip
                  title={t('common:Warning')}
                  arrow
                  placement="top"
                  className="text-sm font-medium leading-5 inter"
                >
                  <div className="flex gap-1">
                    <span>
                      <WarningIcon color="#FFA500" />
                    </span>
                    <span className="ml-2">
                      {trimIf(fullDescriptionOrWarning, warning)}
                    </span>
                  </div>
                </Tooltip>

                {textIsLongerThen(warning, 100) && (
                  <ShowMore
                    isExpanded={fullDescriptionOrWarning}
                    onChange={setFullDescriptionOrWarning}
                  />
                )}
              </Box>
            ) : (
              <Tooltip
                title={t('description')}
                arrow
                placement="top"
                className="text-sm font-medium leading-5 inter"
              >
                <Box className="flex flex-col justify-between  border border-[#E6E8EC] w-full border-l-0 pl-4 pt-2 pr-4 pb-2 gap-2 text-left min-h-[160px] max-h-[170px] overflow-auto">
                  {description ? (
                    <>
                      <FieldValueToDisplay
                        className="text-sm font-medium leading-5 inter"
                        fieldValue={trimIf(
                          fullDescriptionOrWarning,
                          description
                        )}
                        defaultValue={''}
                      />
                      {textIsLongerThen(description, 100) && (
                        <ShowMore
                          isExpanded={fullDescriptionOrWarning}
                          onChange={setFullDescriptionOrWarning}
                        />
                      )}
                    </>
                  ) : (
                    t('no_description')
                  )}
                </Box>
              </Tooltip>
            )}
            <Box className=" border border-[#E6E8EC] w-full border-l-0 pl-4 pt-2 pr-4 pb-2 text-sm font-medium	leading-7 inter min-h-[160px] max-h-[170px]">
              {pricingSource.originalPrice ? (
                <div className="flex justify-between">
                  <h2>{t('original_price')} :</h2>
                  <span>{pricingSource.originalPrice}</span>
                </div>
              ) : null}
              {pricingSource.displayedPrice ? (
                <div className="flex justify-between">
                  <h2>{t('displayedPrice')} :</h2>
                  <span>{pricingSource.displayedPrice}</span>
                </div>
              ) : null}
              {pricingSource.pricem2 ? (
                <div className="flex justify-between">
                  <h2>{t('price_per_m2')} :</h2>
                  <span>{pricingSource.pricem2}</span>
                </div>
              ) : null}

              {pricingSource.parkings && pricingSource.parkings.length > 0
                ? pricingSource.parkings.map((parking, index) => (
                    <div key={index} className="flex justify-between">
                      <h2>
                        {t('parking', { what: t('price') }) +
                          ` N° ${index + 1}`}{' '}
                        :
                      </h2>

                      <span>{parking.parkingPrice}</span>
                    </div>
                  ))
                : null}

              {pricingSource.annualCharges ? (
                <div className="flex justify-between">
                  <h2>{t('annual_charges')} :</h2>
                  <span> {pricingSource.annualCharges}</span>
                </div>
              ) : null}
            </Box>
            <Box className="flex flex-col justify-between border border-[#E6E8EC] w-full border-l-0 pl-4 pt-2 pr-4 pb-2 text-sm font-medium leading-7 inter min-h-[160px] max-h-[170px] overflow-auto">
              <div>
                <div className="flex justify-between">
                  <h2>{t('parking', { what: '' })} :</h2>
                  <span>{setFieldBytype('parkingAvailable')}</span>
                </div>

                {row?.parkingAvailable && row?.parkingNumbers && (
                  <div className="flex justify-between">
                    <h2>{t('parking', { what: t('numbers') })} :</h2>
                    <span>{row?.parkingNumbers}</span>
                  </div>
                )}

                <div className="flex justify-between">
                  <h2>{t('parking', { what: t('boxes') })} :</h2>
                  <span>{setFieldBytype('parkingBoxesAvailable')}</span>
                </div>

                {row?.parkingBoxesAvailable && row?.parkingBoxesNumbers && (
                  <div className="flex justify-between">
                    <h2>
                      {t('parking', {
                        what: t('boxes') + ' ' + t('numbers'),
                      })}{' '}
                      :
                    </h2>
                    <span>{row?.parkingBoxesNumbers}</span>
                  </div>
                )}

                {showAllCharacteristics && (
                  <div>
                    {row?.floor && (
                      <div className="flex justify-between">
                        <h2>{t('floor')} :</h2>
                        <span>{row?.floor}</span>
                      </div>
                    )}

                    {row?.balcony && (
                      <div className="flex justify-between">
                        <h2>{t('balcony')} :</h2>
                        <span>{row?.balcony}</span>
                      </div>
                    )}

                    {row?.terrace && (
                      <div className="flex justify-between">
                        <h2>{t('terrace')} :</h2>
                        <span>{row?.terrace}</span>
                      </div>
                    )}

                    {row?.orientations && (
                      <div className="flex justify-between">
                        <h2>{t('orientations')} :</h2>
                        <span>
                          {row?.orientations[0]?.translations[locale]?.name}
                        </span>
                      </div>
                    )}

                    {row?.pool && (
                      <div className="flex justify-between">
                        <h2>{t('pool')} :</h2>
                        <span>{setFieldBytype('pool')}</span>
                      </div>
                    )}

                    {row?.lift && (
                      <div className="flex justify-between">
                        <h2>{t('lift')} :</h2>
                        <span>{setFieldBytype('lift')}</span>
                      </div>
                    )}

                    {row?.garden && (
                      <div className="flex justify-between">
                        <h2>{t('garden')} :</h2>
                        <span>{setFieldBytype('garden')}</span>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <ShowMore
                isExpanded={showAllCharacteristics}
                onChange={toggleAllCharacteristics}
              />
            </Box>
            <Box className="border border-[rgb(230,232,236)] w-full border-t-0 min-h-[160px]">
              <div className="flex justify-between w-[90%] mx-auto mb-4">
                {row?.rooms && (
                  <Tooltip title={'Rooms'} arrow placement="top">
                    <Box className="flex mt-6 gap-1">
                      {' '}
                      <HomeIcon
                        className="mr-1 col-span-1 opacity-50"
                        color="#6E6E72"
                      />{' '}
                      <span className="inter font-normal text-sm leading-4">
                        {row?.rooms}
                      </span>
                    </Box>
                  </Tooltip>
                )}
                {row?.bedrooms && (
                  <Tooltip title="Bedrooms" arrow placement="top">
                    <Box className="flex mt-6 gap-1">
                      {' '}
                      <BedroomIcon
                        className="mr-1 col-span-1 opacity-50"
                        color="#6E6E72"
                      />{' '}
                      <span className="inter font-normal text-sm leading-4">
                        {row?.bedrooms}
                      </span>
                    </Box>
                  </Tooltip>
                )}
                {row?.bathrooms && (
                  <Tooltip title="Bathrooms" arrow placement="top">
                    <Box className="flex mt-6 gap-1">
                      <BathroomOutlinedIcon
                        className="col-span-1 opacity-50 text-[#6E6E72]"
                        sx={{ fontSize: '16px' }}
                      />
                      <span className="inter font-normal text-sm leading-4">
                        {row?.bathrooms}
                      </span>
                    </Box>
                  </Tooltip>
                )}
              </div>
              <div className="flex justify-between w-[90%] mx-auto">
                {row?.livingArea && (
                  <Tooltip title="Living area" arrow placement="top">
                    <Box className="flex mt-6 gap-1">
                      {' '}
                      <SquareIcon
                        className="col-span-1 opacity-50"
                        color="#6E6E72"
                      />{' '}
                      <div className="flex inter font-normal text-sm leading-4">
                        <span className="mx-1">
                          {(row.addFromToLivingArea
                            ? t('propertiesPage:from') + ' '
                            : '') +
                            (row.useTildeForSurface ? '~' : '') +
                            row?.livingArea}
                        </span>
                        <span>m</span>
                        <sup>2</sup>
                      </div>
                    </Box>
                  </Tooltip>
                )}
                {row?.landSurface && (
                  <Tooltip title="Land surface" arrow placement="top">
                    <Box className="flex mt-6 gap-1">
                      {' '}
                      <MountainIcon
                        className="col-span-1 opacity-50"
                        color="#6E6E72"
                      />{' '}
                      <div className="flex inter font-normal text-sm leading-4">
                        <span className="mx-1">
                          {(row.addFromToLandSurface
                            ? t('propertiesPage:from') + ' '
                            : '') +
                            (row.useTildeForSurface ? '~' : '') +
                            row?.landSurface}
                        </span>
                        <span>m</span>
                        <sup>2</sup>
                      </div>
                    </Box>
                  </Tooltip>
                )}
              </div>
            </Box>
            <Box className="border border-[#E6E8EC] w-full border-t-0 border-l-0 grid gap-2 p-4 text-sm font-medium leading-5 inter min-h-[160px]">
              {row?.usefulArea && (
                <div className="flex justify-between">
                  <h2>{t('useful_area')} :</h2>
                  <p>
                    {(row.addFromToUsefulArea
                      ? t('propertiesPage:from') + ' '
                      : '') +
                      (row.useTildeForSurface ? '~' : '') +
                      row?.usefulArea}
                  </p>
                </div>
              )}
              {row?.terraceSurface && (
                <div className="flex justify-between">
                  <h1>{t('terrace_surface')} :</h1>
                  <p>
                    {(row.useTildeForSurface ? '~' : '') + row?.terraceSurface}
                  </p>
                </div>
              )}
              {row?.gardenSurface && (
                <div className="flex justify-between">
                  <h2>{t('garden_surface')} :</h2>
                  <p>
                    {(row.useTildeForSurface ? '~' : '') + row?.gardenSurface}
                  </p>
                </div>
              )}
            </Box>
            <Box className="border border-[#E6E8EC] w-full border-t-0 border-l-0 grid gap-2 p-4  min-h-[160px]">
              <h2 className="text-sm font-medium leading-5 inter">
                {t('contacts')} :
              </h2>
              <div className="flex">
                {row && <PropertyContact row={row} showIntermediateBroker />}
              </div>
            </Box>

            <Box className="border border-[#E6E8EC] w-full border-t-0 border-l-0 p-4 grid gap-0 text-sm font-medium leading-5 inter  min-h-[160px]">
              <div className="flex justify-between w-full">
                <h2>{t('common:date_created')} :</h2>
                <span>{row?.createdAt || '-'}</span>
              </div>
              <div className="flex justify-between">
                <h2>{t('common:date_updated')} :</h2>
                <span>{row?.updatedAt || '-'}</span>
              </div>
              <div className="flex gap-2">
                <h2>{t('common:Ranking')} :</h2>
                <Rating
                  name="rating"
                  value={row?.rating}
                  max={3}
                  readOnly
                  icon={<Star2Icon className="mr-1" color="#FFC839" />}
                  emptyIcon={<Star2Icon className="mr-1" color="#E6E8EC" />}
                />
              </div>

              {(publishWebsite ||
                (publishGateways && publishGateways.length > 0)) && (
                <Tooltip
                  title={
                    publishWebsite &&
                    publishGateways &&
                    publishGateways.length > 0
                      ? t(
                          'common:Published_on_websites_and_Published_on_gateways'
                        )
                      : publishWebsite
                      ? t('common:Publish_on_websites')
                      : t('common:Publish_on_gateways')
                  }
                  arrow
                  placement="top"
                >
                  <div className="flex items-center gap-2">
                    <div className="w-[12px] h-[12px] rounded-full bg-green-500"></div>
                    {publishWebsite && <span> {t('common:Website')} </span>}
                    {publishWebsite &&
                      publishGateways &&
                      publishGateways.length > 0 && (
                        <span> {t('common:and')} </span>
                      )}
                    {publishGateways && publishGateways.length > 0 && (
                      <span> {t('common:gateways')} </span>
                    )}
                  </div>
                </Tooltip>
              )}
              <ul className="ml-10" style={{ listStyle: 'square' }}>
                {publishGateways &&
                  publishGateways.length > 0 &&
                  publishGateways.map(
                    (element, i) =>
                      element?.gateway?.isActive && (
                        <li key={i}> {element?.gateway?.name}</li>
                      )
                  )}
              </ul>
            </Box>
          </Box>
        </Fade>
      </ClickAwayListener>
    </Modal>
  );
};

export default PropertiesInfoModal;
