import { useTranslation } from 'react-i18next';
import { PropertyWidgetPreviewProps } from '../../../../../types/brochureEditor';
import TableWidget from '../../tableWidget';
import useLocale from '../../../../../hooks/useLocale';

const PropertyRoomsWidget = ({
  property,
  widgetOptions,
}: PropertyWidgetPreviewProps) => {
  const { t } = useTranslation('propertiesPage');
  const addNumberOf = useAddNumberOf();

  return (
    <TableWidget
      title={t('rooms')}
      items={[
        {
          id: '7',
          label: addNumberOf(t('rooms')),
          value: String(property.rooms),
        },
        {
          id: '8',
          label: addNumberOf(t('Bedrooms')),
          value: String(property.bedrooms),
        },
        {
          id: '9',
          label: addNumberOf(t('Bathrooms')),
          value: String(property.bathrooms),
        },
        {
          id: '10',
          label: addNumberOf(t('brochureEditor:parking')),
          value: String(property.parkingNumbers),
        },
      ]}
      widgetOptions={widgetOptions}
    />
  );
};

export default PropertyRoomsWidget;

function useAddNumberOf() {
  const { locale } = useLocale();
  return (value: string) => {
    if (locale === 'fr') {
      return 'Nb. de ' + value.toLocaleLowerCase();
    }
    return value;
  };
}
