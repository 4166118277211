import { DistanceArrayItem } from './index';
import { useFieldArray, useForm } from 'react-hook-form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropertyDistanceItem from './item';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DistanceInfo } from '../../../../../types/property';

interface Props {
  serverData: any;
  mutate: any;
  item: DistanceArrayItem;
  keyname: string;
}

const getDefaultValues = (
  distances: DistanceInfo[],
  keyname: string
): { [p: string]: { realId: number | null }[] } => {
  return {
    [keyname]: distances
      ? distances.map((el: DistanceInfo) => ({ realId: el.id }))
      : [{ realId: null }],
  };
};

const PropertyDistanceItemWrapper = (props: Props) => {
  const { item, serverData, keyname, mutate } = props;
  const { t } = useTranslation('propertiesPage');
  const { control } = useForm({
    defaultValues: getDefaultValues(serverData.distances[keyname], keyname),
  });
  const serverDistances = serverData.distances[keyname];

  const { fields, append, remove } = useFieldArray({
    control,
    name: keyname,
  });

  const onAddClick = () => {
    append({ realId: null });
  };

  const onRemoveClick = (index: number) => {
    remove(index);
  };

  return (
    <div className={'pt-4'}>
      <div className={'mb-2 flex justify-between items-center'}>
        <p className={' font-medium text-lg'}>{t(item.label)}</p>
      </div>
      <div className={'flex flex-col gap-4'}>
        {fields.map((field, index) => (
          <PropertyDistanceItem
            key={field.id}
            item={item}
            latitude={serverData.address.latitude}
            longitude={serverData.address.longitude}
            serverData={serverData}
            mutate={mutate}
            serverValues={
              serverDistances
                ? serverDistances.find(
                    (el: DistanceInfo) => el.id === field.realId
                  )
                : null
            }
            isLastItem={fields.length === 1}
            onRemoveClick={() => onRemoveClick(index)}
          />
        ))}
      </div>
      <div className={'flex justify-end items-end pt-4'}>
        <Button
          onClick={onAddClick}
          variant="outlined"
          sx={{ height: '48px', color: '#1D1D1F', gap: '8px' }}
        >
          <AddIcon fontSize={'small'} />
          {t('Add_another')}
        </Button>
      </div>
    </div>
  );
};

export default PropertyDistanceItemWrapper;
