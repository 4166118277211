import { GridColumns } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { INetworkMemberItem } from '../../../../types/networks';
import useLocale from '../../../../hooks/useLocale';
import { formatDate } from '../../../../helpers/utils';
import { statuses } from '../singleNetwork';
import { ActionData } from '../../../../types/actionData';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useAnchorElement } from '../../../../hooks/useAnchorElement';
import ActionMenu from '../../../../components/common/actionMenu';
import React from 'react';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { Link, useParams } from 'react-router-dom';
import terminate from '../actions/terminate';
import acceptInvitation from '../actions/acceptInvitation';
import exit from '../actions/exit';
import declineInvitation from '../actions/declineInvitation';
import useMediaQuery from '@mui/material/useMediaQuery';
function getStatusColor(status: number): string {
  switch (status.toString()) {
    case statuses.pending:
      return 'border-[#ED6C02] text-[#ED6C02]'; // Pending - yellow border
    case statuses.accepted:
      return 'border-[#4CAF50] text-[#4CAF50]'; // Accepted - green border
    case statuses.declined:
      return 'border-[#F50057] text-[#F50057]'; // Declined - red border
    case statuses.terminated:
      return 'border-[#F50057] text-[#F50057]'; // Terminated - blue border
    case statuses.exited:
      return 'border-[#ED6C02] text-[#ED6C02]'; // Exited - orange border
    default:
      return 'border-gray-500 text-gray-500'; // Default color - gray border
  }
}

const ItemActionMenu = ({
  item,
  action,
  userAuthorisedToEdit,
  mutate,
}: {
  item: INetworkMemberItem;
  action?: string;
  userAuthorisedToEdit?: boolean;
  mutate?: () => void;
}) => {
  const { networkId } = useParams();
  const menuActionData: ActionData[] = [
    {
      label: 'view',
      link: `/networks/${networkId}/agencies/${item.agency.id}?itemId=${item.id}`,
      icon: VisibilityOutlinedIcon,
      hide: action,
    },
  ];

  if (
    userAuthorisedToEdit &&
    item.status.id.toString() !== statuses.terminated
  ) {
    menuActionData.push({
      label: 'terminate',
      onClick: () => terminate(item.id, mutate),
      icon: DoDisturbOnOutlinedIcon,
    });
  }

  if (
    !userAuthorisedToEdit &&
    item.agency.userAgencyOwner &&
    item.status.id.toString() !== statuses.accepted
  ) {
    menuActionData.push(
      {
        label: 'accept',
        onClick: () => acceptInvitation(item.id, mutate),
        icon: CheckIcon,
      },
      {
        label: 'decline',
        onClick: () => declineInvitation(item.id, mutate),
        icon: CloseIcon,
      }
    );
  }

  if (
    !userAuthorisedToEdit &&
    item.agency.userAgencyOwner &&
    item.status.id.toString() === statuses.accepted
  ) {
    menuActionData.push({
      label: 'exit',
      onClick: () => exit(item.id, mutate),
      icon: DoDisturbOnOutlinedIcon,
    });
  }

  const anchor = useAnchorElement();

  return (
    <>
      <ActionMenu
        {...anchor}
        menuAction={menuActionData}
        transPage={'networks'}
        action={action}
      />
    </>
  );
};

export const useMembersListColumns = ({
  isLoading,
  userAuthorisedToEdit,
  mutate,
}: {
  isLoading?: boolean;
  userAuthorisedToEdit?: boolean;
  mutate?: () => void;
}): GridColumns<INetworkMemberItem> => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { networkId } = useParams();
  const isHigherThen768 = useMediaQuery('(min-width:768px)');

  const commonSettings = {
    sortable: false,
    disableColumnMenu: true,
  };

  const columns: GridColumns<INetworkMemberItem> = [
    {
      field: 'name',
      width: 250,
      flex: 0,
      headerName: t('brochureEditor:name'),
      renderCell: ({ row }) => {
        return (
          <Link to={`/networks/${networkId}/agencies/${row.agency.id}?itemId=${row.id}`}>
            <div className={'flex items-center gap-2'}>
              <div
                className={
                  'w-[53px] h-[53px] rounded-full bg-gray-100 overflow-hidden flex items-center justify-center object-contain'
                }
              >
                {row.agency.logo?.x96 ? (
                  <img src={row.agency.logo.x96} alt={row.agency.name} />
                ) : null}
              </div>
              {row.agency.name}
            </div>
          </Link>
        );
      },
    },
    {
      field: 'status',
      minWidth: 105,
      flex: 1,
      headerName: t('networks:Invite_status'),
      renderCell: ({ row }) => {
        return (
          <>
            {row.status ? (
              <div
                className={`flex py-0.5 px-2 rounded-md items-center border w-fit mb-1 ${getStatusColor(
                  row.status?.id
                )}`}
              >
                <span className="text-[12px] 2xl:text-[14px] leading-5 first-letter:uppercase">
                  {row.status?.translations
                    ? row.status?.translations?.[locale]?.name
                    : row.status?.keyname}
                </span>
              </div>
            ) : null}
          </>
        );
      },
    },
    {
      field: 'sent',
      minWidth: 120,
      flex: 1,
      headerName: t('networks:Invite_sent'),
      renderCell: ({ row }) => {
        return <div>{formatDate(row.dateInvitation)}</div>;
      },
    },
    {
      field: 'accepted',
      flex: 1,
      minWidth: 140,
      headerClassName: ` ${!isHigherThen768 && '!border-r-0'}`,
      cellClassName: ` ${!isHigherThen768 && '!border-r-0'}`,
      headerName: t('networks:Invite_accepted'),
      renderCell: ({ row }) => {
        return (
          <div>{row.dateAcceptance ? formatDate(row.dateAcceptance) : '-'}</div>
        );
      },
    },
    {
      field: 'actions',
      minWidth: 75,
      headerClassName: '!border-r-0 !hidden md:!block',
      headerName: t('common:actions'),
      renderCell: ({ row }) =>
        isHigherThen768 && (
          <ItemActionMenu
            item={row}
            userAuthorisedToEdit={userAuthorisedToEdit}
            mutate={mutate}
          />
        ),
    },
  ];

  return columns.map((column) => ({
    ...commonSettings,
    ...column,
    ...(isLoading
      ? {
          renderCell: () => (
            <Skeleton
              variant="text"
              animation="wave"
              width="100%"
              height={20}
            />
          ),
        }
      : {}),
  }));
};
