import LoopSharpIcon from '@mui/icons-material/LoopSharp';
import { useTranslation } from 'react-i18next';
import React from 'react';

type UpdateIndicatorProps = {
  requestedUpdate: boolean;
  requestedUpdateDone: boolean;
};

const UpdateIndicator = ({
  requestedUpdate,
  requestedUpdateDone,
}: UpdateIndicatorProps) => {
  const { t } = useTranslation('propertiesPage');
  return (
    <div className={'flex flex-col gap-2 items-end'}>
      {requestedUpdate ? (
        <div className="opacity-80 text-blue-800 px-4 py-3 bg-white rounded border border-blue-800 justify-center items-center gap-4 inline-flex">
          <LoopSharpIcon
            sx={{
              width: '22px',
              height: '22px',
            }}
            fontSize={'small'}
          />
          <div className="text-sm font-medium leading-2">
            {t('You_launched_an_update_for_this_matching_list')} <br />
            {t('Data_may_change')}
          </div>
        </div>
      ) : null}
      {!requestedUpdate && requestedUpdateDone ? (
        <div className="opacity-80 text-[#4CAF50] px-4 py-3 bg-white rounded border border-[#4CAF50] justify-center items-center gap-4 inline-flex">
          <LoopSharpIcon
            sx={{
              width: '22px',
              height: '22px',
            }}
            fontSize={'small'}
          />
          <div className="text-sm font-medium leading-2">
            {t('Update_is_ready')}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default UpdateIndicator;
