import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Box, Fade, Modal } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TableGridX from '../../../components/tableGridX';
import useListingCollection from '../../../hooks/useListingCollection';
import {
  PreviewSubjectTypes,
  TemplateApi,
} from '../../../types/brochureEditor';
import { useLeadsListColumns } from '../utils/useLeadsListColumns';
import CloseIcon from '@mui/icons-material/Close';
import GridTableView from '../../../components/cardsTablesList';
import GridView from '../../../components/cardsTablesList/cards/list';
import { KeyedMutator } from 'swr';
interface Props {
  api: string;
  title: string;
  openModal: boolean;
  onClose: () => void;
  mutate?: KeyedMutator<unknown>;
}

const skeletonItems: TemplateApi[] = Array(6)
  .fill('')
  .map((_, index) => ({
    id: index,
    name: '',
    usedFor: String(PreviewSubjectTypes.PROPERTY),
    brand: '',
    pages: [],
    layout: [],
    properties: [],
    promotions: [],
    departments: [],
    createdAt: '',
    updatedAt: '',
    createdBy: null,
  }));

const LeadsPopup = (props: Props) => {
  const { openModal, onClose, api, title, mutate } = props;
  const { t } = useTranslation('common');

  const { itemsToDisplay, setPage, itemsPerPage, page, loadingItemsToDisplay } =
    useListingCollection<TemplateApi>({
      basedUrl: api,
      currentPage: 'leads',
    });

  const columns = useLeadsListColumns({
    isLoading: loadingItemsToDisplay,
    mutate,
  });

  const totalCount = itemsToDisplay?.['hydra:totalItems'] || 0;
  const results = itemsToDisplay?.['hydra:member'] || [];
  const rows = loadingItemsToDisplay ? skeletonItems : results;

  return (
    <Modal
      open={openModal}
      onClose={onClose}
      classes={{ root: 'grid place-items-center' }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Fade in={openModal}>
          <Box
            sx={{
              maxHeight: '92vh',
              overflowY: 'auto',
              overflowX: 'hidden',
              width: {
                xs: '88%',
              },
              borderRadius: 2,
              bgcolor: '#FBFBFD',
              boxShadow: 24,
              '&::-webkit-scrollbar': {
                width: '10px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#BBC0C4',
                borderRadius: 8,
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#888',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1',
              },
            }}
          >
            <div
              className={'p-6 flex flex-col h-[92vh] min-h-[500px] relative'}
            >
              <div className="flex justify-between !items-start w-full h-[7%]">
                <p className={'mb-8 text-lg md:text-2xl'}>{t(title)} </p>
              </div>
              <button
                onClick={onClose}
                className={
                  'absolute right-6 top-7 opacity-60 transition-opacity duration-300 hover:opacity-80'
                }
              >
                <CloseIcon />
              </button>
              <div className={'flex-grow min-h-[500px]'}>
                <GridTableView
                  isGridView={false}
                  gridElement={
                    <GridView
                      leadsItems={results}
                      isLoading={loadingItemsToDisplay}
                      isLeads
                      page={page - 1}
                      rowCount={totalCount}
                      handleChangePage={(
                        event: React.MouseEvent | null,
                        page: number
                      ) => {
                        setPage(page + 1);
                      }}
                      rowsPerPage={itemsPerPage}
                      handleChangeRowsPerPage={(page: number) => {
                        setPage(page + 1);
                      }}
                    />
                  }
                  tableElement={
                    <Box className="w-full h-full flex-grow">
                      <TableGridX
                        sx={{
                          '&': {
                            minHeight: '500px',
                          },
                        }}
                        key={String(loadingItemsToDisplay)}
                        disableSelectionOnClick={true}
                        disableColumnSelector={true}
                        rows={rows}
                        // @ts-ignore
                        columns={columns}
                        autoPageSize
                        pageSize={itemsPerPage}
                        page={page - 1}
                        paginationMode="server"
                        rowCount={totalCount}
                        onPageChange={(page: number) => {
                          setPage(page + 1);
                        }}
                      />
                    </Box>
                  }
                />
              </div>
            </div>
          </Box>
        </Fade>
      </ClickAwayListener>
    </Modal>
  );
};

export default LeadsPopup;
