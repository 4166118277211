import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import {
  getEmailContext,
  listButton,
} from '../../../properties/propertiesListing/viewSentEmails';
import { useTranslation } from 'react-i18next';
import { http } from '../../../../libs/axios';
import { ContactTapInnerProps } from './search-criteria-tab';
import { toast } from 'react-toastify';
import { formatDateAndTime } from '../../../../helpers/utils';
import useLocale from '../../../../hooks/useLocale';
import PermissionIcon from '../../../../features/icons/permissionIcon';
import { Link } from 'react-router-dom';
import { ApiListing, IMedia } from '../../../../types/api';
import { Translations } from '../../../../types/translations';
import classNames from 'classnames';

interface Property {
  id: number;
  createdAt: string;
  updatedAt: string;
  reference: string;
  agencyReference: string;
  translations: Translations;
  media:
    | {
        images: IMedia[];
        pdf: IMedia[];
      }
    | [];
}

interface SentBy {
  id: number;
  firstname: string;
  lastname: string;
  roles: string[];
  isSuperAdmin: boolean;
}

interface EmailData {
  id: number;
  emailContext: number;
  properties: Property[];
  sentAt: string;
  sentBy: SentBy;
}

const Communication = (props: ContactTapInnerProps) => {
  const { id } = props;
  const { locale } = useLocale();
  const { t } = useTranslation('propertiesPage');
  const [rows, setRows] = useState<EmailData[]>([]);
  const [page, setPage] = useState(1);
  const [apiParams, setApiParams] = useState<string>('');
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res = await http.get<ApiListing<EmailData>>(
          `/contacts/${id}/sent-emails?${apiParams}&page=${page}&itemsPerPage=${5}`
        );
        const dataWithIds = res.data['hydra:member'].map(
          (row: EmailData, index: number) => ({
            ...row,
            id: index,
          })
        );
        setRows(dataWithIds);
        setTotalRows(res.data['hydra:totalItems']);
      } catch (error) {
        toast.error(`${t('something_wrong_try_again_later')}`, {
          position: 'bottom-center',
          autoClose: 3000,
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [apiParams, page]);

  useEffect(() => {
    const newApiParams = activeIndex ? `emailContext=${activeIndex}` : '';
    if (newApiParams !== apiParams) {
      setApiParams(newApiParams);
    }
  }, [activeIndex]);

  const findImagesProperty = (item: EmailData): string | undefined => {
    if (Array.isArray(item?.properties[0]?.media)) {
      return undefined;
    }

    const images = item?.properties[0]?.media.images;

    if (images && images.length > 0) {
      const mainImage = images.find((elem: IMedia) => elem.isMain);
      return mainImage ? mainImage.url.thumbnail : images[0]?.url.thumbnail;
    }

    return undefined;
  };

  const Columns: GridColDef<EmailData>[] = [
    {
      field: 'Reference',
      headerClassName: '!pl-3',
      cellClassName: '!pl-3 !pt-1',
      width: 380,
      sortable: false,
      renderHeader: () => (
        <Box className="leading-5 flex gap-2 Inter font-medium text-sm pl-2 py-5 text-[#030814]">
          <span>{t('common:Property_title')}</span>
          <span>/</span>

          <span>{t('common:Reference')} </span>
        </Box>
      ),
      renderCell: ({ row }) => {
        return (
          <div className="w-full flex flex-wrap h-full">
            <div className="w-[30%] h-full">
              <Link
                to={`/properties/${row?.properties[0]?.id}`}
                id={`img_property_${row?.properties[0]?.id}`}
                className="block h-full w-[85%]"
              >
                <img
                  src={findImagesProperty(row) || '/img/default_image.jpg'}
                  alt=""
                  className="!object-cover max-w-inherit"
                />
              </Link>
            </div>
            <div className="w-[70%] flex flex-col justify-around">
              <Tooltip
                title={row.properties[0]?.translations?.[locale]?.title}
                arrow
                placement="top"
              >
                <span className="block truncate">
                  {row.properties[0]?.translations?.[locale]?.title}
                </span>
              </Tooltip>

              {row.properties[0]?.agencyReference ? (
                <Tooltip
                  title={`${t('Reference_Agency')}: ${
                    row.properties[0]?.agencyReference
                  }`}
                  arrow
                  placement="top"
                >
                  <div
                    className="flex gap-1 py-1 px-1 rounded-md items-center min-w-[135px] max-w-[135px]"
                    style={{ border: '1px solid #00bfff' }}
                  >
                    <PermissionIcon
                      className="w-[25px] col-span-1"
                      color="#00bfff"
                    />
                    <span className="text-[#00bfff] block truncate inter leading-4 font-normal text-sm">
                      {row.properties[0]?.agencyReference}
                    </span>
                  </div>
                </Tooltip>
              ) : (
                <div>
                  <div
                    className="flex gap-1 py-1 px-1 mb-[6px] rounded-md items-center min-w-[135px] max-w-[135px]"
                    style={{ border: '1px solid #3446B9 ' }}
                  >
                    <PermissionIcon className=" col-span-1" color="#3446B9" />{' '}
                    <span className="text-blue inter leading-4 font-normal text-sm">
                      {row.properties[0]?.reference}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: 'emailSentAt',
      headerName: 'Date',
      width: 250,
      sortable: false,
      renderCell: ({ row }) => {
        return <span>{formatDateAndTime(row.sentAt)}</span>;
      },
    },
    {
      field: 'generatedBy',
      headerName: 'Type',
      width: 250,
      sortable: false,
      renderCell: ({ row }) => {
        return <div>{t(getEmailContext(row.emailContext))}</div>;
      },
    },
    {
      field: 'sentBy',
      headerName: 'Sent_by',
      width: 210,
      sortable: false,
      headerClassName: 'hideRightSeparator',
      renderHeader: () => (
        <span className="leading-5 font-medium text-sm font-[Inter]">
          {t('common:Sent_by')}
        </span>
      ),
      renderCell: ({ row }) => {
        return (
          <div className="font-normal leading-4 text-sm font-[Inter]">
            {row.sentBy.firstname + ' ' + row.sentBy.lastname}
          </div>
        );
      },
    },
  ];
  return (
    <Box sx={{ width: '100%' }}>
      <div className="mb-8 flex gap-3">
        {listButton.map(({ label, id }) => (
          <div
            onClick={() => {
              setActiveIndex(id);
            }}
            className={classNames(
              'p-2 cursor-pointer  flex justify-center items-center h-[35px]  border border-#E6E8EC rounded-[4px] text-sm font-medium',
              {
                'bg-[#3446B9] text-white': activeIndex === id,
                'bg-gray-200': activeIndex !== id,
                'w-[60px]': id === 0,
              }
            )}
            key={id}
          >
            <p>{t(label)}</p>
          </div>
        ))}
      </div>
      <div>
        {isLoading ? (
          <div className="min-h-[300px]">
            <span className="flex justify-center items-center h-[250px]">
              <CircularProgress disableShrink />
            </span>
          </div>
        ) : (
          <DataGrid
            sx={{
              '& .hideRightSeparator > .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
            }}
            rows={rows}
            columns={Columns}
            autoHeight={true}
            loading={!rows}
            disableColumnMenu
            disableSelectionOnClick
            autoPageSize
            page={page - 1}
            paginationMode="server"
            rowCount={totalRows || 0}
            onPageChange={(page: number) => {
              setPage(page + 1);
            }}
            pageSize={5}
            rowsPerPageOptions={[5]}
            rowHeight={70}
          />
        )}
      </div>
    </Box>
  );
};

export default Communication;
