import { FC, useCallback, useMemo } from 'react';
import { KeyedMutator } from 'swr';
import { useTranslation } from 'react-i18next';

import {
  FieldValue,
  FieldValuePayload,
  Gateway,
  IBuildingBlock,
  IBuildingBlockApi,
  IContactOptionApi,
  IProperty,
  IPropertyApi,
  IPropertyBuyOrRent,
  IRoomBlock,
  IRoomBlockApi,
  PropertyStatuses,
  PropertyStepperStep,
} from '../../../types/property';
import {
  guaranteeArray,
  guaranteeObject,
  guaranteeString,
  guaranteeStringOrNulable,
} from '../../../helpers/utils/formatters';
import { addressIsEmpty } from '../utils';
import { ucfirst } from '../../../helpers/utils';
import { http } from '../../../libs/axios';
import { useIsRent } from '../../../hooks/useIsRent';

import TabbedStepper from '../../../components/stepper';
import { createDescriptionStep } from '../../../components/reusableSteps/description';
import PropertyGeneralInformationStep from './steps/generalInformation';
import PropertyDetailsStep from './steps/details';
import PropertyMediaStep from './steps/media';
import PropertyPublicationStep from './steps/publication';
import PropertyOwnerStep from './steps/owner';
import PropertyLeadsStep from './steps/leads';
import PropertyPricingStep from './steps/pricing';
import PropertyCommentsStep from './steps/comments';
import TransactionDetailsStep from './steps/transactionDetails';
import PropertyPipelinesStep from './steps/pipelines';
import PropertyVisibilityStep from './steps/visibility';
import ConveniencesStep from './steps/conveniences';

const mapStepNamesToComponents = {
  'General Information': PropertyGeneralInformationStep,
  Contacts: PropertyOwnerStep,
  'Property details': PropertyDetailsStep,
  Pricing: PropertyPricingStep,
  'Transaction details': TransactionDetailsStep,
  Conveniences: ConveniencesStep,
  Descriptions: createDescriptionStep({
    withAi: true,
  }),
  Media: PropertyMediaStep,
  Publication: PropertyPublicationStep,
  Leads: PropertyLeadsStep,
  Comments: PropertyCommentsStep,
  Pipelines: PropertyPipelinesStep,
  Visibility: PropertyVisibilityStep,
} as Record<string, FC<PropertyStepperStep>>;

const PropertyStepper: FC<{
  serverData?: IPropertyApi;
  mutate?: KeyedMutator<IPropertyApi>;
  isLoading?: boolean;
}> = ({ serverData, mutate }) => {
  const { t } = useTranslation('common');

  const handleSuccess = useCallback(
    async ({ tabTitle }: { tabTitle?: string }) => {
      if (serverData) {
        const what = tabTitle
          ? ucfirst(tabTitle)
          : t('propertiesPage:Property');
        const payload = {
          historicAction: t('alerts:has_been_updated', { what }),
        };
        await http.post(`/v1/properties/${serverData.id}/historics`, payload);
      }
    },
    [serverData, t]
  );

  const filteredSteps = useMemo(() => {
    const result = { ...mapStepNamesToComponents };
    const status = serverData?.status?.id?.toString();
    if (status !== PropertyStatuses.SOLD && status !== PropertyStatuses.RENT) {
      delete result['Transaction details'];
    }

    return result;
  }, [serverData]);

  const isRent = useIsRent(serverData);
  const processDiff = useCallback(
    async ({
      diff,
      defaultValues,
    }: {
      diff: Partial<IProperty>;
      defaultValues?: IProperty;
    }) => {
      const result = { ...diff };
      if (isRent && serverData?.pricingBuy) {
        result.pricingBuy = null;
      } else if (!isRent && serverData?.pricingRent) {
        result.pricingRent = null;
      }

      if (
        (result.address && addressIsEmpty(result.address)) ||
        (defaultValues?.address && addressIsEmpty(defaultValues.address))
      ) {
        result.address = null;
      }
      if (
        (result.replacementAddress &&
          addressIsEmpty(result.replacementAddress)) ||
        (defaultValues?.replacementAddress &&
          addressIsEmpty(defaultValues.replacementAddress))
      ) {
        result.replacementAddress = null;
      }

      if (result.dimensions) {
        await http.patch(`/v1/properties/${serverData?.id}/dimensions`, {
          dimensions: result.dimensions,
        });
        delete result.dimensions;
      }

      return { diff: result };
    },
    [isRent, serverData]
  );

  return (
    <TabbedStepper
      mapStepNamesToComponents={filteredSteps}
      serverData={serverData}
      mutate={mutate}
      singularName={t('Property')}
      apiPath="/v1/properties"
      clientPath="/properties"
      convertToClient={convertToClient}
      onSuccess={handleSuccess}
      processDiff={processDiff}
    />
  );
};

export default PropertyStepper;

function prepareContactOptions(
  options?: Record<string, IContactOptionApi> | IContactOptionApi[]
) {
  // return options;
  if (!options) return [];
  return guaranteeArray(options).map((o) => ({
    mainContact: o.mainContact,
    contact: String(o.contact.id),
  }));
}

function prepareRoomBlocksOptions(
  options?: Record<string, IRoomBlockApi> | IRoomBlockApi[]
) {
  // return options;
  if (!options) return [];
  return guaranteeArray(options).map((o) => ({
    type: o?.type?.id,
    buildingBlock: o?.buildingBlock?.id,
    id: String(o?.id),
    name: o?.name,
    surface: o?.surface,
  }));
}

function prepareBuildingBlocksOptions(
  options?: Record<string, IBuildingBlockApi> | IBuildingBlockApi[]
) {
  if (!options) return [];
  return guaranteeArray(options).map((o) => ({
    orientation: o?.orientation?.id,
    id: String(o?.id),
    name: o?.name,
    surface: o?.surface,
  }));
}

function prepareRentOrBuy(data?: IPropertyBuyOrRent) {
  if (!data) return undefined;
  const returnedValue = { ...data };

  if (
    data.propertyCommissionRepartition &&
    Array.isArray(data.propertyCommissionRepartition)
  ) {
    returnedValue.propertyCommissionRepartition =
      data.propertyCommissionRepartition.filter((el) => el.mainBroker);
    returnedValue.propertyCommissionRepartitionIntermediate =
      data.propertyCommissionRepartition.filter((el) => el.intermediateBroker);
  }

  return returnedValue;
}

const convertToClient = (serverData?: IPropertyApi): IProperty | undefined => {
  if (!serverData) return undefined;

  const {
    // general
    status,
    category,
    type,
    availability,
    availableFrom,
    availableTo,
    address,
    replacementAddress,
    conveniences,
    translations,
    landRegister,
    department,
    mainBroker,
    rateMainBroker,
    intermediateBroker,
    rateIntermediateBroker,

    // details
    orientations,
    position,
    state,
    sunlight,
    heatingType,
    energy,
    soundLevel,
    views,
    floor,
    floors,
    balcony,
    terrace,
    lift,
    pool,
    garden,
    rooms,
    bedrooms,
    bathrooms,
    livingArea,
    usefulArea,
    landSurface,
    terraceSurface,
    gardenSurface,
    addFromToLivingArea,
    addFromToSurfaceHabitableTotale,
    addFromToLandSurface,
    addFromToUsefulArea,
    useTildeForSurface,
    surfaceBalcon,
    surfaceVerandaJardinHiver,
    surfaceUtileSecondaire,
    surfacePPE,
    surfaceSousSol,
    surfaceNetteDePlancher,
    surfaceLoggia,
    surfaceNette,
    surfacePonderee,
    hauteurDuPlafond,
    surfaceBruteDePlancher,
    surfaceLocative,
    surfaceHabitableTotale,
    surfaceExterne,
    surfaceTotale,
    volume,
    surfaceCave,
    surfaceGaletas,
    garage,
    nbGarage,
    wc,
    parkingAvailable,
    parkingNumbers,
    parkingBoxesAvailable,
    parkingBoxesNumbers,
    originalPrice,
    displayedPrice,
    pricem2,
    parkingPrice,
    charges,
    // details / mandate
    mandateType,
    mandateStartDate,
    mandateEndDate,
    mandateRenewalDate,
    // pricing
    pricingBuy,
    pricingRent,
    // media
    videoLinks,
    links3d,
    publishWebsite,
    publishedLocation,
    newBuilding,
    publishGateways,
    mainBrokerHT,
    mainBrokerTTC,
    intermediateBrokerHT,
    intermediateBrokerTTC,
    owners,
    externals,
    promotion,
    buildingBlocks,
    roomBlocks,
    fieldValues,
    warningField,
    propertyBuy,
    propertyRent,
    visibility,
    networks,
    // eslint-disable-next-line
    historics,
    // eslint-disable-next-line
    createdAt,
    // eslint-disable-next-line
    updatedAt,
    ...rest
  } = serverData;

  const result: IProperty = {
    ...rest,

    // general
    status: guaranteeString(status?.id),
    warningField: {
      translations: Array.isArray(warningField?.translations)
        ? {}
        : warningField?.translations,
    },

    type: guaranteeString(type?.id),
    category: guaranteeString(category?.id),
    availability: guaranteeString(availability?.id),
    availableFrom: guaranteeString(availableFrom),
    availableTo: guaranteeString(availableTo),
    fieldValues: sanitizeFieldValuesFromServer(guaranteeArray(fieldValues)),
    landRegister,
    promotion: guaranteeStringOrNulable(promotion?.id),
    visibility,
    address,
    replacementAddress,
    mainBroker: guaranteeString(mainBroker?.id),
    rateMainBroker,
    mainBrokerHT,
    mainBrokerTTC,
    intermediateBroker: intermediateBroker
      ? String(intermediateBroker.id)
      : null,
    rateIntermediateBroker,
    intermediateBrokerHT,
    intermediateBrokerTTC,
    department: guaranteeString(department?.id),
    translations: guaranteeObject(translations),
    conveniences: [],
    // details
    orientations: orientations[0] ? [guaranteeString(orientations[0].id)] : [],
    position,
    state: Number(state?.id),
    sunlight: Number(sunlight?.id),
    heatingType: Number(heatingType?.id),
    energy: Number(energy?.id),
    soundLevel: Number(soundLevel?.id),
    views: views.map((v) => Number(v?.id)),
    floor,
    floors,
    balcony,
    terrace,
    lift,
    pool,
    garden,
    rooms,
    buildingBlocks: prepareBuildingBlocksOptions(
      buildingBlocks
    ) as IBuildingBlock[],
    roomBlocks: prepareRoomBlocksOptions(roomBlocks) as IRoomBlock[],
    bedrooms,
    bathrooms,
    livingArea,
    usefulArea,
    landSurface,
    terraceSurface,
    gardenSurface,
    wc,
    addFromToLivingArea,
    addFromToSurfaceHabitableTotale,
    addFromToLandSurface,
    addFromToUsefulArea,
    useTildeForSurface,
    surfaceBalcon,
    surfaceVerandaJardinHiver,
    surfaceUtileSecondaire,
    surfacePPE,
    surfaceSousSol,
    surfaceNetteDePlancher,
    surfaceLoggia,
    surfaceNette,
    surfacePonderee,
    hauteurDuPlafond,
    surfaceBruteDePlancher,
    surfaceLocative,
    surfaceHabitableTotale,
    surfaceExterne,
    surfaceTotale,
    volume,
    surfaceCave,
    surfaceGaletas,
    garage,
    nbGarage,
    parkingAvailable,
    parkingNumbers,
    parkingBoxesAvailable,
    parkingBoxesNumbers,
    originalPrice,
    displayedPrice,
    pricem2,
    parkingPrice,
    charges,
    // details / mandate
    mandateType: guaranteeString(mandateType?.id).length
      ? guaranteeString(mandateType?.id)
      : null,
    mandateStartDate,
    mandateEndDate,
    mandateRenewalDate,
    // pricing
    pricingBuy: {
      displayedPrice: pricingBuy?.displayedPrice,
      estimatedPrice: pricingBuy?.estimatedPrice,
      originalPrice: pricingBuy?.originalPrice,
      pricem2: pricingBuy?.pricem2,
      recommendedPrice: pricingBuy?.recommendedPrice,
      parkings: (pricingBuy?.parkings.length > 0
        ? pricingBuy?.parkings.map((elem) => {
            if (
              elem?.parkingIncluded ||
              elem?.parkingMandatory ||
              elem?.parkingType ||
              elem?.parkingPrice
            )
              return {
                parkingPrice: elem.parkingPrice,
                parkingIncluded: guaranteeString(elem?.parkingIncluded?.id)
                  .length
                  ? guaranteeString(elem?.parkingIncluded?.id)
                  : null,
                parkingMandatory: guaranteeString(elem?.parkingMandatory?.id)
                  .length
                  ? guaranteeString(elem?.parkingMandatory?.id)
                  : null,
                parkingType: guaranteeString(elem?.parkingType?.id).length
                  ? guaranteeString(elem?.parkingType?.id)
                  : null,
              };
          })
        : []) as {
        parkingPrice?: number | null;
        parkingIncluded?: string | null;
        parkingMandatory?: string | null;
        parkingType?: string | null;
      }[],

      // charges
      annualCharges: pricingBuy?.annualCharges,
      chargesIncluded: guaranteeString(pricingBuy?.chargesIncluded?.id).length
        ? guaranteeString(pricingBuy?.chargesIncluded?.id)
        : null,
      monthlyHeatingCharges: pricingBuy?.monthlyHeatingCharges,
      heatingAnnualChargesIncluded: guaranteeString(
        pricingBuy?.heatingAnnualChargesIncluded?.id
      ).length
        ? guaranteeString(pricingBuy?.heatingAnnualChargesIncluded?.id)
        : null,

      monthlyPPECharges: pricingBuy?.monthlyPPECharges,
      chargesm2Year: pricingBuy?.chargesm2Year,
      // general infos
      currency: guaranteeString(pricingBuy?.currency?.id).length
        ? guaranteeString(pricingBuy?.currency?.id)
        : null,
      propertyLiableToVAT: pricingBuy?.propertyLiableToVAT,
      parkingLiableToVAT: pricingBuy?.parkingLiableToVAT,
      income: pricingBuy?.income,
      regime: guaranteeString(pricingBuy?.regime?.id).length
        ? guaranteeString(pricingBuy?.regime?.id)
        : null,
      businessAsset: pricingBuy?.businessAsset,
      revenue: pricingBuy?.revenue,
      taxes: pricingBuy?.taxes,
      incomeTaxRates: pricingBuy?.incomeTaxRates,
      renovationFund: pricingBuy?.renovationFund,
      // publication
      hidePriceOnWebsite: pricingBuy?.hidePriceOnWebsite,
      hidePriceOnPortal: pricingBuy?.hidePriceOnPortal,
      hidePriceOnBrochure: pricingBuy?.hidePriceOnBrochure,
    },
    pricingRent: {
      displayedPrice: pricingRent?.displayedPrice,
      originalPrice: pricingRent?.originalPrice,
      pricem2: pricingRent?.pricem2,
      estimatedPrice: pricingRent?.estimatedPrice,
      recommendedPrice: pricingRent?.recommendedPrice,
      priceReduction: pricingRent?.priceReduction,
      frequency: guaranteeString(pricingRent?.frequency?.id).length
        ? guaranteeString(pricingRent?.frequency?.id)
        : null,
      moderatedPrice: pricingRent?.moderatedPrice,
      rentalGuaranteeDeposit: pricingRent?.rentalGuaranteeDeposit,
      // parking
      parkings: (pricingRent?.parkings.length > 0
        ? pricingRent?.parkings.map((elem) => {
            if (
              elem?.parkingIncluded ||
              elem?.parkingMandatory ||
              elem?.parkingType ||
              elem?.parkingPrice
            )
              return {
                parkingPrice: elem.parkingPrice,
                parkingIncluded: guaranteeString(elem?.parkingIncluded?.id)
                  ?.length
                  ? guaranteeString(elem?.parkingIncluded?.id)
                  : null,
                parkingMandatory: guaranteeString(elem?.parkingMandatory?.id)
                  ?.length
                  ? guaranteeString(elem?.parkingMandatory?.id)
                  : null,
                parkingType: guaranteeString(elem?.parkingType?.id)?.length
                  ? guaranteeString(elem?.parkingType?.id)
                  : null,
              };
          })
        : []) as {
        parkingPrice?: number | null;
        parkingIncluded?: string | null;
        parkingMandatory?: string | null;
        parkingType?: string | null;
      }[],

      // charges
      annualCharges: pricingRent?.annualCharges,
      chargesIncluded: guaranteeString(pricingRent?.chargesIncluded?.id).length
        ? guaranteeString(pricingRent?.chargesIncluded?.id)
        : null,
      monthlyHeatingCharges: pricingRent?.monthlyHeatingCharges,
      individualChargesIncluded: guaranteeString(
        pricingRent?.individualChargesIncluded?.id
      ).length
        ? guaranteeString(pricingRent?.individualChargesIncluded?.id)
        : null,

      maintenanceContract: guaranteeString(pricingRent?.maintenanceContract?.id)
        .length
        ? guaranteeString(pricingRent?.maintenanceContract?.id)
        : null,
      accessoryExpenses: pricingRent?.accessoryExpenses,
      monthlyCharges: pricingRent?.monthlyCharges,
      chargesm2Year: pricingRent?.chargesm2Year,
      // general infos
      currency: guaranteeString(pricingRent?.currency?.id).length
        ? guaranteeString(pricingRent?.currency?.id)
        : null,
      propertyLiableToVAT: pricingRent?.propertyLiableToVAT,
      parkingLiableToVAT: pricingRent?.parkingLiableToVAT,
      income: pricingRent?.income,

      serafe: pricingRent?.serafe,
      waterElectricity: pricingRent?.waterElectricity,
      telenetwork: pricingRent?.telenetwork,
      // publication
      hidePriceOnWebsite: pricingRent?.hidePriceOnWebsite,
      hidePriceOnPortal: pricingRent?.hidePriceOnPortal,
      hidePriceOnBrochure: pricingRent?.hidePriceOnBrochure,
    },
    // media
    videoLinks,
    links3d,
    // publication
    publishWebsite,
    publishedLocation: publishedLocation || {
      realWebsite: true,
      alternativeWebsite: false,
      realBrochure: true,
      alternativeBrochure: false,
      realPortals: true,
      alternativePortals: false,
    },
    newBuilding,
    publishGateways: [] as Gateway[],
    owners: prepareContactOptions(owners),
    externals: prepareContactOptions(externals),
    networks:
      networks && networks.length ? networks.map((item) => item.id) : [],
    dimensions: [],
  };

  if (propertyBuy) {
    result.propertyBuy = prepareRentOrBuy(propertyBuy);
  }

  if (propertyRent) {
    result.propertyRent = prepareRentOrBuy(propertyRent);
  }

  result.publishGateways = guaranteeArray(publishGateways).map((res) => {
    return {
      ...res,
      gateway: res.gateway.id,
      settingPortal: res.settingPortal?.id || null,
    };
  });

  if (conveniences) {
    for (const series of Object.values(conveniences)) {
      result.conveniences = result.conveniences.concat(
        series.map((choice) => String(choice.id))
      );
    }
  }

  return result;
};

function sanitizeFieldValuesFromServer(
  fieldValues: (FieldValue | undefined)[]
) {
  return fieldValues
    .filter((value) => value && value.dataField)
    .map((value) => ({
      ...value,
      dataField: String(value?.dataField.id),
    })) as FieldValuePayload[];
}
