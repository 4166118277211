import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Skeleton } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';

import { http } from '../../libs/axios';
import { useNotification } from '../../hooks/useNotification';
import { parseFileName } from '../../helpers/utils/downloadFile';
import ServerSideControlledSwitch from './serverSideControlledSwitch';
import { Document, DocumentDate } from '../../types/property';
import LazyPdfPreview from '../../components/common/lazyPdfPreview';
import CreatedUpdated from '../../components/tableGridX/createdUpdated';
import CreatedUpdatedHeader from '../../components/tableGridX/createdUpdatedHeader';

const PREVIEW_WIDTH = 120;

const A4_ASPECT = 210 / 297;

const PREVIEW_HEIGHT = PREVIEW_WIDTH / A4_ASPECT;

const CELL_PADDING = 10;

export const getFileName = (item: Document) => {
  return item.friendlyName || item.originalName || item.customName || item.name;
};

const getPreviewHeight = (previewWidth: number) => {
  return previewWidth / A4_ASPECT;
};

export const FilePreview = ({
  item,
  regeneratedId,
  previewWidth,
  isSmall,
}: {
  item: Document;
  regeneratedId?: number;
  previewWidth?: number;
  isSmall?: boolean;
}) => {
  const width = previewWidth || PREVIEW_WIDTH;

  return item.isBrochure ? (
    <>
      {item.name && (
        <Link to={item.name} target="_blank" aria-label="Download file">
          <LazyPdfPreview
            documentProps={{
              file: item.pattern === regeneratedId ? null : item.name,
            }}
            thumbnailProps={{
              width: width,
              height: getPreviewHeight(PREVIEW_WIDTH),
              className: 'pointer-events-none',
            }}
            skeletonHeight={PREVIEW_HEIGHT}
          />
        </Link>
      )}
    </>
  ) : (
    <div className={'overflow-hidden'}>
      <div
        className={classNames(
          'bg-[#F5F5F7] p-2 pb-4 rounded flex flex-col items-center justify-end gap-2 relative',
          {
            'h-[8rem] w-[6rem]': !isSmall,
            'h-[4rem] w-[40px]': isSmall,
          }
        )}
      >
        <PictureAsPdfOutlinedIcon
          sx={{
            height: isSmall ? '1.5rem' : '3rem',
            width: '100%',
            fill: '#9898a1',
          }}
        />
      </div>
    </div>
  );
};

export default function useColumns({
  isLoading,
  onMainChange,
  isPopup,
  regenerate,
  regeneratedId,
}: {
  isLoading: boolean;
  onMainChange?: () => unknown;
  isPopup?: boolean;
  regenerate?: (templateId: number) => Promise<unknown>;
  regeneratedId?: number;
}) {
  const { t } = useTranslation('propertiesPage');
  const { addNotification } = useNotification();
  const columns: GridColumns<Document> = [
    {
      field: 'name',
      headerName: t('common:Name'),
      flex: 1,
      renderCell: ({ row }) => (
        <Link
          className="first-letter:uppercase font-medium text-base leading-5 truncate"
          to={row.url ? row.url.original : row.name}
          target="_blank"
        >
          {parseFileName(getFileName(row))}
        </Link>
      ),
    },
    {
      field: 'type',
      headerName: t('type'),
      flex: 0,
      renderCell: ({ row }) => (
        <div>
          {
            row.isMergedPdf
              ? t('Merged')
              : row.isBrochure
              ? 'Brochure'
              : 'Document'
            /* no translation as both words are spelled the same in EN and FR */
          }
        </div>
      ),
    },
    {
      field: 'preview',
      headerName: t('preview'),
      width: PREVIEW_WIDTH + 2 * CELL_PADDING,
      renderCell: ({ row }) => (
        <>
          <div className={'p-2 w-full flex justify-center items-center'}>
            {row.pattern && row.pattern === regeneratedId ? (
              <Skeleton
                width="100%"
                height={PREVIEW_HEIGHT}
                variant="rectangular"
                animation="wave"
              />
            ) : (
              <FilePreview item={row} regeneratedId={regeneratedId} />
            )}
          </div>
        </>
      ),
    },
    {
      field: 'createdBy',
      headerName: t('brochureEditor:created_by'),
      renderCell: ({ row }) =>
        row?.createdBy?.avatar && (
          <img
            src={row.createdBy.avatar}
            alt="avatar"
            className="w-8 h-8 rounded-full"
          />
        ),
    },
    {
      field: 'createdAt',
      width: 200,
      renderHeader: () => <CreatedUpdatedHeader />,
      renderCell: ({ row }) => (
        <CreatedUpdated
          created={normalDate(row.createdAt)}
          updated={normalDate(row.updatedAt) || null}
        />
      ),
    },
    {
      field: 'regenerate',
      headerName: t('regenerate'),
      flex: 0,
      cellClassName: 'flex !justify-center',
      renderCell: ({ row }) => {
        return row.pattern && regenerate ? (
          <div className="flex justify-center">
            <IconButton
              type="button"
              onClick={() => regenerate(row.pattern as number)}
              disabled={row.pattern === regeneratedId}
              className={classNames({
                'animate-spin': row.pattern === regeneratedId,
              })}
            >
              <AutorenewIcon />
            </IconButton>
          </div>
        ) : null;
      },
    },
  ];

  if (!isPopup) {
    columns.push({
      field: 'isMain',
      headerName: t('main'),
      minWidth: 250,
      renderCell({ row }) {
        return (
          <>
            {row.isBrochure ? (
              <ServerSideControlledSwitch
                checked={row.isMain || false}
                onChange={async (checked) => {
                  try {
                    await markAsMain(row.id, checked);
                    addNotification(t('file_has_been_selected'), 'success');
                  } catch (error) {
                    addNotification(t('alerts:unknown_error'), 'error');
                  }
                }}
              />
            ) : null}
          </>
        );
      },
    });
  }

  async function markAsMain(id: number, isMain: boolean) {
    await http.patch(`/brochures/${id}`, { isMain });
    onMainChange?.();
  }

  const commonSettings = {
    sortable: false,
    disableColumnMenu: true,
  };

  return columns.map((column) => ({
    ...commonSettings,
    ...column,
    ...(isLoading
      ? {
          renderCell: () => (
            <Skeleton
              variant="text"
              animation="wave"
              width="100%"
              height={20}
            />
          ),
        }
      : {}),
  }));
}

function normalDate(d: DocumentDate | string | null | undefined) {
  return typeof d === 'object' ? d?.date : d;
}
