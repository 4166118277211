import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControl } from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import axios from 'axios';

import InputFormField from '../../../components/form/inputFormField';
import InputLayout from '../../../components/form/components/inputLayout';
import SelectFormField from '../../../components/form/selectFormField';
import SpinnerLoading from '../../../features/spinner/spinnerLoading';

import { useNotification } from '../../../hooks/useNotification';
import { useQuery } from '../../../hooks/useQuery';

import { ContactData } from './utils';

interface Props {
  reference?: string;
  promotionReference?: string;
  properties?: { label: string; value: string; id: string }[];
  contactData?: ContactData;
}

const transformValues = (contactData?: ContactData) => {
  if (contactData) {
    return {
      firstname: contactData.firstname || '',
      lastname: contactData.lastname || '',
      email: contactData.email || '',
      phone: contactData.phone || '',
      message: '',
      property: '',
    };
  }

  return {
    firstname: '',
    lastname: '',
    email: '',
    message: '',
    phone: '',
    property: '',
  };
};

function ContactForm(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    register,
    formState: { defaultValues },
    setValue,
    watch,
  } = useForm({
    defaultValues: transformValues(props.contactData),
  });
  const { addNotification } = useNotification();
  const { t } = useTranslation('usersPage');
  const query = useQuery();
  const locale = query.get('lang') || 'en';

  useEffect(() => {
    if (props.contactData) {
      reset({
        firstname: props.contactData.firstname,
        lastname: props.contactData.lastname,
        phone: props.contactData.phone,
        email: props.contactData.email,
      });
    }
  }, [props.contactData]);

  useEffect(() => {
    setValue('property', props.reference || '', {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [props.reference, setValue]);

  const onSubmit = async (data: any) => {
    const isPromotionPage = !!props.promotionReference;
    const isPropertySelected = !!data.property;

    let url = '';
    const body: Record<string, string> = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      message: data.message,
      phone: data.phone,
    };

    if (!isPromotionPage) {
      url = '/leads';
      body.reference = data.property;
    } else if (isPropertySelected) {
      url = '/leads';
      body.reference = data.property;
      body.promotionReference = props.promotionReference as string;
    } else {
      url = '/promotions/leads';
      body.reference = props.promotionReference as string;
    }

    setIsLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_HOST}${url}`, body);
      reset();
      setValue('property', props.reference || '', {
        shouldValidate: true,
        shouldDirty: true,
      });
      addNotification(
        t('common:Your_message_was_successfully_send_We_will_contact_you_soon')
      );
    } catch (error) {
      addNotification(
        t(
          'common:An_error_occurred_while_sending_your_message_Please_try_again_later'
        ),
        'error'
      );
    }
    setIsLoading(false);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={'w-full relative'}
      action=""
    >
      <div className={'grid xl:grid-cols-2 gap-4'}>
        {props.properties && (
          <div className="xl:col-span-2">
            <SelectFormField
              options={props.properties}
              label={t('common:property', { lng: locale })}
              {...register('property')}
              value={watch('property')}
            />
          </div>
        )}
        <InputFormField
          type="text"
          label={t('firstname', { lng: locale })}
          disabled={!!props.contactData && !!defaultValues?.firstname}
          {...register('firstname', { required: true })}
        />
        <InputFormField
          type="text"
          label={t('lastname', { lng: locale })}
          disabled={!!props.contactData && !!defaultValues?.lastname}
          {...register('lastname', { required: true })}
        />
        <InputFormField
          type="email"
          label={t('email', { lng: locale })}
          disabled={!!props.contactData && !!defaultValues?.email}
          {...register('email', { required: true })}
        />
        <InputFormField
          type="text"
          label={t('phone', { lng: locale })}
          disabled={!!props.contactData && !!defaultValues?.phone}
          {...register('phone', { required: true })}
        />
        <div className="xl:col-span-2">
          <InputLayout label={t('Message', { lng: locale })}>
            <Controller
              name={'message'}
              control={control}
              defaultValue={''}
              render={({ field: { value, ...restField } }) => (
                <FormControl
                  sx={{
                    border: '1px solid #c4c4c4',
                    borderRadius: '4px',
                    '&:hover': {
                      borderColor: '#1D1D1F',
                    },
                  }}
                >
                  <TextareaAutosize
                    minRows={4}
                    maxRows={6}
                    value={value}
                    placeholder={t('Enter_your_message_here', { lng: locale })}
                    className={
                      'MuiInputBase-input MuiOutlinedInput-input rounded p-4 comment_area'
                    }
                    {...restField}
                  />
                </FormControl>
              )}
            />
          </InputLayout>
        </div>
        <div className="xl:col-span-2">
          <button
            disabled={isLoading}
            type="submit"
            className="w-full text-center px-8 py-4 rounded-full bg-blue text-white transition-colors hover:bg-blue-900"
          >
            <span className="text-[14px] leading-none uppercase">
              {t('Send', { lng: locale })}
            </span>
          </button>
        </div>
      </div>
      {isLoading ? (
        <div className={'absolute inset-0 z-10'}>
          <SpinnerLoading />
        </div>
      ) : null}
    </form>
  );
}

export default ContactForm;
