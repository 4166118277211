import React from 'react';
import WidgetWrapper from './wrapper';
import PriceIcon from '../../../features/icons/priceIcon';
import { Link } from 'react-router-dom';
import { WidgetProps } from '../index';
import { IDashboardProperty } from '../../../types/user';
import { useTranslation } from 'react-i18next';
import { formatPriceWithCurrency } from '../utils/useOffersListColumns';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Tooltip } from '@mui/material';

const Item = (props: { item: IDashboardProperty }) => {
  const { item } = props;
  const isHigherThen768 = useMediaQuery('(min-width:768px)');
  return (
    <div
      className={`self-stretch pb-2 border-b border-gray-200 grid grid-cols-3 gap-4`}
    >
      <Link
        className={
          'grow shrink basis-0 text-blue-800 text-xs sm:text-sm font-medium leading-none'
        }
        to={isHigherThen768 ? `/properties/${item.id}` : '#'}
      >
        <Tooltip title={item.reference} arrow placement="top">
          <span className="block whitespace-nowrap lg:truncate">
            {item.reference}
          </span>
        </Tooltip>
      </Link>
      <div className="grow shrink basis-0 h-4 justify-start items-start gap-2 flex">
        <div className="w-4 h-4 relative opacity-30">
          <PriceIcon />
        </div>
        <Tooltip
          title={formatPriceWithCurrency(item.price, item.currency)}
          arrow
          placement="top"
        >
          <div className="grow shrink basis-0 text-zinc-900 text-xs sm:text-sm font-normal leading-none whitespace-nowrap lg:truncate">
            {formatPriceWithCurrency(item.price, item.currency)}
          </div>
        </Tooltip>
      </div>
      <Tooltip
        title={`${formatPriceWithCurrency(
          item.commission,
          item['commission-currency']
        )} (${
          item['commission-pourcentage'] ? item['commission-pourcentage'] : '-'
        }%)`}
        arrow
        placement="top"
      >
        <div className="grow shrink basis-0 text-zinc-900 text-xs sm:text-sm font-normal leading-none whitespace-nowrap lg:truncate">
          {formatPriceWithCurrency(
            item.commission,
            item['commission-currency']
          )}{' '}
          ({item['commission-pourcentage']}%)
        </div>
      </Tooltip>
    </div>
  );
};

const PropertiesSoldWidget: React.FC<WidgetProps> = (props) => {
  const { data } = props;
  const { t } = useTranslation('common');
  const isLowerThen520 = useMediaQuery('(max-width:520px)');
  return (
    <WidgetWrapper
      emptyData={!data['properties-sold'].length}
      title={'PropertiesSold'}
      linkText={''}
      link={null}
    >
      <div
        className={`flex-col justify-center items-start gap-8 inline-flex ${
          isLowerThen520 ? 'w-[420px]' : 'w-full'
        }`}
      >
        <div className="self-stretch flex-col justify-center items-start gap-2 flex">
          <div className="self-stretch pb-2 border-b border-gray-200 justify-start items-start gap-4 inline-flex">
            <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium leading-none">
              {t('Reference')}
            </div>
            <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium leading-none">
              {t('SoldPrice')}
            </div>
            <div className="grow shrink basis-0 h-4 justify-start items-start gap-2 flex">
              <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium leading-none">
                {t('Commission')}
              </div>
            </div>
          </div>
          {data['properties-sold']?.map((item) => (
            <Item key={item.reference} item={item} />
          ))}
        </div>
      </div>
    </WidgetWrapper>
  );
};

export default PropertiesSoldWidget;
