import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from 'react-i18next';

function BackLink({
  url,
  label = 'common:Back',
}: {
  url: string;
  label?: string;
}) {
  const { t } = useTranslation('common');
  return (
    <Link
      className={
        'mb-[10px] lg:mb-[30px] xl:mb-[40px] bg-white rounded lg:py-3 px-4 inline-flex items-center group back_btn '
      }
      to={url}
    >
      <span
        className={
          'opacity-50 mr-2 text-[12px] flex transition-all duration-300 group-hover:opacity-70 group-hover:-translate-x-0.5'
        }
      >
        <ArrowBackIosIcon width={'12px'} height={'12px'} fontSize="inherit" />
      </span>
      <span
        className={
          'opacity-50 transition-opacity duration-300 group-hover:opacity-70'
        }
      >
        {t(label)}
      </span>
    </Link>
  );
}

export default BackLink;
