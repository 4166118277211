import CropPortraitOutlinedIcon from '@mui/icons-material/CropPortraitOutlined';
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import SignpostOutlinedIcon from '@mui/icons-material/SignpostOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import AbcOutlinedIcon from '@mui/icons-material/AbcOutlined';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import FlagCircleOutlinedIcon from '@mui/icons-material/FlagCircleOutlined';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CollectionsIcon from '@mui/icons-material/Collections';
import ImageAspectRatio from '@mui/icons-material/ImageAspectRatio';

import {
  PreviewSubjectTypes,
  WidgetDetails,
} from '../../../types/brochureEditor';

import {
  horizontalAlign,
  verticalAlign,
  separator,
  textAlign,
  color,
  booleanControl,
  objectFit,
  imageSelector,
  choiceControl,
  textEditor,
  richTextEditor,
} from './controlsTypes';

import Text from '../components/widgets/independent/text';
import RichText from '../components/widgets/independent/richText';
import PageIndex from '../components/widgets/independent/pageIndex';

import Title from '../components/widgets/universal/title';
import Description from '../components/widgets/universal/description';
import Broker from '../components/widgets/universal/broker';
import Image from '../components/widgets/universal/image';
import Agency from '../components/widgets/universal/agency';
import Address from '../components/widgets/universal/address';
import Map from '../components/widgets/universal/map';
import AgencyLogo from '../components/widgets/universal/agencyLogo';

import MultipageGalleryWidget from '../components/widgets/universal/multipageGallery';
import MultipageDescriptionWidget from '../components/widgets/universal/multipageDescription';

import PropertyConveniencesWidget from '../components/widgets/property/conveniences';
import PropertyDetailsWidget from '../components/widgets/property/details';
import PropertyGeneralDataWidget from '../components/widgets/property/generalData';
import PropertyParkingWidget from '../components/widgets/property/parking';
import PropertyPriceWidget from '../components/widgets/property/price';
import PropertyRoomsWidget from '../components/widgets/property/rooms';
import PropertySurfacesWidget from '../components/widgets/property/surfaces';
import PropertyJustPriceWidget from '../components/widgets/property/justPrice';
import ImageWithPriceWidget from '../components/widgets/property/imageWithPrice';
import PropertyAreasWidget from '../components/widgets/property/areas';

import PromotionDetails from '../components/widgets/promotion/details';
import PromotionChildPropertiesWidget from '../components/widgets/promotion/childProperties';
import PromotionLogo from '../components/widgets/promotion/promotionLogo';
import CustomAttributesWidget, {
  SpecialValues,
  tagOptionsGetter,
} from '../components/widgets/property/customAttributes';

export const widgetTypes: Record<string, WidgetDetails> = {
  blank: {
    name: 'Blank',
    options: null,
    icon: CropPortraitOutlinedIcon,
    preview: {},
    placement: {
      layoutParts: true,
      pages: true,
    },
  },

  // independent

  pageIndex: {
    name: 'page_index',
    icon: LooksOneOutlinedIcon,
    options: {},
    preview: { independent: PageIndex },
    placement: {
      layoutParts: true,
      pages: false,
    },
  },
  text: {
    name: 'text',
    icon: AbcOutlinedIcon,
    options: {
      textAlign,
      color,
      separator,
      text: textEditor,
    },
    preview: { independent: Text },
    placement: {
      layoutParts: true,
      pages: true,
    },
  },
  richText: {
    name: 'rich_text',
    icon: WysiwygIcon,
    options: {
      content: richTextEditor,
    },
    preview: { independent: RichText },
    placement: {
      layoutParts: true,
      pages: true,
    },
  },

  // universal

  agencyLogo: {
    name: 'agency_logo',
    icon: FlagCircleOutlinedIcon,
    options: {},
    preview: { universal: AgencyLogo },
    placement: {
      layoutParts: true,
      pages: false,
    },
    // TODO: implement selection of agencies to pick logo?
    subjectType: PreviewSubjectTypes.PROPERTY,
  },
  title: {
    name: 'title',
    icon: TextFieldsOutlinedIcon,
    options: {
      textAlign,
      color,
      separator2: separator,
      withCity: booleanControl('propertiesPage:City'),
      withReference: booleanControl('common:Reference'),
    },
    preview: { universal: Title },
    placement: {
      layoutParts: false,
      pages: true,
    },
  },
  description: {
    name: 'description',
    icon: ArticleOutlinedIcon,
    options: {
      source: choiceControl('common:Source', [
        { label: 'propertiesPage:Description', value: 'main' },
        { label: 'promotionPage:second_description', value: 'second' },
      ]),
      separator,
      textAlign,
      color,
    },
    preview: { universal: Description },
    placement: {
      layoutParts: false,
      pages: true,
    },
  },
  image: {
    name: 'image',
    icon: ImageOutlinedIcon,
    options: {
      objectFit,
      separator,
      image: imageSelector,
    },
    preview: { universal: Image },
    placement: {
      layoutParts: false,
      pages: true,
    },
  },
  multipageGallery: {
    name: 'multipage_gallery',
    icon: CollectionsIcon,
    options: {
      objectFit,
    },
    preview: { universal: MultipageGalleryWidget },
    placement: {
      layoutParts: false,
      pages: false,
      repeatablePages: true,
    },
  },
  multipageDescription: {
    name: 'multipage_description',
    icon: ArticleOutlinedIcon,
    options: {
      source: choiceControl('common:Source', [
        { label: 'propertiesPage:Description', value: 'main' },
        { label: 'promotionPage:second_description', value: 'second' },
      ]),
      separator,
      textAlign,
      color,
    },
    preview: { universal: MultipageDescriptionWidget },
    placement: {
      layoutParts: false,
      pages: false,
      repeatablePages: true,
    },
  },
  address: {
    name: 'address',
    icon: SignpostOutlinedIcon,
    options: {
      horizontalAlign,
      verticalAlign,
      separator,
      withQr: booleanControl('qr_code'),
    },
    preview: { universal: Address },
    placement: {
      layoutParts: false,
      pages: true,
    },
  },
  map: {
    name: 'map',
    icon: RoomOutlinedIcon,
    options: {
      withQr: booleanControl('qr_code'),
      zoom: choiceControl(
        'zoom',
        [
          { label: '9', value: '9' },
          { label: '10', value: '10' },
          { label: '11', value: '11' },
          { label: '12', value: '12' },
          { label: '13', value: '13' },
          { label: '14', value: '14' },
          { label: '15', value: '15' },
          { label: '16', value: '16' },
          { label: '17', value: '17' },
        ],
        '13'
      ),
    },
    preview: { universal: Map },
    placement: {
      layoutParts: false,
      pages: true,
    },
  },
  broker: {
    name: 'broker_data',
    icon: PeopleAltOutlinedIcon,
    options: {
      horizontalAlign,
      verticalAlign,
      separator1: separator,
      withAvatar: booleanControl('avatar'),
      withEmail: booleanControl('email'),
      withPhone: booleanControl('phone'),
      separator2: separator,
      source: choiceControl('data_source', [
        { label: 'main_broker', value: 'main' },
        { label: 'intermediate_broker', value: 'intermediate' },
      ]),
    },
    preview: { universal: Broker },
    placement: {
      layoutParts: false,
      pages: true,
    },
  },
  agency: {
    name: 'agency_info',
    icon: LocalPoliceIcon,
    options: {
      horizontalAlign,
      verticalAlign,
      separator,
      withLogo: booleanControl('logo'),
      withService: booleanControl('service'),
      withAddress: booleanControl('address'),
      withEmail: booleanControl('email'),
      withPhone: booleanControl('phone'),
    },
    preview: { universal: Agency },
    placement: {
      layoutParts: false,
      pages: true,
    },
  },

  // property

  conveniences: {
    name: 'common:Conveniences',
    icon: ViewListOutlinedIcon,
    options: tableLayoutOptions(),
    preview: { property: PropertyConveniencesWidget },
    placement: {
      layoutParts: false,
      pages: true,
    },
    subjectType: PreviewSubjectTypes.PROPERTY,
  },
  propertyDetails: {
    name: 'common:property-details',
    icon: ViewListOutlinedIcon,
    options: tableLayoutOptions(),
    preview: { property: PropertyDetailsWidget },
    placement: {
      layoutParts: false,
      pages: true,
    },
    subjectType: PreviewSubjectTypes.PROPERTY,
  },
  generalData: {
    name: 'common:general-information',
    icon: ViewListOutlinedIcon,
    options: {
      withCategory: booleanControl('category'),
      withPropertyType: booleanControl('property_type'),
      withAvailability: booleanControl('availability'),
      ...tableLayoutOptions(),
    },
    preview: { property: PropertyGeneralDataWidget },
    placement: {
      layoutParts: false,
      pages: true,
    },
    subjectType: PreviewSubjectTypes.PROPERTY,
  },
  parking: {
    name: 'propertiesPage:Parking',
    icon: ViewListOutlinedIcon,
    options: tableLayoutOptions(),
    preview: { property: PropertyParkingWidget },
    placement: {
      layoutParts: false,
      pages: true,
    },
    subjectType: PreviewSubjectTypes.PROPERTY,
  },
  customAttributes: {
    name: 'propertiesPage:Custom_attributes',
    icon: ViewListOutlinedIcon,
    options: {
      tag: choiceControl(
        'propertiesPage:Custom_attributes_tags',
        tagOptionsGetter,
        SpecialValues.ALL
      ),
      separator2: separator,
      ...tableLayoutOptions(),
    },
    preview: { property: CustomAttributesWidget },
    placement: {
      layoutParts: false,
      pages: true,
    },
    subjectType: PreviewSubjectTypes.PROPERTY,
  },
  price: {
    name: 'propertiesPage:price',
    icon: ViewListOutlinedIcon,
    options: tableLayoutOptions(),
    preview: { property: PropertyPriceWidget },
    placement: {
      layoutParts: false,
      pages: true,
    },
    subjectType: PreviewSubjectTypes.PROPERTY,
  },
  justPrice: {
    name: 'just_price',
    icon: ViewListOutlinedIcon,
    options: {
      horizontalAlign,
      verticalAlign,
      separator,
      textAlign,
      color,
    },
    preview: { property: PropertyJustPriceWidget },
    placement: {
      layoutParts: false,
      pages: true,
    },
    subjectType: PreviewSubjectTypes.PROPERTY,
  },
  imageWithPrice: {
    name: 'image_with_price',
    icon: ImageOutlinedIcon,
    options: {
      objectFit,
      image: imageSelector,
      separator,
      horizontalAlign,
      color,
    },
    preview: { property: ImageWithPriceWidget },
    placement: {
      layoutParts: false,
      pages: true,
    },
    subjectType: PreviewSubjectTypes.PROPERTY,
  },
  rooms: {
    name: 'propertiesPage:rooms',
    icon: ViewListOutlinedIcon,
    options: tableLayoutOptions(),
    preview: { property: PropertyRoomsWidget },
    placement: {
      layoutParts: false,
      pages: true,
    },
    subjectType: PreviewSubjectTypes.PROPERTY,
  },
  surfaces: {
    name: 'surfaces',
    icon: ViewListOutlinedIcon,
    options: tableLayoutOptions(),
    preview: { property: PropertySurfacesWidget },
    placement: {
      layoutParts: false,
      pages: true,
    },
    subjectType: PreviewSubjectTypes.PROPERTY,
  },
  areas: {
    name: 'propertiesPage:areas',
    icon: ImageAspectRatio,
    options: {},
    preview: { property: PropertyAreasWidget },
    placement: {
      layoutParts: false,
      pages: false,
      repeatablePages: true,
    },
    subjectType: PreviewSubjectTypes.PROPERTY,
  },

  // promotion

  promotionDetails: {
    name: 'promotionPage:promotionDetails',
    icon: ViewListOutlinedIcon,
    options: tableLayoutOptions(),
    preview: { promotion: PromotionDetails },
    placement: {
      layoutParts: false,
      pages: true,
    },
    subjectType: PreviewSubjectTypes.PROMOTION,
  },
  childPropertiesList: {
    name: 'promotionPage:Properties',
    icon: ApartmentIcon,
    options: tableLayoutOptions(),
    preview: { promotion: PromotionChildPropertiesWidget },
    placement: {
      layoutParts: false,
      pages: true,
    },
    subjectType: PreviewSubjectTypes.PROMOTION,
  },
  promotionLogo: {
    name: 'promotion_logo',
    icon: FlagCircleOutlinedIcon,
    options: {},
    preview: { promotion: PromotionLogo },
    placement: {
      layoutParts: true,
      pages: false,
    },
    subjectType: PreviewSubjectTypes.PROMOTION,
  },
};

function tableLayoutOptions() {
  return {
    layout: choiceControl('Layout', [
      { label: 'single_column', value: 'onecolumn' },
      { label: 'two_columns', value: 'twocolumns' },
    ]),
    variant: choiceControl('Variant', [
      { label: 'smaller_text', value: 'withseparators' },
      { label: 'bigger_text', value: 'biggertext' },
    ]),
    separator,
    withSeparator: booleanControl('common:Separator', true),
  };
}
