import { useEffect, useState } from 'react';

import useLocale from '../../../../hooks/useLocale';

import {
  Page as PageType,
  PageTypes,
  REPEATABLE_AREA,
} from '../../../../types/brochureEditor';

import { useWidgetOptions } from '../../lib/hooks/usePagesEditor';
import { usePreviewSubject } from '../../lib/hooks/usePreviewSubject';
import { pickMultipagePropsGenerator } from '../../lib/pickMultipagePropsGenerator';

import SinglePage from './singlePage';

function Page({
  page,
  isEditMode = false,
  isFirst = false,
}: {
  page?: PageType | null;
  isEditMode?: boolean;
  isFirst?: boolean;
}) {
  const { previewSubject } = usePreviewSubject();
  // const previewSubject = null;
  const { locale } = useLocale();
  const [perPageProps, setPerPageProps] = useState<Record<string, unknown>[]>();

  const repeatableWidgetType = page?.widgets?.[REPEATABLE_AREA]?.type;
  const gen = pickMultipagePropsGenerator(repeatableWidgetType);
  const widgetOptions = useWidgetOptions({ previewSubject, page });

  useEffect(() => {
    if (gen) {
      const ctx = { page, locale, previewSubject, widgetOptions };
      gen(ctx).then(setPerPageProps);
    }
  }, []);

  if (page?.type === PageTypes.REPEATABLE) {
    if (!perPageProps) {
      // wait until perPageProps are asynchronously resolved
      return null;
    }

    if (perPageProps.length && isEditMode) {
      return <SinglePage page={page} isFirst={isFirst} multipageProps={{}} />;
    }

    return (
      <>
        {perPageProps?.map((props, index) => (
          <SinglePage
            key={index}
            page={page}
            isFirst={isFirst}
            multipageProps={props}
          />
        ))}
      </>
    );
  }

  return <SinglePage page={page} isFirst={isFirst} />;
}

export default Page;
