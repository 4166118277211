import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper } from '@mui/material';
import { AxiosError } from 'axios';
import { KeyedMutator } from 'swr/dist/types';

import {
  Pipeline,
  PipelineForm,
  PipelineProjectTypes,
  PipelineStatus,
  PipelineStepPayload,
  PipelineStepTypes,
} from '../../../../types/pipelines';
import { http } from '../../../../libs/axios';

import { useAuth } from '../../../../hooks/useAuth';
import { useProtectedForm } from '../../../../hooks/useProtectedForm';

import StepsFieldset from '../../../../components/stepper/stepsFieldset';
import PreviousButton from '../../../../components/stepper/previousButton';
import SaveButton from '../../../../components/stepper/saveButton';
import InputFormField from '../../../../components/form/inputFormField';
import SelectFormField from '../../../../components/form/selectFormField';

import EditSteps from './steps';
import {
  PipelineColumns,
  PipelineStepsStateItem,
  useStepsState,
} from './steps/useStepsState';

import ServicesSelect from '../servicesSelect';

function EditPipelineForm({
  pipeline,
  mutate,
}: {
  pipeline: Pipeline | null;
  mutate?: KeyedMutator<Pipeline>;
}) {
  const { t } = useTranslation('common');

  const projectTypes = useMemo(
    () => [
      {
        id: PipelineProjectTypes.PROPERTY,
        value: t('property'),
      },
      {
        id: PipelineProjectTypes.PROMOTION,
        value: t('Promotion'),
      },
    ],
    [t]
  );

  const { register, handleSubmit, getValues, setValue } =
    useProtectedForm<PipelineForm>(
      {
        defaultValues: useDefaultValues(pipeline),
      },
      pipeline
    );

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const isUsed = Number(pipeline?.usedOn);

  const onSubmit = useCallback(
    async (values: PipelineForm) => {
      setIsLoading(true);
      try {
        const payload: PipelineForm & {
          pipelineSteps?: PipelineStepPayload[];
        } = values;

        if (!isUsed) {
          const columns = useStepsState.getState().columns;
          validateColumns(columns);
          payload.pipelineSteps = prepareColumns(columns);
        }

        if (pipeline?.id) {
          const { data } = await http.patch<Pipeline>(
            `/pipelines/${pipeline?.id}`,
            payload
          );
          mutate?.(data);
        } else {
          const { data } = await http.post<Pipeline>('/pipelines', payload);
          if (data.id) {
            navigate(`/settings/pipelines/${data.id}`, {
              state: {
                forceHideConfirmationPopup: true,
              },
            });
          }
        }
        toast.success(
          t('alerts:has_been_saved', {
            what: t('pipeline', { count: 1 }),
          }) as string,
          {
            autoClose: 3000,
          }
        );
      } catch (e) {
        let msg: string;
        if (e instanceof ValidationError) {
          msg = t(e.message);
        } else if (e instanceof AxiosError) {
          msg = t('alerts:server_error');
        } else {
          msg = t('alerts:unknown_error');
        }
        toast.error(msg, {
          autoClose: 3000,
        });
      }
      setIsLoading(false);
    },
    [navigate, pipeline?.id, t, mutate, isUsed]
  );

  const submitButton = useRef<HTMLButtonElement>(null);
  const isPublished = pipeline?.status === PipelineStatus.PUBLISHED;

  const save = useCallback(() => {
    setValue('status', pipeline?.status || PipelineStatus.UNPUBLISHED);
    submitButton.current?.click();
  }, [setValue, pipeline?.status]);

  const toggleStatusAndSave = useCallback(() => {
    setValue(
      'status',
      isPublished ? PipelineStatus.UNPUBLISHED : PipelineStatus.PUBLISHED
    );
    submitButton.current?.click();
  }, [setValue, isPublished]);

  return (
    <form id="stepper_page" onSubmit={handleSubmit(onSubmit)}>
      <Paper className="p-16" elevation={0}>
        <div className="grid gap-7">
          <StepsFieldset title={t('general_settings')} detailsTopMargin={24}>
            <div className="grid gap-16 grid-cols-3 w-full col-span-2 pb-16 border-b border-gray-200">
              <InputFormField
                {...register('name')}
                label={t('Name')}
                isRequired
              />
              <SelectFormField
                {...register('projectType')}
                label={t('project_type')}
                isRequired
                options={projectTypes}
                defaultValue={getValues('projectType')}
              />
              <ServicesSelect register={register} getValues={getValues} />
            </div>
          </StepsFieldset>
          <StepsFieldset title={t('pipeline_steps')} detailsTopMargin={24}>
            <div className="col-span-2 pb-16 border-b border-gray-200">
              {isUsed ? (
                <p>{t('editing_steps_not_allowed_pipeline')}</p>
              ) : (
                <EditSteps defaultSteps={pipeline?.pipelineSteps} />
              )}
            </div>
          </StepsFieldset>
        </div>
        <div className="flex gap-4 justify-end pt-12">
          <PreviousButton
            type="button"
            icon={false}
            onClick={save}
            disabled={isLoading}
          >
            {t('usersPage:save')}
          </PreviousButton>
          <SaveButton
            type="button"
            onClick={toggleStatusAndSave}
            disabled={isLoading}
            text={`${t('usersPage:save')} & ${t(
              isPublished ? 'unpublish' : 'publish'
            )}`}
          ></SaveButton>
        </div>
      </Paper>

      <input type="hidden" {...register('status')} />
      <button disabled={isLoading} ref={submitButton} className="hidden">
        submit
      </button>
    </form>
  );
}

export default EditPipelineForm;

function useDefaultValues(pipeline: Pipeline | null): PipelineForm {
  const { user } = useAuth();
  if (pipeline) {
    return {
      name: pipeline.name,
      projectType: pipeline.projectType,
      serviceType: pipeline.serviceType?.id || null,
      status: pipeline.status,
      createdBy: user.id, //pipeline.createdBy?.id || null,
    };
  }
  return {
    name: '',
    projectType: null,
    serviceType: null,
    status: null,
    createdBy: user.id,
  };
}

function prepareColumns(
  columns: Record<PipelineStepTypes, PipelineStepsStateItem[]>
) {
  const result: PipelineStepPayload[] = [];
  for (const [column, stepsItems] of Object.entries(columns)) {
    let i = 0;
    for (const step of stepsItems) {
      const item: PipelineStepPayload = {
        name: step.name,
        color: step.color,
        isOfferStep: step.isOfferStep,
        isSentFiles: step.isSentFiles,
        type: column,
        sort: i++,
      };
      if (step.serverId) {
        item.id = step.serverId;
      }
      result.push(item);
    }
  }
  return result;
}

class ValidationError extends Error {}

function validateColumns(columns: PipelineColumns) {
  if (columns[PipelineStepTypes.ENTRANCE].length < 1) {
    throw new ValidationError('pipeline_should_have_an_entrance_step');
  }
  if (columns[PipelineStepTypes.ENTRANCE].length > 1) {
    throw new ValidationError('pipeline_should_have_only_one_entrance_step');
  }
  if (columns[PipelineStepTypes.CLOSING].length < 1) {
    throw new ValidationError('pipeline_should_have_a_closing_step');
  }
}
