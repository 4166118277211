import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  DateRange,
  DesktopDateRangePicker,
  MobileDateRangePicker,
  PickersShortcutsItem,
  PickersShortcutsProps,
} from '@mui/x-date-pickers-pro';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Chip, Divider, List, ListItem } from '@mui/material';
import { useEffect, useState } from 'react';
import useLocale from '../../../hooks/useLocale';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
dayjs.extend(utc);

type Props = {
  value: any;
  setValue: any;
};

const DASHBOARD_SHORTCUT_KEY = 'dashboardDateShortcut';

function DashboardDatePicker({ value, setValue }: Props) {
  const { t } = useTranslation('common');
  const { locale } = useLocale();
  const [selectedShortcut, setSelectedShortcut] = useState<string | null>('');
  useEffect(() => {
    if (locale === 'fr') {
      dayjs.locale('fr');
    } else {
      dayjs.locale('en');
    }
  }, [locale]);

  useEffect(() => {
    const storedShortcut = localStorage.getItem(DASHBOARD_SHORTCUT_KEY);

    if (storedShortcut) {
      const matchingShortcut = shortcutsItems.find(
        (shortcut) => shortcut.label === storedShortcut
      );

      if (matchingShortcut) {
        // @ts-ignore
        const newValue = matchingShortcut.getValue();

        // Set the new value in the state
        setValue(newValue);
        setSelectedShortcut(storedShortcut);
      }
    }
  }, []);

  const getDateRange = (
    amount: number,
    unit: 'day' | 'month' | 'year'
  ): [Dayjs, Dayjs] => {
    const today = dayjs();
    return [today.subtract(amount, unit), today];
  };

  const getWeekRange = (): [Dayjs, Dayjs] => {
    const today = dayjs();
    const prevWeek = today.subtract(7, 'day');
    return [prevWeek.startOf('week'), prevWeek.endOf('week')];
  };

  const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
      label: 'LastWeek',
      getValue: getWeekRange,
    },
    {
      label: 'Last7Days',
      getValue: () => getDateRange(7, 'day'),
    },
    {
      label: 'Last30Days',
      getValue: () => getDateRange(30, 'day'),
    },
    {
      label: 'Last6Months',
      getValue: () => getDateRange(6, 'month'),
    },
    {
      label: 'LastYear',
      getValue: () => getDateRange(1, 'year'),
    },
    {
      label: 'Reset',
      getValue: () => {
        const today = dayjs();
        const end = today.subtract(1, 'month');
        return [end, today];
      },
    },
  ];
  const isMobile = useMediaQuery('(max-width:1024px)');

  function CustomRangeShortcuts(
    props: PickersShortcutsProps<DateRange<Dayjs>>
  ) {
    const { items, onChange, isValid, changeImportance = 'accept' } = props;

    if (items == null || items.length === 0) {
      return null;
    }

    const resolvedItems = items.map((item) => {
      const newValue = item.getValue({ isValid });

      return {
        label: item.label,
        onClick: () => {
          // @ts-ignore
          onChange(newValue, changeImportance, item);
          const shortcutLabel = item.label;

          if (shortcutLabel === 'Reset') {
            localStorage.removeItem(DASHBOARD_SHORTCUT_KEY);
            setSelectedShortcut(null);
          } else {
            localStorage.setItem(DASHBOARD_SHORTCUT_KEY, shortcutLabel);
            setSelectedShortcut(shortcutLabel);
          }
        },
        disabled: !isValid(newValue),
      };
    });

    return (
      <Box
        sx={{
          gridRow: 1,
          gridColumn: 2,
        }}
      >
        <List
          dense
          className="grid grid-cols-2 lg:grid-cols-3"
          sx={(theme) => ({
            px: theme.spacing(4),
            my: theme.spacing(1),
            '& .MuiListItem-root': {
              pt: 0,
              pl: 0,
              pr: theme.spacing(1),
            },
          })}
        >
          {resolvedItems.map((item) => {
            return (
              <ListItem key={item.label}>
                <Chip
                  variant={
                    selectedShortcut === item.label ? 'outlined' : 'filled'
                  }
                  label={t(item.label)}
                  onClick={item.onClick}
                  disabled={item.disabled}
                />
              </ListItem>
            );
          })}
        </List>
        <Divider />
      </Box>
    );
  }

  return (
    <div className="w-[48%]">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
        {isMobile ? (
          <MobileDateRangePicker
            slots={{
              shortcuts: CustomRangeShortcuts,
              field: SingleInputDateRangeField,
            }}
            slotProps={{
              shortcuts: {
                items: shortcutsItems,
              },
              toolbar: {
                hidden: true,
              },
              actionBar: { actions: [] },
              textField: {
                InputProps: {
                  endAdornment: <KeyboardArrowDownIcon fontSize={'small'} />,
                },
              },
            }}
            format={'MMM DD'}
            disableFuture
            disableAutoMonthSwitching
            onChange={([newFrom, newTo]) => {
              if (newFrom && newTo) {
                setValue([newFrom, newTo]);
              }
            }}
            value={value}
            sx={{
              '& .MuiInputBase-input': {
                height: '40px',
                fontSize: '14px',
                padding: '0 1rem',
                cursor: 'pointer',
                pointerEvents: 'none',
              },
              '& .MuiInputBase-root.MuiOutlinedInput-root': {
                borderColor: '#E6E8EC !important',
                cursor: 'pointer',
                background: '#fff',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#E6E8EC !important',
              },
            }}
          />
        ) : (
          <DesktopDateRangePicker
            slotProps={{
              shortcuts: {
                items: shortcutsItems,
              },
              actionBar: { actions: [] },
              textField: {
                InputProps: {
                  endAdornment: <KeyboardArrowDownIcon fontSize={'small'} />,
                },
              },
            }}
            slots={{
              shortcuts: CustomRangeShortcuts,
              field: SingleInputDateRangeField,
            }}
            value={value}
            calendars={2}
            format={'MMM DD'}
            disableFuture
            onChange={(newValue) => {
              setValue(newValue);
              // if(newValue[0]) {
              //   localStorage.setItem('dashboardStartDate', JSON.stringify(dayjs(newValue[0]).format('YYYY-MM-DD')))
              // }
              // if(newValue[1]) {
              //   localStorage.setItem('dashboardEndDate', JSON.stringify(dayjs(newValue[1]).format('YYYY-MM-DD')))
              // }
            }}
            sx={{
              '& .MuiInputBase-input': {
                height: '40px',
                fontSize: '14px',
                padding: '0 1rem',
                cursor: 'pointer',
                pointerEvents: 'none',
              },
              '& .MuiInputBase-root.MuiOutlinedInput-root': {
                borderColor: '#E6E8EC !important',
                cursor: 'pointer',
                background: '#fff',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#E6E8EC !important',
              },
            }}
          />
        )}
      </LocalizationProvider>
    </div>
  );
}

export default DashboardDatePicker;
