import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { Box, Button, Fade, Modal } from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { PostAdd, ScheduleSend } from '@mui/icons-material';
import useSWR from 'swr';

import { Document } from '../../../types/property';
import PopupFormSuccess from '../../properties/propertiesListing/propertiesMatching/steps/success';
import PopupStepper from '../../../components/popupStepper';
import SendEmailForm, { EmailSettingsResponse } from '../sendEmail/form';
import { http } from '../../../libs/axios';
import FileSelection from '../fileSelection';
import ToggleSendingAutomation from './toggleAutomation';

enum Steps {
  SELECTION,
  PREVIEW,
}

const AutomatedSending = ({ propertyId }: { propertyId?: string }) => {
  const [openModal, setOpenModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const formRef = useRef<HTMLFormElement | null>(null);

  function onClose() {
    setOpenModal(false);
    setSuccessMessage('');
  }

  function handleEmailSent(messageHtml?: string) {
    setSuccessMessage(messageHtml || t('automation_preferences_updated'));
  }

  const { data: sendAutomationSettings } = useSWR<EmailSettingsResponse>(
    `/v1/properties/${propertyId}/auto-email`
  );
  const defaultValues = sendAutomationSettings
    ? {
        selectedTemplate:
          Number(sendAutomationSettings?.emailTemplate?.id) || null,
        selectedTemplateLang: sendAutomationSettings?.emailTemplateLang || null,
        greeting: sendAutomationSettings?.displayGreeting || false,
        text: sendAutomationSettings.text1 || '',
        secondText: sendAutomationSettings.text2 || '',
        subject: sendAutomationSettings.subject || '',
      }
    : undefined;
  useEffect(() => {
    if (sendAutomationSettings) {
      const brochures = sendAutomationSettings.brochures || [];
      const documents = sendAutomationSettings.documents || [];
      setSelection([
        ...brochures.map((b) => b.id),
        ...documents.map((d) => d.id),
      ]);
      setIsAutomationEnabled(sendAutomationSettings.isActive || false);
    }
  }, [sendAutomationSettings]);

  const [isAutomationEnabled, setIsAutomationEnabled] = useState(false);
  const [selection, setSelection] = useState<GridSelectionModel>([]);
  const { data: files, mutate } = useSWR<Document[]>(
    `/v1/properties/${propertyId}/documents?itemsPerPage=100`
  );
  const selectedItems = selection.map((id) =>
    files?.find((file) => file.id === id)
  );

  const { t } = useTranslation('propertiesPage');

  const content = successMessage ? (
    <PopupFormSuccess onClose={onClose}>
      <div dangerouslySetInnerHTML={{ __html: successMessage }}></div>
    </PopupFormSuccess>
  ) : (
    <PopupStepper
      onClose={onClose}
      disableNext={(step) =>
        step?.id === Steps.SELECTION && selection.length === 0
      }
      steps={[
        {
          id: Steps.SELECTION,
          Icon: PostAdd,
          title: t('Select_files'),
          body: (
            <div className="h-[calc(100vh-300px)] flex flex-col gap-4">
              <ToggleSendingAutomation
                propertyId={propertyId}
                checked={isAutomationEnabled}
                onChange={setIsAutomationEnabled}
              />
              <FileSelection
                files={files}
                mutate={mutate}
                rowSelectionModel={selection}
                onRowsSelectionHandler={setSelection}
                isPopup
              />
            </div>
          ),
        },
        {
          id: Steps.PREVIEW,
          Icon: MailOutlineIcon,
          title: t('Email_preview'),
          body: defaultValues && (
            <SendEmailForm
              items={selectedItems.filter(Boolean) as Document[]}
              onEmailSent={handleEmailSent}
              formRef={formRef}
              propertyId={propertyId}
              contact={null}
              sendOnServer={(body) =>
                http.patch(`/v1/properties/${propertyId}/auto-email`, {
                  autoEmail: { ...body, isActive: isAutomationEnabled },
                })
              }
              defaultValues={defaultValues}
              disableReceiverField
            />
          ),
          nextButton: () => {
            const onClick = () => {
              if (formRef && formRef.current) {
                formRef.current.requestSubmit();
              }
            };

            return (
              <div
                onClick={onClick}
                className="h-[40px] rounded flex items-center justify-center p-4 gap-y-2 bg-blue cursor-pointer gap-6 text-white text-[14px] transition-colors duration-100 drop-shadow hover:bg-[rgb(36,49,129)]"
              >
                <span>{t('common:Save')}</span>
                <ArrowForwardIosIcon
                  sx={{
                    width: '16px',
                    height: '16px',
                  }}
                  fontSize="small"
                />
              </div>
            );
          },
        },
      ]}
    />
  );

  return (
    <>
      <Button
        variant="outlined"
        className="flex justify-center items-center"
        sx={{
          height: '42px',
        }}
        onClick={() => setOpenModal(true)}
      >
        <Box className="flex justify-center items-center" gap="16px">
          <ScheduleSend fontSize="small" />
          <span className="font-medium">{t('send_documents_automation')}</span>
        </Box>
      </Button>

      <Modal
        open={openModal}
        onClose={onClose}
        classes={{ root: 'grid place-items-center' }}
      >
        <Fade in={openModal}>
          <Box
            sx={{
              maxHeight: '90vh',
              overflowY: 'auto',
              overflowX: 'hidden',
              width: {
                xs: successMessage ? '40%' : '90%',
              },
              borderRadius: 2,
              bgcolor: 'background.paper',
              boxShadow: 24,
            }}
          >
            {content}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AutomatedSending;
