import {
  FormEventHandler,
  MutableRefObject,
  ReactElement,
  Suspense,
  useState,
} from 'react';
import classNames from 'classnames';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import Input from '@mui/material/Input';
import { IconButton, MenuItem, Select, Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';

import { ISettingEmails } from '../../types/settings';
import useLocale from '../../hooks/useLocale';
import InputLayout from '../form/components/inputLayout';
import SpinnerLoading from '../../features/spinner/spinnerLoading';
import TemplateLanguageSelect from './templateLanguageSelect';
import WysiwygEditor from '../form/wysiwygEditor';

const SendEmailReusableForm = ({
  children,
  onSubmit,
  formId,
  formRef,
  isLoading,
  usersList,
  allNames,
  formMethods,
  disableReceiverField = false,
}: {
  children: ReactElement;
  onSubmit: FormEventHandler<HTMLFormElement>;
  formId: string;
  formRef: MutableRefObject<HTMLFormElement | null>;
  isLoading: boolean;
  usersList: string;
  allNames?: string;
  formMethods: UseFormReturn<EmailState>;
  disableReceiverField?: boolean;
}) => {
  const { control, register, setValue, getValues, watch } = formMethods;

  const selectedTemplate = watch('selectedTemplate');
  useChangeCallback(selectedTemplate, () => {
    setValue('selectedTemplateLang', null);
  });

  const { data: templates } = useSWR<ISettingEmails[]>(
    '/email_templates?isActive=true'
  );
  const template = templates?.find((el) => el.id === selectedTemplate);

  const selectedTemplateLang = watch('selectedTemplateLang');
  useChangeCallback(selectedTemplateLang, () => {
    syncWithSelectedTemplate();
  });

  function syncWithSelectedTemplate() {
    if (selectedTemplate && selectedTemplateLang) {
      setValue(
        'subject',
        template?.translations?.[selectedTemplateLang]?.subject || ''
      );
      setValue(
        'text',
        template?.translations?.[selectedTemplateLang]?.text1 || ''
      );
      setValue(
        'secondText',
        template?.translations?.[selectedTemplateLang]?.text2 || ''
      );
    }
  }

  const { t } = useTranslation('propertiesPage');
  const { locale } = useLocale();

  return (
    <form
      ref={formRef}
      className="relative flex-grow flex flex-col"
      onSubmit={onSubmit}
      id={formId}
    >
      {isLoading && (
        <div className="absolute inset-0 z-10">
          <SpinnerLoading />
        </div>
      )}

      <div className="flex flex-col">
        <div className="self-stretch px-8 py-2 border-b border-gray-200 justify-start items-start flex-col gap-2 inline-flex">
          {templates && templates.length ? (
            <div className="flex gap-4 items-end w-full">
              <div className="w-1/5 min-w-[10rem]">
                <InputLayout label={t('Select_email_template')}>
                  <Select
                    IconComponent={
                      selectedTemplate
                        ? () => (
                            <IconButton
                              className="mr-3"
                              onClick={() => setValue('selectedTemplate', null)}
                            >
                              <CloseIcon
                                sx={{
                                  width: '14px',
                                  height: '14px',
                                }}
                              />
                            </IconButton>
                          )
                        : undefined
                    }
                    className={classNames('h-12 w-full')}
                    defaultValue={getValues('selectedTemplate')}
                    {...register('selectedTemplate')}
                  >
                    {templates?.map((item, index: number) => (
                      <MenuItem
                        id={`${item.id}`}
                        key={index}
                        value={item?.id}
                        className="capitalize"
                      >
                        {item.translations?.[locale]?.title}
                      </MenuItem>
                    ))}
                  </Select>
                </InputLayout>
              </div>

              <Controller
                control={control}
                name="selectedTemplateLang"
                render={({ field }) => (
                  <TemplateLanguageSelect
                    selectedTemplate={selectedTemplate}
                    templates={templates}
                    selectedTemplateLang={field.value}
                    setSelectedTemplateLang={field.onChange}
                  />
                )}
              />
            </div>
          ) : null}

          <Controller
            control={control}
            name={'greeting'}
            render={({ field }) => (
              <Tooltip
                className={'text-center'}
                title={t(
                  'When_activated_each_of_your_emails_text_will_start_with_greeting_saved_for_each_contact'
                )}
                arrow
                placement="top-start"
              >
                <label
                  className={classNames(
                    'flex items-center ml-[-16px] cursor-pointer'
                  )}
                >
                  <Checkbox
                    className="private_checkbox"
                    {...field}
                    defaultChecked={field.value}
                  />
                  <span className={'text-[14px]'}>
                    {t('Activate_Greetings')}
                  </span>
                </label>
              </Tooltip>
            )}
          />
        </div>

        {!disableReceiverField && (
          <div className="self-stretch p-8 border-b border-gray-200 justify-start items-start gap-2 inline-flex">
            <div className="w-40 text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
              {t('To')}
            </div>
            <div className="grow shrink basis-0 text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
              <div>
                {usersList ? (
                  <p title={allNames}>{usersList}</p>
                ) : (
                  <ThreeDots />
                )}
              </div>
            </div>
          </div>
        )}

        <div className="self-stretch p-8 border-b border-gray-200 justify-start items-start gap-2 inline-flex">
          <div className="w-40 text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
            {t('Subject')}
          </div>
          <div className="grow shrink basis-0 text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
            <Input
              required
              {...register('subject')}
              sx={{
                '&': {
                  width: '100%',
                },
                '&:after, &:before': {
                  display: 'none',
                },
              }}
              placeholder={t('Click_to_add_subject')}
            />
          </div>
        </div>
        <div className="self-stretch p-8 border-b border-gray-200 justify-start items-start gap-2 inline-flex">
          <div className="w-40 text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
            {t('Text')}
          </div>
          <div className="grow shrink basis-0 text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
            <Controller
              name={'text'}
              control={control}
              defaultValue={getValues('text')}
              render={({ field: { value, onChange } }) => (
                <Suspense fallback={null}>
                  <WysiwygEditor value={value} onChange={onChange} />
                </Suspense>
              )}
            />
          </div>
        </div>

        <div className="px-8 flex flex-col divide-y">{children}</div>

        <div className="self-stretch p-8 border-b border-gray-200 justify-start items-start gap-2 inline-flex">
          <div className="w-40 text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
            {t('Another_text')}
          </div>
          <div className="grow shrink basis-0 text-zinc-900 text-base font-normal font-['Inter'] leading-tight">
            <Controller
              name={'secondText'}
              control={control}
              defaultValue={getValues('text')}
              render={({ field: { value, onChange } }) => (
                <Suspense fallback={null}>
                  <WysiwygEditor value={value} onChange={onChange} />
                </Suspense>
              )}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default SendEmailReusableForm;

export interface EmailState {
  selectedTemplate: number | null;
  selectedTemplateLang: string | null;
  greeting: boolean;
  subject: string;
  text: string;
  secondText: string;
}

// Runs the callback only when some value is changed.
// Unlike useEffect(callback, [value]), it will not run the callback on first render
function useChangeCallback<T>(value: T, callback: () => unknown) {
  const [prevValue, setPrevValue] = useState<T>(value);
  if (prevValue !== value) {
    setPrevValue(value);
    callback();
  }
}

function ThreeDots() {
  return (
    <div className="flex gap-0.5 justify-start items-center">
      <div className="h-1 w-1 bg-blue rounded-full animate-bounce [animation-delay:-0.3s]"></div>
      <div className="h-1 w-1 bg-blue rounded-full animate-bounce [animation-delay:-0.15s]"></div>
      <div className="h-1 w-1 bg-blue rounded-full animate-bounce"></div>
    </div>
  );
}
