import { IPropertyApi, PropertyListingItem } from '../../../types/property';
import { IPromotionListItem } from '../../../types/promotion';
import PermissionIcon from '../../../features/icons/permissionIcon';
import useLocale from '../../../hooks/useLocale';
import { abbreviate, renderPropertyAdress } from '../../../helpers/utils';
import HomeIcon from '../../../features/icons/homeIcon';
import { Rating, Tooltip } from '@mui/material';
import BedroomIcon from '../../../features/icons/bedroomIcon';
import SquareIcon from '../../../features/icons/squareIcon';
import MountainIcon from '../../../features/icons/mountainIcon';
import { Link } from 'react-router-dom';
import PropertyContactOptimized from '../../tableGridX/propertyContactOptimized';
import { useState } from 'react';
import PropertiesInfoModal from '../../../pages/properties/propertiesListing/propertiesReferenceModal/modal';
import PropertiesListingActionMenu from '../../tableGridX/propertiesListingActionMenu';
import WarningIcon from '../../../features/icons/warningIcon';
import { CustomWidthTooltip } from '../../../pages/properties/propertiesListing/propertiesReferenceModal';
import classNames from 'classnames';
import PromotionsListingActionMenu from '../../tableGridX/promotionsListingActionMenu';
import { useIsRent } from '../../../hooks/useIsRent';
import { useTranslation } from 'react-i18next';
import Star2Icon from '../../../features/icons/star2Icon';
import Status from './status';
import CardGallery from './elements/gallery';
import EditCardIcon from '../../../features/icons/editCardIcon';
import MountIcon from '../../../features/icons/mountIcon';
import NetworksPropertiesListingActionMenu from '../../tableGridX/propertiesListingActionMenu/networks';
import renderShortPropertyAdress from './elements/shortAddress';
import MLSNetworkIcon from '../../../features/icons/MLSNetworkIcon';
import { getSingleOptionByLanguage } from '../../../const/propertiesOptions';
import TabGridTag from '../../../features/tags/tabGridTag';
import { IMercureMatchingTime } from '../../../types/matching';
import { MatchingIndicator } from './elements/matchingIndicator';
import { formatPriceWithCurrency } from '../../../pages/index/utils/useOffersListColumns';
import EmailAutomationIndicator from './elements/emailAutomationIndicator';

interface Props {
  item: PropertyListingItem | IPromotionListItem;
  isPromotion?: boolean;
  isNetworks?: boolean;
  isActiveProperties?: boolean;
  matchingRemainingTime?: IMercureMatchingTime | null;
}

export const PropertyReplacementName = ({
  propertyItem,
  locale,
}: {
  propertyItem: PropertyListingItem | IPropertyApi;
  locale: string;
}) => {
  const { t } = useTranslation('propertiesPage');

  return (
    <>
      {propertyItem?.type?.translations?.[locale]?.value}
      {propertyItem?.rooms
        ? `${
            propertyItem?.rooms >= 2
              ? ' ' + propertyItem.rooms + ` ${t('rooms')}`
              : ' ' + propertyItem.rooms + ` ${t('room')}`
          }`
        : ''}
      {propertyItem?.address?.translations?.[locale]?.city &&
        ', ' + propertyItem?.address?.translations?.[locale]?.city}
    </>
  );
};

const Card = (props: Props) => {
  const {
    item,
    isPromotion,
    isNetworks = false,
    matchingRemainingTime,
    isActiveProperties,
  } = props;
  const { locale } = useLocale();
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation('propertiesPage');

  const warningTranslations =
    item && 'warningField' in item && item.warningField
      ? Array.isArray(item?.warningField?.translations)
        ? {}
        : item?.warningField?.translations
      : null;

  const isRent = useIsRent(
    !isPromotion ? (item as PropertyListingItem) : undefined
  );

  let pricing = null;
  if (isPromotion) {
    const promoItem = item as IPromotionListItem;
    pricing = (
      <div
        className={
          'self-stretch text-zinc-900 text-[22px] font-normal leading-7'
        }
      >
        {promoItem.propertiesMinPrice && (
          <Tooltip title={t('Min_Price')} arrow placement="top">
            <span>
              {formatPriceWithCurrency(
                promoItem.propertiesMinPrice,
                promoItem?.propertiesCurrency ?? ''
              )}{' '}
              {' - '}
            </span>
          </Tooltip>
        )}
        {promoItem.propertiesMaxPrice && (
          <Tooltip title={t('Max_Price')} arrow placement="top">
            <span>
              {formatPriceWithCurrency(
                promoItem.propertiesMaxPrice,
                promoItem?.propertiesCurrency ?? ''
              )}
            </span>
          </Tooltip>
        )}
      </div>
    );
  } else {
    const propertyItem = item as PropertyListingItem;
    pricing = (
      <>
        {!isNetworks &&
          (propertyItem?.publishWebsite?.publishWebsite ||
            (propertyItem?.publishGateways &&
              propertyItem?.publishGateways.length > 0)) && (
            <Tooltip
              title={
                propertyItem?.publishWebsite?.publishWebsite &&
                propertyItem?.publishGateways.length > 0
                  ? t('common:Published_on_websites_and_Published_on_gateways')
                  : propertyItem?.publishWebsite?.publishWebsite
                  ? t('common:Publish_on_websites')
                  : t('common:Publish_on_gateways')
              }
              arrow
              placement="top"
            >
              <div className="w-[10px] h-[10px] rounded-full bg-green-500"></div>
            </Tooltip>
          )}
        <div className="self-stretch text-zinc-900 text-2xl font-normal leading-7">
          {isNetworks && 'pricing' in propertyItem ? (
            <>
              {formatPriceWithCurrency(
                propertyItem.pricing,
                propertyItem?.currency ?? ''
              )}
            </>
          ) : (
            <>
              {isRent
                ? formatPriceWithCurrency(
                    propertyItem.pricingRent?.displayedPrice,
                    propertyItem?.pricingRent?.currency?.value
                  )
                : formatPriceWithCurrency(
                    propertyItem.pricingBuy?.displayedPrice,
                    propertyItem?.pricingBuy?.currency?.value
                  )}
            </>
          )}
        </div>
      </>
    );
  }
  const propertyItem = item as PropertyListingItem;
  const fullServiceName = propertyItem?.department
    ? getSingleOptionByLanguage(propertyItem.department.service)
    : '';
  const splitServiceName = fullServiceName?.split(' ');
  const shortServiceName =
    splitServiceName?.length > 1
      ? abbreviate(splitServiceName)
      : fullServiceName;

  const property = item as PropertyListingItem;

  return (
    <div className="@container p-2 bg-white rounded flex flex-col justify-between">
      <div
        className={classNames('', {
          'flex flex-col flex-grow': !isPromotion,
        })}
      >
        <div className={'h-64 relative'}>
          <div className="z-10 absolute left-2 top-2 justify-start items-start gap-2 flex flex-col">
            {isPromotion ? (
              <div className="px-2 py-1 bg-white rounded justify-start items-center gap-1 flex">
                <PermissionIcon color="#3446B9" />
                <div className="text-blue-800 text-sm font-normal leading-none">
                  {item.reference}
                </div>
              </div>
            ) : (
              <Tooltip
                title={
                  propertyItem.agencyReference
                    ? t('Reference_Agency')
                    : t('Reference')
                }
                arrow
                placement="top"
              >
                <div
                  onClick={() => setOpenModal(true)}
                  className=" cursor-pointer px-2 py-1 bg-white rounded justify-start items-center gap-1 flex"
                >
                  <PermissionIcon color="#3446B9" />
                  <div className="text-blue-800 text-sm font-normal leading-none">
                    {propertyItem.agencyReference
                      ? propertyItem.agencyReference
                      : item.reference}
                  </div>
                </div>
              </Tooltip>
            )}

            {'type' in item && item.type && (
              <div className="px-2 py-1 bg-neutral-100 rounded justify-start items-center gap-1 flex">
                <div className="text-zinc-900 text-sm font-normal leading-none capitalize">
                  {item.type?.translations &&
                  item.type?.translations[locale]?.value !== undefined
                    ? `${item.type?.translations[locale]?.value}`
                    : item.type.keyname}
                </div>
              </div>
            )}
          </div>
          <>
            <div
              className={
                'z-10 flex flex-col absolute right-2 top-2 items-end gap-2'
              }
            >
              {isNetworks ? (
                <>
                  {'invitations' in item &&
                  item.invitations &&
                  item.invitations?.length &&
                  item.invitations?.filter((item) => item.status === 2)
                    .length ? (
                    <div className="z-10 px-2 py-1 bg-neutral-100 rounded flex justify-center items-center mb-auto w-fit self-end">
                      <CustomWidthTooltip
                        // @ts-ignore
                        title={
                          <span>
                            {t('Shared_from')}:{' '}
                            {item.invitations
                              ?.filter((item) => item.status === 2)
                              .map(
                                (item) =>
                                  item.network?.translations?.[locale]?.name
                              )
                              .join(',')}
                          </span>
                        }
                        arrow
                        placement="top"
                      >
                        <span>
                          <MLSNetworkIcon
                            width={'18'}
                            height={'18'}
                            color={'currentColor'}
                          />
                        </span>
                      </CustomWidthTooltip>
                    </div>
                  ) : null}
                </>
              ) : (
                <div className="px-2 py-1 bg-neutral-100 rounded">
                  <div className="text-green-500 text-sm font-normal leading-none capitalize">
                    {item?.status?.translations[locale]?.value}
                  </div>
                </div>
              )}
            </div>

            <div className="absolute z-10 bottom-2.5 left-2.5 flex gap-2">
              <MatchingIndicator
                matchingRemainingTime={
                  matchingRemainingTime as IMercureMatchingTime | null
                }
                item={item}
                isPromotion={isPromotion}
              />
              {property.autoEmail?.isActive && (
                <EmailAutomationIndicator className="w-[26px] h-[26px]" />
              )}
            </div>

            <div className="flex gap-2 flex-col absolute bottom-[10px] right-[10px] h-[80%] justify-end z-10">
              {isNetworks ? (
                <div className=" bg-neutral-100 max-h-[28px] max-w-[29px]">
                  {'invitations' in item && item.invitations?.length ? (
                    <NetworksPropertiesListingActionMenu
                      propertiesCards
                      row={item}
                    />
                  ) : null}
                </div>
              ) : (
                <>
                  {warningTranslations && warningTranslations[locale]?.value ? (
                    <div className="z-10 px-2 py-1 bg-neutral-100 rounded flex justify-center items-center mb-auto w-fit self-end">
                      <CustomWidthTooltip
                        // @ts-ignore
                        title={
                          <span>{warningTranslations[locale]?.value}</span>
                        }
                        arrow
                        placement="top"
                      >
                        <span>
                          <WarningIcon
                            width={'14'}
                            height={'14'}
                            color="#FFA500"
                          />
                        </span>
                      </CustomWidthTooltip>
                    </div>
                  ) : null}

                  {isPromotion ? (
                    <div className="z-20">
                      <Tooltip title="Edit" arrow placement="top">
                        <Link
                          className="hidden md:block"
                          to={`/${isPromotion ? 'promotions' : 'properties'}/${
                            item.id
                          }/edit${isPromotion ? '' : '/general-information'}`}
                        >
                          <div className="px-[6px] py-[6px] bg-neutral-100 rounded">
                            <div className="text-blue-800 text-sm font-normal leading-none capitalize">
                              <EditCardIcon width={'14'} height={'16'} />
                            </div>
                          </div>
                        </Link>
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="flex relative z-20 gap-1">
                      {isActiveProperties ? (
                        <Tooltip title="Edit" arrow placement="top">
                          <Link
                            className="hidden md:block"
                            to={`/${
                              isPromotion ? 'promotions' : 'properties'
                            }/${item.id}/edit${
                              isPromotion ? '' : '/general-information'
                            }`}
                          >
                            <div className="px-[6px] py-[6px] bg-neutral-100">
                              <div className="text-blue-800 text-sm font-normal leading-none capitalize">
                                <EditCardIcon width={'14'} height={'16'} />
                              </div>
                            </div>
                          </Link>
                        </Tooltip>
                      ) : null}
                      <span className=" bg-neutral-100 max-h-[28px] max-w-[29px]">
                        <PropertiesListingActionMenu
                          isActiveProperties={isActiveProperties}
                          propertiesCards={true}
                          row={item}
                        />
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
          <div className={'bg-gray-400 w-full h-full'}>
            <>
              {'media' in item && (
                <CardGallery
                  mainImage={item.mainImage}
                  items={item.media?.images}
                />
              )}
              {'images' in item && 'coverImage' in item && (
                <CardGallery coverImage={item.coverImage} items={item.images} />
              )}
            </>
          </div>
        </div>
        <div className="pt-4">
          {isPromotion ? (
            <Link
              to={`/${isPromotion ? 'promotions' : 'properties'}/${item.id}`}
            >
              <div className="cursor-pointer text-zinc-900 text-base font-normal leading-tight hover:opacity-80 duration-300 transition-opacity">
                {item?.translations?.[locale]?.title}
              </div>
            </Link>
          ) : (
            <div
              onClick={() => (isNetworks ? null : setOpenModal(true))}
              className={classNames(
                'text-zinc-900 text-base font-normal leading-tight',
                {
                  'cursor-pointer hover:opacity-80 duration-300 transition-opacity':
                    !isNetworks,
                }
              )}
            >
              {item?.translations?.[locale]?.title ? (
                <span>{item?.translations?.[locale]?.title}</span>
              ) : (
                <div className="leading-6">
                  <p className="inline-block">
                    <PropertyReplacementName
                      propertyItem={propertyItem as PropertyListingItem}
                      locale={locale}
                    />
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
        <div
          className={classNames('pt-4 flex flex-col', {
            'justify-end flex-grow': !isPromotion,
          })}
        >
          {isPromotion || isNetworks ? null : (
            <div className={'pb-2 flex items-center gap-3'}>
              {propertyItem.department && (
                <TabGridTag
                  textContent={shortServiceName}
                  textToolTip={fullServiceName}
                  className=" bg-blue font-bold border-0 capitalize h-[20px]"
                  color="white"
                  fontSize="10px"
                />
              )}

              <Rating
                name="rating"
                value={'rating' in item ? item.rating : null}
                max={3}
                readOnly
                icon={
                  <Star2Icon
                    width={'18'}
                    height={'18'}
                    className="mr-1"
                    color="#FFC839"
                  />
                }
                emptyIcon={
                  <Star2Icon
                    width={'18'}
                    height={'18'}
                    className="mr-1"
                    color="#E6E8EC"
                  />
                }
              />
            </div>
          )}

          <div className="flex items-center gap-2 pb-4">{pricing}</div>

          <div className={'divide-y flex flex-col border-b border-t'}>
            <div className="w-full text-neutral-500 text-sm py-1">
              <div className="-ml-2">
                {isNetworks
                  ? renderShortPropertyAdress(item.address, true)
                  : renderPropertyAdress(item.address, true)}
              </div>
            </div>
            {isNetworks || isPromotion ? null : (
              <div className="items-center gap-2 @xs:gap-4 flex flex-wrap py-2">
                {'livingArea' in item && item.livingArea ? (
                  <div className="basis-0 justify-start items-center gap-1.5 @xs:gap-2.5 flex">
                    <Tooltip title="Living area" arrow placement="top">
                      <div>
                        <SquareIcon color="#6E6E72" />
                      </div>
                    </Tooltip>
                    <div className="text-zinc-900 text-sm leading-none  whitespace-nowrap">
                      {(item.addFromToLivingArea
                        ? t('propertiesPage:from') + ' '
                        : '') +
                        (item.useTildeForSurface ? '~' : '') +
                        item.livingArea}{' '}
                      <span>
                        {' '}
                        m<sup>2</sup>
                      </span>
                    </div>
                  </div>
                ) : null}
                {'bedrooms' in item && item.bedrooms ? (
                  <div className="basis-0 justify-start items-center gap-1.5 @xs:gap-2.5 flex">
                    <Tooltip title="Bedrooms" arrow placement="top">
                      <div>
                        <BedroomIcon color="#6E6E72" />
                      </div>
                    </Tooltip>
                    <div className="text-zinc-900 text-sm leading-none  whitespace-nowrap">
                      {item.bedrooms}
                    </div>
                  </div>
                ) : null}
                {'landSurface' in item && item.landSurface ? (
                  <div className="basis-0 justify-start items-center gap-1.5 @xs:gap-2.5 flex">
                    <Tooltip title="Land surface" arrow placement="top">
                      <div>
                        <MountainIcon color="#6E6E72" />
                      </div>
                    </Tooltip>
                    <div className="text-zinc-900 text-sm leading-none whitespace-nowrap">
                      {(item.addFromToLandSurface
                        ? t('propertiesPage:from') + ' '
                        : '') +
                        (item.useTildeForSurface ? '~' : '') +
                        item.landSurface}
                      <span>
                        {' '}
                        m<sup>2</sup>
                      </span>
                    </div>
                  </div>
                ) : null}
                {'rooms' in item && item.rooms ? (
                  <div className="basis-0 justify-start items-center @xs:gap-2.5 flex">
                    <Tooltip title="Rooms" arrow placement="top">
                      <div>
                        <HomeIcon className="mr-1" color="#6E6E72" />
                      </div>
                    </Tooltip>
                    <div className="text-zinc-900 text-sm leading-none whitespace-nowrap">
                      {item.rooms}
                    </div>
                  </div>
                ) : null}
                {'maxRooms' in item &&
                'minRooms' in item &&
                (item.maxRooms || item.minRooms) ? (
                  <div className="basis-0 justify-start items-start gap-1.5 @xs:gap-2.5 flex">
                    <Tooltip title="Min and max rooms" arrow placement="top">
                      <div>
                        <HomeIcon className="mr-2" color="#6E6E72" />
                      </div>
                    </Tooltip>
                    <div className="text-zinc-900 text-sm leading-none whitespace-nowrap">
                      {item.minRooms && item.maxRooms
                        ? `${item.minRooms} / ${item.maxRooms}`
                        : '-'}
                    </div>
                  </div>
                ) : null}
                {'maxSurface' in item &&
                'minSurface' in item &&
                (item.minSurface || item.maxSurface) ? (
                  <div className="basis-0 justify-start items-start gap-1.5 @xs:gap-2.5 flex">
                    <Tooltip title="Min and max surface" arrow placement="top">
                      <div>
                        <SquareIcon color="#6E6E72" />
                      </div>
                    </Tooltip>
                    <div className="text-zinc-900 text-sm leading-none whitespace-nowrap">
                      {item.minSurface && item.maxSurface
                        ? `${item.minSurface} / ${item.maxSurface}`
                        : '-'}
                      <span>
                        {' '}
                        m<sup>2</sup>
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
          <div
            className={classNames('mb-1 pt-2', {
              'grid grid-cols-2': !isPromotion,
              'flex w-full': isPromotion,
            })}
          >
            {!isPromotion ? (
              <>
                {isNetworks ? (
                  <div className={'flex flex-col gap-1 col-span-2'}>
                    {'restrictedShare' in item ? (
                      <div className="text-sm basis-0 justify-start items-center gap-1.5 @xs:gap-2.5 flex">
                        <div className={'opacity-50'}>{t('Share_type')}:</div>
                        <div className="font-medium opacity-70 text-zinc-900 text-sm leading-none  whitespace-nowrap">
                          {t(item.restrictedShare ? 'Restricted' : 'Advanced')}
                        </div>
                      </div>
                    ) : null}

                    {'commissionRate' in item ? (
                      <div className="text-sm basis-0 justify-start items-center gap-1.5 @xs:gap-2.5 flex">
                        <div className={'opacity-50'}>
                          {t('Commission_Rate')}:
                        </div>
                        <div className="font-medium opacity-70 text-zinc-900 text-sm leading-none  whitespace-nowrap">
                          {item.commissionRate} %
                          {item.isCommissionRateHt ? '(HT)' : '(TTC)'}
                        </div>
                      </div>
                    ) : null}

                    {'commissionRateShared' in item ? (
                      <div className="text-sm basis-0 justify-start items-center gap-1.5 @xs:gap-2.5 flex">
                        <div className={'opacity-50'}>
                          {t('Commission_Rate_Shared')}:
                        </div>
                        <div className="font-medium opacity-70 text-zinc-900 text-sm leading-none  whitespace-nowrap">
                          {item.commissionRateShared} %
                          {item.isCommissionRateSharedHt ? '(HT)' : '(TTC)'}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <PropertyContactOptimized
                    row={item as PropertyListingItem}
                    isGrid
                  />
                )}
              </>
            ) : (
              <Status item={item} />
            )}
          </div>
        </div>
      </div>
      <div className="border-t pt-1">
        {isPromotion ? (
          <PromotionsListingActionMenu
            isList
            row={item as IPromotionListItem}
          />
        ) : (
          <>
            {isNetworks ? null : (
              <div className="flex items-center gap-2  mx-auto rounded-full bg-[#F5F5F7] py-2 pl-3">
                {'department' in item && item?.department?.logo?.x96 ? (
                  <span className="!min-w-[20%] ">
                    <img
                      className="w-[40px] h-[40px] object-contain rounded-full"
                      src={item?.department?.logo?.x96}
                    />
                  </span>
                ) : (
                  <span className="!min-w-[20%]">
                    <MountIcon color="#FFF" />
                  </span>
                )}

                {'department' in item && (
                  <Tooltip title={item?.department?.name} arrow placement="top">
                    <span
                      className="font-[Inter] font-medium	truncate text-sm leading-4	text-[#1D1D1F] text-left"
                      style={{
                        marginRight: '5px',
                        overflow: 'hidden',
                        // display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: '2',
                        whiteSpace: 'normal',
                      }}
                    >
                      {item?.department?.name}
                    </span>
                  </Tooltip>
                )}
              </div>
            )}
          </>
        )}
      </div>
      {isNetworks ? null : (
        <PropertiesInfoModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          listingItem={item as PropertyListingItem}
        />
      )}
    </div>
  );
};

export default Card;
