import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import Logo from '../../components/common/logo';
import { useTranslation } from 'react-i18next';
import ContactForm from './components/form';
import { useEffect, useState } from 'react';
import { Translations } from '../../types/translations';

interface ServerResponse {
  data: ContactData | null;
  success: boolean;
}

export interface ContactData {
  greeting: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  lang: string;
  types: {
    id: number;
    keyname: string;
    translations: Translations;
  }[];
  agencyLogo: string;
}

const langMap: Record<string, string> = {
  english: 'en',
  en: 'en',
  french: 'fr',
  fr: 'fr',
};

export const getShortLangCode = (lang: string): string => {
  const normalizedLang = lang.toLowerCase();

  return langMap[normalizedLang] || 'en';
};

const NewsletterConfirmation = () => {
  const { token } = useParams();
  const { data: verificationData } = useSWR<ServerResponse>(
    `${process.env.REACT_APP_API_HOST}/newsletter/validate?token=${token}`
  );

  const { data: contactData } = useSWR<ServerResponse>(
    verificationData && verificationData.success
      ? `${process.env.REACT_APP_API_HOST}/contacts/${token}`
      : null
  );
  const [language, setLanguage] = useState(
    contactData && contactData?.data && contactData?.data.lang
      ? getShortLangCode(contactData?.data.lang)
      : 'en'
  );
  const [isRequestSuccessful, setIsRequestSuccessful] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.overflowY = 'scroll';
  }, []);

  if (!contactData) return null;

  return (
    <div className={'pb-16'}>
      <div
        className={
          'w-full px-4 py-8 bg-white border-b border-gray-200 inline-flex justify-between items-center xl:px-16'
        }
      >
        {contactData?.data?.agencyLogo ? (
          <div>
            <img
              className="block w-full h-[24px] object-contain"
              src={contactData?.data?.agencyLogo}
              alt=""
            />
          </div>
        ) : (
          <div>
            <Logo />
          </div>
        )}
      </div>
      <section className={'pt-16'}>
        <div className="px-4 lg:px-0 max-w-[880px] w-full m-auto text-center">
          <div className="h1 mb-8">
            {t('common:thank_you_for_subscription', { lng: language })}
          </div>
          <p className={'text-center'}>
            {t(
              `common:${
                isRequestSuccessful
                  ? 'Your_details_successfully_saved'
                  : 'Please_review_your_details_to_complete_subscription'
              }`,
              { lng: language }
            )}
          </p>
        </div>
      </section>
      <section className={'max-w-[44rem] m-auto p-8 pt-16'}>
        {isRequestSuccessful ? null : (
          <ContactForm
            contactData={contactData?.data}
            onLanguageChange={setLanguage}
            locale={language}
            tokenId={token}
            isRequestSuccessful={isRequestSuccessful}
            onRequestSuccessful={setIsRequestSuccessful}
          />
        )}
      </section>
    </div>
  );
};

export default NewsletterConfirmation;
