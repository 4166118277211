import { Box, Fade, Modal } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../features/icons/closeIcon';
import { DataGrid } from '@mui/x-data-grid';
import { IPropertyApi } from '../../../types/property';
import useListingCollection from '../../../hooks/useListingCollection';
import { usePublishedPropertiesListColumns } from '../utils/usePublishedPropertiesListColumns';

interface Props {
  title: string;
  openModal: boolean;
  onClose: () => void;
}
const skeletonItems: any = Array(6)
  .fill('')
  .map((_, index) => ({
    currency: '',
    id: index,
    media: null,
    displayedPrice: null,
    name: '',
    city: '',
    price: 0,
    reference: '',
    agencyReference: '',
    source: '',
    title: null,
    department: null,
    address: null,
  }));

const PublishedPropertiesPopup = (props: Props) => {
  const { openModal, onClose, title } = props;

  const { t } = useTranslation('propertiesPage');

  const { itemsToDisplay, setPage, itemsPerPage, page, loadingItemsToDisplay } =
    useListingCollection<IPropertyApi>({
      basedUrl: '/v1/properties/published',
      currentPage: 'propertiesPublished',
      initialItemsPerPage: 6,
    });

  const columns = usePublishedPropertiesListColumns({
    isLoading: loadingItemsToDisplay,
  });

  const totalRows = itemsToDisplay?.['hydra:totalItems'] || 0;
  const results = itemsToDisplay?.['hydra:member'] || [];
  const rows = loadingItemsToDisplay ? skeletonItems : results;

  return (
    <Modal
      open={openModal}
      onClose={onClose}
      classes={{ root: 'grid place-items-center' }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Fade in={openModal}>
          <Box
            sx={{
              maxHeight: '90vh',
              overflowY: 'auto',
              overflowX: 'hidden',
              width: {
                xs: '79%',
              },
              borderRadius: 2,
              bgcolor: '#FBFBFD',
              boxShadow: 24,
            }}
          >
            <div
              className={
                'px-8 pb-6 pt-4 flex flex-col h-[87vh] min-h-[500px] relative'
              }
            >
              <div className="flex justify-between !items-start w-full">
                <p className={'mb-4 text-lg md:text-2xl'}>{t(title)} </p>
              </div>
              <button
                onClick={onClose}
                className={
                  'absolute right-8 top-8 opacity-60 transition-opacity duration-300 hover:opacity-80'
                }
              >
                <CloseIcon />
              </button>
              <Box sx={{ width: '100%' }}>
                <DataGrid
                  sx={{
                    '& .hideRightSeparator > .MuiDataGrid-columnSeparator': {
                      display: 'none',
                    },
                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                      display: 'none',
                    },
                  }}
                  rows={rows}
                  columns={columns}
                  autoHeight={true}
                  loading={!rows}
                  disableColumnMenu
                  disableSelectionOnClick
                  autoPageSize
                  page={page - 1}
                  paginationMode="server"
                  rowCount={totalRows || 0}
                  onPageChange={(page: number) => {
                    setPage(page + 1);
                  }}
                  pageSize={itemsPerPage}
                  rowsPerPageOptions={[5]}
                  rowHeight={70}
                />
              </Box>
            </div>
          </Box>
        </Fade>
      </ClickAwayListener>
    </Modal>
  );
};

export default PublishedPropertiesPopup;
