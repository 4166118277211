import { useTranslation } from 'react-i18next';
import TitleHeader from '../../../components/common/titleHeader';
import { useFieldArray, useForm } from 'react-hook-form';
import InputFormField from '../../../components/form/inputFormField';
import PlusIcon from '../../../features/icons/plusIcon';
import { Box, IconButton } from '@mui/material';
import RemoveIcon from '../../../features/icons/removeIcon';
import { useEffect, useState } from 'react';
import SaveButton from '../../../components/stepper/saveButton';
import { http } from '../../../libs/axios';
import AddressFields from './addressFields';
import useLocale from '../../../hooks/useLocale';
import { Translations } from '../../../types/translations';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../../hooks/useNotification';
export const sectorAddresses = {
  city: '',
  zipCode: '',
};

const CreateAddress = () => {
  const { t } = useTranslation('common');
  const { locale } = useLocale();
  const navigate = useNavigate();
  const { addNotification } = useNotification();
  const [loading, setLoading] = useState(false);

  const name = 'sectorAddresses';

  const { register, watch, control, handleSubmit, setValue } = useForm();

  const fieldArray = useFieldArray({
    control,
    name: 'dimensions',
  });

  useEffect(() => {
    if (fieldArray.fields.length === 0) {
      fieldArray.append({ ...sectorAddresses });
    }
  }, [fieldArray]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      await http.post('/sectors', {
        name: data.name,
        sectorAddresses: data.sectorAddresses.map(
          (item: { translations: Translations; zipCode: string }) => ({
            city: item.translations[locale]?.city,
            zipCode: item.zipCode,
          })
        ),
      });
      addNotification(t('address_created_successfully'), 'success');
      navigate('/settings/addresses');
    } catch (e: any) {
      const errorMessage =
        e.response?.data?.message || t('error_creating_address');

      addNotification(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <TitleHeader
      title={t('create_new_address')}
      goBackLink="/settings/addresses"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-[40%] mb-10">
          <InputFormField
            isRequired
            {...register('name')}
            label={t('address_name')}
            variant="outlined"
          />
        </div>

        {fieldArray.fields.map((field, index) => {
          return (
            <div key={field.id} className="grid grid-cols-12 gap-4 mb-8">
              <div className="col-span-10">
                <AddressFields
                  register={register}
                  control={control}
                  watch={watch}
                  name={name}
                  index={index}
                />
              </div>
              <div className="flex items-center mt-6 xl:gap-4">
                <button
                  disabled={!watch(`${name}.${index}.zipCode`)}
                  type="button"
                  onClick={() => fieldArray.append({ ...sectorAddresses })}
                  className={`text-white w-[10vw] rounded px-2 xl:px-3 py-2 inline-flex items-center lg:gap-2  ${
                    watch(`${name}.${index}.zipCode`)
                      ? 'bg-blue'
                      : 'bg-gray-400 cursor-not-allowed'
                  }`}
                >
                  <PlusIcon
                    className="hidden min-[1100px]:block"
                    color="currentcolor"
                  />
                  {t('add')}
                </button>
                <div>
                  <IconButton
                    type="button"
                    onClick={() => {
                      setValue(
                        `${name}.${index}.translations.${locale}.city`,
                        ''
                      );
                      setValue(`${name}.${index}.zipCode`, '');

                      fieldArray.remove(index);
                    }}
                    aria-label="Remove"
                  >
                    <RemoveIcon className="text-attention" />
                  </IconButton>
                </div>
              </div>
            </div>
          );
        })}
        <Box className="w-full flex  justify-end px-16 py-8">
          <SaveButton isLoading={loading} />
        </Box>
      </form>
    </TitleHeader>
  );
};

export default CreateAddress;
