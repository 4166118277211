import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';
import { useTranslation } from 'react-i18next';

type MatchingStatisticsProps = {
  statistics: any;
};

export const StatisticItem = (props: {
  title: string;
  value: number;
  percentage?: number;
  total?: number;
}) => {
  const progress = props.total
    ? Math.round((props.value / props.total) * 100)
    : 100;

  return (
    <div className="h-[100px] px-8 py-4 bg-white flex-col justify-start items-start gap-4 inline-flex">
      <div className="self-stretch h-12 flex-col justify-start items-start gap-1 flex">
        <div className="self-stretch text-zinc-900 text-sm font-medium leading-none">
          {props.title}
        </div>
        <div className="self-stretch text-blue-800 text-2xl font-normal leading-7">
          {props.value}
          {props.percentage ? (
            <span className="ml-1">({props.percentage}%)</span>
          ) : null}
        </div>
      </div>
      <div
        style={{ width: `${progress}%`, maxWidth: '100%' }}
        className="self-stretch h-1 bg-blue-800 rounded-[300px]"
      />
    </div>
  );
};

const MatchingStatistics = ({ statistics }: MatchingStatisticsProps) => {
  const { t } = useTranslation('propertiesPage');
  return (
    <Accordion
      key={'statistics'}
      className={'w-full'}
      defaultExpanded={false}
      sx={{
        boxShadow: 'none',
        margin: '0',
        '&': {
          width: '100%',
          backgroundColor: '#FBFBFD',
        },
        '&::before': {
          display: 'none',
        },
        '&.Mui-expanded': {
          margin: '0',
        },
        '&.Mui-disabled': {
          backgroundColor: 'inherit',
        },
      }}
    >
      <AccordionSummary
        className={`g_contact`}
        expandIcon={<KeyboardArrowDownIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          padding: '1rem',

          '& .MuiAccordionSummary-content': {
            margin: '0',
            alignItems: 'center',
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '0',
            alignItems: 'center',
          },
        }}
      >
        <p className={''}>{t('Statistics')}</p>
      </AccordionSummary>
      <AccordionDetails
        style={{
          boxShadow: 'none',
        }}
      >
        {statistics ? (
          <div className={'grid grid-cols-3 lg:grid-cols-6 gap-1'}>
            <StatisticItem
              title={t('Email_sent')}
              value={statistics.emailsSent}
            />
            <StatisticItem
              title={t('Opened_emails')}
              value={statistics.emailsOpened}
              percentage={statistics.percentageOpened}
              total={statistics.emailsSent}
            />
            <StatisticItem
              title={t('Delivered_emails')}
              value={statistics.emailsDelivered}
              total={statistics.emailsSent}
            />
            <StatisticItem
              title={t('Visited_emails')}
              value={statistics.emailVisits}
              total={statistics.emailsSent}
            />
            <StatisticItem
              title={t('leads')}
              value={statistics.leads}
              total={statistics.emailsSent}
            />
            <StatisticItem
              title={t('Visits')}
              value={statistics.totalVisits}
              total={statistics.emailsSent}
            />
          </div>
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};

export default MatchingStatistics;
