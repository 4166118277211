import React, { useEffect, useMemo, useState } from 'react';
import { GridSelectionModel } from '@mui/x-data-grid';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { http } from '../../../../libs/axios';
import { toast } from 'react-toastify';
import { IMatching, IMercureMatching } from '../../../../types/matching';
import { useTranslation } from 'react-i18next';
import { ApiListing } from '../../../../types/api';
import { IContact } from '../../../../types/contacts';
import PropertyInfoCard from '../../../../components/matching/common/components/propertyInfoCard';
import Statistics from '../../../../components/matching/common/components/statistics';
import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import UpdateIndicator from '../../../../components/matching/common/components/regenerate/indicator';
import TitleWithButton from '../../../../components/matching/common/components/titleWithButton';
import ContactsListingTable from '../../../../pages/properties/propertiesListing/propertiesMatching/steps/users/listing';
import { ContactsListingTable as PromotionContactsListingTable } from '../../promotions/steps/users/listing';
import useMercure from '../../../../features/mercure/useMercure';

type Props = {
  type: 'promotion' | 'regular';
  matchings?: any;
  mutate?: any;
  rowSelectionUsers: GridSelectionModel;
  onRowsSelectionHandler: (rowSelectionModel: GridRowSelectionModel) => void;
  matching?: IMatching | null;
  onRegenerateMatching?: (id?: number, remove?: boolean) => void;
  onContactsUpdate?: (value: number) => void;
  page?: number;
  setPage?: (value: number) => void;
  filter?: number | null;
  setFilter?: (value: number | null) => void;
  contacts?: ApiListing<IContact>;
  promotionId?: number;
  selectedMatching?: any;
  onMatchingSelect?: (item: any) => void;
};

const UsersStep = ({
  type,
  matchings,
  mutate,
  matching,
  rowSelectionUsers,
  onRowsSelectionHandler,
  onRegenerateMatching,
  onContactsUpdate,
  contacts,
  page,
  setPage,
  filter,
  setFilter,
  selectedMatching,
  onMatchingSelect,
  promotionId,
}: Props) => {
  const [requestedUpdate, setRequestedUpdate] = useState(false);
  const [requestedUpdateDone, setRequestedUpdateDone] = useState(false);
  const { t } = useTranslation('propertiesPage');

  const { data } = useMercure<IMercureMatching>('matching');

  useEffect(() => {
    if (data && data.matching === info?.id && data.status === 'completed') {
      setRequestedUpdate(false);
      setRequestedUpdateDone(true);
      mutate?.();

      if (onRegenerateMatching) {
        onRegenerateMatching(info?.id, true);
      }
    }
  }, [data]);

  const info = useMemo(() => {
    if (matching) {
      return matching;
    }
  }, [matching]);

  const regenerateMatching = () => {
    const url =
      type === 'regular'
        ? `/v1/properties/${info?.property.id}/contacts/matching`
        : `/v1/promotions/${promotionId}/generate-matching`;
    http
      .get(`${url}?regenerate=true`)
      .then(() => {
        toast.success(`${t('Your_matching_is_re_launched')}`, {
          position: 'top-right',
          autoClose: 3000,
        });
        setRequestedUpdate(true);
        if (onRegenerateMatching) {
          onRegenerateMatching(info?.id);
        }
      })
      .catch(() => {
        toast.error(
          `${t('There_is_been_an_server_error_Matching_was_not_started')}`
        );
      });
  };

  const onFilterChange = (value: number | null) => {
    setFilter?.(value);
    setPage?.(1);
  };

  useEffect(() => {
    if (contacts && onContactsUpdate) {
      onContactsUpdate(contacts['hydra:totalItems']);
    }
  }, [contacts]);

  return (
    <div className={'min-h-[400px] flex flex-col gap-8'}>
      <div className={'flex w-full justify-between items-start'}>
        {type === 'promotion' && matchings ? (
          <div className="w-full flex-col justify-start items-start gap-4 inline-flex">
            <div className={'w-full items-center flex justify-between'}>
              <div className="text-zinc-900 text-base font-normal leading-none">
                {t('Matchings')}
              </div>
              <UpdateIndicator
                requestedUpdate={requestedUpdate}
                requestedUpdateDone={requestedUpdateDone}
              />
            </div>
            <div className={'w-full flex flex-wrap gap-2'}>
              {matchings.length ? (
                matchings.map((item: any) => (
                  <div key={item.id} className={''}>
                    <PropertyInfoCard
                      isSmall
                      property={item.property}
                      spentTime={item.spentTime}
                      contactsMatched={item.contacts.length}
                      selected={
                        selectedMatching
                          ? item.id === selectedMatching.id
                          : false
                      }
                      onClick={() => onMatchingSelect?.(item)}
                    />
                  </div>
                ))
              ) : (
                <Skeleton variant={'rectangular'} height={127} width={255} />
              )}
            </div>
          </div>
        ) : (
          <div className="w-full max-w-[406px] flex-col justify-start items-start gap-4 inline-flex">
            <div className="text-zinc-900 text-base font-normal leading-none">
              {t('Selected_matching')}
            </div>
            {info && info.property ? (
              <PropertyInfoCard
                property={info.property}
                spentTime={info.spentTime}
              />
            ) : (
              <Skeleton variant={'rectangular'} height={166} width={406} />
            )}
          </div>
        )}
        {type === 'regular' ? (
          <UpdateIndicator
            requestedUpdate={requestedUpdate}
            requestedUpdateDone={requestedUpdateDone}
          />
        ) : null}
      </div>

      <div className="w-full h-px bg-gray-200" />
      {type === 'promotion' && !selectedMatching?.statistics ? (
        <Skeleton variant={'rectangular'} height={56} />
      ) : (
        <Statistics
          statistics={info?.statistics || selectedMatching?.statistics}
        />
      )}
      <div className="w-full h-px bg-gray-200" />

      <TitleWithButton
        onClick={regenerateMatching}
        disabled={requestedUpdate}
        totalItems={
          contacts?.['hydra:totalItems'] || selectedMatching?.contactsMatched
        }
      />

      {type === 'promotion' && (
        <>
          {selectedMatching?.contacts ? (
            <PromotionContactsListingTable
              contacts={selectedMatching.contacts}
              onRowsSelectionHandler={onRowsSelectionHandler}
              rowSelectionModel={rowSelectionUsers}
            />
          ) : (
            <Skeleton variant={'rectangular'} height={300} width={'100%'} />
          )}
        </>
      )}

      {type === 'regular' && (
        <div className="w-full justify-start items-start gap-2 inline-flex">
          <button
            onClick={() => onFilterChange(null)}
            className={classNames(
              'px-4 py-2 bg-gray-50 rounded justify-center items-center gap-4 flex',
              {
                'bg-neutral-100': filter === null,
              }
            )}
          >
            <div
              className={classNames('text-sm font-normal leading-none', {
                'text-blue-800': filter === null,
              })}
            >
              {t('All')}{' '}
            </div>
          </button>
          <button
            onClick={() => onFilterChange(1)}
            className={classNames(
              'px-4 py-2 bg-gray-50 rounded justify-center items-center gap-4 flex',
              {
                'bg-neutral-100': filter === 1,
              }
            )}
          >
            <div
              className={classNames('text-sm font-normal leading-none', {
                'text-blue-800': filter === 1,
              })}
            >
              {t('Sent_emails_only')}
            </div>
          </button>
          <button
            onClick={() => onFilterChange(0)}
            className={classNames(
              'px-4 py-2 bg-gray-50 rounded justify-center items-center gap-4 flex',
              {
                'bg-neutral-100': filter === 0,
              }
            )}
          >
            <div
              className={classNames('text-sm font-normal leading-none', {
                'text-blue-800': filter === 0,
              })}
            >
              {t('Not_sent_emails_only')}
            </div>
          </button>
        </div>
      )}

      {type === 'regular' && (
        <ContactsListingTable
          contacts={contacts}
          rowSelectionModel={rowSelectionUsers}
          onRowsSelectionHandler={onRowsSelectionHandler}
          page={page}
          setPage={setPage}
        />
      )}
    </div>
  );
};

export default UsersStep;
