import classNames from 'classnames';
import { ReactNode } from 'react';

import { useLayoutEditor } from '../lib/hooks/useLayoutEditor';
import LayoutPart from './layoutPart';
import { Page } from '../../../types/brochureEditor';

const gridAreas = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];

const Mockup = ({
  page,
  gridTemplateAreas,
  withLayoutStyles,
  displayGeneratedDateIfItIsEnabled = false,
  renderCell,
}: {
  page?: Page;
  gridTemplateAreas: string;
  withLayoutStyles?: boolean;
  displayGeneratedDateIfItIsEnabled?: boolean;
  renderCell?: ({
    area,
    isHeader,
    isFooter,
    justifyContent,
    index,
  }: {
    area: string;
    index: number;
    isHeader?: boolean;
    isFooter?: boolean;
    justifyContent?: string;
  }) => ReactNode;
}) => {
  const { withHeader, withFooter, header, footer } = useLayoutEditor();

  const finalHeader = { ...header };
  if (page?.headerBg) {
    finalHeader.bgImage = page.headerBg;
  }
  const finalFooter = { ...footer };
  if (page?.footerBg) {
    finalFooter.bgImage = page.footerBg;
  }

  return (
    <div
      className={classNames('flex flex-col gap-[1%] w-full', {
        'pt-[6.9%]': !withHeader,
        'pb-[6.9%]': !withFooter,
      })}
    >
      {withHeader && (
        <LayoutPart
          renderCell={renderCell}
          options={finalHeader}
          withStyles={withLayoutStyles}
          displayGeneratedDateIfItIsEnabled={displayGeneratedDateIfItIsEnabled}
          isHeader
        />
      )}

      <div
        className="grid grid-cols-2 grid-rows-4 gap-[2%] w-full min-h-0 flex-grow px-[6.9%]"
        style={{ gridTemplateAreas }}
        data-middle-part
      >
        {gridAreas
          .filter((area) => gridTemplateAreas.includes(area))
          .map((area, index) => (
            <div
              key={area}
              style={{ gridArea: area }}
              className="grid relative min-h-0"
            >
              {renderCell?.({ area, index })}
            </div>
          ))}
      </div>

      {withFooter && (
        <LayoutPart
          renderCell={renderCell}
          options={finalFooter}
          withStyles={withLayoutStyles}
          displayGeneratedDateIfItIsEnabled={displayGeneratedDateIfItIsEnabled}
        />
      )}
    </div>
  );
};

export default Mockup;
