import * as React from 'react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const FormWrapper = (props: {
  title?: string;
  onSubmit: () => void;
  children: ReactNode;
  buttons: ReactNode;
  isEditMode?: boolean;
}) => {
  const { title, children, onSubmit, buttons } = props;
  const { t } = useTranslation('common');

  return (
    <>
      {title ? (
        <p className={'text-[24px] border-b pb-2 mb-8'}>
          {t(`${title.replaceAll(' ', '_')}`)}
        </p>
      ) : null}
      <form
        className={'grid grid-cols-3 gap-8 2xl:gap-x-16 pt-4 relative'}
        onSubmit={onSubmit}
        onKeyDown={(event) => {
          if (event.code === 'Enter') {
            event.preventDefault();
          }
        }}
      >
        <>{children}</>
        <div className="col-start-1 col-end-4 flex gap-4 justify-end border-t mt-8 pt-16 pb-8">
          {buttons}
        </div>
      </form>
    </>
  );
};

export default FormWrapper;
