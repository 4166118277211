import { PropertyListingItem } from '../../../types/property';
import { IPromotionListItem } from '../../../types/promotion';
import MockCard from './mock-item';
import Card from './item';
import { Skeleton, TablePagination } from '@mui/material';
import { IMercureMatchingTime } from '../../../types/matching';
import { IContact } from '../../../types/contacts';
import ContactCard from './contactCard';
import { IDashboardOfferItem } from '../../../types/user';
import OfferCrad from '../../../pages/index/components/offerCrad';
import { TemplateApi } from '../../../types/brochureEditor';
import { useTranslation } from 'react-i18next';
interface Props {
  items?: PropertyListingItem[] | IPromotionListItem[];
  contactItems?: IContact[] | null;
  offerItems?: IDashboardOfferItem[] | null;
  leadsItems?: TemplateApi[] | null;
  isPromotion?: boolean;
  isContact?: boolean;
  isOffers?: boolean;
  isLeads?: boolean;
  isAgency?: boolean;
  isLoading?: boolean;
  page: number;
  handleChangePage: any;
  rowsPerPage: number;
  handleChangeRowsPerPage: any;
  rowCount: number;
  isNetworks?: boolean;
  isActiveProperties?: boolean;
  matchingRemainingTime?: IMercureMatchingTime | null;
  rowsPerPageOptions?: number[];
}

const mockUpItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const GridView = (props: Props) => {
  const {
    items,
    isPromotion,
    isContact,
    isOffers,
    isLeads,
    leadsItems,
    contactItems,
    offerItems,
    isLoading,
    isAgency,
    page,
    handleChangePage,
    rowsPerPage,
    handleChangeRowsPerPage,
    rowCount,
    isNetworks,
    matchingRemainingTime,
    isActiveProperties,
    rowsPerPageOptions,
  } = props;
  const { t } = useTranslation('propertiesPage');
  return (
    <div>
      <div
        className={'grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4'}
      >
        {isLoading ? (
          <>
            {mockUpItems.map((el) => (
              <MockCard key={el} />
            ))}
          </>
        ) : (
          <>
            {items &&
              items.map((el) => (
                <Card
                  key={el.id}
                  item={el}
                  isPromotion={isPromotion}
                  isNetworks={isNetworks}
                  matchingRemainingTime={matchingRemainingTime}
                  isActiveProperties={isActiveProperties}
                />
              ))}

            {isContact && contactItems
              ? contactItems.map((elm) => (
                  <ContactCard key={elm.id} item={elm} />
                ))
              : null}
            {isOffers && offerItems
              ? offerItems.map((elm) => (
                  <OfferCrad key={elm.id} offerItem={elm} isOffers={isOffers} />
                ))
              : null}
            {isLeads &&
              (leadsItems?.length ? (
                leadsItems.map((elm) => (
                  <OfferCrad
                    key={elm.id}
                    leadsItem={elm as any}
                    isLeads={isLeads}
                    isAgency={isAgency}
                  />
                ))
              ) : (
                <div className="col-span-4 text-center h-[23vh] grid items-center font-medium font-[Inter] text-lg">
                  {t('No_Leads')}
                </div>
              ))}
          </>
        )}
      </div>
      <div className={'w-full flex items-end justify-end pt-8'}>
        {isLoading ? (
          <Skeleton variant="rectangular" height={40} width={200} />
        ) : (
          <TablePagination
            component="div"
            sx={{
              '&::-webkit-scrollbar': {
                height: '10px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#BBC0C4',
                borderRadius: 8,
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#888',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1',
              },
            }}
            count={rowCount}
            page={page}
            labelRowsPerPage={t('Rows_Per_Page')}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions ? rowsPerPageOptions : []}
          />
        )}
      </div>
    </div>
  );
};

export default GridView;
