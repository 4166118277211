import { useTranslation } from 'react-i18next';
import { formatPriceWithDash as formatPrice } from '../../../../../helpers/utils';
import { useIsRent } from '../../../../../hooks/useIsRent';
import { PropertyWidgetPreviewProps } from '../../../../../types/brochureEditor';
import TableWidget from '../../tableWidget';
import useLocale from '../../../../../hooks/useLocale';

const PropertyPriceWidget = ({
  property,
  widgetOptions,
}: PropertyWidgetPreviewProps) => {
  const { t } = useTranslation('propertiesPage');
  const isRent = useIsRent(property);
  const { locale } = useLocale();
  const source = isRent ? property.pricingRent : property.pricingBuy;

  if (source.hidePriceOnBrochure) {
    return null;
  }

  const lines = [
    {
      id: '17',
      label: t('price'),
      value: formatPrice(source?.displayedPrice),
    },
    {
      id: '18',
      label: t('Price/m2'),
      value: formatPrice(source?.pricem2),
    },
    {
      id: '20',
      label: t('Annual_charges'),
      value: formatPrice(source?.annualCharges),
    },
    {
      id: '201',
      label: t('Charges_included'),
      value: source?.chargesIncluded?.translations?.[locale]?.name || '',
    },
    {
      id: '2011',
      label: t('Heating_Annual_charges_included'),
      value:
        // @ts-ignore
        source?.heatingAnnualChargesIncluded?.translations?.[locale]?.name ||
        '',
    },
    {
      id: '2012',
      label: t('Monthly_Heating_charges'),
      value: formatPrice(source?.monthlyHeatingCharges),
    },
    {
      id: '202',
      label: t('Monthly_PPE_charges'),
      // @ts-ignore
      value: formatPrice(source?.monthlyPPECharges),
    },
    {
      id: '203',
      label: t('Charges/m2/Year'),
      value: formatPrice(source?.chargesm2Year),
    },
    {
      id: '204',
      label: t('Individual_charges_included'),
      value:
        // @ts-ignore
        source?.individualChargesIncluded?.translations?.[locale]?.name || '',
    },
    {
      id: '205',
      label: t('Income_tax_rates'),
      // @ts-ignore
      value: source?.incomeTaxRates ? source?.incomeTaxRates + ' %' : '',
    },
  ];

  if (source?.parkings) {
    source.parkings.map((parking, index) => {
      lines.push(
        {
          id: `parkingPrice_${index}`,
          label: `${t('Parking_price')} N° ${index + 1}`,
          value: formatPrice(parking.parkingPrice),
        },
        {
          id: `parkingIncluded_${index}`,
          label: `${t('Parking_included')} N° ${index + 1}`,
          value: parking.parkingIncluded?.translations?.[locale]?.name || '',
        },
        {
          id: `parkingMandatory_${index}`,
          label: `${t('Parking_mandatory')} N° ${index + 1}`,
          value: parking.parkingMandatory?.translations?.[locale]?.name || '',
        },
        {
          id: `parkingType_${index}`,
          label: `${t('Parking_type')} N° ${index + 1}`,
          value: parking.parkingType?.translations?.[locale]?.name || '',
        }
      );
    });
  }

  return (
    <TableWidget
      title={t('price')}
      items={lines}
      widgetOptions={widgetOptions}
    />
  );
};

export default PropertyPriceWidget;
