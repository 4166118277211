import React, { useEffect } from 'react';
import { toast, ToastContentProps } from 'react-toastify';
import useMercure from '../../features/mercure/useMercure';
import { useAuth } from '../../hooks/useAuth';
import Notification from './item/item';
import { useNavigate } from 'react-router-dom';
import { IMedia } from '../../types/api';
import { Translations } from '../../types/translations';

export interface Item {
  coverImage?: IMedia;
  mainImage?: IMedia;
  createdAt: string; // Assuming date is represented as a string
  id: number;
  reference: string;
  agencyReference: string;
  translations: Translations;
  updatedAt: string;
}

export interface Lead {
  date: string;
  id: number;
  message: string;
  promotion: Item | null;
  property: Item | null;
  source: string;
}

interface INotificationMercure {
  user: number;
  data: Item | Lead;
  message: string;
}

const RealTimeNotification = () => {
  const {
    user: { id: userId },
  } = useAuth();
  const navigate = useNavigate();
  const { data: notification } = useMercure<INotificationMercure>(
    'realtime-notification'
  );

  const onView = () => {
    if (notification?.message === 'matching_promotion_done') {
      navigate(`/promotions/${notification?.data.id}/edit/general-information`);
    }
    if (notification?.message === 'matching_property_done') {
      navigate(`/properties/${notification?.data.id}/edit/general-information`);
    }
    if (notification?.message === 'new_lead') {
      let url = '';

      if ('property' in notification.data && notification.data.property) {
        url = `/properties/${notification.data.property.id}`;
      }

      if ('promotion' in notification.data && notification.data.promotion) {
        url = `/promotions/${notification.data.promotion.id}`;
      }

      navigate(`${url}/edit/leads`);
    }
  };

  const displayNotification = () => {
    toast(
      (props: ToastContentProps) => (
        <Notification
          notificationType={notification?.message}
          data={notification?.data}
          onHide={props.closeToast}
          onView={onView}
        />
      ),
      {
        position: 'top-right',
        autoClose: 100000,
        closeButton: false,
      }
    );
  };

  useEffect(() => {
    if (notification && notification.user === userId) {
      displayNotification();
    }
  }, [notification]);

  return <></>;
};

export default RealTimeNotification;
