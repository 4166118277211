import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ContactsListingTable from './contactsListing/contactsListingTable';
import FilterLayout from '../../components/globalFilter/filterLayout/filterLayout';
import ContactsFilter from './contactsListing/contactsFilter';
import { initialContactFilterValues } from '../../types/contacts';
import { useForm } from 'react-hook-form';
import { useFiltersProperties } from '../../hooks/useFiltersProperties';
import usePropertyFilterStore from '../../hooks/usePropertyFilterStore';
import { camelCase, isArray, mapValues, pickBy } from 'lodash';
import useListingCollection from '../../hooks/useListingCollection';
import useLocale from '../../hooks/useLocale';
import SkeletonTableGrid from '../../components/skeletonTableGrid';
import { skeletonContactsListingConfig } from '../../components/material-ui/tablesConfig/contactsListingConfig';
import useTranslationForTableGridXConfig from '../../hooks/useTranslationForTableGridX';
import { useAuth } from '../../hooks/useAuth';
import { http } from '../../libs/axios';
import GridTableView from '../../components/cardsTablesList';
import GridView from '../../components/cardsTablesList/cards/list';
import ContactMagicSearch from './contactMagicSearch';
import { useContactMagicSearch } from './useContactMagicSearch';

const Contacts = () => {
  const { locale } = useLocale();
  const location = useLocation();
  const { pathname } = location;

  const { isAssistant } = useAuth();
  const [isChecked, setIsChecked] = useState(false);
  const [contactSearch, setContactSearch] = useState('');
  const isArchivedContacts = pathname.includes('archived');
  const isRejectedContacts = pathname.includes('rejected');
  const [isLoadingAi, setIsLoadingAi] = useState(false);
  const { reset, register, setValue, watch, control, getValues } = useForm({
    defaultValues: initialContactFilterValues,
  });

  const {
    itemsToDisplay,
    loadingItemsToDisplay,
    resetFilter,
    setPage,
    itemsPerPage,
    getFiltredProperties,
    page,
    setItemsPerPage,
    setLoadingItemsToDisplay,
  } = useListingCollection({
    basedUrl: `/contacts`,
    isActiveProperties: !isArchivedContacts,
    status: isRejectedContacts ? '3' : null,
    currentPage: 'contacts',
    isChecked: isChecked,
    initialItemsPerPage:
      Number(localStorage.getItem('contactItemsPerPage')) || 10,
  });
  const { agencyHierarchy, setAssistantAgencyHierarchy, setAgencyHierarchy } =
    useFiltersProperties();

  const { getInputValue, mainFilterState, sleep } = usePropertyFilterStore({
    basedUrl: `/contacts`,
    currentPage: 'contacts',
  });

  const { listing, isSearching } = useContactMagicSearch({
    searchQuery: contactSearch,
    itemsPerPage,
    page,
  });

  const contactData = !isArchivedContacts
    ? listing || itemsToDisplay
    : itemsToDisplay;
  const contactLoading = loadingItemsToDisplay || isSearching;

  const skeletonContactsListingColumns = useTranslationForTableGridXConfig([
    skeletonContactsListingConfig,
    'common',
  ]);

  useEffect(() => {
    handleResetFilter();
    setPage(1);
    setLoadingItemsToDisplay(true);
  }, [isArchivedContacts]);

  const { t } = useTranslation('usersPage');
  // update department depending to selected agent when the user is assistant
  useEffect(() => {
    if (isAssistant && watch('agents')) {
      const brokerId = watch('agents');
      setValue('departments', []);
      setAssistantAgencyHierarchy(brokerId);
    }
  }, [watch('agents')]);

  useEffect(() => {
    !agencyHierarchy && !isAssistant && setAgencyHierarchy();
  }, [setAgencyHierarchy]);

  // scroll and submit search
  const handleFilterDataPreparation = () => {
    const dataTosend = pickBy(watch(), (value) => {
      return isArray(value)
        ? value.length > 0
        : value !== undefined && value !== null && value !== '';
    });
    const newdataTosend: any = mapValues(dataTosend, (value, key) => {
      if (
        key === 'agents' ||
        key === 'owner-types' ||
        key === 'client-types' ||
        key === 'external-types' ||
        key === 'constructor-types'
      ) {
        return [Number(value)];
      }
      if (typeof value === 'string') {
        return value.trim();
      }
      return value;
    });
    delete newdataTosend.client;
    delete newdataTosend.owner;
    delete newdataTosend.external;

    // adapt form propertirs by removing the '-' character :  client-types => clientTypes
    for (const key in newdataTosend) {
      if (Object.prototype.hasOwnProperty.call(newdataTosend, key)) {
        const transformedKey = key.includes('-') ? camelCase(key) : key;
        newdataTosend[transformedKey] = newdataTosend[key];
        if (key !== transformedKey) {
          delete newdataTosend[key];
        }
      }
    }

    return newdataTosend;
  };

  //scroll to specific div
  const smoothScrollToElement = async (elementId: string) => {
    const targetElement = document.getElementById(elementId);

    if (!targetElement) {
      return;
    }
    targetElement.scrollIntoView({ behavior: 'smooth' });
    await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust the timeout as needed
  };

  const submitSearch = async () => {
    try {
      // Start smooth scrolling and wait for it to complete before proceeding
      await smoothScrollToElement('specificDivId');
      // Proceed with the rest of the logic
      const newdataTosend: any = await handleFilterDataPreparation();
      newdataTosend.archive = isArchivedContacts ? true : false;
      newdataTosend.lang = locale;
      await getFiltredProperties(newdataTosend);
    } catch (error) {
      console.log('Error occurred:', error);
    }
  };

  const handleResetFilter = () => {
    setValue('client', false);
    setValue('owner', false);
    setValue('external', false);
    reset();
    resetFilter();
  };

  const {
    user: { id: userId },
  } = useAuth();

  const MyContactsString = localStorage.getItem('@auth');
  const MyContacts = MyContactsString ? JSON.parse(MyContactsString) : null;

  const ShowUserContacts = async (e: any) => {
    if (setIsLoadingAi) {
      setIsLoadingAi(true);
    }
    try {
      const res = await http.patch(`/users/${userId}/only-my-contacts`, {
        showOnlyMyContacts: e.target.checked,
      });
      MyContacts.state.user.showOnlyMyContacts = res.data.showOnlyMyContacts;
      localStorage.setItem('@auth', JSON.stringify(MyContacts));

      setIsChecked(!isChecked);
    } finally {
      if (setIsLoadingAi) {
        setIsLoadingAi(false);
      }
    }
  };

  const filters = (
    <FilterLayout
      page="contacts"
      handleResetFilter={handleResetFilter}
      submitSearch={submitSearch}
    >
      <ContactsFilter
        register={register}
        setValue={setValue}
        watch={watch}
        control={control}
        getValues={getValues}
        getInputValue={getInputValue}
        mainFilterState={mainFilterState}
        sleep={sleep}
      />
    </FilterLayout>
  );

  return (
    <section className="w-full h-full flex flex-col">
      <div className={'flex  justify-between items-center mb-8'}>
        <div className="w-[30%]">
          <Typography className="!text-3xl">{t('common:Contacts')}</Typography>
          {contactData && contactData['hydra:totalItems'] ? (
            <Typography variant="subtitle1" className="text-sm text-gray-400">
              {contactData['hydra:totalItems']} {t('common:Contacts')}
            </Typography>
          ) : null}
          <div className="flex items-center">
            <Checkbox
              onChange={ShowUserContacts}
              defaultChecked={MyContacts.state.user.showOnlyMyContacts}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
            />
            <Typography className="!font-medium	!leading-6 text-sm lg:!text-base	!font-['Inter']">
              {t('propertiesPage:Show_only_my_contacts')}
            </Typography>
          </div>
        </div>
        <div className="flex gap-1 w-[48%]">
          {!isArchivedContacts ? (
            <div className="w-[85%]">
              <ContactMagicSearch
                contactSearch={contactSearch}
                setContactSearch={setContactSearch}
              />
            </div>
          ) : null}
          {!isArchivedContacts ? (
            <Link className="hidden w-[40%] md:block" to={'/contacts/create'}>
              <Button
                className="add_contact w-full h-full"
                variant="contained"
                disableElevation
                sx={{ background: 'background.default' }}
                startIcon={<AddIcon fontSize="medium" />}
              >
                {t('common:Add_contact')}
              </Button>
            </Link>
          ) : null}
        </div>
      </div>

      {filters}

      <div id="specificDivId" />

      <>
        {isLoadingAi ? (
          <span className="flex justify-center items-center h-[100%]">
            {' '}
            <CircularProgress disableShrink />
          </span>
        ) : (
          <GridTableView
            gridElement={
              <GridView
                contactItems={contactData && contactData['hydra:member']}
                isLoading={contactLoading}
                isContact
                page={page - 1}
                rowCount={(contactData && contactData['hydra:totalItems']) || 0}
                handleChangePage={(
                  event: React.MouseEvent | null,
                  page: number
                ) => {
                  setPage(page + 1);
                }}
                handleChangeRowsPerPage={(event: {
                  target: { value: number };
                }) => {
                  setItemsPerPage(event.target.value);
                  if (page > 1) {
                    setPage(1);
                  }
                  localStorage.setItem(
                    'contactItemsPerPage',
                    JSON.stringify(event.target.value)
                  );
                }}
                rowsPerPage={JSON.parse(
                  localStorage.getItem('contactItemsPerPage') || '10'
                )}
                rowsPerPageOptions={[10, 25, 50, 100]}
              />
            }
            tableElement={
              <Box className="w-full !min-h-[75%]">
                {contactLoading ? (
                  <SkeletonTableGrid
                    className="w-full !min-h-[80%]"
                    disableSelectionOnClick={true}
                    disableColumnSelector={true}
                    rows={Array.from({ length: itemsPerPage }).map(
                      (el, index) => {
                        return {
                          id: index,
                          status: '',
                          name: '',
                          agency: '',
                          agent: '',
                          type: '',
                          createdAt: '',
                          action: '',
                        };
                      }
                    )}
                    columns={skeletonContactsListingColumns}
                    rowCount={0}
                  />
                ) : (
                  <ContactsListingTable
                    itemsPerPage={itemsPerPage}
                    data={contactData!}
                    handlePage={setPage}
                    page={page}
                    setItemsPerPage={setItemsPerPage}
                    rowsPerPage={JSON.parse(
                      localStorage.getItem('contactItemsPerPage') || '10'
                    )}
                  />
                )}
              </Box>
            }
          />
        )}
      </>
    </section>
  );
};

export default Contacts;
