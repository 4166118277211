import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { http } from '../../../libs/axios';

type Props = {
  nbproperties?: number;
  title: string;
  type: string;
  children?: ReactNode;
  setIsChecked?: (newValue: boolean) => void;
  setIsLoadingAi?: (value: boolean) => void;
  hideMyProperties?: boolean;
  isChecked?: boolean;
};

function PropertiesListingHeader({
  nbproperties,
  title,
  type,
  children,
  setIsChecked,
  isChecked,
  setIsLoadingAi,
  hideMyProperties,
}: Props) {
  const { t } = useTranslation();
  const {
    user: { id: userId },
  } = useAuth();

  const MyPropertiesString = localStorage.getItem('@auth');
  const MyProperties = MyPropertiesString
    ? JSON.parse(MyPropertiesString)
    : null;

  const ShowUserProperties = async (e: any) => {
    if (setIsChecked) {
      if (setIsLoadingAi) {
        setIsLoadingAi(true);
      }
      try {
        const res = await http.patch(`/users/${userId}/only-my-properties`, {
          showOnlyMyProperties: e.target.checked,
        });

        MyProperties.state.user.showOnlyMyProperties =
          res.data.showOnlyMyProperties;
        localStorage.setItem('@auth', JSON.stringify(MyProperties));
        setIsChecked(!isChecked);
      } finally {
        if (setIsLoadingAi) {
          setIsLoadingAi(false);
        }
      }
    }
  };

  return (
    <Box
      className={classNames(
        'w-full flex gap-2 lg:gap-4 flex-col sm:flex-row sm:items-center',
        {
          'lg:pb-4': type === 'active',
          'lg:pb-6': type !== 'active',
        }
      )}
    >
      <Box className="flex flex-col mr-auto">
        <Typography className="!text-2xl lg:!text-3xl mb-2">{title}</Typography>

        {nbproperties != undefined && (
          <Typography variant="subtitle1" className="text-sm text-gray-400">
            {nbproperties + ' ' + t('propertiesPage:perperties_found')}
          </Typography>
        )}
        {!hideMyProperties ? (
          <div className="flex items-start lg:items-center">
            <Checkbox
              className="!pt-0"
              onChange={ShowUserProperties}
              defaultChecked={MyProperties.state.user.showOnlyMyProperties}
            />
            <Typography className="!font-medium	!leading-6 !text-sm lg:!text-base	!font-['Inter']">
              {t('propertiesPage:Show_only_my_properties')}
            </Typography>
          </div>
        ) : null}
      </Box>

      {children}

      {type === 'active' && (
        <>
          <FormControlLabel
            className="!hidden"
            control={<Checkbox />}
            label={t('propertiesPage:Show_properties_without_promotions')}
          />

          <Link to="new">
            <Button
              className="add_new_property !hidden lg:!flex lg:!py-3"
              variant="contained"
              sx={{ background: 'background.default', whiteSpace: 'nowrap' }}
              startIcon={<AddIcon fontSize="medium" />}
              disableElevation
            >
              <span>{t('propertiesPage:add_new_property')}</span>
            </Button>
          </Link>
        </>
      )}
    </Box>
  );
}

export default PropertiesListingHeader;
