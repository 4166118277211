import { Box, Tooltip, Typography } from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid';
import TabGridTag from '../../../features/tags/tabGridTag';
import {
  getUserLevel,
  getUserRole,
  getUserRoleColor,
  getuserAgency,
} from '../utils';
import GroupAvatars from '../../agencies/verticalTree/components/avatarGroup';
import { useTranslation } from 'react-i18next';

type Props = {
  cardKeys: string[];
  data: GridValidRowModel;
};

const displayValue = (data: GridValidRowModel, item: string) => {
  if (data[item] !== null) {
    if (item === 'Role') {
      return data.isSuperAdmin ? (
        <TabGridTag
          key={'superAdmin'}
          textContent={'Super admin'}
          fontSize="14px"
          className="m-0"
          color="#ff0000"
        />
      ) : data['roles'].length === 0 ? (
        <TabGridTag
          key={'unassigned'}
          textContent={'Unassigned'}
          fontSize="14px"
          color="#1D1D1F"
        />
      ) : (
        data['roles'].map((role: string, i: number) => (
          <TabGridTag
            key={`${role}-${i}`}
            textContent={getUserRole(role)}
            fontSize="14px"
            color={getUserRoleColor(role)}
          />
        ))
      );
    } else if (item === 'Level') {
      return getUserLevel(data);
    } else if (item === 'Belong to') {
      return getuserAgency(data);
    } else if (item === 'Related users') {
      return (
        <GroupAvatars avatarGroup={data?.users[`hydra:member`]} length={10} />
      );
    } else if (item === 'Related assistant') {
      return (
        <GroupAvatars
          avatarGroup={data?.assistants[`hydra:member`]}
          length={10}
          isAssistant
        />
      );
    } else {
      return data[item]?.name ? data[item].name : data[item];
    }
  } else {
    return '-';
  }
};

function InfoCard({ cardKeys, data }: Props) {
  const { t } = useTranslation('usersPage');
  if (!data || !cardKeys) return null;
  return (
    <Box className="col-span-1 flex flex-col border border-gray-200">
      {cardKeys &&
        cardKeys.map((item, i) => (
          <Box
            key={`${item}-${i}`}
            className="flex border-b border-gray-200 bg-white h-16 hover:bg-gray-100"
          >
            <Typography
              variant="subtitle1"
              className="col-span-1 px-4 flex items-center capitalize w-[50%]"
            >
              {t(item.replaceAll(' ', '_'))}
            </Typography>
            <Typography
              variant="subtitle1"
              className="col-span-3 grow shrink basis-0 pr-2 flex items-center overflow-x-auto lg:truncate"
            >
              {item == 'Role' ? (
                <span className="block lg:truncate">
                  {displayValue(data, item)}
                </span>
              ) : (
                <Tooltip title={displayValue(data, item)} arrow placement="top">
                  <span className="block lg:truncate ml-1">
                    {displayValue(data, item)}
                  </span>
                </Tooltip>
              )}
            </Typography>
          </Box>
        ))}
    </Box>
  );
}

export default InfoCard;
