import { Dispatch, SetStateAction, useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import TableGridX from '../../../components/tableGridX';
import { PropertyListingItem } from '../../../types/property';
import { ApiListing } from '../../../types/api';
import GridView from '../../../components/cardsTablesList/cards/list';
import GridTableView from '../../../components/cardsTablesList';
import { useTranslation } from 'react-i18next';
import { IMercureMatchingTime } from '../../../types/matching';
import usePropertiesColumns from './columns/usePropertiesColumns';
import useNetworkPropertiesColumns from './columns/useNetworkPropertiesColumns';
import useMercure from '../../../features/mercure/useMercure';

export type TableProps = {
  itemsPerPage: number;
  data: ApiListing<PropertyListingItem> | null;
  handlePage: Dispatch<SetStateAction<number>>;
  page: number;
  setSelectedRows: (rows: PropertyListingItem[]) => void;
  loadingItemsToDisplay: boolean;
  autoHeight?: boolean;
  setItemsPerPage: (value: number) => void;
  setNbproperties: Dispatch<SetStateAction<number | undefined>>;
  aiListing?: any;
  isNetworks?: boolean;
  isLoadingAi?: boolean;
  isActiveProperties?: boolean;
};

const emptyPropertyListingItem = {
  address: null,
  category: null,
  type: null,
  displayedPrice: null,
  mainBroker: null,
  mainImage: null,
  owners: [],
  rating: null,
  reference: null,
  agencyReference: null,
  status: null,
  translations: {},
  createdAt: '',
  updatedAt: '',
  warningField: null,
  bedrooms: null,
  landSurface: null,
  livingArea: null,
  useTildeForSurface: null,
  addFromToLivingArea: null,
  addFromToLandSurface: null,
  rooms: null,
  publishWebsite: null,
  publishGateways: [],
  pricingBuy: null,
  pricingRent: null,
  media: null,
  autoEmail: null,
};

const skeletonItems: PropertyListingItem[] = Array(6)
  .fill('')
  .map((_, index) => ({ id: index, ...emptyPropertyListingItem }));

const fixedRows = (rows: PropertyListingItem[] | []) => {
  return rows.map((el) => {
    if (!el.id) {
      el.id = parseInt(el.reference as string);
    }

    return el;
  });
};

const PropertiesListingTable = ({
  itemsPerPage,
  data,
  aiListing,
  handlePage,
  page,
  setSelectedRows,
  loadingItemsToDisplay,
  setItemsPerPage,
  setNbproperties,
  isNetworks,
  isLoadingAi,
  isActiveProperties,
}: // autoHeight = false,
TableProps) => {
  const { data: matchingRemainingTime } = useMercure<IMercureMatchingTime>(
    'matching-remaining-time'
  );

  const propertiesListingColumns = usePropertiesColumns({
    isLoading: loadingItemsToDisplay,
    isActiveProperties,
    remainingTime: matchingRemainingTime,
  });

  const networkPropertiesListingColumns = useNetworkPropertiesColumns({
    isLoading: loadingItemsToDisplay,
  });

  const columns = isNetworks
    ? networkPropertiesListingColumns
    : propertiesListingColumns;

  const { t } = useTranslation('propertiesPage');
  function loadData(
    data: ApiListing<PropertyListingItem> | null,
    aiData?: any
  ): PropertyListingItem[] {
    if (aiData?.properties?.length > 0) return aiData?.properties;
    return data?.['hydra:member'] || [];
  }
  const results = loadData(data, aiListing ?? null);
  const rowCount =
    aiListing?.totalProperties > 0 && aiListing?.aiDataSearch?.length != 0
      ? aiListing.totalProperties
      : aiListing?.totalProperties == 0
      ? 0
      : data?.['hydra:member'] && data['hydra:totalItems'];

  setNbproperties(rowCount);
  const rows = loadingItemsToDisplay ? skeletonItems : fixedRows(results);

  useEffect(() => {
    !localStorage.getItem('propertylistInfos') &&
      localStorage.setItem(
        'propertylistInfos',
        JSON.stringify({ page: 1, itemsPerPage })
      );
  }, []);

  return (
    <>
      {aiListing?.totalProperties === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Typography variant="h6">
            {' '}
            {t('Sorry_no_items_match_your_search')}
          </Typography>
        </Box>
      ) : (
        <>
          {isLoadingAi ? (
            <span className="flex justify-center items-center h-[100%]">
              {' '}
              <CircularProgress disableShrink />
            </span>
          ) : (
            <GridTableView
              gridElement={
                <GridView
                  items={rows}
                  matchingRemainingTime={matchingRemainingTime}
                  isNetworks={isNetworks}
                  isLoading={loadingItemsToDisplay}
                  page={page - 1}
                  rowCount={rowCount || 0}
                  isActiveProperties={isActiveProperties}
                  handleChangePage={(
                    event: React.MouseEvent | null,
                    page: number
                  ) => {
                    localStorage.setItem(
                      'propertylistInfos',
                      JSON.stringify({ page: page + 1, itemsPerPage })
                    );
                    handlePage(page + 1);
                  }}
                  rowsPerPage={itemsPerPage}
                  handleChangeRowsPerPage={(event: {
                    target: { value: number };
                  }) => {
                    setItemsPerPage(event.target.value);
                    if (page > 1) {
                      handlePage(1);
                    }
                    localStorage.setItem(
                      'itemsPerPage',
                      JSON.stringify(event.target.value)
                    );
                  }}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                />
              }
              tableElement={
                <Box className="w-full h-full flex-grow pb-[60px]">
                  <TableGridX
                    pageSize={itemsPerPage}
                    onPageSizeChange={(newPageSize) => {
                      setItemsPerPage(newPageSize);
                      handlePage(1);
                    }}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    pagination
                    key={String(loadingItemsToDisplay)}
                    disableSelectionOnClick={true}
                    disableColumnSelector={true}
                    rows={rows}
                    columns={columns}
                    autoHeight={true}
                    // pageSize={itemsPerPage}
                    page={page - 1}
                    paginationMode="server"
                    rowCount={rowCount || 0}
                    onPageChange={(page: number) => {
                      localStorage.setItem(
                        'propertylistInfos',
                        JSON.stringify({ page: page + 1, itemsPerPage })
                      );
                      handlePage(page + 1);
                    }}
                    checkboxSelection={!isNetworks}
                    onSelectionModelChange={(ids) => {
                      const selectedIDs = new Set(ids);
                      const selectedRows = rows.filter((row) =>
                        selectedIDs.has(row.id)
                      );
                      setSelectedRows(selectedRows);
                    }}
                  />
                </Box>
              }
            />
          )}
        </>
      )}
    </>
  );
};
export default PropertiesListingTable;
