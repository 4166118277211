import ListingHeader from '../../components/tableGridX/listingHeader';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
// import SettingsBarsIcon from '../../features/icons/settingsBarsIcon';
import EmailIcon from '../../features/icons/emailIcon';
import WebsitesIcon from '../../features/icons/websitesIcon';
import CustomAttributesIcon from '../../features/icons/customAttributesIcon';
import BrochuresIcon from '../../features/icons/brochuresIcon';
import MLSNetworkIcon from '../../features/icons/MLSNetworkIcon';
import PipelinesIcon from '../../features/icons/pipelinesIcon';
import CustomAttrTags from '../../features/icons/customAttrTags';
import LineStyleIcon from '@mui/icons-material/LineStyle';
import { useAuth } from '../../hooks/useAuth';
import useSWR from 'swr';

const SettingsListPage = () => {
  const { t } = useTranslation('propertiesPage');
  const {
    user: { company, department, filial, agency },
  } = useAuth();
  const { data: detailCompany } = useSWR(`companies/${company?.id}/details`);

  const settingsNav = [
    // {
    //   title: 'General',
    //   link: '/settings/general',
    //   icon: (
    //     <SettingsBarsIcon width={'32'} height={'32'} color={'currentColor'} />
    //   ),
    // },
    {
      title: 'Custom attributes',
      link: '/settings/properties',
      icon: (
        <CustomAttributesIcon
          width={'32'}
          height={'32'}
          color={'currentColor'}
        />
      ),
      action: detailCompany?.settingCustomAttAccess,
    },
    {
      title: 'Custom attributes tags',
      link: '/settings/customAttrTags',
      icon: (
        <CustomAttrTags
          className="origin-center rotate-90"
          width={'32'}
          height={'32'}
          color={'currentColor'}
        />
      ),
      action: detailCompany?.settingCustomAttTagAccess,
    },
    {
      title: 'Portals',
      link: '/settings/portals',
      icon: <WebsitesIcon width={'32'} height={'32'} color={'currentColor'} />,
      action: detailCompany?.settingPortalAccess,
    },
    {
      title: 'Brochures',
      link: '/brochure-templates',
      icon: <BrochuresIcon width={'32'} height={'32'} color={'currentColor'} />,
      action: detailCompany?.settingBrochureAccess,
    },
    {
      title: 'Email templates',
      link: '/settings/email-templates',
      icon: <EmailIcon width={'32'} height={'32'} color={'currentColor'} />,
      action: detailCompany?.settingEmailAccess,
    },
    {
      title: 'MLS Networks',
      link: '/settings/networks',
      icon: (
        <MLSNetworkIcon width={'32'} height={'32'} color={'currentColor'} />
      ),
      action: true,
    },
    {
      title: 'pipelines',
      link: '/settings/pipelines',
      icon: <PipelinesIcon />,
      action: detailCompany?.settingPipelineAccess,
    },
  ];

  if (company && !department && !filial && !agency) {
    settingsNav.push({
      title: 'Space types',
      link: '/settings/space-type',
      icon: <LineStyleIcon fontSize="large" />,
      action: detailCompany?.settingSpaceTypeAccess,
    });
  }

  return (
    <div className={'pt-8'}>
      <ListingHeader title={t('common:Settings')} />

      <div className="p-8 bg-white mt-8">
        <div className="grid grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
          {settingsNav.map(
            (el) =>
              el.action && (
                <NavLink
                  key={el.link}
                  className="h-44 p-4 hover:text-white bg-gray-50 text-blue-800 hover:bg-blue-800 rounded flex-col justify-center items-center gap-4 inline-flex transition-colors duration-300"
                  to={el.link}
                >
                  <div className="">{el.icon}</div>
                  <div className="self-stretch text-center text-base font-normal leading-tight">
                    {t(el.title.replaceAll(' ', '_'))}
                  </div>
                </NavLink>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsListPage;
