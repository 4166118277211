import { CSSProperties } from 'react';

import {
  PreviewSubject,
  WidgetOptions,
} from '../../../../types/brochureEditor';
import { IAddress } from '../../../../types/address';

export function useAlignmentStyles(
  widgetOptions?: WidgetOptions
): CSSProperties {
  const verticalAlign = (widgetOptions?.verticalAlign?.value ||
    'center') as string;
  const horizontalAlign = (widgetOptions?.horizontalAlign?.value ||
    'center') as string;

  return {
    alignContent: verticalAlign,
    justifyItems: horizontalAlign,
  };
}

export function useAddress(previewSubject: PreviewSubject) {
  return previewSubject.useReplacementAddress
    ? previewSubject.replacementAddress
    : previewSubject.address;
}

export function useGoogleMapsLink(address: IAddress | null) {
  if (!address?.latitude || !address?.longitude) {
    return null;
  }
  return `https://www.google.com/maps/search/?api=1&query=${address.latitude},${address.longitude}`;
}
