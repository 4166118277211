import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { WidgetPreviewProps } from '../../../../../types/brochureEditor';
import {
  useAddress,
  useAlignmentStyles,
  useGoogleMapsLink,
} from '../../../lib/hooks';
import QrCode from '../../qrCode';
import { renderFullPropertyAdress } from '../../../../../helpers/utils';

const AddressBlock = ({
  previewSubject,
  widgetOptions,
}: WidgetPreviewProps) => {
  const { t } = useTranslation('brochureEditor');
  const styles = useAlignmentStyles(widgetOptions);
  const address = useAddress(previewSubject);
  const googleMapsLink = useGoogleMapsLink(address);
  const addressString = renderFullPropertyAdress({ address });

  let contents: ReactNode = null;
  if (address) {
    const withQr = widgetOptions?.withQr?.value;

    contents = (
      <>
        {withQr && googleMapsLink && (
          <div className="mb-7">
            <QrCode value={googleMapsLink} />
          </div>
        )}
        <h2 className="text-2xl font-medium leading-7">{t('address')}</h2>
        <div className="text-sm font-medium leading-none">{addressString}</div>
      </>
    );
  } else {
    contents = <p>{t('no_address')}</p>;
  }

  return (
    <div className="grid gap-1" style={styles}>
      {contents}
    </div>
  );
};

export default AddressBlock;
