import { FC } from 'react';
import classNames from 'classnames';
import EraseIcon from '../../../../features/icons/eraseIcon';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

interface AlphabetPickerProps {
  selectedLetter: string | null;
  onLetterClick: (letter: string) => void;
  handleReset: () => void;
}

const AlphabetPicker: FC<AlphabetPickerProps> = ({
  onLetterClick,
  selectedLetter,
  handleReset,
}) => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  const { t } = useTranslation('');

  return (
    <Box
      component="div"
      sx={{
        '&::-webkit-scrollbar': {
          height: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#BBC0C4',
          borderRadius: 8,
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#888',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#f1f1f1',
        },
      }}
      className={'flex items-center gap-4 overflow-x-auto'}
    >
      {alphabet.map((letter) => (
        <button
          className={classNames('text-[24px]', {
            'text-blue': selectedLetter === letter,
          })}
          key={letter}
          onClick={() => onLetterClick(letter)}
        >
          {letter}
        </button>
      ))}
      {selectedLetter ? (
        <button
          onClick={handleReset}
          className={
            'px-4 flex items-center cursor-pointer btn_clear text-[#3446B9]'
          }
        >
          <EraseIcon className="mr-2" color="#3446B9" />
          {t('propertiesPage:clear')}
        </button>
      ) : null}
    </Box>
  );
};

export default AlphabetPicker;
