import { FC } from 'react';

import { Broker, IPropertyApi } from './property';
import { LayoutEditorState } from '../pages/brochureTemplates/lib/hooks/useLayoutEditor';
import { IMedia } from './api';
import { IAddress } from './address';
import { Translations } from './translations';
import { IPromotionApi } from './promotion';

// enums

export enum PageTypes {
  DEFAULT,
  HEADER,
  FOOTER,
  REPEATABLE,
}

export const START_PAGE_ID = 3; // 1 and 2 reserved for header and footer above
export const REPEATABLE_AREA = 'ALL';

export enum PreviewSubjectTypes {
  PROPERTY,
  PROMOTION,
}

// client-side types

export interface Template {
  options: TemplateOptions;
  pages: Record<string, Page>;
  overrides: Record<string, Override>;
  layout: LayoutEditorState;
  properties: number[];
  promotions: number[];
}

export interface TemplateOptions {
  name: string;
  usedFor: string;
  brandColor: string;
  departments: string[];
}

export interface Page {
  id: number;
  idOnServer: number | null;
  mockup?: string;
  widgets?: Record<string, Widget>;
  type: number | null;
  headerBg: IMedia | null;
  footerBg: IMedia | null;
  skipGeneratedDate?: boolean;
}

export type Override = Record<string, Page>;

export interface Widget {
  type: string;
  options: WidgetOptions;
}

export type WidgetOptions = Record<string, WidgetOptionValue> | null;

export interface WidgetOptionValue {
  value: string | boolean | null;
}

export interface WidgetDetails {
  name: string;
  icon?: FC;
  preview: {
    universal?: FC<WidgetPreviewProps>;
    independent?: FC<IndependentWidgetProps>;
    property?: FC<PropertyWidgetPreviewProps>;
    promotion?: FC<PromotionWidgetPreviewProps>;
  };
  placement: {
    layoutParts: boolean;
    pages: boolean;
    repeatablePages?: boolean;
  };
  subjectType?: PreviewSubjectTypes;
  options: Record<string, WidgetOption<any>> | null;
}

export type Choice = { label: string; value: string };

export type ChoiceGetter = (
  {
    property,
    promotion,
  }: {
    property?: IPropertyApi;
    promotion?: IPromotionApi;
  },
  locale: string
) => Choice[];

export type WidgetOption<T> = {
  name: string;
  control: FC<EditorControlProps<T>>;
  defaultValue: T;
  choices?: Choice[];
  getChoices?: ChoiceGetter;
  onlyForPreview?: boolean;
};

export interface EditorControlProps<T> {
  label?: string;
  value?: T;
  onChange?: (newValue: T) => unknown;
  choices?: Choice[];
  previewSubject?: PreviewSubject | null;
}

export interface IndependentWidgetProps {
  widgetOptions?: WidgetOptions;
  index: number;
  multipageProps: unknown;
}

export interface WidgetPreviewProps {
  previewSubject: PreviewSubject;
  widgetOptions?: WidgetOptions;
  index: number;
  multipageProps: unknown;
}

export interface PropertyWidgetPreviewProps {
  property: IPropertyApi;
  widgetOptions?: WidgetOptions;
  index: number;
  multipageProps: unknown;
}

export interface PromotionWidgetPreviewProps {
  promotion: IPromotionApi;
  widgetOptions?: WidgetOptions;
  index: number;
  multipageProps: unknown;
}

export interface LayoutPart {
  bgColor: string;
  bgImage: IMedia | null;
  borderColor: string;
  textColor: string;
  showGeneratedDate: boolean;
}

// API response types

export interface TemplateApi {
  id: number;
  name: string | null;
  usedFor: string | null;
  brand: string | null;
  pages: PageApi[];
  layout: LayoutEditorState[] | LayoutEditorState;
  properties: { id: number }[];
  promotions: { id: number }[];
  departments: { id: number; name: string }[];
  createdAt: string;
  updatedAt: string;
  createdBy: TemplateAuthor | null;
}

export interface OverrideApi {
  id: number;
  pattern: {
    id: number;
  };
  property: {
    id: number;
  } | null;
  promotion: {
    id: number;
  } | null;
  pages: PageApi[];
}

export interface PageApi {
  id: number;
  template: string | null;
  widgets: Widget[] | Record<string, Widget> | null;
  patternPageId: number | null;
  type: number | null;
  headerBg: IMedia | null;
  footerBg: IMedia | null;
  shouldSkipGeneratedDate?: boolean;
}

export interface TemplateAuthor {
  id: number;
  firstname: string | null;
  lastname: string | null;
  avatar: string | null;
  isSuperAdmin: boolean | null;
}

// request payload types

export type TemplatePayload = {
  name: string | null;
  brand: string | null;
  pages: PagePayload[];
  layout: LayoutEditorState;
  properties: number[];
  departments: string[];
  usedFor: string | null;
};

export interface OverridePayload {
  pattern: string;
  property?: string;
  promotion?: string;
  pages: PagePayload[];
}

export type PagePayload = Omit<PageApi, 'id'> & { id?: number };

// misc

export interface PreviewSubject {
  id: number;
  images: IMedia[];
  address: IAddress | null;
  replacementAddress: IAddress | null;
  useReplacementAddress: boolean | null;
  department: { id: number } | null;
  mainBroker: Broker | null;
  intermediateBroker: Broker | null;
  translations: Translations;
  reference: string;
  referenceAgency?: string | null;
  agencyReference: string | null;
  type: PreviewSubjectTypes;
}

export interface DetailsTableOption {
  id: string;
  label: string;
  value: string;
}

export type MultipagePropsGenerator = (
  ctx: MultipageGontext
) => Promise<Record<string, unknown>[]>;

export interface MultipageGontext {
  page?: Page | null;
  locale: string;
  previewSubject: PreviewSubject | null;
  widgetOptions?: WidgetOptions;
}
