import { MenuItem, Select } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ISettingEmails } from '../../types/settings';
import InputLayout from '../form/components/inputLayout';

export const languages: Record<string, string> = {
  en: 'English',
  fr: 'French',
  de: 'German',
};

const TemplateLanguageSelect = ({
  selectedTemplate,
  templates,
  selectedTemplateLang,
  setSelectedTemplateLang,
}: {
  selectedTemplate: number | null;
  templates?: ISettingEmails[];
  selectedTemplateLang: string | null;
  setSelectedTemplateLang: (value: string | null) => void;
}) => {
  const { t } = useTranslation('propertiesPage');

  if (!selectedTemplate || !templates) {
    return null;
  }

  const template = templates.find((el) => el.id === selectedTemplate);
  const options = template
    ? Object.keys(template.translations).map(
        (key) => template.translations[key]
      )
    : [];

  return (
    <InputLayout label={t('Select_template_language')}>
      <Select
        className={classNames('h-12 w-[150px]')}
        value={selectedTemplateLang}
        onChange={(event) => setSelectedTemplateLang(event.target.value)}
      >
        {options.map((item, index: number) => (
          <MenuItem
            id={`${item?.locale}`}
            key={index}
            value={item?.locale}
            className="capitalize"
          >
            {item ? t(`common:${languages[item.locale]}`) : ''}
          </MenuItem>
        ))}
      </Select>
    </InputLayout>
  );
};

export default TemplateLanguageSelect;
