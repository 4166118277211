// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style-lists ul li {
  list-style: disc inside;
}

.style-lists ol li {
  list-style: decimal inside;
}
`, "",{"version":3,"sources":["webpack://./src/pages/brochureTemplates/components/widgets/universal/list-styles.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".style-lists ul li {\n  list-style: disc inside;\n}\n\n.style-lists ol li {\n  list-style: decimal inside;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
