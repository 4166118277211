import { useEffect, useState } from 'react';
import { IContactTask } from '../../../../types/contacts';
import { Controller } from 'react-hook-form';
import InputFormField from '../../../../components/form/inputFormField';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import Swal from 'sweetalert2';

import InputLayout from '../../../../components/form/components/inputLayout';
import { TextareaAutosize } from '@mui/base';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckIcon from '@mui/icons-material/Check';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { AxiosResponse } from 'axios';
import { http } from '../../../../libs/axios';
import { useNotification } from '../../../../hooks/useNotification';
import SpinnerLoading from '../../../../features/spinner/spinnerLoading';
import useSWR from 'swr';
import useLocale from '../../../../hooks/useLocale';
import { Translations } from '../../../../types/translations';
import DateRangeField from '../../../../components/form/dateRangeField';
import TimeField from '../../../../components/form/TimeField';
import CloseIcon from '../../../../features/icons/closeIcon';
import { updateHistoric } from '../../../../helpers/utils/contacts';
import { IPropertyApi } from '../../../../types/property';
import usePropertiesAutocomplete from '../../../../hooks/usePropertiesAutocomplete';
// import AutoCompleteField from '../../../../components/form/autoCompleteField';
import { useTranslation } from 'react-i18next';
import { useProtectedForm } from '../../../../hooks/useProtectedForm';
import Autocomplete from '@mui/material/Autocomplete';

export interface FormValues {
  title: string;
  type: number | null;
  topic: string;
  status: number | null;
  note: string;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  startTime: Dayjs | null;
  endTime: Dayjs | null;
  notificationTime: number | null;
  notificationOption: number;
  property: number | null;
  properties: {
    id: number | string;
    label: string;
  }[];
}

function addTimeToDate(date: Dayjs, time?: Dayjs | null) {
  if (!time) return date;
  return date
    .set('hour', time.get('hour'))
    .set('minute', time.get('minute'))
    .set('second', time.get('second'));
}

interface Props {
  data: IContactTask | null;
  createMode?: boolean;
  onDeleteItem?: () => void;
  contactId: string;
  mutate: any;
  onAfterSubmit: () => void;
  taskId?: string | number;
  editMode?: boolean;
  verticalMode?: boolean;
}

const notifyOptions = [
  {
    id: 0,
    value: 'minutes',
  },
  {
    id: 1,
    value: 'hours',
  },
  {
    id: 2,
    value: 'days',
  },
  {
    id: 3,
    value: 'weeks',
  },
];

const transformDataFromServer = (data: IContactTask, locale: string) => {
  const returnerValue: Partial<FormValues> = {
    title: data.title,
    topic: data.topic,
    note: data.note,
    property: data.property ? data.property.id : null,
    properties: data.properties
      ? data.properties.map((el) => ({
          id: el.id.toString(),
          label: el.translations?.[locale]?.title || el.id.toString(),
        }))
      : [],
    startDate: data.startDate ? dayjs(data.startDate) : null,
    endDate: data.endDate ? dayjs(data.endDate) : null,
    startTime: data.startDate ? dayjs(data.startDate) : null,
    endTime: data.endDate ? dayjs(data.endDate) : null,
    notificationTime: null,
    notificationOption: 0,
    type: data?.type?.id || null,
    status: data?.status?.id || null,
  };

  if (data.reminderTime) {
    const notification = getReminderValue(data.reminderTime);
    returnerValue.notificationOption = notification.option;
    returnerValue.notificationTime = notification.time;
  } else {
    returnerValue.notificationOption = 0;
    returnerValue.notificationTime = null;
  }

  return returnerValue;
};

const getReminderValue = (minutes: string | null) => {
  if (minutes === null) {
    return {
      time: null,
      option: 0,
    };
  }

  const time = parseInt(minutes);

  const returnedValue = {
    time: time,
    option: 0,
  };

  if (time >= 60) {
    returnedValue.time = time / 60;
    returnedValue.option = 1;
  }

  if (time >= 1440) {
    returnedValue.time = time / 24 / 60;
    returnedValue.option = 2;
  }

  if (time >= 10080) {
    returnedValue.time = time / 7 / 24 / 60;
    returnedValue.option = 3;
  }

  return returnedValue;
};

const getReminderTime = (time: string, option: number) => {
  const timeNumber = parseInt(time);

  switch (option) {
    case 0:
      return timeNumber;
    case 1:
      return timeNumber * 60;
    case 2:
      return timeNumber * 24 * 60;
    case 3:
      return timeNumber * 7 * 24 * 60;
  }
};

const defaultOptions: [] = [];

const CreateTaskForm = (props: Props) => {
  const {
    data,
    createMode,
    onDeleteItem,
    contactId,
    mutate,
    onAfterSubmit,
    taskId,
    editMode,
    verticalMode,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useNotification();
  const { locale } = useLocale();

  const { data: statuses } = useSWR('/task_statuses');
  const { data: taskTypes } = useSWR('/task_types');
  const { t } = useTranslation('common');
  const statusOptions = statuses
    ? statuses.map((item: { id: number; translations: Translations }) => ({
        id: item.id,
        value: item.translations?.[locale]?.name || '',
      }))
    : [];

  const tasksOptions = taskTypes
    ? taskTypes.map((item: { id: number; translations: Translations }) => ({
        id: item.id,
        value: item.translations?.[locale]?.name || '',
      }))
    : [];

  const initialValues = {
    title: '',
    type: null,
    topic: '',
    status: null,
    note: '',
    date: '',
    time: '',
    property: null,
    properties: [],
    notificationTime: null,
    notificationOption: 0,
    startDate: dayjs(new Date()).add(9, 'hour'),
    endDate: dayjs(new Date()).add(10, 'hour'),
    startTime: dayjs(new Date()).add(9, 'hour'),
    endTime: dayjs(new Date()).add(10, 'hour'),
  };

  const defaultValues = data
    ? transformDataFromServer(data, locale)
    : initialValues;

  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    formState: { isDirty },
    setValue,
  } = useProtectedForm<FormValues>(
    {
      defaultValues,
    },
    data
  );

  const status = watch('status');

  useEffect(() => {
    if (statusOptions?.length && !status) {
      setValue('status', statusOptions[0]?.id);
    }
  }, [statusOptions, status, setValue]);

  const onSubmit = (data: any) => {
    const body = {
      title: data.title,
      topic: data.topic,
      type: data.type,
      status: data.status,
      property: data.property,
      properties:
        data.properties && data.properties.length
          ? data.properties.map((el: { id: string }) => el.id)
          : [],
      startDate: addTimeToDate(data.startDate, data.startTime),
      endDate: addTimeToDate(data.endDate, data.endTime),
      note: data.note,
      reminderTime: getReminderTime(
        data.notificationTime,
        data.notificationOption
      ),
    };

    submitDataToServer(body);
  };

  const deleteTask = () => {
    if (onDeleteItem) {
      onDeleteItem();
    }

    if (editMode) {
      setIsLoading(true);

      http
        .delete(`/contact/${contactId}/task/${taskId}`)
        .then(() => {
          const msg = `${t('Task_has_been_deleted')}`;
          addNotification(msg, 'success');
          updateHistoric(contactId, msg);
          if (mutate) {
            mutate();
          }
          setIsLoading(false);
        })
        .catch((error) => {
          let message;
          if (error.response) {
            message = 'Server error';
          } else if (error.request) {
            message = t('Failed_to_connect_to_server');
          } else {
            message = t('Unknown_error');
          }
          addNotification(message, 'error');
        });
    }
  };

  const onDeleteItemClick = async () => {
    const { isConfirmed } = await Swal.fire({
      text: t(
        'Please_confirm_removal_of_the_task_This_action_cannot_be_reverted'
      ),
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: 'Remove task',
      showClass: {
        popup: 'block',
      },
      hideClass: {
        popup: 'hidden',
      },
    });
    if (isConfirmed) {
      deleteTask();
    }
  };

  const submitDataToServer = async (body: any) => {
    let promise: Promise<AxiosResponse>;
    if (editMode) {
      promise = http.patch(`contact/${contactId}/task/${taskId}`, body);
    } else {
      promise = http.post(`contact/${contactId}/task`, body);
    }

    setIsLoading(true);
    promise
      .finally(() => {
        setIsLoading(false);
      })
      .then(() => {
        const msg = `${t('Task_has_been')} ${
          editMode ? t('updated') : t('created')
        }.`;
        addNotification(msg, 'success');
        updateHistoric(contactId, msg);
        if (mutate) {
          mutate();
        }
        onAfterSubmit();
        if (!editMode) {
          reset();
        }
      })
      .catch((error) => {
        let message;
        if (error.response) {
          message = 'Server error';
        } else if (error.request) {
          message = t('Failed_to_connect_to_server');
        } else {
          message = t('Unknown_error');
        }
        addNotification(message, 'error');
      });
  };

  const startDate = watch('startDate');
  const startTime = watch('startTime');

  useEffect(() => {
    if (startTime) {
      setValue('endTime', startTime.add(1, 'hour'));
    }
  }, [startTime, setValue]);

  const { data: defaultProperty } = useSWR<IPropertyApi>(
    defaultValues.property
      ? `/v1/properties/${defaultValues.property}`
      : undefined
  );

  const {
    options,
    defaultValue: defaultAutocompleteValue,
    searchQuery,
    setSearchQuery,
  } = usePropertiesAutocomplete({
    selectedProperty: defaultProperty,
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames('pt-4 relative', {
        'grid grid-cols-3 gap-8 2xl:gap-x-16': verticalMode,
        'flex flex-col gap-4': !verticalMode,
      })}
    >
      <div className={'order-1'}>
        <InputFormField
          type="text"
          label={t('Task_title')}
          {...register('title', { required: true })}
          isRequired
        />
      </div>
      <InputLayout className={'order-2'} label={t('Task_type')}>
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <Select
              sx={{
                '& .MuiSelect-select': {
                  textTransform: 'capitalize',
                },
              }}
              className={'h-[56px]'}
              labelId="level-label"
              defaultValue={field.value}
              {...field}
            >
              {tasksOptions &&
                tasksOptions.map(
                  (item: { id: number; value: string }, index: number) => (
                    <MenuItem
                      key={index}
                      value={item?.id}
                      className="capitalize"
                    >
                      {item.value}
                    </MenuItem>
                  )
                )}
            </Select>
          )}
        />
      </InputLayout>

      <div className="order-2">
        <InputLayout label={t('Properties')}>
          <Controller
            control={control}
            name="properties"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, item) => {
                  onChange(item);
                }}
                onInputChange={(event, newInputValue) => {
                  setSearchQuery(newInputValue);
                }}
                value={value}
                multiple
                options={options || defaultOptions}
                defaultValue={[defaultAutocompleteValue]}
                loadingText={
                  !searchQuery ? t('common:start_typing') : undefined
                }
                renderInput={(params) => (
                  <TextField
                    className="select_broker_contact"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            )}
          />
        </InputLayout>
      </div>

      <div className="pt-4 order-3 grid gap-2">
        <InputLayout label={''}>
          <DateRangeField
            nameFrom="startDate"
            nameTo="endDate"
            control={control}
            labelFrom={t('From')}
            labelTo={t('To')}
          />
        </InputLayout>
        <div className="flex gap-10">
          <InputLayout>
            <TimeField
              name="startTime"
              control={control}
              digitalClockProps={{
                skipDisabled: true,
                timeStep: 15,
              }}
            />
          </InputLayout>
          <InputLayout>
            <TimeField
              name="endTime"
              control={control}
              digitalClockProps={{
                skipDisabled: true,
                minTime: startDate
                  ? addTimeToDate(startDate, startTime)
                  : undefined,
                timeStep: 15,
              }}
            />
          </InputLayout>
        </div>
      </div>
      <div
        className={classNames('grid', {
          'order-7': verticalMode,
          'order-4': !verticalMode,
        })}
      >
        {watch('notificationTime') === null ? (
          <div className="flex items-center">
            <Button
              onClick={() => {
                setValue('notificationTime', 30, { shouldDirty: true });
              }}
              className="w-full flex"
            >
              {t('Add_notification')}
            </Button>
          </div>
        ) : (
          <InputLayout label={t('Notify_me_before')}>
            <div className={'flex gap-4 items-center'}>
              <div className={'w-1/5 min-w-[75px]'}>
                <InputFormField
                  type="number"
                  label=""
                  {...register('notificationTime')}
                />
              </div>
              <div className={'w-full'}>
                <Controller
                  name="notificationOption"
                  control={control}
                  render={({ field }) => (
                    <Select
                      sx={{
                        '& .MuiSelect-select': {
                          textTransform: 'capitalize',
                        },
                      }}
                      className={'h-[56px] w-full'}
                      labelId="level-label"
                      defaultValue={field.value}
                      {...field}
                    >
                      {notifyOptions.map(
                        (
                          item: { id: number; value: string },
                          index: number
                        ) => (
                          <MenuItem key={index} value={item?.id}>
                            {item.value}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  )}
                />
              </div>
              <div className="">
                <Tooltip title={t('Remove_notification')}>
                  <IconButton
                    aria-label="Remove notification"
                    onClick={() =>
                      setValue('notificationTime', null, { shouldDirty: true })
                    }
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </InputLayout>
        )}
      </div>
      <div className={'order-5'}>
        <InputFormField type="text" label={t('Topic')} {...register('topic')} />
      </div>
      <InputLayout className={'order-6'} label={t('Status')} isRequired>
        <Controller
          key={watch('status')}
          name="status"
          control={control}
          render={({ field }) => (
            <Select
              sx={{
                '& .MuiSelect-select': {
                  textTransform: 'capitalize',
                },
              }}
              className={'h-[56px]'}
              labelId="level-label"
              required
              defaultValue={field.value}
              {...field}
            >
              {statusOptions &&
                statusOptions.map(
                  (item: { id: number; value: string }, index: number) => (
                    <MenuItem
                      key={index}
                      value={item?.id}
                      className="capitalize"
                    >
                      {item.value}
                    </MenuItem>
                  )
                )}
            </Select>
          )}
        />
      </InputLayout>
      <InputLayout className={'order-7'} label={t('Notes')}>
        <Controller
          name={'note'}
          control={control}
          defaultValue={''}
          render={({ field: { value, ...restField } }) => (
            <FormControl
              sx={{
                border: '1px solid #c4c4c4',
                borderRadius: '4px',
                '&:hover': {
                  borderColor: '#1D1D1F',
                },
              }}
            >
              <TextareaAutosize
                minRows={4}
                maxRows={6}
                value={value}
                className={
                  'MuiInputBase-input MuiOutlinedInput-input rounded p-4'
                }
                {...restField}
              />
            </FormControl>
          )}
        />
      </InputLayout>
      <div
        className={classNames(
          'col-start-1 col-end-4 flex items-center gap-4 order-8',
          {
            'justify-between': createMode,
            'justify-end': !createMode,
            'pt-8': !verticalMode,
          }
        )}
      >
        <Button
          onClick={onDeleteItemClick}
          type="button"
          variant="outlined"
          className="flex justify-center items-center order-7"
          sx={{
            height: '42px',
            color: createMode ? '#1D1D1F' : '#F50057',
          }}
        >
          <Box className="flex justify-center items-center" gap="16px">
            {!createMode ? <DeleteOutlineIcon fontSize="small" /> : null}
            <div className="font-medium">
              {createMode ? t('cancel') : t('Delete_task')}
            </div>
          </Box>
        </Button>
        <Button
          disabled={!isDirty}
          type="submit"
          variant="outlined"
          className="flex justify-center items-center"
          sx={{
            color: '#3446B9',
            height: '42px',
          }}
        >
          <Box className="flex justify-center items-center" gap="16px">
            <div className="font-medium">
              {createMode ? t('Save') : t('Update')}
            </div>
            <CheckIcon
              fontSize="small"
              sx={{
                width: '16px',
                height: '14px',
              }}
            />
          </Box>
        </Button>
      </div>
      {isLoading ? (
        <div className={'absolute inset-0 z-10'}>
          <SpinnerLoading />
        </div>
      ) : null}
    </form>
  );
};

export default CreateTaskForm;
