import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { devtools } from 'zustand/middleware';
import { useMemo } from 'react';

import {
  Widget,
  Page,
  PageTypes,
  Override,
  REPEATABLE_AREA,
  PreviewSubject,
} from '../../../../types/brochureEditor';
import { widgetTypes } from '../widgetTypes';
import {
  getPagesWithoutLayoutParts,
  standartizeWidgetOptionValues,
} from '../utils';
import { gridTemplateAreasOptions } from '../../components/editorLayout/mockupSelection';

interface PagesEditorSlice {
  pageIdCount: number;
  pages: Record<string, Page>;
  overrides: Record<string, Override>;
  editedPageId: number | null;
  editedLayoutPartId: number | null;
  editedAreaId: string | null;
  setPages: (pages: Record<string, Page>) => void;
  setOverrides: (overrides: Record<string, Override>) => void;
  addPage: () => void;
  removePage: (pageId: number) => void;
  setEditedPage: (pageId: number | null) => void;
  setEditedArea: (
    areaId: string | null,
    containingLayoutPartId?: number
  ) => void;
  setPageMockup: (pageId: number, template: string) => void;
  setPageProperty: (pageId: number, property: keyof Page, value: any) => void;
  assignWidgetToArea: (pageId: number, area: string, widget: Widget) => void;
  setWidgetOption: (
    pageId: number,
    areaId: string,
    key: string,
    value: string | boolean,
    editedPropertyId?: number
  ) => void;
  clearArea: (pageId: number, area: string) => void;
}

export const usePagesEditor = create<PagesEditorSlice>()(
  devtools(
    immer((set) => ({
      pageIdCount: 0,
      pages: {},
      overrides: {},
      editedPageId: null,
      editedLayoutPartId: null,
      editedAreaId: null,
      setPages: (pages) => {
        set((state) => {
          state.pages = pages;
        });
      },
      setOverrides: (overrides) => {
        set((state) => {
          state.overrides = overrides;
        });
      },
      addPage: () =>
        set((state) => {
          const maxPageId = Math.max(
            ...Object.values(state.pages).map((page) => page.id)
          );
          state.pageIdCount = Math.max(maxPageId, state.pageIdCount) + 1;
          state.pages[state.pageIdCount] = {
            id: state.pageIdCount,
            idOnServer: null,
            type: PageTypes.DEFAULT,
            headerBg: null,
            footerBg: null,
          };
        }),
      setEditedPage: (pageId) => {
        set((state) => {
          state.editedAreaId = null;
          state.editedPageId = pageId;
        });
      },
      setEditedArea: (areaId, containingLayoutPartId) => {
        set((state) => {
          state.editedAreaId = areaId;
          state.editedLayoutPartId = containingLayoutPartId || null;
        });
      },
      removePage: (pageId) => {
        set((state) => {
          delete state.pages[pageId];
        });
      },
      setPageMockup: (pageId, mockup) => {
        set((state) => {
          const page = state.pages[pageId];
          if (page) {
            page.mockup = mockup;
          }
        });
      },
      setPageProperty(pageId, property, value) {
        set((state) => {
          const page = state.pages[pageId];
          if (page) {
            // @ts-ignore
            page[property] = value;
          }
        });
      },
      assignWidgetToArea: (pageId, area, widget) => {
        set((state) => {
          const page = state.pages[pageId];
          if (page) {
            if (!page.widgets) {
              page.widgets = {};
            }
            if (widget.type === 'blank') {
              delete page.widgets[area];
            } else {
              page.widgets[area] = widget;
            }
            if (widget.type === 'multipageDescription') {
              page.mockup = gridTemplateAreasOptions[0];
            }
          }
        });
      },
      setWidgetOption: (pageId, area, key, value, editedPropertyId) => {
        set((state) => {
          let source: Record<string, Page> = state.pages;
          if (editedPropertyId) {
            if (!state.overrides[editedPropertyId]) {
              state.overrides[editedPropertyId] = {};
            }
            // @ts-ignore
            source = state.overrides[editedPropertyId];
          }
          // @ts-ignore
          if (!source[pageId]) {
            // @ts-ignore
            source[pageId] = { id: pageId };
          }
          // @ts-ignore
          if (!source[pageId].widgets) {
            // @ts-ignore
            source[pageId].widgets = {};
          }
          // @ts-ignore
          if (!source[pageId].widgets[area]) {
            // @ts-ignore
            source[pageId].widgets[area] = {};
          }
          if (
            // @ts-ignore
            !source[pageId].widgets[area].options ||
            // @ts-ignore
            isEmptyArray(source[pageId].widgets[area].options)
          ) {
            // @ts-ignore
            source[pageId].widgets[area].options = {};
          }
          // @ts-ignore
          if (!source[pageId].widgets[area].options[key]) {
            // @ts-ignore
            source[pageId].widgets[area].options[key] = { value };
          } else {
            // @ts-ignore
            source[pageId].widgets[area].options[key].value = value;
          }
        });
      },
      clearArea: (pageId, area) => {
        set((state) => {
          const page = state.pages[pageId];
          if (page && page.widgets) {
            delete page.widgets[area];
          }
        });
      },
    }))
  )
);

export const usePagesEditorComputables = () => {
  const { pages, editedPageId, editedLayoutPartId, editedAreaId } =
    usePagesEditor();

  const justPages = getPagesWithoutLayoutParts(pages);

  let editedPage = null;
  let editedPageIndex = null;
  if (editedPageId) {
    editedPage = pages[editedPageId] || null;
    editedPageIndex = justPages.map((page) => page.id).indexOf(editedPageId);
  }

  const editedPageIsRepeatable = editedPage?.type === PageTypes.REPEATABLE;
  const editedAreaIdFull = editedPageIsRepeatable
    ? REPEATABLE_AREA
    : editedAreaId;

  let editedLayoutPart = null;
  if (editedLayoutPartId) {
    editedLayoutPart = pages[editedLayoutPartId] || null;
  }

  let editedWidget = null;
  if (editedLayoutPart && editedAreaId) {
    editedWidget = editedLayoutPart.widgets?.[editedAreaId] || null;
  } else if (editedPage && editedAreaIdFull) {
    editedWidget = editedPage.widgets?.[editedAreaIdFull] || null;
  }

  editedWidget = standartizeWidgetOptionValues(editedWidget);

  let editedWidgetDetails = null;
  if (editedWidget) {
    editedWidgetDetails = widgetTypes[editedWidget.type] || null;
  }

  const header = Object.values(pages).find(
    (page) => page.type === PageTypes.HEADER
  );
  const footer = Object.values(pages).find(
    (page) => page.type === PageTypes.FOOTER
  );

  return {
    pages: justPages,
    editedPage,
    editedPageIndex,
    editedPageIsRepeatable,
    editedAreaIdFull:
      editedPageIsRepeatable && !editedLayoutPartId
        ? REPEATABLE_AREA
        : editedAreaId,
    editedLayoutPart,
    editedWidget,
    editedWidgetDetails,
    isEditingLayout: !!editedLayoutPartId,
    header,
    footer,
    editedEntityId: editedLayoutPartId || editedPageId,
  };
};

export function useWidgetOptions({
  previewSubject,
  page,
}: {
  previewSubject?: PreviewSubject | null;
  page?: Page | null;
}) {
  const { overrides } = usePagesEditor();

  return useMemo(() => {
    const widgetOverrides =
      overrides[previewSubject?.id || '']?.[page?.id || '']?.widgets?.[
        REPEATABLE_AREA
      ]?.options;

    const widgetOptions = {
      ...page?.widgets?.[REPEATABLE_AREA]?.options,
      ...widgetOverrides,
    };

    return widgetOptions;
  }, [page, overrides]);
}

function isEmptyArray(array: unknown) {
  return Array.isArray(array) && array.length === 0;
}
