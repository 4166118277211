import {
  ICustomAttributesApi,
  IDataFields,
  ISettings,
} from '../../../../../types/settings';

import CustomAttributeItem from './customAttributeItem';
import { useTranslation } from 'react-i18next';
import { useProtectedForm } from '../../../../../hooks/useProtectedForm';

const CustomAttributesList = ({
  fieldsList,
}: {
  fieldsList: ICustomAttributesApi[];
}) => {
  const defaultValues = useDefaultValues(fieldsList);
  const { register, watch, resetField, control, getValues, setValue } =
    useProtectedForm<ISettings>({ defaultValues }, fieldsList);
  const { t } = useTranslation('propertiesPage');
  return fieldsList.length ? (
    <div>
      <div className="pb-2 border-b border-gray-200 mb-8 pt-10 border-t">
        <div className="grow shrink basis-0 text-zinc-900 text-base md:text-2xl font-normal leading-7">
          {t('Created_attributes')}
        </div>
      </div>

      {[...fieldsList].map((field: any, index: number) => {
        return (
          <div key={field + index}>
            <CustomAttributeItem
              getValues={getValues}
              defaultValues={defaultValues}
              control={control}
              resetField={resetField}
              name="dataFields"
              setValue={setValue}
              watch={watch}
              index={index}
              register={register}
            />
          </div>
        );
      })}
    </div>
  ) : null;
};

export default CustomAttributesList;

function useDefaultValues(data: ICustomAttributesApi[]): {
  dataFields: IDataFields[];
} {
  const dataFields = data.map((item) => {
    return {
      ...item,
      id: String(item.id),
      category: String(item?.category?.id),
      isVisible: item?.isVisible ? '1' : '0',
      step: String(item?.step),
      type: String(item?.type),
      departments: item?.departments?.map((department) =>
        String(department?.id)
      ),
      // propertyType: String(item?.propertyType?.id),
      publishedOnWebsite: item?.publishedOnWebsite ? true : false,
      tag: item?.tag?.id ? String(item?.tag?.id) : null,
      propertyTypes: item?.propertyTypes?.map((propertyType) =>
        String(propertyType?.id)
      ),
    };
  });

  // @ts-ignore omg type safety of custom attributes is brilliant!!!!
  return { dataFields };
}
