import AttachEmailOutlined from '@mui/icons-material/AttachEmailOutlined';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import ActionMenu from '../../components/common/actionMenu';
import { useAnchorElement } from '../../hooks/useAnchorElement';
import { ActionData } from '../../types/actionData';
import { Document } from '../../types/property';
import { useState } from 'react';
import SendBrochurePopup from './sendBrochurePopup';
import MergeFilesPopup from './mergeFilesPopup';

const SelectedFilesActionMenu = ({
  selectedFiles,
  propertyId,
  mutate,
  onMergedFilesSuccess,
}: {
  propertyId?: string;
  selectedFiles: (Document | undefined)[];
  mutate?: any;
  onMergedFilesSuccess?: () => void;
}) => {
  const anchor = useAnchorElement();
  const [openSendFilesModal, setOpenSendFilesModal] = useState(false);
  const [openMergeFilesModal, setOpenMergeFilesModal] = useState(false);

  const onSendFilesClick = () => {
    setOpenSendFilesModal(true);
  };

  const onMergeFilesClick = () => {
    setOpenMergeFilesModal(true);
  };

  const menuActionData: ActionData[] = [
    {
      label: 'Send_selected_brochures_via_email',
      icon: AttachEmailOutlined,
      onClick: onSendFilesClick,
      class: '!hidden md:!block',
      disabled: selectedFiles.length === 0,
    },
    {
      label: 'Merge_selected_files',
      icon: CallMergeIcon,
      onClick: onMergeFilesClick,
      class: '!hidden md:!block',
      disabled: selectedFiles.length <= 1,
    },
  ];

  return (
    <>
      <ActionMenu
        {...anchor}
        menuAction={menuActionData}
        transPage={'brochureEditor'}
      />
      {selectedFiles.length === 0 ? null : (
        <>
          <SendBrochurePopup
            propertyId={propertyId}
            rowSelection={selectedFiles}
            openModal={openSendFilesModal}
            setOpenModal={setOpenSendFilesModal}
          />
          <MergeFilesPopup
            propertyId={propertyId}
            selectedFiles={selectedFiles}
            openModal={openMergeFilesModal}
            setOpenModal={setOpenMergeFilesModal}
            onMergedFilesSuccess={onMergedFilesSuccess}
            mutate={mutate}
          />
        </>
      )}
    </>
  );
};

export default SelectedFilesActionMenu;
