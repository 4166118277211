import { useEffect, useMemo, useState } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import NotificationDetails from './notificationDetails';
import { Box, Fade, IconButton, Link, Typography } from '@mui/material';
import CloseIcon from '../../features/icons/closeIcon';
import ItemList from './itemList';
import NotifBtn from './notifBtn';
import useSWR, { mutate } from 'swr';
import { useAuth } from '../../hooks/useAuth';
import { INotification, INotificationApi } from '../../types/notification';
import { ApiListing } from '../../types/api';
import useLocale from '../../hooks/useLocale';
import Notification from './notification';
import { http } from '../../libs/axios';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useTranslation } from 'react-i18next';
export type notificationsBtnT = {
  id: number;
  label: string;
  active: boolean;
};
export const listButton: notificationsBtnT[] = [
  { id: 1, label: 'All_updates', active: true },
  { id: 2, label: 'Unread', active: false },
  { id: 3, label: 'Read', active: false },
];

export default function Notifications() {
  const {
    user: { id: userId },
  } = useAuth();

  const { t } = useTranslation('notificationsPage');
  const [hiddenBlock, setHiddenBlock] = useState(true);
  const [articleId, setArticleId] = useState<number | null>(null);
  const [drawerWidth, setDrawerWidth] = useState('23%');
  const [unreadCount, setUnreadCount] = useState(0);
  const showHiddenBlock = () => {
    setHiddenBlock(false);
    setDrawerWidth('60%');
  };

  const { locale } = useLocale();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const APIkey = `/notifications/users/${userId}`;

  const { data: notifications } = useSWR<ApiListing<INotificationApi>>(APIkey);
  const notificationList = useMemo(
    () => (notifications ? notifications['hydra:member'] : []),
    [notifications]
  );

  const [showFiltredList, setShowFiltredList] = useState<INotificationApi[]>(
    []
  );

  useEffect(() => {
    setShowFiltredList(notificationList);
    setUnreadCount(notificationList.filter((elem) => !elem.isRead).length);
  }, [notificationList]);
  const [buttons, setButtons] = useState<notificationsBtnT[]>(listButton);
  const [activeBtn, setActiveBtn] = useState<number>(1);

  const setId = (id: number) => {
    setArticleId(id);
  };
  const onSubmit = async () => {
    const idsToSend = notificationList.filter((item) => item.isRead === false);
    if (idsToSend.length) {
      await http.patch(APIkey, changeToRead(idsToSend));
      mutate(APIkey);
    }
  };
  const readOne = async (index: number) => {
    await http.patch(APIkey, {
      toRead: [index],
    });
    filterData(activeBtn);
  };
  const filterData = (btnIndex: number) => {
    switch (btnIndex) {
      case 2:
        mutate(APIkey + '?isRead=' + false);
        setShowFiltredList(notificationList.filter((elem) => !elem.isRead));
        break;
      case 3:
        mutate(APIkey);
        setShowFiltredList(notificationList.filter((elem) => elem.isRead));
        break;
      default:
        mutate(APIkey);
        setShowFiltredList(notificationList);
        break;
    }
    mutate(APIkey);
  };
  const toggleDrawer = (toggle: boolean) => {
    setTimeout(() => setIsDrawerOpen(toggle), 50);
  };

  const changeActive = (activeIndex: number) => {
    setActiveBtn(activeIndex);
    setUnreadCount(notificationList.filter((elem) => !elem.isRead).length);
    listButton.map((elem) => {
      elem.id === activeIndex ? (elem.active = true) : (elem.active = false);
    });
    setButtons(listButton);
    filterData(activeBtn);
    setHiddenBlock(true);
    setDrawerWidth('23%');
  };

  const changeToRead = (
    notificationList:
      | { id: number; isRead: boolean; notification: INotification }[]
      | undefined
  ): { toRead: number[] } => {
    const notifId: number[] = [];

    notificationList &&
      notificationList.map((item) => {
        !item.isRead && notifId.push(item.notification.id);
      });

    return { toRead: notifId };
  };

  useEffect(() => {
    filterData(activeBtn);
    setUnreadCount(
      notificationList.filter((elem) => elem.isRead == false).length
    );
  }, [activeBtn]);

  const onClickAway = () => {
    if (isDrawerOpen) {
      setIsDrawerOpen(false);
      setHiddenBlock(true);
    }
  };

  return (
    <>
      <NotifBtn
        toggleDrawer={toggleDrawer}
        changeActive={changeActive}
        count={unreadCount}
        activeDot={notificationList?.find((elem) => elem.isRead == false)}
      />

      <SwipeableDrawer
        swipeAreaWidth={0}
        PaperProps={{
          sx: { maxWidth: drawerWidth, width: drawerWidth },
        }}
        anchor={'right'}
        open={isDrawerOpen}
        onClose={() => {
          toggleDrawer(false);
          setHiddenBlock(true);
          setDrawerWidth('23%');
        }}
        onOpen={() => toggleDrawer(true)}
      >
        <ClickAwayListener onClickAway={onClickAway}>
          <Fade in={isDrawerOpen}>
            <Box
              sx={{
                height: '100%',
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  borderRight: '1px solid #E6E8EC',
                  width: '100%',
                }}
              >
                <div className="flex items-start justify-between p-6">
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '24px',
                      fontWeight: 'normal',
                      lineHeight: '28px',
                    }}
                  >
                    {t('Whats_new_on_Real')}
                  </Typography>
                  <IconButton
                    style={{
                      cursor: 'pointer',
                      marginRight: '-14px',
                      marginTop: '-14px',
                    }}
                    onClick={() => {
                      hiddenBlock ? toggleDrawer(false) : setHiddenBlock(true);
                      setHiddenBlock(true);
                      setDrawerWidth('23%');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '24px',
                    padding: '30px 10px',

                    fontFamily: 'Inter',
                    borderTop: '1px solid #E6E8EC',
                    borderBottom: '1px solid #E6E8EC',
                  }}
                >
                  <ul className="flex gap-2 flex-wrap text-center">
                    {buttons?.map(({ id, label, active }) => (
                      <div key={id} onClick={() => changeActive(id)}>
                        <ItemList
                          index={id}
                          name={t(label)}
                          active={active}
                          count={unreadCount}
                        />
                      </div>
                    ))}
                  </ul>
                  <Box>
                    <Link
                      onClick={onSubmit}
                      href="#"
                      sx={{
                        textDecoration: 'none',
                        fontFamily: 'Inter',
                        fontSize: '11px',
                        lineHeight: '16px',
                        fontWeight: '500',
                        color: '#3446B9',
                      }}
                    >
                      {' '}
                      {t('Mark_all_as_read')}
                    </Link>
                  </Box>
                </Box>

                <div className="border border-gray-100 rounded-lg">
                  <ol className="divide-y divider-gray-200 flex flex-col gap-2 py-2">
                    {showFiltredList?.map(({ id, notification, isRead }) => (
                      <Notification
                        key={id}
                        index={id}
                        notifId={notification.id}
                        title={notification?.translations[locale]?.title || ''}
                        date={notification?.publishDate || ''}
                        description={
                          notification?.translations[locale]?.description || ''
                        }
                        readOne={readOne}
                        read={isRead}
                        activeBtn={activeBtn}
                        showHiddenBlock={showHiddenBlock}
                        setId={setId}
                      />
                    ))}
                  </ol>
                </div>
              </Box>
              <Box
                className={`${
                  hiddenBlock ? 'hidden' : ''
                } max-w-[61%] min-w-[61%]`}
              >
                <NotificationDetails
                  notificationList={
                    notificationList.find(
                      (elem) => elem.id == articleId
                    ) as INotificationApi
                  }
                />
              </Box>
            </Box>
          </Fade>
        </ClickAwayListener>
      </SwipeableDrawer>
    </>
  );
}
