import { useTranslation } from 'react-i18next';
import {
  MultipagePropsGenerator,
  WidgetPreviewProps,
} from '../../../../../types/brochureEditor';

import './list-styles.css';

const CONTENT_WRAPPER_CLASS =
  'text-[19px] leading-[calc(28/24)] grow relative style-lists grid gap-[1.5em] content-start';

const MultipageDescriptionWidget = ({
  widgetOptions,
  multipageProps,
}: WidgetPreviewProps) => {
  const textAlign = widgetOptions?.textAlign?.value;
  const color = widgetOptions?.color?.value;
  const props = multipageProps as { description?: string; index: number };
  const { t } = useTranslation('common');

  return (
    <div className="flex flex-col gap-4">
      {props?.index === 0 && (
        <h2
          className="text-2xl font-semibold leading-7"
          style={{ color: 'var(--brand-color)' }}
        >
          {t('Description')}
        </h2>
      )}
      <div
        className={CONTENT_WRAPPER_CLASS}
        // @ts-ignore
        style={{ textAlign, color }}
        dangerouslySetInnerHTML={{ __html: props?.description || '' }}
      ></div>
    </div>
  );
};

export default MultipageDescriptionWidget;

export const generateProps: MultipagePropsGenerator = async ({
  locale,
  previewSubject,
  widgetOptions,
}) => {
  if (!previewSubject) {
    return [{ description: '', index: 0 }];
  }

  const PAGE_HEIGHT = calculatePageHeight();

  const source = widgetOptions?.source?.value;
  let description = '';
  if (source === 'second') {
    description =
      previewSubject.translations?.[locale]?.secondDescription || '';
  } else {
    description = previewSubject.translations?.[locale]?.description || '';
  }

  const elements = createHtmlElementsFromString(description);
  const eachPageProps = [];

  const currentPageContent = createTestDiv();
  const potentialPageContent = createTestDiv();

  let pageIndex = 0;

  for (const element of elements) {
    // @ts-ignore
    const thisElementContent: string = element.outerHTML || element.textContent;

    if (measureElementHeight(element) > PAGE_HEIGHT) {
      console.error(
        'Element content is way too big for a single page so it will be trimmed in order to fit the page: ',
        element
      );

      // append this element to empty page
      if (currentPageContent.children.length !== 0) {
        eachPageProps.push({
          description: currentPageContent.innerHTML,
          index: pageIndex++,
        });
      }
      currentPageContent.innerHTML = '';
      potentialPageContent.innerHTML = '';
      eachPageProps.push({
        description: thisElementContent,
        index: pageIndex++,
      });
    } else {
      // check if this element fits current page
      potentialPageContent.innerHTML += thisElementContent;
      if (potentialPageContent.clientHeight > PAGE_HEIGHT) {
        // if it does not fit, end filling up current page and add it to a new blank page
        eachPageProps.push({
          description: currentPageContent.innerHTML,
          index: pageIndex++,
        });
        currentPageContent.innerHTML = thisElementContent;
        potentialPageContent.innerHTML = thisElementContent;
      } else {
        // if it fits, append it to current page
        currentPageContent.innerHTML += thisElementContent;
      }
    }
  }

  // We have processed all elements by now. If there is an unfinished page left, finish it.
  if (currentPageContent.children.length !== 0) {
    eachPageProps.push({
      description: currentPageContent.innerHTML,
      index: pageIndex++,
    });
  }

  currentPageContent.remove();
  potentialPageContent.remove();

  return eachPageProps;
};

function createHtmlElementsFromString(string = '') {
  const div = document.createElement('div');
  div.innerHTML = string;
  return Array.from(div.childNodes);
}

function createTestDiv() {
  const testDiv = document.createElement('div');
  testDiv.className = CONTENT_WRAPPER_CLASS;
  testDiv.style.width = '795px';
  testDiv.style.visibility = 'hidden';
  testDiv.style.position = 'fixed';
  testDiv.style.top = '0';
  document.body.appendChild(testDiv);
  return testDiv;
}

function measureElementHeight(element: ChildNode) {
  const measurable = createTestDiv();
  measurable.appendChild(element);
  const height = measurable.clientHeight;
  measurable.remove();
  return height;
}

function calculatePageHeight() {
  const middlePart = document.querySelector(
    '[data-main-area] [data-middle-part]'
  );
  console.log(middlePart?.clientHeight);
  return (middlePart?.clientHeight || 996) - 28 - 16;
}
