import React, { useState } from 'react';
import WidgetWrapper from './wrapper';
import { WidgetProps } from '../index';
import { useTranslation } from 'react-i18next';
import LeadsPopup from '../components/leadsPopup';
import { ILeadItem } from '../../../types/contacts';
import useLocale from '../../../hooks/useLocale';
import {
  ContactCell,
  DateCell,
  MessageCell,
  ReferenceCell,
  SourceCell,
  StatusCell,
  TypeCell,
} from '../utils/leadTableCells';
import useMediaQuery from '@mui/material/useMediaQuery';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import LeadActionsMenu from '../../contacts/singleContactPage/utils/leadActions';
import { KeyedMutator } from 'swr';
export const desktopTitles = [
  { name: 'Date' },
  { name: 'Deal_type' },
  { name: 'Status' },
  { name: 'Property_reference', className: 'col-span-3' },
  { name: 'Contact', className: 'col-span-2' },
  { name: 'Source', className: 'col-span-2' },
  { name: 'Message', className: 'col-span-2' },
];

export const mobileTitles = [
  { name: 'Contact', className: '' },
  { name: 'Reference' },
  { name: 'Source' },
];

export const FullLeadItem = (props: {
  item: ILeadItem;
  mutate?: KeyedMutator<ILeadItem>;
}) => {
  const { item, mutate } = props;
  const { locale } = useLocale();

  return (
    <div className="grid grid-cols-12 gap-2 py-2">
      <DateCell
        item={item}
        wrapperClassName={'grow shrink basis-0 pt-1'}
        hideIconOnMobile
      />
      <TypeCell
        item={item}
        locale={locale}
        wrapperClassName={'pt-1 grow shrink basis-0'}
        isSmall
      />
      <StatusCell
        item={item}
        wrapperClassName={'pt-1 grow shrink basis-0'}
        withSendEmail
      />
      <ReferenceCell
        item={item}
        locale={locale}
        wrapperClassName={'h-[70px] col-span-3 grow shrink basis-0'}
        isSmall
      />
      <ContactCell
        item={item}
        wrapperClassName={'col-span-2 grow shrink basis-0'}
        isSmall
      />
      <SourceCell
        item={item}
        wrapperClassName={'col-span-2 grow shrink basis-0'}
        customHeight
        hideIconOnMobile
      />
      <div
        className={'col-span-2 grow shrink basis-0 flex gap-1 justify-between'}
      >
        <MessageCell item={item} />
        <div className={'mt-[-8px]'}>
          <LeadActionsMenu
            row={{
              ...item,
              parentId: item.contact ? item.contact.id.toString() : '',
            }}
            mutateForStatus={mutate}
            withSendFiles
          />
        </div>
      </div>
    </div>
  );
};

export const MobileItem = (props: { item: ILeadItem }) => {
  const { item } = props;
  const { t } = useTranslation();
  const id = item.property ? item.property.id : '';

  return (
    <div className="grid grid-cols-3 gap-1 py-1 border-b border-gray-200">
      <div className={'text-zinc-900 text-sm font-medium leading-none'}>
        <Link
          to={`/contacts/${item.contact?.id}/contact-information`}
          id={`img_contact_${item.contact?.id}`}
          className="max-w-full font-medium inline-flex items-center justify-center gap-2 overflow-hidden"
        >
          <p
            title={item.contact?.fullname}
            className={classNames(
              'whitespace-nowrap max-w-full overflow-ellipsis overflow-hidden text-sm'
            )}
          >
            {item.contact?.fullname}
          </p>
        </Link>
      </div>
      <div className={'text-zinc-900 text-sm font-medium leading-none'}>
        <Link className="inline-flex max-w-full" to={`/properties/${id}`}>
          <p
            title={item?.property?.agencyReference || item?.property?.reference}
            className={classNames(
              'whitespace-nowrap max-w-full font-medium overflow-ellipsis overflow-hidden text-sm'
            )}
          >
            {item?.property?.agencyReference || item?.property?.reference}
          </p>
        </Link>
      </div>
      <div className={'text-zinc-900 text-sm font-medium leading-none'}>
        <p
          className={classNames(
            'whitespace-nowrap max-w-full overflow-ellipsis overflow-hidden text-sm'
          )}
          title={t(item.source)}
        >
          {t(item.source)}
        </p>
      </div>
    </div>
  );
};

const AgencyLeadsWidget: React.FC<WidgetProps> = (props) => {
  const { data, mutate } = props;
  const { t } = useTranslation('common');
  const [openModal, setOpenModal] = useState(false);
  const isMobile = useMediaQuery('(max-width:1280px)');

  const titles = isMobile || !props.wide ? mobileTitles : desktopTitles;

  return (
    <WidgetWrapper
      wide={props.wide}
      title={'MyAgencyRecentLeads'}
      linkText={'ViewAll'}
      link={null}
      onButtonClick={() => setOpenModal(true)}
      emptyData={
        data['agency-recent-leads'] && !data['agency-recent-leads'].length
      }
    >
      <div className="w-full flex-col justify-center items-start gap-8 inline-flex">
        <div className="self-stretch flex-col justify-center items-start gap-2 flex">
          <div
            className={classNames(
              'grid self-stretch pb-2 border-b border-gray-200 justify-start items-start',
              {
                'grid-cols-12 gap-4': !isMobile || props.wide,
                '!grid-cols-3 !gap-1': isMobile || !props.wide,
              }
            )}
          >
            {titles.map((el, index) => (
              <div
                key={index}
                className={classNames(
                  'grow shrink basis-0 text-zinc-900 text-sm font-medium leading-none overflow-hidden',
                  el.className
                )}
              >
                <p
                  className={'whitespace-nowrap text-ellipsis overflow-hidden'}
                  title={t(el.name)}
                >
                  {t(el.name)}
                </p>
              </div>
            ))}
          </div>
          <div
            className={classNames('flex flex-col w-full', {
              'divide-y divide-gray-200': !isMobile || props.wide,
            })}
          >
            {data['agency-recent-leads'] &&
              data['agency-recent-leads'].map((item, index) => {
                if (isMobile || !props.wide) {
                  return <MobileItem key={index} item={item} />;
                } else {
                  return (
                    <FullLeadItem key={index} item={item} mutate={mutate} />
                  );
                }
              })}
          </div>
        </div>

        <LeadsPopup
          title={'MyAgencyRecentLeads'}
          openModal={openModal}
          onClose={() => setOpenModal(false)}
          api={'/prospects/my-agency-leads'}
          mutate={mutate}
        />
      </div>
    </WidgetWrapper>
  );
};

export default AgencyLeadsWidget;
