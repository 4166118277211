import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

import { useColumns } from './useColumns';
import TableGridX from '../../../components/tableGridX';
import useListingCollection from '../../../hooks/useListingCollection';
import { Pipeline, PipelineStepTypes } from '../../../types/pipelines';
import TitleHeader from '../../../components/common/titleHeader';

const skeletonItems: Pipeline[] = Array(6)
  .fill('')
  .map((_, index) => ({
    id: index,
    name: null,
    projectType: null,
    serviceType: null,
    usedOn: null,
    status: null,
    pipelineSteps: {
      [PipelineStepTypes.ENTRANCE]: [],
      [PipelineStepTypes.REGULAR]: [],
      [PipelineStepTypes.CLOSING]: [],
    },
    createdAt: null,
    updatedAt: null,
    createdBy: null,
  }));

const PipelinesListing = () => {
  const { t } = useTranslation('common');

  const {
    itemsToDisplay,
    setPage,
    itemsPerPage,
    page,
    loadingItemsToDisplay,
    mutate,
  } = useListingCollection<Pipeline>({
    basedUrl: `/pipelines`,
    isActiveProperties: true,
    currentPage: 'pipelines',
  });

  const columns = useColumns({
    isLoading: loadingItemsToDisplay,
    mutate,
  });

  const totalCount = itemsToDisplay?.['hydra:totalItems'] || 0;
  const results = itemsToDisplay?.['hydra:member'] || [];
  const rows = loadingItemsToDisplay ? skeletonItems : results;

  return (
    <>
      <TitleHeader
        wrapperClassName={'h-full pb-4 flex flex-col items-start'}
        titleElement={
          <div>
            <Typography className="!text-3xl">{t('pipelines')}</Typography>
            {itemsToDisplay && itemsToDisplay['hydra:totalItems'] ? (
              <Typography variant="subtitle1" className="text-sm text-gray-400">
                {totalCount
                  ? `${totalCount} ${t('pipeline', { count: totalCount })}`
                  : ''}
              </Typography>
            ) : null}
          </div>
        }
        goBackLink="/settings"
        rightElement={
          <Link to={'/settings/pipelines/new'}>
            <Button
              variant="contained"
              sx={{ background: 'background.default' }}
              startIcon={<AddIcon fontSize="medium" />}
              disableElevation
            >
              {t('createNew')}
            </Button>
          </Link>
        }
      >
        <div className="h-full w-full">
          <TableGridX
            sx={{
              '& .MuiDataGrid-virtualScroller': {
                overflow: 'hidden',
              },
            }}
            key={String(loadingItemsToDisplay)}
            disableSelectionOnClick={true}
            disableColumnSelector={true}
            rows={rows}
            // @ts-ignore
            columns={columns}
            autoPageSize
            pageSize={itemsPerPage}
            page={page - 1}
            paginationMode="server"
            rowCount={totalCount}
            onPageChange={(page: number) => {
              setPage(page + 1);
            }}
            rowHeight={64}
          />
        </div>
      </TitleHeader>
    </>
  );
};

export default PipelinesListing;
