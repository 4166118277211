import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { LayoutPart } from '../../../../types/brochureEditor';

export interface LayoutEditorState {
  withHeader: boolean;
  withFooter: boolean;
  header: LayoutPart;
  footer: LayoutPart;
}

interface LayoutEditorSlice extends LayoutEditorState {
  loadState: (state: LayoutEditorState) => void;
  toggleHeader: (isEnabled: boolean) => void;
  toggleFooter: (isEnabled: boolean) => void;
  setHeaderProperty: (property: keyof LayoutPart, value: any) => void;
  setFooterProperty: (property: keyof LayoutPart, value: any) => void;
}

export const initialLayoutPartState = {
  bgColor: '',
  bgImage: null,
  borderColor: '#fff',
  textColor: '',
  showGeneratedDate: true,
};

export const initialLayoutState: LayoutEditorState = {
  withHeader: false,
  withFooter: false,
  header: { ...initialLayoutPartState },
  footer: { ...initialLayoutPartState },
};

export const useLayoutEditor = create<LayoutEditorSlice>()(
  immer((set) => ({
    ...initialLayoutState,
    loadState(state) {
      set(() => state);
    },
    toggleHeader(isEnabled) {
      set((state) => {
        state.withHeader = isEnabled;
      });
    },
    toggleFooter(isEnabled) {
      set((state) => {
        state.withFooter = isEnabled;
      });
    },
    setHeaderProperty(property, value) {
      set((state) => {
        // @ts-ignore
        state.header[property] = value;
      });
    },
    setFooterProperty(property, value) {
      set((state) => {
        // @ts-ignore
        state.footer[property] = value;
      });
    },
  }))
);

export const useLayoutEditorComputables = () => {
  const { withHeader, withFooter, header, footer } = useLayoutEditor();
  return {
    layout: { withHeader, withFooter, header, footer },
  };
};
