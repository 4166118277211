import { Translations } from '../types/translations';

export type stepperOptionT = { id?: number; value: string };

export type optionType = {
  id: string | number;
  defaultTagLocale?: string | undefined;
  keyname: string;
  translations: Translations;
};
export type referenceAgency = {
  agencyReferences:
    | {
        id: number;
        active: boolean;
        agencyReference: string;
      }[]
    | null;
};

export type categoryOptionType = {
  id: string | number;
  keyname: string;
  translations: Translations;
  propertyTypes: optionType[];
};

export const getPropertyTypeById = (
  options: categoryOptionType[],
  id: unknown
) => {
  return options?.filter((category: categoryOptionType) => category.id == id);
};

export const getSingleOptionByLanguage = (option?: optionType): string => {
  const language = localStorage.getItem('i18nextLng') || 'en';
  if (!option) return '';
  return (
    option?.translations?.[language]?.value ||
    option?.translations?.[language]?.name ||
    option.keyname
  );
};

export function getOptionsByLanguage(
  options: {
    id: string | number;
    keyname: string;
    defaultTagLocale?: string;
    translations: Translations;
  }[] = [],
  locale?: string
): { value: string; id: number; key: number }[] {
  let language = 'en';
  if (locale) language = locale;
  else language = localStorage.getItem('i18nextLng') || 'en';

  return options.map((option, locale) => {
    const { id, keyname, defaultTagLocale, translations } = option;
    const translation = translations?.[language];
    let value: string;
    if (locale) {
      if (defaultTagLocale && translations?.[defaultTagLocale]?.name) {
        value =
          translation?.value ||
          translation?.name ||
          translations[defaultTagLocale]?.name + ' (tag not translated)';
      } else {
        value = translation?.value || translation?.name || '';
      }
    } else {
      value = translation?.value || translation?.name || keyname;
    }

    return { value, id: Number(id), key: Number(id) };
  });
}

export interface ReturnedOptionString {
  value: string;
  id: string;
}
export const getOptionsByLanguageString = (
  options?: optionType[] | categoryOptionType[],
  locale?: string
): ReturnedOptionString[] => {
  const intermediateOptions = getOptionsByLanguage(options, locale);
  return intermediateOptions.map(({ value, id }) => ({
    value,
    id: String(id),
  }));
};

const dealOptions: stepperOptionT[] = [
  { id: 132, value: 'for sale' },
  { id: 1, value: 'for rent' },
  { id: 2, value: 'commercial rent' },
  { id: 3, value: 'commercial sale' },
];

export type infoType = {
  id: number;
  label: string;
  options: stepperOptionT[];
};
export interface InfoTypeWithDefault extends infoType {
  defaultValue: stepperOptionT;
}
const GenreOptions = [
  { id: 1, value: 'Mr' },
  { id: 2, value: 'Mrs' },
];
const titleOptions = [
  { value: 'Dear Mr.', id: 12 },
  { value: 'Dear Mrs.', id: 1 },
  { value: 'Dear Mr.,Dear Mrs.', id: 2 },
];
const originOptions = [
  { value: 'Website', id: 11 },
  { value: 'Telephone', id: 1 },
  { value: 'Newsletter', id: 2 },
  { value: 'Email', id: 3 },
  { value: 'Properstar', id: 4 },
  { value: 'immomig.ch', id: 5 },
  { value: 'Acheter-Louer', id: 6 },
  { value: 'Immoscout 24', id: 7 },
];
const languagesOptions = [
  { id: 12, value: 'French' },
  { id: 1, value: 'English' },
];
export const position = [
  {
    value: 'penthouse',
    id: 1,
  },
  {
    value: 'attic',
    id: 2,
  },
  {
    value: 'top_floor',
    id: 3,
  },

  {
    value: 'intermediate_floor',
    id: 4,
  },
  {
    value: 'floor',
    id: 5,
  },
  {
    value: 'mezzanine',
    id: 6,
  },
  {
    value: 'waterfront',
    id: 7,
  },
  {
    value: 'single_storey',
    id: 8,
  },
  {
    value: 'lower_ground_floor',
    id: 9,
  },
  {
    value: 'upper_ground_floor',
    id: 10,
  },
  {
    value: 'ground_floor',
    id: 11,
  },
  {
    value: 'garden_level',
    id: 12,
  },
  {
    value: 'basement',
    id: 13,
  },
  {
    value: 'raised',
    id: 14,
  },
];
export {
  originOptions,
  languagesOptions,
  GenreOptions,
  titleOptions,
  dealOptions,
};
