import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import useSWR from 'swr';

import { Document, IPropertyApi } from '../../types/property';
import { PreviewSubjectTypes } from '../../types/brochureEditor';

import SelectPdfTemplate from '../../components/common/selectPdfTemplate';
import BackLink from '../../components/common/backLink';
import PropertyTags from '../../components/common/propertyTags';
import ListingHeader from '../../components/tableGridX/listingHeader';
import EditPropertyButton from '../properties/singleProperty/editPropertyButton';

import DownloadBrochureButton from './downloadBrochureButton';
import AutomatedSending from './automation';
import FileSelection from './fileSelection';
import { GridSelectionModel } from '@mui/x-data-grid';
import SelectedFilesActionMenu from './selectedFilesActionMenu';

const GeneratedBrochures = () => {
  const { t } = useTranslation('propertiesPage');

  const { propertyId, promotionId } = useParams();

  const { data: property } = useSWR<IPropertyApi>(
    propertyId ? `/v1/properties/${propertyId}` : null
  );

  let backLink = '';
  let backLabel = '';
  let subjectType = null;
  let subjectId;
  if (propertyId) {
    backLink = `/properties/${propertyId}`;
    backLabel = 'propertiesPage:property_details';
    subjectType = PreviewSubjectTypes.PROPERTY;
    subjectId = propertyId;
  } else if (promotionId) {
    backLink = `/promotions/${promotionId}`;
    backLabel = 'promotionPage:promotionDetails';
    subjectType = PreviewSubjectTypes.PROMOTION;
    subjectId = promotionId;
  }

  const [selection, setSelection] = useState<GridSelectionModel>([]);
  const { data: files, mutate } = useSWR<Document[]>(
    `/v1/${propertyId ? 'properties' : 'promotions'}/${
      propertyId || promotionId
    }/documents?itemsPerPage=100`
  );
  const selectedItems = selection.map((id) =>
    files?.find((file) => file.id === id)
  );
  const totalCount = files?.length;

  const onMergedFilesSuccess = () => {
    setSelection([]);
  };

  return (
    <>
      <div>
        <BackLink url={backLink} label={backLabel} />
      </div>

      <ListingHeader
        title={t('common:brochures_and_files')}
        itemsCountText={
          totalCount ? `${totalCount} ${t('files', { count: totalCount })}` : ''
        }
      >
        <div className="grow flex max-lg:flex-col justify-between gap-4">
          <div className="xl:pt-1">
            {property && <PropertyTags property={property} />}
          </div>

          <div className="flex flex-col gap-4 xl:items-end">
            <div className="flex !flex-row max-xl:flex-col xl:items-center gap-3 justify-end">
              <SelectPdfTemplate
                subjectId={subjectId}
                subjectType={subjectType}
              />
              <DownloadBrochureButton
                propertyId={propertyId}
                promotionId={promotionId}
                mutate={mutate}
              />
              {propertyId && <EditPropertyButton propertyId={propertyId} />}{' '}
            </div>

            {propertyId && (
              <div className="flex justify-end gap-4">
                <SelectedFilesActionMenu
                  propertyId={propertyId}
                  selectedFiles={selectedItems}
                  onMergedFilesSuccess={onMergedFilesSuccess}
                  mutate={mutate}
                />
                <AutomatedSending propertyId={propertyId} />
              </div>
            )}
          </div>
        </div>
      </ListingHeader>

      <FileSelection
        files={files}
        mutate={mutate}
        rowSelectionModel={selection}
        onRowsSelectionHandler={setSelection}
      />
    </>
  );
};

export default GeneratedBrochures;
