import { useTranslation } from 'react-i18next';
import { PropertyWidgetPreviewProps } from '../../../../../types/brochureEditor';
import TableWidget from '../../tableWidget';
import { useCallback } from 'react';

const PropertySurfacesWidget = ({
  property,
  widgetOptions,
}: PropertyWidgetPreviewProps) => {
  const { t } = useTranslation('propertiesPage');

  const formatSurface = useCallback(
    (value: number, suffix = '', addFromPrefix = false) => {
      if (value) {
        let string = addDigitSeparatorsToNumber(value);

        if (property.useTildeForSurface) {
          string = `~${string}`;
        }
        if (addFromPrefix) {
          string = `${t('propertiesPage:from')} ${string}`;
        }
        if (suffix) {
          string += ` ${suffix}`;
        }

        return string;
      }
      return '';
    },
    [property, t]
  );

  return (
    <TableWidget
      title={t('brochureEditor:surfaces')}
      items={[
        {
          id: '10',
          label: t('Living_Area'),
          value: formatSurface(
            property.livingArea,
            'm²',
            property.addFromToLivingArea
          ),
        },
        {
          id: '11',
          label: t('useful_area'),
          value: formatSurface(
            property.usefulArea,
            'm²',
            property.addFromToUsefulArea
          ),
        },
        {
          id: '12',
          label: t('Land_surface'),
          value: formatSurface(
            property.landSurface,
            'm²',
            property.addFromToLandSurface
          ),
        },
        {
          id: '13',
          label: t('terrace_surface'),
          value: formatSurface(property.terraceSurface, 'm²'),
        },
        {
          id: '14',
          label: t('garden_surface'),
          value: formatSurface(property.gardenSurface, 'm²'),
        },
        {
          id: '15',
          label: t('Volume'),
          value: formatSurface(property.gardenSurface, 'm³'),
        },
      ]}
      widgetOptions={widgetOptions}
    />
  );
};

export default PropertySurfacesWidget;

function addDigitSeparatorsToNumber(number: number) {
  return number.toLocaleString('de-CH');
}
