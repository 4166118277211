import { mutate } from 'swr';
import { toast } from 'react-toastify';
import i18next, { t } from 'i18next';
import { Link } from 'react-router-dom';
import { GridColumns, GridValidRowModel } from '@mui/x-data-grid';
import { Avatar, Box, CircularProgress, Switch, Tooltip } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import TabGridTag from '../../../features/tags/tabGridTag';
import { http } from '../../../libs/axios';
import {
  getuserAgency,
  getUserLevel,
  getUserRole,
  getUserRoleColor,
} from '../../../pages/users/utils';
import { captureException } from '@sentry/react';
import { UserDetail } from '../../../types/user';
import { useState } from 'react';
import { standardizePhoneNumber } from '../../../helpers/utils/formatters';

const handleChange = async (
  event: React.ChangeEvent<HTMLInputElement>,
  setIsLoading: (value: boolean) => void
) => {
  setIsLoading(true);
  try {
    const res = await http.patch(`/users/${event.target.id}/enabled`, {
      enabled: event.target.checked,
    });
    const { data, status } = res;
    if (status === 200) {
      const getUserApiRequirment = JSON.parse(
        localStorage.getItem('page_userItemPerPage') as string
      );
      mutate(
        `/users?page=${getUserApiRequirment.page}&itemsPerPage=${getUserApiRequirment.itemsPerPage}`
      );
      if (data.enabled) {
        toast.success(i18next.t(`alerts:user_activate`) as string, {
          autoClose: 3000,
        });
      } else {
        toast.success(i18next.t(`alerts:user_desactivate`) as string, {
          autoClose: 3000,
        });
      }
    } else {
      toast.warn(i18next.t(`alerts:user_not_activate`) as string, {
        autoClose: 3000,
      });
    }
  } catch (event: any) {
    captureException(event);
    if (event.response.status === 452) {
      const msg = event.response.data.message.replaceAll(' ', '_');
      toast.warning(t(`usersPage:${msg}`) as string, {
        position: 'bottom-center',
        autoClose: 5000,
        style: { width: '500px', textAlign: 'center' },
      });
    } else if (event.response.status === 465) {
      toast.warning(event.response.data.message as string, {
        autoClose: 3000,
      });
    } else {
      toast.warning(i18next.t(`alerts:user_not_activate`) as string, {
        autoClose: 3000,
      });
    }
  } finally {
    setIsLoading(false);
  }
};

const isLarge = localStorage.getItem('isLarge');
const isMacLarge = localStorage.getItem('isMacLarge');
const isMediumLarge = localStorage.getItem('isMediumLarge');
// const isMedium = localStorage.getItem('isMedium');
const ActivationCell = ({ row }: { row: UserDetail }) => {
  const [loading, setIsLoading] = useState(false);

  return loading ? (
    <div className="z-10 ml-6">
      <CircularProgress size={20} disableShrink />
    </div>
  ) : (
    <Switch
      id={String(row.id)}
      disabled={row.isSuperAdmin}
      checked={row.enabled}
      onChange={(event) => handleChange(event, setIsLoading)}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  );
};
export const usersListingConfig: GridColumns<UserDetail> = [
  {
    field: 'avatar',
    headerName: 'Avatar',
    headerClassName: '!bg-white',
    width: 80,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params) => <Avatar src={params.row.avatar} />,
  },
  {
    field: 'roles',
    headerName: 'Role',
    width: 130,
    renderCell: (params) => {
      const {
        row: { isSuperAdmin },
      } = params;
      return (
        <Box className="max-w-full flex-wrap flex  text-xm">
          {isSuperAdmin ? (
            <TabGridTag
              key={'superAdmin'}
              textContent={'Super admin'}
              fontSize="14px"
              className="m-0"
              color="#ff0000"
            />
          ) : params.value.length === 0 ? (
            <TabGridTag
              key={'unassigned'}
              textContent={'Unassigned'}
              fontSize="14px"
              color="#1D1D1F"
            />
          ) : (
            params.value.map((role: string) => (
              <TabGridTag
                key={role + Math.random()}
                textContent={getUserRole(role)}
                fontSize="14px"
                color={getUserRoleColor(role)}
              />
            ))
          )}
        </Box>
      );
    },
  },
  {
    field: 'firstname',
    headerName: 'firstname',
    width:
      isLarge === 'true'
        ? 250
        : isMacLarge === 'true'
        ? 251
        : isMediumLarge === 'true'
        ? 200
        : 140,
    renderCell: (params) => (
      <Tooltip title={params.row.firstname} arrow placement="top">
        <span>{params.row.firstname}</span>
      </Tooltip>
    ),
  },
  {
    field: 'lastname',
    headerName: 'lastname',
    width:
      isLarge === 'true'
        ? 250
        : isMacLarge === 'true'
        ? 251
        : isMediumLarge === 'true'
        ? 200
        : 140,
    renderCell: (params) => (
      <Tooltip title={params.row.lastname} arrow placement="top">
        <span>{params.row.lastname}</span>
      </Tooltip>
    ),
  },
  {
    field: 'email',
    headerName: 'email',
    width: isLarge === 'true' ? 300 : 250,
    renderCell: (params) => {
      return (
        <Tooltip title={params.value} arrow placement="top">
          <a className="text-blue underline" href={`mailto:${params.value}`}>
            {params.value}
          </a>
        </Tooltip>
      );
    },
  },
  {
    field: 'phone',
    headerName: 'phone',
    width:
      isLarge === 'true'
        ? 182
        : isMacLarge === 'true'
        ? 155
        : isMediumLarge === 'true'
        ? 150
        : 130,
    renderCell: ({ row }) => {
      return <div>{standardizePhoneNumber(row.phone)}</div>;
    },
  },
  {
    field: 'level',
    headerName: 'level',
    width: 85,
    renderCell: ({ row }) => {
      return (
        <Box className="w-3/4 flex justify-center items-center ">
          <span>{getUserLevel(row)}</span>
        </Box>
      );
    },
  },
  {
    field: 'belong to',
    headerName: 'Belong_to',
    width:
      isLarge === 'true'
        ? 150
        : isMacLarge === 'true'
        ? 150
        : isMediumLarge === 'true'
        ? 146
        : 120,
    renderCell: ({ row }) => {
      return (
        <Tooltip title={getuserAgency(row)} arrow placement="top">
          {getuserAgency(row)}
        </Tooltip>
      );
    },
  },
  {
    field: 'enabled',
    disableColumnMenu: true,
    sortable: false,
    headerName: 'Activation',
    width: isLarge === 'true' ? 120 : 89,
    renderCell: ({ row }) => <ActivationCell row={row} />,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: isLarge === 'true' ? 100 : 75,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (value) => {
      return (
        <Box className="w-full flex justify-center items-center ">
          <Link to={`/users/${value.id}`}>
            <Tooltip
              title={`${t('propertiesPage:user_details')}`}
              arrow
              placement="top"
            >
              <span>
                <VisibilityOutlinedIcon
                  color="primary"
                  className="w-5 h-5 cursor-pointer"
                />
              </span>
            </Tooltip>
          </Link>
        </Box>
      );
    },
  },
];
export const usersListingConfigForAgent: GridColumns<GridValidRowModel> = [
  {
    field: 'avatar',
    headerName: 'Avatar',
    width: 80,
    headerClassName: '!bg-white',
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params) => <Avatar src={params.row.avatar} />,
  },
  {
    field: 'roles',
    headerName: 'Role',
    width: 130,
    renderCell: (params) => {
      const {
        row: { isSuperAdmin },
      } = params;
      return (
        <Box className="max-w-full flex-wrap flex  text-xm">
          {isSuperAdmin ? (
            <TabGridTag
              key={'superAdmin'}
              textContent={'Super admin'}
              fontSize="14px"
              className="m-0"
              color="#ff0000"
            />
          ) : (
            params.value.map((role: string) => (
              <TabGridTag
                key={role + Math.random()}
                textContent={getUserRole(role)}
                fontSize="14px"
                color={getUserRoleColor(role)}
              />
            ))
          )}
        </Box>
      );
    },
  },
  {
    field: 'firstname',
    headerName: 'firstname',
    width: isLarge === 'true' ? 300 : isMediumLarge === 'true' ? 243 : 171,
    renderCell: (params) => (
      <Tooltip title={params.row.firstname} arrow placement="top">
        <span>{params.row.firstname}</span>
      </Tooltip>
    ),
  },
  {
    field: 'lastname',
    headerName: 'lastname',
    width: isLarge === 'true' ? 300 : isMediumLarge === 'true' ? 243 : 171,
    renderCell: (params) => (
      <Tooltip title={params.row.firstname} arrow placement="top">
        <span>{params.row.lastname}</span>
      </Tooltip>
    ),
  },
  {
    field: 'email',
    headerName: 'email',
    width: isLarge === 'true' ? 300 : isMediumLarge === 'true' ? 250 : 250,

    renderCell: (params) => {
      return (
        <Tooltip title={params.value} arrow placement="top">
          <a className="text-blue underline" href={`mailto:${params.value}`}>
            {params.value}
          </a>
        </Tooltip>
      );
    },
  },
  {
    field: 'phone',
    headerName: 'phone',

    width: isLarge === 'true' ? 300 : 200,
    renderCell: ({ row }) => {
      return <div>{standardizePhoneNumber(row.phone)}</div>;
    },
  },
  {
    field: 'level',
    headerName: 'level',

    renderCell: ({ row }) => {
      return getUserLevel(row);
    },
  },
  {
    field: 'belong to',
    headerName: 'Belong_to',
    width: isLarge === 'true' ? 132 : 152,
    renderCell: ({ row }) => {
      return (
        <Tooltip title={getuserAgency(row)} arrow placement="top">
          {getuserAgency(row)}
        </Tooltip>
      );
    },
  },
];
