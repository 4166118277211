import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Headroom from 'react-headroom';
import classNames from 'classnames';
import {
  enableBodyScroll,
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

import PDFIcon from '../../features/icons/pdfIcon';
import Logo from '../common/logo';
// import LandingCta from './cta';

import './headroom.css';
import Hamburger from './hamburger';
import { standardizePhoneNumber } from '../../helpers/utils/formatters';

const LandingHeader = ({
  locale,
  logo,
  email,
  phone,
  brochureLink,
  cta,
  onCtaClick,
  navLinks = [],
}: {
  locale: string;
  logo?: {
    x32?: string | null;
    x64?: string | null;
    x96?: string | null;
    x368?: string | null;
  } | null;
  email?: string | null;
  phone?: string | null;
  brochureLink?: string | null;
  cta?: boolean;
  onCtaClick?: MouseEventHandler;
  navLinks?: NavLink[];
}) => {
  const { t } = useTranslation('propertiesPage');
  const [activeLink, setActiveLink] = useState<NavLink>();

  useEffect(() => {
    const options = {
      rootMargin: '-200px 0px -200px 0px',
      threshold: 0.01,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const activeLink = navLinks.find(
            (link) => link.element === entry.target
          );
          setActiveLink(activeLink);
        }
      });
    }, options);

    for (const { element } of navLinks) {
      if (element) {
        observer.observe(element);
      }
    }

    return () => observer.disconnect();
  }, [navLinks]);

  const [isHamburgerMenuVisible, toggleHamburgerMenu] = useState(false);
  const scrollable = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isHamburgerMenuVisible) {
      disableBodyScroll(scrollable.current as HTMLDivElement);
      document.body.style.overflowY = '';
    } else {
      enableBodyScroll(scrollable.current as HTMLDivElement);
      document.body.style.overflowY = 'scroll';
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isHamburgerMenuVisible]);

  const contactInfo = (
    <>
      {phone && (
        <a
          href={`tel:${phone}`}
          className="text-zinc-900 text-base leading-none"
        >
          {standardizePhoneNumber(phone)}
        </a>
      )}
      {email && (
        <a
          href={`mailto:${email}`}
          className="text-zinc-900 text-base leading-none"
        >
          {email}
        </a>
      )}
      {brochureLink && (
        <a href={brochureLink} target="_blank" download rel="noreferrer">
          <Button
            variant="contained"
            sx={{
              height: '48px',
              background: '#F5F5F7',
              color: 'text.primary',
              display: 'inline-flex',
              gap: '.75rem',
              '&:hover': {
                background: '#dadadc',
              },
            }}
            disableElevation

            // download file without opening new tab - will work only if we ask BE to add Access-Control header on /uploads
            // onClick={() => downloadFile({ url: data.brochure })}
          >
            <PDFIcon />
            {t('download_brochure', { lng: locale })}
          </Button>
        </a>
      )}
      {cta && (
        <Button
          onClick={(e) => {
            toggleHamburgerMenu(false);
            onCtaClick?.(e);
          }}
          variant="contained"
          sx={{ height: '48px' }}
          disableElevation
        >
          {t('Contact_a_broker', { lng: locale })}
        </Button>
        // <LandingCta onClickPrevented={onCtaClick}>
        //   {t('Contact_us', { lng: locale })}
        // </LandingCta>
      )}
    </>
  );

  return (
    <>
      <Headroom disableInlineStyles>
        <div className="w-full px-4 py-8 bg-white border-b border-gray-200 inline-flex justify-between items-center xl:px-16">
          <div className="sm:w-2/5 max-sm:hidden flex items-center gap-2 lg:gap-4">
            {navLinks.map((link) => (
              <a
                href={link.href}
                key={link.href}
                className={classNames(
                  'block p-2 -my-2 lg:p-4 lg:-my-4 rounded capitalize',
                  {
                    'bg-blue text-white pointer-events-none':
                      activeLink?.href === link.href,
                    'hover:bg-gray-50': activeLink?.href !== link.href,
                  }
                )}
              >
                {link.title}
              </a>
            ))}
          </div>

          <div className="sm:w-1/5 relative max-w-[180px]">
            {logo?.x96 ? <img src={logo.x96} alt="" /> : <Logo />}
          </div>

          <div className="sm:w-2/5 max-sm:hidden flex items-center justify-end gap-4">
            {contactInfo}
          </div>

          <button
            className="sm:hidden p-2 -my-2 border border-gray-200 rounded"
            onClick={() => toggleHamburgerMenu(!isHamburgerMenuVisible)}
            aria-label="toggle menu"
          >
            <Hamburger isOpened={isHamburgerMenuVisible} />
          </button>
        </div>
      </Headroom>

      <div
        ref={scrollable}
        className={classNames(
          'z-[15] fixed inset-0 bg-white overflow-y-auto sm:hidden pt-[88px]',
          {
            invisible: !isHamburgerMenuVisible,
          }
        )}
      >
        <nav className="flex flex-col justify-center divide-y divide-gray-200 border-b border-gray-200">
          {navLinks.map((link) => (
            <a
              href={link.href}
              key={link.href}
              className="block p-4 capitalize"
              onClick={() => toggleHamburgerMenu(false)}
            >
              {link.title}
            </a>
          ))}
        </nav>

        <div className="mt-8 flex flex-col gap-4 items-center">
          {contactInfo}
        </div>
      </div>
    </>
  );
};

export default LandingHeader;

interface NavLink {
  title: string;
  href: string;
  element?: HTMLDivElement | null;
}
