import useSWR from 'swr';
import { SelectChangeEvent, Skeleton } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Pipeline } from '../../../types/pipelines';
import SelectFormField from '../../form/selectFormField';

function PipelineSelect({
  pipelinesEndpoint,
  handlePipelineChange,
}: {
  pipelinesEndpoint?: string | null;
  handlePipelineChange?: (
    event: SelectChangeEvent<unknown>,
    child: ReactNode
  ) => unknown;
}) {
  const { t } = useTranslation('common');

  const { data: availablePipelines } = useSWR<Pipeline[]>(pipelinesEndpoint);
  const options = availablePipelines?.map((pipeline) => ({
    id: pipeline.id,
    value: pipeline.name || '',
  }));

  if (!options) {
    return <Skeleton variant="rectangular" height={40} />;
  }

  return (
    <SelectFormField
      capitalize={false}
      isDisable={options.length == 0 && true}
      label={t('select_pipeline_template')}
      options={options}
      onChange={handlePipelineChange}
    />
  );
}

export default PipelineSelect;
