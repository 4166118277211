import { useTranslation } from 'react-i18next';
import CheckedIcon from '../../../../../features/icons/checkedIcon';
import { Box, Stack, Typography } from '@mui/material';
import useLocale from '../../../../../hooks/useLocale';
import { useMemo } from 'react';
import { IConvenienceChoiceApi } from '../../../../../types/conveniences';

function PropertyFeatures({
  className,
  featuresStyle,
  conveniences,
}: {
  className?: string;
  featuresStyle?: string;
  conveniences: Record<string, IConvenienceChoiceApi[]>;
}) {
  const { t } = useTranslation('propertiesPage');
  const { locale } = useLocale();

  const propertyFeatures = useMemo(() => {
    const propertyFeaturesArray: any[] = [];

    Object.keys(conveniences).map((key) => {
      conveniences[key]?.map((singleFeature: any) => {
        propertyFeaturesArray.push(singleFeature);
      });
    });

    if (propertyFeaturesArray) {
      if (propertyFeaturesArray && !propertyFeaturesArray.includes(undefined))
        return propertyFeaturesArray.map(({ name }) => name);
      else {
        return [];
      }
    }
  }, [locale, conveniences]) as [];

  if (!conveniences || !propertyFeatures.length) return null;

  return (
    <Stack
      sx={{
        color: 'text.primary',
      }}
      className={
        'bg-[#FFFFFF] w-full rounded border p-4 2xl:p-8 flex flex-col ' +
        className
      }
    >
      <Typography variant="h6" className="mb-8">
        {t('property_features')}
      </Typography>
      <Box
        sx={{
          color: 'text.primary',
        }}
        className={
          'bg-[#FFFFFF]  grid grid-cols-4 gap-3 2xl:gap-4 mt-4 ' + featuresStyle
        }
      >
        {propertyFeatures?.map((feature: string, index: number) => (
          <Box
            sx={{
              color: 'text.primary',
            }}
            key={index}
            className=" bg-[#FFFFFF] col-span-1 flex"
          >
            <Box className="w-5 h-5 2xl:w-7 rounded border aspect-square p-1 flex justify-center items-center mr-2 max-w-8">
              <CheckedIcon color="green" />
            </Box>
            <Box className={'text-[14px] 2xl:text-[1rem]'} component="span">
              {t(feature.replaceAll(' ', '_'))}
            </Box>
          </Box>
        ))}
      </Box>
    </Stack>
  );
}

export default PropertyFeatures;
