import { Ref, forwardRef } from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const toolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline'],
  [{ color: [] }, { background: [] }],
  ['clean'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['link', 'image', 'video'],
];

const modules = {
  clipboard: { matchVisual: false },
  toolbar: toolbarOptions,
};

function WysiwygEditor(props: ReactQuillProps, ref: Ref<ReactQuill>) {
  return (
    <ReactQuill
      className="bg-white !prose !max-w-none"
      theme="snow"
      modules={modules}
      ref={ref}
      {...props}
    />
  );
}

export default forwardRef(WysiwygEditor);
