import React from 'react';
import WidgetWrapper from './wrapper';
import PriceIcon from '../../../features/icons/priceIcon';
import { Link } from 'react-router-dom';
import { WidgetProps } from '../index';
import { IDashboardProperty } from '../../../types/user';
import { useTranslation } from 'react-i18next';
import { formatPriceWithCurrency } from '../utils/useOffersListColumns';

const Item = (props: { item: IDashboardProperty }) => {
  const { item } = props;
  return (
    <div className="self-stretch pb-2 border-b border-gray-200 justify-start items-start gap-4 inline-flex">
      <Link
        className={
          'grow shrink basis-0 text-blue-800 text-sm font-medium leading-none'
        }
        to={`/properties/${item.id}`}
      >
        <span>{item.reference}</span>
      </Link>
      <div className="grow shrink basis-0 h-4 justify-start items-start gap-2 flex">
        <div className="w-4 h-4 relative opacity-30">
          <PriceIcon />
        </div>
        <div className="grow shrink basis-0 text-zinc-900 text-sm font-normal leading-none">
          {formatPriceWithCurrency(item.price, item.currency)}
        </div>
      </div>
      <div className="grow shrink basis-0 text-zinc-900 text-sm font-normal leading-none">
        {formatPriceWithCurrency(item.commission, item['commission-currency'])}{' '}
        ({item['commission-pourcentage']}%)
      </div>
    </div>
  );
};

const PropertiesSoldWidget: React.FC<WidgetProps> = (props) => {
  const { data } = props;
  const { t } = useTranslation('common');

  return (
    <WidgetWrapper
      emptyData={!data['properties-sold'].length}
      title={'PropertiesSold'}
      linkText={''}
      link={null}
    >
      <div className="w-full flex-col justify-center items-start gap-8 inline-flex">
        <div className="self-stretch flex-col justify-center items-start gap-2 flex">
          <div className="self-stretch pb-2 border-b border-gray-200 justify-start items-start gap-4 inline-flex">
            <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium leading-none">
              {t('Reference')}
            </div>
            <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium leading-none">
              {t('SoldPrice')}
            </div>
            <div className="grow shrink basis-0 h-4 justify-start items-start gap-2 flex">
              <div className="grow shrink basis-0 text-zinc-900 text-sm font-medium leading-none">
                {t('Commission')}
              </div>
            </div>
          </div>
          {data['properties-sold']?.map((item) => (
            <Item key={item.reference} item={item} />
          ))}
        </div>
      </div>
    </WidgetWrapper>
  );
};

export default PropertiesSoldWidget;
