import { useTranslation } from 'react-i18next';
import NearbyPlacesMap from './placesMap';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Button } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import classNames from 'classnames';

interface Props {
  type: string;
  name: string;
  radius: number;
  latitude: number;
  longitude: number;
  onPlaceSelected: (item: any) => void;
  selectedPlace: any;
  mapVisible: boolean;
  atLeastOneFieldFilled: boolean;
  isDirty: boolean;
  isSubmitSuccessful: boolean;
}

const PropertyDistancesMap = (props: Props) => {
  const { t } = useTranslation('propertiesPage');
  const {
    type,
    name,
    radius,
    latitude,
    longitude,
    mapVisible,
    onPlaceSelected,
  } = props;
  const [places, setPlaces] = useState<any[]>([]);
  const [hoveredPlaceId, setHoveredPlaceId] = useState<string | null>(null);

  const onPlacesLoaded = (result: any) => {
    setPlaces(result);
  };

  return (
    <div
      className={classNames('grid grid-cols-2 gap-4', {
        'pb-4': mapVisible,
      })}
    >
      {mapVisible ? (
        <div>
          <NearbyPlacesMap
            id={type}
            type={type}
            radius={radius}
            latitude={latitude}
            longitude={longitude}
            onPlacesLoaded={onPlacesLoaded}
            selectedPlace={props.selectedPlace}
            setHoveredPlaceId={setHoveredPlaceId}
            setSelectedPlace={onPlaceSelected}
            hoveredPlaceId={hoveredPlaceId}
          />
        </div>
      ) : null}
      <div
        className={classNames('flex flex-col gap-2 justify-between', {
          'col-span-2': !mapVisible,
        })}
      >
        {mapVisible ? (
          <div className={'flex flex-col divide-y'}>
            <p className={'mb-4'}>
              <span className={'font-medium'}>
                {t(name)} {t('nearBy')}{' '}
              </span>
              <span className="opacity-80">({t('findByGoogle')})</span>
            </p>
            {places && places.length ? (
              places.map((item, index) => (
                <div
                  className={classNames(
                    'w-full p-2 flex justify-between items-center',
                    {
                      'bg-gray-200':
                        props.selectedPlace &&
                        props.selectedPlace.place_id === item.place_id,
                      'bg-gray-100':
                        hoveredPlaceId && hoveredPlaceId === item.place_id,
                    }
                  )}
                  key={item.place_id}
                >
                  <p>
                    {index + 1}. {item.name}
                  </p>
                  <div>
                    <Button
                      onClick={() =>
                        onPlaceSelected(
                          props.selectedPlace &&
                            props.selectedPlace.place_id === item.place_id
                            ? null
                            : item
                        )
                      }
                      variant={
                        props.selectedPlace &&
                        props.selectedPlace.place_id === item.place_id
                          ? 'outlined'
                          : 'contained'
                      }
                      sx={{
                        height: '40px',
                        display: 'flex',
                        gap: '8px',
                        minWidth: '102px',
                      }}
                      className={classNames('', {
                        '!bg-white':
                          props.selectedPlace &&
                          props.selectedPlace.place_id === item.place_id,
                      })}
                    >
                      {props.selectedPlace &&
                      props.selectedPlace.place_id === item.place_id ? (
                        <>
                          <DeleteOutlineIcon fontSize={'small'} />
                          {t('Clear')}
                        </>
                      ) : (
                        <>
                          <CheckIcon fontSize={'small'} />
                          {t('Select')}
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              ))
            ) : (
              <div className={'pt-4'}>{t('nothing')}</div>
            )}
          </div>
        ) : null}
        <div className={'w-full flex justify-end gap-2 pt-2'}>
          {/*<div className="">*/}
          {/*  <Button*/}
          {/*    disabled={isDirty}*/}
          {/*    onClick={onClearForm}*/}
          {/*    variant="outlined"*/}
          {/*    sx={{ height: '48px', display: 'flex', gap: '8px', color: '#1D1D1F' }}*/}
          {/*  >*/}
          {/*    <DeleteOutlineIcon fontSize={'small'}/>*/}
          {/*    {t('Clear')}*/}
          {/*  </Button>*/}
          {/*</div>*/}
          {/*<div className="">*/}
          {/*  <Button*/}
          {/*    disabled={!(selectedPlace || (isDirty && atLeastOneFieldFilled))}*/}
          {/*    type={'submit'}*/}
          {/*    variant="contained"*/}
          {/*    sx={{ height: '48px', gap: '8px' }}*/}
          {/*  >*/}
          {/*    <CheckIcon fontSize={'small'} />*/}
          {/*    {t('Save')}*/}
          {/*  </Button>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default PropertyDistancesMap;
