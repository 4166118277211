import { Button, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import CalendarIcon from '../../../features/icons/calendarIcon';
import EditIcon from '../../../features/icons/editIcon';
import PermissionIcon from '../../../features/icons/permissionIcon';
import TabGridTag from '../../../features/tags/tabGridTag';
import SelectPdfTemplate from '../../../components/common/selectPdfTemplate';
import { PreviewSubjectTypes } from '../../../types/brochureEditor';
import ViewArchivedLeads from '../../properties/propertiesListing/viewArchivedLeads';
import { useState } from 'react';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';

interface Props {
  id: number;
  reference: string | null;
  createdAt: string;
  archive?: boolean;
}

function SinglePromotionHeader(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [viewModalArchivedLeads, setViewModalArchivedLeads] = useState(false);

  const onEditButtonClick = () => {
    navigate(`/promotions/${props.id}/edit`);
  };
  const { id } = useParams();

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        color: 'text.primary',
        bgColor: 'background.default',
      }}
    >
      <section className="flex flex-col gap-2 mr-1">
        <Typography variant="h3">
          {t('promotionPage:promotionDetails')}
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TabGridTag
            icon={<PermissionIcon color="#3446B9" />}
            iconPlacement="start"
            textContent={`ID: ${props.reference}`}
            color="#3446B9"
            className="flex gap-1 items-center py-0 px-2 !max-w-full"
          />

          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <CalendarIcon />
            <p>{props.createdAt}</p>
          </Box>
        </Box>
      </section>
      <section className="flex gap-4 justify-center items-start">
        {!props.archive ? (
          <>
            <SelectPdfTemplate
              subjectId={id}
              subjectType={PreviewSubjectTypes.PROMOTION}
            />
            <Button
              onClick={onEditButtonClick}
              variant="contained"
              disableElevation
              startIcon={<EditIcon color={'#fff'} />}
            >
              {t('common:editInfos')}
            </Button>
          </>
        ) : null}
        {props.archive ? (
          <>
            <Button
              variant={'outlined'}
              className={'flex gap-2'}
              onClick={() => setViewModalArchivedLeads(true)}
            >
              <ArchiveOutlinedIcon
                fontSize="small"
                sx={{
                  width: '16px',
                  height: '14px',
                }}
              />
              <div className="font-medium">
                {' '}
                {t('propertiesPage:archived_leads')}{' '}
              </div>
            </Button>
            {viewModalArchivedLeads && (
              <ViewArchivedLeads
                id={Number(props.id)}
                isModalOpen={viewModalArchivedLeads}
                setIsModalOpen={setViewModalArchivedLeads}
                isPromotion
              />
            )}
          </>
        ) : null}
      </section>
    </Box>
  );
}

export default SinglePromotionHeader;
