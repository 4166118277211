import { IPropertyApi } from '../../types/property';
import { IMedia } from '../../types/api';
import PropertyAvatar from './propertyAvatar';

const PropertyFirstImage = ({
  row,
  fullScalePicture = false,
}: {
  row: IPropertyApi;
  fullScalePicture?: boolean;
}) => {
  const firstImageUrl = useFirstPropertyImageUrl(
    Array.isArray(row.media) ? undefined : row?.media?.images
  );

  return (
    <PropertyAvatar
      row={row}
      defaultImage={firstImageUrl || '/img/default_image.jpg'}
      fullScalePicture={fullScalePicture}
    />
  );
};

export default PropertyFirstImage;

export function useFirstPropertyImageUrl(images?: IMedia[]) {
  if (!images || !Array.isArray(images)) {
    return null;
  }
  const mainImages = images.filter(
    ({ type, isMain }) => type.includes('image') && isMain
  );
  return mainImages.length > 0 ? mainImages[0]?.url?.thumbnail : null;
}
