import { countries } from '../../../const/countries';
import useLocale from '../../../hooks/useLocale';
import { Controller } from 'react-hook-form';
import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import { useState } from 'react';
import {
  IAgentInCharge,
  IContactCompany,
  IOffice,
} from '../../../types/contacts';
import { useNotification } from '../../../hooks/useNotification';
import { AxiosResponse } from 'axios';
import { http } from '../../../libs/axios';
import Autocomplete from '@mui/material/Autocomplete';
import InputLayout from '../../../components/form/components/inputLayout';
import { TextareaAutosize } from '@mui/base';
import CreateContactWrapper from './wrapper';
import MultiplePhoneNumbers from './multiple-phone-numbers';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { getOptionsByLanguage } from '../../../const/propertiesOptions';
import InputFormField from '../../../components/form/inputFormField';
import { selectOptionStringT } from '../../../components/form/selectFormField';
import { AutocompleteOption } from '../../../components/form/autoCompleteField';
import { ExtendedIcountry } from '../components/forms/individual-form-handler';
import { updateHistoric } from '../../../helpers/utils/contacts';
import IndividualAgent from './individual-agent';
import { useProtectedForm } from '../../../hooks/useProtectedForm';
import { useTranslation } from 'react-i18next';

export interface InitialValues {
  legalName: string;
  description?: string;
  email?: string;
  phone?: string;
  website?: string;
  business?: number | string | null;
  office?: { id: number | string; label: string; office: number } | null;
  agentInCharge?: { id: number | string; label: string } | null;
  mainContactPerson?: string;
  emailContactPerson?: string;
  city?: string;
  adresse?: string;
  zipCode?: string;
  country?: { id: string; label: string } | null;
  phonesContactPerson?: { name: string; value: string }[];
}

const transformDataForFormUsage = (
  locale: string,
  data?: IContactCompany,
  idAgence?: number,
  nameAgence?: string,
  office?: IOffice,
  agentInCharge?: IAgentInCharge
) => {
  const initialValue: InitialValues = {
    legalName: '',
    description: '',
    email: '',
    phone: '',
    website: '',
    business: null,
    office: null,
    agentInCharge: null,
    mainContactPerson: '',
    emailContactPerson: '',
    city: '',
    adresse: '',
    zipCode: '',
    country: null,
    phonesContactPerson: [{ name: 'phone', value: '' }],
  };

  if (data) {
    const countryItem: ExtendedIcountry | null | undefined =
      data.address &&
      data.address.translations &&
      data.address.translations[locale]
        ? countries.find(
            (el: ExtendedIcountry) =>
              el[locale] === data.address?.translations?.[locale]?.country
          )
        : null;

    if (data.translations && data.translations[locale]) {
      initialValue.description = data.translations[locale]?.description;
    }

    if (
      data.address &&
      data.address.translations &&
      data.address.translations[locale]
    ) {
      initialValue.city = data.address.translations[locale]?.city;
      initialValue.adresse = data.address.translations[locale]?.adresse;
      initialValue.country = countryItem
        ? { id: String(countryItem.alpha2), label: countryItem[locale] }
        : null;
    }

    initialValue.legalName = data.legalName;
    initialValue.zipCode = data.address.zipCode;
    initialValue.email = data.email;
    initialValue.phone = data.phone;
    initialValue.website = data.website;
    initialValue.mainContactPerson = data.mainContactPerson;
    initialValue.emailContactPerson = data.emailContactPerson;
    if (data.phonesContactPerson && data.phonesContactPerson.length) {
      initialValue.phonesContactPerson = data.phonesContactPerson.map(
        ({ telephone }) => ({ name: 'phone', value: telephone })
      );
    }

    if (data.business) {
      initialValue.business = data.business.id;
    }

    if (idAgence && nameAgence && office) {
      initialValue.office = {
        id: idAgence.toString(),
        label: nameAgence,
        office: office.id,
      };
    }

    if (agentInCharge) {
      initialValue.agentInCharge = {
        id: agentInCharge.id,
        label: `${agentInCharge.firstname} ${agentInCharge.lastname}`,
      };
    }
  }

  return initialValue;
};

const CompanyView = (props: {
  isEditMode: boolean;
  id?: string;
  data?: IContactCompany;
  idAgence?: number;
  nameAgence?: string;
  office?: IOffice;
  agentInCharge?: IAgentInCharge;
  mutate?: any;
  archived?: boolean;
}) => {
  const {
    isEditMode,
    id,
    data,
    idAgence,
    nameAgence,
    office,
    agentInCharge,
    mutate,
    archived,
  } = props;
  const { locale } = useLocale();
  const { register, handleSubmit, control, watch } = useProtectedForm(
    {
      defaultValues: transformDataForFormUsage(
        locale,
        data,
        idAgence,
        nameAgence,
        office,
        agentInCharge
      ),
    },
    data
  );
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useNotification();
  const navigate = useNavigate();
  const watchLegalName = watch('legalName');
  const { t } = useTranslation('common');
  const countryOptions: AutocompleteOption[] = countries.map((country) => ({
    id: String(country.alpha2),
    label: country[locale],
  }));

  const { data: businesses } = useSWR(`/businesses`);

  const businessesOptions = getOptionsByLanguage(businesses || [], locale);

  const onSubmit = (data: any) => {
    const body = {
      company: {
        phone: data.phone,
        email: data.email,
        website: data.website,
        business: data.business,
        legalName: data.legalName,
        mainContactPerson: data.mainContactPerson,
        emailContactPerson: data.emailContactPerson,
        phonesContactPerson: data.phonesContactPerson
          .filter((item: { value: any }) => item.value)
          .map((item: { value: string }) => ({ telephone: item.value })),
        translations: {
          [`${locale}`]: {
            locale: locale,
            description: data.description,
          },
        },
        address: {
          zipCode: data.zipCode,
          translations: {
            [`${locale}`]: {
              locale: locale,
              country: data.country ? data.country.label : null,
              city: data.city,
              adresse: data.adresse,
            },
          },
        },
      },
      office: data.office ? parseInt(data.office.office) : null,
      agentInCharge: data.agentInCharge
        ? parseInt(data.agentInCharge.id)
        : null,
    };

    submitDataToServer(body);
  };

  const submitDataToServer = async (body: any) => {
    let promise: Promise<AxiosResponse>;
    if (isEditMode) {
      promise = http.patch(`/contacts/${id}`, body);
    } else {
      promise = http.post('/contacts/company', body);
    }

    setIsLoading(true);
    promise
      .finally(() => {
        setIsLoading(false);
      })
      .then(({ data }) => {
        if (isEditMode) {
          if (mutate) {
            mutate(data);
          }
          addNotification(
            `${t('Your_contact_has_been_successfully_updated')}`,
            'success'
          );
          updateHistoric(data.id, 'Company information has been updated');
          // navigate(0)
        } else {
          addNotification(
            `${t(
              'Contact_has_been_successfully_created_Keep_going_and_fill_the_rest_of_information'
            )}`
          );
          updateHistoric(data.id, 'Company has been added');
          navigate(`/contacts/${data.id}/contact-information`, {
            state: {
              forceHideConfirmationPopup: true,
            },
          });
        }
      })
      .catch((error) => {
        let message;
        if (error.response) {
          if (error.response.status === 474) {
            message = t('Email_Already_used');
          } else message = 'Server error';
        } else if (error.request) {
          message = t('Failed_to_connect_to_server');
        } else {
          message = t('Unknown_error');
        }
        addNotification(message, 'error');
      });
  };

  return (
    <CreateContactWrapper
      id={id}
      mutate={mutate}
      archived={archived}
      title={'Company information'}
      isLoading={isLoading}
      onSubmit={handleSubmit(onSubmit)}
      isEditMode={isEditMode}
      buttonDisabled={!(watchLegalName && watchLegalName.length)}
    >
      <>
        <InputFormField
          type="text"
          label={t('Legal_name')}
          isRequired
          {...register('legalName', { required: true })}
        />
        <InputLayout label={t('Business')}>
          <Controller
            name="business"
            control={control}
            render={({ field }) => (
              <Select
                className={'h-[56px]'}
                labelId="level-label"
                {...field}
                value={field.value as string}
              >
                {businessesOptions.map(
                  (item: selectOptionStringT, index: number) => (
                    <MenuItem
                      key={index}
                      value={item?.id}
                      className="capitalize"
                    >
                      {item.value}
                    </MenuItem>
                  )
                )}
              </Select>
            )}
          />
        </InputLayout>
        <InputLayout label={t('Description')}>
          <Controller
            name={'description'}
            control={control}
            defaultValue={''}
            render={({ field: { value, ...restField } }) => (
              <FormControl
                sx={{
                  border: '1px solid #c4c4c4',
                  borderRadius: '4px',
                  '&:hover': {
                    borderColor: '#1D1D1F',
                  },
                }}
              >
                <TextareaAutosize
                  minRows={4}
                  maxRows={6}
                  value={value}
                  className={
                    'MuiInputBase-input MuiOutlinedInput-input rounded p-4'
                  }
                  {...restField}
                />
              </FormControl>
            )}
          />
        </InputLayout>
        <InputFormField
          type="number"
          label={t('Phone')}
          {...register('phone')}
        />
        <InputFormField
          type="email"
          label={t('Email')}
          {...register('email')}
        />
        <InputFormField
          type="url"
          label={t('Website')}
          {...register('website')}
        />
        <div className={'col-start-1 col-end-3'}>
          <InputFormField
            type="text"
            label={t('Address')}
            {...register('adresse')}
          />
        </div>
        <InputFormField
          type="number"
          label={t('Postal_code')}
          {...register('zipCode')}
        />
        <InputFormField type="text" label={t('City')} {...register('city')} />
        <InputLayout label={t('Country')}>
          <Controller
            control={control}
            name="country"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, item) => {
                  onChange(item);
                }}
                value={value}
                options={countryOptions}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} />
                )}
              />
            )}
          />
        </InputLayout>
        <div />
        <InputFormField
          type="text"
          label={t('Main_contact_person')}
          {...register('mainContactPerson')}
        />
        <InputFormField
          type="email"
          label={t('Email')}
          {...register('emailContactPerson')}
        />
        <MultiplePhoneNumbers
          register={register}
          control={control}
          name={'phonesContactPerson'}
        />
        <IndividualAgent control={control} />
      </>
    </CreateContactWrapper>
  );
};

export default CompanyView;
